


import React, { useEffect, useState, useRef } from 'react'
import { Card, Modal, Form, Row, Col } from 'react-bootstrap'
import Loading from '../../helpers/Loader/loader';
import apiManager from './../../helpers/apiManager';
import { AiOutlineInfoCircle } from 'react-icons/ai'
import {useSelector} from 'react-redux';
import { customData } from '../../helpers/constants';
import AdminStyleWrapper from './admin.style';
import { useNavigate } from 'react-router';
import { PRIVATE_ROUTE } from '../../helpers/routerConstants';
import {useLocation} from 'react-router';

const DDInfo = () => {
  const location = useLocation();
   const navigate = useNavigate();
    const externalId = useSelector((state) => state.app.externalId);
    const uid = useSelector((state) => state.app.uid);
    const applicationId = useSelector((state) => state.app.applicationId);
    const [loading, setLoading] = useState(false);
    const [errorInfo, setErrorInfo] = useState('');
    const [successInfoShow, setSuccessInfoShow] = useState('');
    const [serverError, setServerError] = useState('');
    const [ddRemarks, setDDRemarks] = useState('');
    const [partners, setPartners]=useState([]);
    const [formValues, setFormValues] = useState({
        structuringPartner: '',
        nextStep: 'Proceed to Structuring',
        adminRemarks: ''
    })
  
    useEffect(() =>{
      if(partners.length > 0){
        setFormValues({
          ...formValues,
          structuringPartner: partners[0].externalId
        })
    }
    },[partners])
    useEffect(() =>{
      const data = {
        ApplicationId: applicationId, 
          InstrumentId: 101
        };
        const data_ = {"BusinessRoles": [location.state.accountRole]};
      apiManager
            .getApplicationData(data)
            .then((response) => {
              const ddApprovalInfo = response.data.length > 0 && response.data.find(instrument => instrument.InstrumentId === 101).DuediligenceInfo;
              setDDRemarks(ddApprovalInfo.Remarks ? ddApprovalInfo.Remarks : '');
              setLoading(false);
              // localStorage.setItem('categories', JSON.stringify(response.data.data));
            })
            .catch((error) => {
              setServerError(true);
              setLoading(false);
            });
            apiManager
            .getProfileData(data_, uid)
            .then((response) => {
              const tempPartners = response.data.length > 0 && response.data.map((comp, idx) =>{
                return(
                  {
                    value: comp.CompanyInfo.Name,
                    externalId: comp.CompanyInfo.ExternalId
                  }
                )
              })
              // const tempPartners = response.data.find(instrument => instrument.InstrumentId === '101').FormFields;
              setPartners(tempPartners);
              setLoading(false);
              // localStorage.setItem('categories', JSON.stringify(response.data.data));
            })
            .catch((error) => {
              setServerError(true);
            });
    },[])
    const handleChange = (event) => {
      const {name, value} = event.target;
      setFormValues({ ...formValues, [name]: value })
    }
    // populate file data
    // console.log( sukukData.ApplicationInfo, 'country code')
  
    const submitInfo = (event) => {
      event.preventDefault();
      const {structuringPartner, adminRemarks} = formValues;
      setLoading(true);
      const data ={
        "StructuringExternalId": structuringPartner,
        "ApplicationId": applicationId,
        "StructuringAssignment": {
          "Status" : 1,
          "AssignedBy": externalId,   
          "Remarks" : adminRemarks
       }
        };
      apiManager.associateStructuringPartner(data, uid)
      .then((res) => {
        setLoading(false);
        setSuccessInfoShow(true);
        setTimeout(()=>{
          setSuccessInfoShow(false)
          navigate('/qadmin'+PRIVATE_ROUTE.NEWDDREQUESTS);
        }, 2000);
      })
      .catch((err) => {
        setServerError(true);
        setLoading(false);
        setErrorInfo(err.res.data.Error.errorMessage);
        setTimeout(()=>{
          setServerError(false)
        }, 2000);
      })
    }
    const {structuringPartner, nextStep, adminRemarks} =  formValues;
    return (
        <AdminStyleWrapper>
      <div className="layout layout-horizontal">
        <div>
            <Card className="sukuk-info-card border-0">
              <Card.Body>
                  <Card.Text>
                          <>
                          {loading && <Loading />}
                        <div className="px-3">
                            <Row className="mb-3 fieldsSpacer">
                                <Col className="p-0">
                                    <label className='w-100 text-start form-label'>Remarks from Due Diligence Partner</label>
                                    <textarea className='w-100' rows={5} defaultValue={ddRemarks} disabled={true}/>
                                </Col>
                            </Row>
                            <p className="sukuk-document-title">{'Next Action'}</p>
                          <Form className="fieldsSpacer">
                            <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail" size="lg">
                              <Form.Label column sm={12} className="text-start m-0 form-label">
                              Next Steps
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                                <Form.Select aria-label="Default select example" onChange={handleChange} 
                                name="nextStep" style={{ height: '45px', width: '100%', borderColor: '#ced4da' }} 
                                value={nextStep}
                                className={JSON.stringify(nextStep).length > 0 ? 'highlight' : ''}
                                >
                                  {/* <option key ="" >Select Action</option> */}
                                  <option key ="Revert to Business" >Revert to Business</option>
                                  <option key ="Revert to Due Diligence" >Revert to Due Diligence</option>
                                  <option key ="Proceed to Structuring" >Proceed to Structuring</option>
                                  <option key ="Close Sukuk Request" >Close Sukuk Request</option>
                                  <option key="Decline Sukuk Request (No-go)" >Decline Sukuk Request (No-go)</option>
                                </Form.Select>
                                
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail" size="lg">
                              <Form.Label column sm={12} className="text-start m-0 form-label">
                              Select Structuring Partner
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                                <Form.Select aria-label="Default select example" onChange={handleChange} 
                                name="structuringPartner" style={{ height: '45px', width: '100%', borderColor: '#ced4da' }} 
                                value={structuringPartner}
                                className={JSON.stringify(structuringPartner).length > 0 ? 'highlight' : ''}
                                >
                                {partners.length > 0 && partners.map(partner => {
                                  return(
                                    <option value={partner.externalId}>{partner.value}</option>
                                  )
                                })}
                                  {/* <option key ="rtb" >Revert to Business</option>
                                  <option key ="pts" >Proceed to Structuring</option>
                                  <option key="dsr" >Decline Sukuk Request (No-go)</option> */}
                                </Form.Select>
                                
                              </Col>
                            </Form.Group>
                            
                            <Form.Group>
                              <Form.Label as="legend" column sm={12} className="form-label">
                              Remarks
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                              <Form.Control
                                as="textarea"
                                placeholder=""
                                name="adminRemarks"
                                value={adminRemarks}
                                onChange={handleChange}
                                className={adminRemarks.length > 0 ? 'highlight' : ''} 
                                style={{height: '100px'}}
                              />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                                {/* <Link to="/dashboard/new-sukuk/info"> */}
                                {/* </Link> */}
                                {/* <Link to="/dashboard/new-sukuk/documents"> */}
                                  <button onClick={(e) => submitInfo(e)} className="custom-button">Submit</button>
                                {/* </Link> */}
                              </Col>
                            </Form.Group>
                          </Form>
  
                        </div>
                        </>
  
                  </Card.Text>
              </Card.Body>
            </Card>
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={successInfoShow}
          onHide={() => setSuccessInfoShow(false)}
        >
          {/* Sucesss Modal  */}
  
          <Modal.Body closeButton>
            <div className="center-title">
              <br></br>
              <h4>Submitted Successfully</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
  
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={serverError}
          onHide={() => setServerError(false)}
        >
          {/* Error Modal  */}
  
          <Modal.Body>
            <div className="center-title">
              <br></br>
              <h4> {errorInfo.length > 0 ? errorInfo : 'Something went wrong, please reload the page.'}</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
      </AdminStyleWrapper>
    )
  }


  export default DDInfo;