import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import apiManager from '../../helpers/apiManager';
import { customData } from '../../helpers/constants';
import { PRIVATE_ROUTE } from '../../helpers/routerConstants'


const types = ['Corporate', 'Sovereign', 'Quasi-sovereign'];
const structuringTypes = ['Musharakah', 'Ijara', 'Mudarabah', 'Murabaha', 'Wakala', 'Salam'];
const period = ['Monthly', 'Quarterly', 'Half Yearly', 'Yearly'];
const structuringActions = ['Structuring initiated', 'Revert to Business', 'Proceed to SPV', 'Decline Sukuk Listing', 'Close Sukuk'];
const RequestForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const applicationId = useSelector(state => state.app.applicationId);
  const uid = useSelector(state => state.app.uid);
  const selectedSukukInfo = useSelector(state => state.app.selectedSukukInfo);
  const [saveLoading, setSaveLoading] = useState(false);
  const [successInfoShow, setSuccessInfoShow] = useState(false);
  const [error, setError] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [serverError, setServerError] = useState(false);
  const [values, setValues] = useState({
    currencyOfSukuk: customData.DEFAULT_CURRENCY,
    sukukSize: '',
    sukukTenure: '',
    sukukType: '',
    sukukStructuringType: structuringTypes[0],
    sukukYieldPeriodicity: period[0],
    sukukYield: '',
    investorShare: '',
    currencyOfRental: '',
    rentalAmount: '',
    creationDate: '',
    yieldStartDate: '',
    currencyOfFaceValue: customData.DEFAULT_CURRENCY,
    FaceValueAmount: '',
    subscriptionStartDate: '',
    subscriptionEndDate: '',
    currencyOfHardCap: '',
    subscriptionType: 'overSubscription',
    isOversubscription: 'Yes',
    hardCapAmount: '',
    overSubscription: 'hardCap',
    underSubscription: 'extendDate',
    extendDays: '',
    extensionsNumbers: '',
    dueDiligenceRemarks: '',
    structuringAction: '',
    currencyOfStructuringFee: customData.DEFAULT_CURRENCY,
    structuringFee: '',
    structuringRemarks: '',
    companyName: '',
    country: '',
    sukukName: ''
  });
  const [validated, setValidated] = useState(false);
  const [currencyCodes, setCurrencyCodes] = useState([]);

  useEffect(() => {
    callCurrencyCodeLookup();
    const info = selectedSukukInfo;
    setSukukInfo(info);
  }, [selectedSukukInfo])
  const setSukukInfo = (info) => {
    const { ApplicationInfo, Account, Country, ApplicationName } = info;
    setValues({
      ...values,
      currencyOfSukuk: ApplicationInfo ? ApplicationInfo.SukukIssuanceCurrency : customData.DEFAULT_CURRENCY,
      sukukSize: ApplicationInfo ? ApplicationInfo.SukukIssuanceSize : '',
      sukukTenure: ApplicationInfo ? ApplicationInfo.SukukTenureInMonths : '',
      companyName: Account ? Account : '',
      country: Country ? Country : '',
      sukukName: ApplicationName ? ApplicationName : ''
    })
  }
  const callCurrencyCodeLookup = () => {
    const currencyCodesdata = {
      "Currency": [],
      "CurrencyCode": customData.ISO_CURRENCY_CODES,
    }
    const storedCurrencyCodes = JSON.parse(localStorage.getItem('isoCurrencyCodes'));
    if (storedCurrencyCodes) {
      setCurrencyCodes(storedCurrencyCodes);
    }
    else {
      apiManager.getISOCurrencyCodes(currencyCodesdata)
        .then((response) => {
          setCurrencyCodes(response.data);
          localStorage.setItem('isoCurrencyCodes', JSON.stringify(response.data));
        })
        .catch((error) => { });
    }
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === true) {
      const data = {
        "ApplicationId": applicationId,
        "StructuringInfo": {
          "Status": "Active",
          "StatusDate": "2022-07-03 10:10:10",
          "IssuanceSize": sukukSize,
          "IssuanceCurrency": currencyOfSukuk,
          "TenureInMonths": sukukTenure,
          "InstrumentType": sukukType,
          "InstrumentStructuringType": sukukStructuringType,
          "InstrumentCreationDate": creationDate,
          "InstrumentYield": {
            "YieldType": "CouponRate",
            "Periodicity": sukukYieldPeriodicity,
            "Value": parseFloat(sukukYield),
            "ValueUOM": sukukStructuringType === 'Ijara' ? "FixedAmount" : "Percentage",
            "ValueCurrency": currencyOfSukuk,
            "StartDate": yieldStartDate,
            "InvestorShareValue": investorShare,
            "InvestorShareUOM": "Percentage",
          },
          // to do
          //   "InvestorShare": {
          //     // "YieldType" : "CouponRate",
          //     "Periodicity" : sukukYieldPeriodicity,
          //     "Value"       : parseInt(investorShare),
          //     "ValueUOM":    "Percentage",
          //     "ValueCurrency" : currencyOfSukuk,
          //     "StartDate": yieldStartDate,
          // },
          "Subscription": {
            "FaceValueAmount": parseInt(FaceValueAmount),
            "FaceValueCurrency": currencyOfFaceValue,
            "StartDate": subscriptionStartDate,
            "EndDate": subscriptionEndDate,
            "OversubscriptionSettings": {
              "ToHardCap": isOversubscription === 'Yes' ? overSubscription === 'hardCap' : null,
              "HardCapAmount": isOversubscription === 'Yes' ? parseInt(hardCapAmount) : null,
              "HardCapAmountCurrency": isOversubscription === 'Yes' ? currencyOfSukuk : null
            },
            "UndersubscriptionSettings": {
              "ToExtendSubscriptionPeriod": underSubscription === 'extendDate',
              "NumberOfDaysToExtend": parseInt(extendDays),
              "MaxNumberOfExtensions": parseInt(extensionsNumbers)
            },
            "FullySubscribedSetting": {
              "ToStopSubscription": true,
              "ToAutoCreateContract": true
            }
          }
        },
        "StructuringApproval": {
          "Remarks": structuringRemarks,
          "Payment": parseInt(structuringFee === '' ? 0 : structuringFee),
          "PaymentCurrency": currencyOfStructuringFee
        }
      }
      apiManager.strucutingApprove(data, uid)
        .then((res) => {
          setSaveLoading(false);
          setSuccessInfoShow(true);
          setTimeout(() => {
            setSuccessInfoShow(false);
            navigate('/structuring' + PRIVATE_ROUTE.NEWSUKUKREQUEST);
          }, 2000);
        })
        .catch((err) => {
          setServerError(true);
          setSaveLoading(false);
          setErrorInfo(err.res.data.Error.errorMessage);
          setTimeout(() => {
            setServerError(false)
          }, 2000);
        })
    }
    setValidated(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }
  const { currencyOfSukuk, sukukSize, sukukTenure, sukukType, sukukStructuringType, sukukYieldPeriodicity,
    sukukYield, investorShare, currencyOfRental, rentalAmount, creationDate, yieldStartDate, currencyOfFaceValue, FaceValueAmount,
    subscriptionStartDate, subscriptionEndDate, currencyOfHardCap, subscriptionType, hardCapAmount, overSubscription, isOversubscription,
    underSubscription, extendDays, extensionsNumbers, dueDiligenceRemarks, structuringAction, currencyOfStructuringFee, structuringFee, structuringRemarks, companyName, country, sukukName } = values;
  return (
    <div className='Container_ structuringForm'>
      <div className="header_ text-start">
        <h4>New Structuring Request</h4>
      </div>
      <Row>
        <Col className="d-flex text-start">
          <label>Sukuk Name: &nbsp;</label>
          <h5 className='text-start' style={{ marginRight: 10 }}>{sukukName}</h5>
        </Col>
        <Col className="d-flex text-start">
          <label>Company Name: &nbsp;</label>
          <h5 style={{ marginRight: 10 }}>{companyName}</h5>
          <span>{country}</span>
        </Col>
      </Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className='sukuk-document-title text-start px-3 my-3'>Issuance</div>
        <div style={{
          // maxWidth: '996px',
          // margin: '0 auto'
        }}>
          <Form.Group as={Col} md="6" className="mb-4" controlId="validationSukukSize">
            <Form.Label>Sukuk Issuance Size<span className='mandatory'>*</span></Form.Label>
            <div className="d-flex">
              <Form.Select
                // disabled={true}
                required
                name="currencyOfSukuk"
                value={currencyOfSukuk}
                onChange={handleChange}
                className={currencyOfSukuk && currencyOfSukuk.length > 0 ? 'highlight' : ''}
                style={{ width: 130, marginRight: 10 }}
              >
                <option value="" disabled defaultValue>
                  Select Currency
                </option>
                {currencyCodes.length > 0 && currencyCodes.map((country) => (
                  <option key={country.CurrencyCode} value={country.CurrencyCode}>{country.CurrencyCode}</option>
                ))}
              </Form.Select>
              <Form.Control
                // disabled={true}
                name="sukukSize" value={sukukSize} onChange={handleChange} required type="number" placeholder="0.00"
                className={sukukSize && sukukSize.length > 0 ? 'highlight text-end' : 'text-end'} />
            </div>
            <Form.Control.Feedback type="invalid" className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" className="mb-4" controlId="validationSukukTenure">
            <Form.Label>Sukuk Tenure (in Months)<span className='mandatory'>*</span></Form.Label>
            <Form.Control
              // disabled={true}
              name="sukukTenure" value={sukukTenure} onChange={handleChange} required type="number" placeholder="0"
              className={sukukTenure && sukukTenure.length > 0 ? 'highlight text-end' : 'text-end'} />
            <Form.Control.Feedback type="invalid" className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" className="mb-4" controlId="validationSukukType">
            <Form.Label>Sukuk Type<span className='mandatory'>*</span></Form.Label>
            <Form.Select
              required
              name="sukukType"
              value={sukukType}
              onChange={handleChange}
              className={sukukType && sukukType.length > 0 ? 'highlight' : ''}
            >
              {types.map((type, idx) => (
                <option key={idx} value={type}>{type}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" className="mb-4" controlId="validationSukukStructuringType">
            <Form.Label>Sukuk Structuring Type<span className='mandatory'>*</span></Form.Label>
            <Form.Select
              required
              name="sukukStructuringType"
              value={sukukStructuringType}
              onChange={handleChange}
              className={sukukStructuringType.length > 0 ? 'highlight' : ''}
            >
              {structuringTypes.map((type, idx) => (
                <option key={idx} value={type}>{type}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" className="mb-4" controlId="validationYieldPeriodicity">
            <Form.Label>Yield Periodicity<span className='mandatory'>*</span></Form.Label>
            <Form.Select
              required
              name="sukukYieldPeriodicity"
              value={sukukYieldPeriodicity}
              onChange={handleChange}
              className={sukukYieldPeriodicity.length > 0 ? 'highlight' : ''}
            >
              {period.map((time, idx) => (
                <option key={idx} value={time}>{time}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
          {sukukStructuringType === "Ijara" ? <Form.Group as={Col} md="6" className="mb-4" controlId="validationSukukRentalAmount">
            <Form.Label>Rental Amount<span className='mandatory'>*</span></Form.Label>
            <div className="d-flex">
              <Form.Select
                required
                name="currencyOfRental"
                value={currencyOfRental}
                onChange={handleChange}
                className={currencyOfRental.length > 0 ? 'highlight' : ''}
                style={{ width: 130, marginRight: 10 }}
              >
                {currencyCodes.length > 0 && currencyCodes.map((country) => (
                  <option key={country.CurrencyCode} value={country.CurrencyCode}>{country.CurrencyCode}</option>
                ))}
              </Form.Select>
              <Form.Control name="rentalAmount" value={rentalAmount} onChange={handleChange} required type="number" placeholder="0.00"
                className={rentalAmount.length > 0 ? 'highlight text-end' : 'text-end'} />
            </div>
            <Form.Control.Feedback type="invalid" className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group> :
            <>
              <Col md={6} className="d-flex justify-content-between">
                <Form.Group style={{ width: 'calc(50% - 10px)' }} className="mb-4" controlId="validationSukukYield">
                  <Form.Label>Projected Yield %<span className='mandatory'>*</span></Form.Label>
                  <Form.Control name="sukukYield" style={{ marginRight: 15 }} value={sukukYield} onChange={handleChange} required type="number" placeholder="0"
                    className={sukukYield.length > 0 ? 'highlight text-start' : 'text-start'} />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    Please enter the value.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group style={{ width: 'calc(50% - 10px)' }} className="mb-4" controlId="validationinvestorShare">
                  <Form.Label>Investor Share %<span className='mandatory'>*</span></Form.Label>
                  <Form.Control name="investorShare" value={investorShare} onChange={handleChange} required type="number" placeholder="0"
                    className={investorShare.length > 0 ? 'highlight text-start' : 'text-start'} />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    Please enter the value.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </>
          }
          <Form.Group as={Col} md="6" className="mb-4" controlId="validationSukukCreationDate">
            <Form.Label>Sukuk Creation Date<span className='mandatory'>*</span></Form.Label>
            <Form.Control
              type="date"
              name="creationDate"
              placeholder="01/May/2022"
              onChange={handleChange}
              value={creationDate}
              required
            />
            <Form.Control.Feedback type="invalid" className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" className="mb-4" controlId="validationYieldStartDate">
            <Form.Label>Yield Start Date<span className='mandatory'>*</span></Form.Label>
            <Form.Control
              type="date"
              name="yieldStartDate"
              placeholder="01/May/2022"
              onChange={handleChange}
              value={yieldStartDate}
              required
            />
            <Form.Control.Feedback type="invalid" className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className='sukuk-document-title text-start px-3 my-3'>Subscription</div>
        <div style={{
          // maxWidth: '996px',
          // margin: '0 auto'
        }}>
          <Form.Group as={Col} md="6" className="mb-4" controlId="validationSukukFaceValue">
            <Form.Label>Face Value (Minimum Subscription Amount)<span className='mandatory'>*</span></Form.Label>
            <div className="d-flex">
              <Form.Select
                required
                name="currencyOfFaceValue"
                value={currencyOfFaceValue}
                onChange={handleChange}
                className={currencyOfFaceValue.length > 0 ? 'highlight' : ''}
                style={{ width: 130, marginRight: 10 }}
              >
                {currencyCodes.length > 0 && currencyCodes.map((country) => (
                  <option key={country.CurrencyCode} value={country.CurrencyCode}>{country.CurrencyCode}</option>
                ))}
              </Form.Select>
              <Form.Control name="FaceValueAmount" value={FaceValueAmount} onChange={handleChange} required type="number" placeholder="0.00"
                className={FaceValueAmount.length > 0 ? 'highlight text-end' : 'text-end'} />
            </div>
            <Form.Control.Feedback type="invalid" className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
          <Row className="mb-4">
            <Form.Group as={Col} md="6" className="mb-4" controlId="validationSubscriptionStartDate">
              <Form.Label>Subscription Start Date<span className='mandatory'>*</span></Form.Label>
              <Form.Control
                type="date"
                name="subscriptionStartDate"
                placeholder="01/May/2022"
                onChange={handleChange}
                value={subscriptionStartDate}
                required />
              <Form.Control.Feedback type="invalid" className="text-start">
                Please enter the value.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" className="mb-4" controlId="validationSubscriptionEndDate">
              <Form.Label>Subscription End Date<span className='mandatory'>*</span></Form.Label>
              <Form.Control
                type="date"
                name="subscriptionEndDate"
                placeholder="01/May/2022"
                onChange={handleChange}
                value={subscriptionEndDate}
                required
              />
              <Form.Control.Feedback type="invalid" className="text-start">
                Please enter the value.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {/* <Card className="">
            <Card.Body className="">
              <Card.Text>
                <Row>
                  <Form.Group as={Col} md="4" className="mb-4 text-start">
                    <div className="d-inline-block">
                      <div className="radio">
                        <label>
                          <input type="radio" onChange={handleChange} value="overSubscription" name="subscriptionType" checked={subscriptionType === "overSubscription"} />
                          Oversubscription
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="mb-4 text-start">
                    <div className="d-inline-block">
                      <div className="radio">
                        <label>
                          <input type="radio" name="subscriptionType" value="underSubscription" onChange={handleChange} checked={subscriptionType === "underSubscription"} />
                          Undersubscription
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                </Row>
                {subscriptionType === "overSubscription" ? (<Row>
                  <Form.Group as={Col} md="4" className="text-start">
                    <div className="d-inline-block">
                      <div className="radio">
                        <label>
                          <input type="radio" name="overSubscription" value="hardCap" onChange={handleChange} checked={overSubscription === "hardCap"} />
                          Hardcap
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="text-start">
                    <div className="d-inline-block">
                      <div className="radio">
                        <label>
                          <input type="radio" name="overSubscription" value="noHardCap" onChange={handleChange} checked={overSubscription === "noHardCap"} />
                          No Hard Cap
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                </Row>) : (<Row>
                  <Form.Group as={Col} md="4" className="text-start">
                    <div className="d-inline-block">
                      <div className="radio">
                        <label>
                          <input type="radio" name="underSubscription" value="extendDate" onChange={handleChange} checked={underSubscription === "extendDate"} />
                          Extend Date
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="text-start">
                    <div className="d-inline-block">
                      <div className="radio">
                        <label>
                          <input type="radio" name="underSubscription" value="closeSukuk" onChange={handleChange} checked={underSubscription === "closeSukuk"} />
                          Close Sukuk
                        </label>
                      </div>
                    </div>
                  </Form.Group></Row>
                )}
                <Row>
                  {subscriptionType === "overSubscription" && overSubscription === "hardCap" && <Form.Group as={Col} md="6" className="mb-4">
                    <div className="d-flex">
                      <Form.Select
                        required
                        name="currencyOfHardCap"
                        value={currencyOfHardCap}
                        onChange={handleChange}
                        className={currencyOfHardCap.length > 0 ? 'highlight' : ''}
                        style={{ width: 130, marginRight: 10 }}
                      >
                        {currencyCodes.length > 0 && currencyCodes.map((country) => (
                          <option key={country.CurrencyCode} value={country.CurrencyCode}>{country.CurrencyCode}</option>
                        ))}
                      </Form.Select>
                      <Form.Control name="hardCapAmount" value={hardCapAmount} onChange={handleChange} required type="number" placeholder="0.00"
                        className={hardCapAmount.length > 0 ? 'highlight text-end' : 'text-end'} />
                    </div>
                    <Form.Control.Feedback type="invalid"  className="text-start">
                      Please enter the value.
                    </Form.Control.Feedback>
                  </Form.Group>
                  }
                  {subscriptionType === "underSubscription" && underSubscription === "extendDate" && <Form.Group as={Col} md="6" className=''>
                    <div className="d-flex align-items-center">
                      <Form.Label style={{ width: 335, marginBottom: 0 }}>Extend Dates By (number of days)<span className='mandatory'>*</span>:</Form.Label>
                      <Form.Control style={{ width: 100 }} name="extendDays" value={extendDays} onChange={handleChange} required type="number" placeholder="0"
                        className={extendDays.length > 0 ? 'highlight text-end' : 'text-end'} />
                    </div>
                  </Form.Group>}
                </Row>
              </Card.Text>
            </Card.Body>
          </Card> */}
          <Row className="subscriptionCards">
            <Col sm={4}>
              <Card className="">
                <Card.Body className="">
                  <Card.Text>
                    <Row>
                      <label className='mb-3 text-start' style={{ fontWeight: 600 }}>
                        Fully Subscribed
                      </label>
                    </Row>
                    <Row>
                      <Form.Group className="text-start mb-3">
                        <div className="d-flex align-items-center" style={{ flexWrap: 'wrap' }}>
                          <Form.Label style={{ width: 192, fontSize: 16 }}>Enable Oversubscription<span className='mandatory'>*</span></Form.Label>
                          <Form.Select
                            name="isOversubscription"
                            value={isOversubscription}
                            onChange={handleChange}
                            className={isOversubscription.length > 0 ? 'highlight' : ''}
                            style={{ width: 80, padding: '0 10px', backgroundImage: 'none' }}
                          >
                            {/* {currencyCodes.length > 0 && currencyCodes.map((country) => ( */}
                            <option key={'yes'} value="Yes">Yes</option>
                            <option key={'no'} value="No">No</option>
                            {/* ))} */}
                          </Form.Select>
                        </div>
                      </Form.Group>
                      {/*<Form.Group as={Col} md="4" className="text-start">
                    <div className="d-inline-block">
                      <div className="radio">
                        <label>
                          <input type="radio" name="overSubscription" value="noHardCap" onChange={handleChange} checked={overSubscription === "noHardCap"} />
                          No Hard Cap
                        </label>
                      </div>
                    </div>
                  </Form.Group> */}
                    </Row>
                    <Row>
                      <p className='mb-2 pb-1 text-start' style={{ fontSize: 14 }}>If oversubscription is not enabled, then the
                        subscription amount will be capped exactly at the
                        issuance size amount</p>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4}>
              <Card>
                <Card.Body className="">
                  {isOversubscription === 'No' && <div className="overlay"></div>}
                  <Card.Text>
                    <Row>
                      <label className="mb-3" style={{ textAlign: 'left', fontWeight: 600 }}>
                        Oversubscription
                      </label>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} sm={12} lg={12} xl={5} className="text-start">
                        <div className="d-inline-block">
                          <div className="radio">
                            <label style={{ fontSize: 16 }}>
                              <input type="radio" name="overSubscription" value="hardCap" onChange={handleChange} checked={overSubscription === "hardCap"} />
                              Hardcap
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group as={Col} sm={12} lg={12} xl={7} className="text-start">
                        <div className="d-inline-block">
                          <div className="radio">
                            <label style={{ fontSize: 16 }}>
                              <input type="radio" name="overSubscription" value="noHardCap" onChange={handleChange} checked={overSubscription === "noHardCap"} />
                              No Hard Cap
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </Row>
                    <Row style={{ minHeight: 83 }}>
                      {overSubscription === "hardCap" && <Form.Group className="mb-4">
                        <Form.Label style={{ fontSize: 14, }} className="text-start">Hardcap Amount (Total)</Form.Label>
                        <div className="d-flex">
                          {/* <Form.Select
                        required
                        name="currencyOfHardCap"
                        value={currencyOfHardCap}
                        onChange={handleChange}
                        className={currencyOfHardCap.length > 0 ? 'highlight' : ''}
                        style={{ width: 130, marginRight: 10 }}
                      >
                        {currencyCodes.length > 0 && currencyCodes.map((country) => (
                          <option key={country.CurrencyCode} value={country.CurrencyCode}>{country.CurrencyCode}</option>
                        ))}
                      </Form.Select> */}
                          <Form.Control style={{ height: 30 }} name="hardCapAmount" value={hardCapAmount} onChange={handleChange} required type="number" placeholder="0.00"
                            className={hardCapAmount.length > 0 ? 'highlight text-end' : 'text-end'} />
                        </div>
                        {hardCapAmount.length > 0 && hardCapAmount < sukukSize && <Form.Control.Feedback className="text-start">
                          please enter the Amount more than sukuk size.
                        </Form.Control.Feedback>
                        }
                        <Form.Control.Feedback type="invalid" className="text-start">
                          Please enter the value.
                        </Form.Control.Feedback>
                      </Form.Group>}
                    </Row>


                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4}>
              <Card className="">
                <Card.Body className="">
                  <Card.Text>
                    <Row>
                      <label className='mb-3' style={{ textAlign: 'left', fontWeight: 600 }}>
                        Undersubscription
                      </label>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} sm={12} lg={12} xl={6} className="text-start">
                        <div className="d-inline-block">
                          <div className="radio">
                            <label style={{ fontSize: 16 }}>
                              <input type="radio" name="underSubscription" value="extendDate" onChange={handleChange} checked={underSubscription === "extendDate"} />
                              Extend Date
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group as={Col} sm={12} lg={12} xl={6} className="text-start">
                        <div className="d-inline-block">
                          <div className="radio">
                            <label style={{ fontSize: 16 }}>
                              <input type="radio" name="underSubscription" value="closeSukuk" onChange={handleChange} checked={underSubscription === "closeSukuk"} />
                              Close Sukuk
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </Row>
                    <Row style={{ minHeight: 83 }}>
                      {underSubscription === "extendDate" && <>
                        <div className="d-flex align-items-center mb-2">
                          <Form.Label style={{ width: 335, marginBottom: 0, fontSize: 14 }}>Extend(number of days)<span className='mandatory'>*</span>:</Form.Label>
                          <Form.Control style={{ width: 60, height: 30 }} name="extendDays" value={extendDays} onChange={handleChange} required type="number" placeholder="0"
                            className={extendDays.length > 0 ? 'highlight text-end' : 'text-end'} />
                        </div>
                        <div className="d-flex align-items-center mb-3">
                          <Form.Label style={{ width: 335, marginBottom: 0, marginRight: 5, fontSize: 14 }}>Number of Extensions<span className='mandatory'>*</span>:</Form.Label>
                          {/* <Form.Control style={{ width: 60, height: 30 }} name="extensionsNumbers" value={extensionsNumbers} onChange={handleChange} required type="number" placeholder="0"
                        className={extensionsNumbers.length > 0 ? 'highlight text-end' : 'text-end'} /> */}
                          <Form.Select
                            name="extensionsNumbers"
                            value={extensionsNumbers}
                            onChange={handleChange}
                            className={extensionsNumbers.length > 0 ? 'highlight' : ''}
                            style={{ minWidth: 60, maxWidth: 60, padding: '0 10px', backgroundImage: 'none' }}
                          >
                            {/* {currencyCodes.length > 0 && currencyCodes.map((country) => ( */}
                            <option key={1} value={1}>1</option>
                            <option key={2} value={2}>2</option>
                            {/* ))} */}
                          </Form.Select>
                        </div>
                      </>}
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <div className='sukuk-document-title text-start px-3 my-3'>Structuring Action</div>
        <div style={{
          // maxWidth: '996px',
          // margin: '0 auto'
        }}>
          <Form.Group as={Col} md="12" className="mb-4">
            <Form.Label>Remarks from Due Diligence Partner</Form.Label>
            <textarea
              name="dueDiligenceRemarks"
              onChange={handleChange}
              value={dueDiligenceRemarks}
              style={{ width: '100%' }}
              rows={3}
              className={dueDiligenceRemarks.length > 0 ? 'highlight  form-control' : 'form-control'}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" className="mb-4">
            <Form.Label>Structuring Action</Form.Label>
            <Form.Select
              name="structuringAction"
              onChange={handleChange}
              value={structuringAction}>
              {structuringActions.length > 0 && structuringActions.map((action) => (
                <option key={action} value={action}>{action}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="6" className="mb-4">
            <Form.Label>Structuring Fee</Form.Label>
            <div className="d-flex">
              <Form.Select
                required
                name="currencyOfStructuringFee"
                value={currencyOfStructuringFee}
                onChange={handleChange}
                className={currencyOfStructuringFee.length > 0 ? 'highlight' : ''}
                style={{ width: 130, marginRight: 10 }}
              >
                {currencyCodes.length > 0 && currencyCodes.map((country) => (
                  <option key={country.CurrencyCode} value={country.CurrencyCode}>{country.CurrencyCode}</option>
                ))}
              </Form.Select>
              <Form.Control name="structuringFee" value={structuringFee} onChange={handleChange} type="number" placeholder="0.00"
                className={structuringFee.length > 0 ? 'highlight text-end' : 'text-end'} />
            </div>
            <Form.Control.Feedback type="invalid" className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-4">
            <Form.Label>Structuring Remarks</Form.Label>
            <textarea
              name="structuringRemarks"
              onChange={handleChange}
              value={structuringRemarks}
              style={{ width: '100%' }}
              rows={3}
              className={structuringRemarks.length > 0 ? 'highlight form-control' : 'form-control'} />
          </Form.Group>
        </div>
        <Button className="custom-button" type="submit" style={{ marginTop: '20px' }}>Submit</Button>
      </Form>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="error-handling-modal"
        show={successInfoShow}
        onHide={() => setSuccessInfoShow(false)}
      >
        {/* Sucesss Modal  */}

        <Modal.Body closeButton>
          <div className="center-title">
            <br></br>
            <h4>The Application is submitted successfully</h4>
            <br></br>
            {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="error-handling-modal"
        show={serverError}
        onHide={() => setServerError(false)}
      >
        {/* Error Modal  */}

        <Modal.Body>
          <div className="center-title">
            <br></br>
            <h4> {errorInfo}</h4>
            <br></br>
            {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default RequestForm;