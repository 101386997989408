import React from 'react'
import FilePreviewStyleWrapper from './filePreview.style';
import { Button, Card, Container, Dropdown, Form } from 'react-bootstrap';
import FileViewer from 'react-file-viewer-extended';
import pdf from '../../../src/test.pdf';
import xlsx from '../../../src/xltest1.xlsx';
import doc from '../../../src/testdoc.docx';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"


const file = 'https://www.clickdimensions.com/links/TestPDFfile.pdf';
const type = 'jpg';

const type2 = 'xlsx';
const type3 = 'docx';
// const file1 = '../../test.pdf';
const type1 = 'pdf'; 
const FilePreview = () => {
  const navigate = useNavigate();
  const document = useSelector(state => state.app.document);
  const documentName = useSelector(state => state.app.documentName);
  const documentType = useSelector(state => state.app.documentType);
  // const docs = [
  //   { url: document }, // Local File
  // ];
  return (
    <FilePreviewStyleWrapper>
    <Container>
       <div className="documentWrapper">
        <div className="header">
            <h3>{documentName}</h3>
            <button className="custom-button" onClick={() => navigate(-1)}>Back</button>
        </div>
        {documentType === 'doc' ?
                <div className="errorMsg">
                    This file is not supported
                </div> : 
              <FileViewer
                  fileType={documentType}
                  filePath={document}
                />
  }
                {/* <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} /> */}
      </div>
    </Container>
    </FilePreviewStyleWrapper>
  )
}

export default FilePreview
