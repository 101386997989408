import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Tabs, Tab, Modal, OverlayTrigger, Tooltip, Spinner  } from 'react-bootstrap';
import Documents from '../../components/sukukTabComponents/documents';
import Info from '../../components/sukukTabComponents/Info';
import apiManager from '../../helpers/apiManager';
import Loading from '../../helpers/Loader/loader';
import { useSelector } from 'react-redux';
import OtherInfo from './otherInfo';
import FormStyleWrapper from './../../components/NewSukuk/form.style';
import {customData} from './../../helpers/constants';
import FundRequestFormStyleWrapper from './fundRequestForm.style';
import {PRIVATE_ROUTE} from '../../helpers/routerConstants'
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

import axios from 'axios';
import { config } from './../../helpers/constants';

import { AiOutlineInfoCircle } from 'react-icons/ai'
import FundsHistoryTable from '../../components/fundsHistory/fundsHistoryTable';
import { useNavigate } from 'react-router';


const url = config.url.API_URL;


const FundsRequest = () => {
  const externalId = useSelector(state => state.app.externalId);
  const applicationName= useSelector(state => state.app.applicationName);
  const uid = useSelector(state => state.app.uid);
  const navigate = useNavigate();
  // const [formFields, setFormFields] = useState([]);
  // const [requiredArtefacts, setRequiredArtefacts] = useState([]);
  const [tab, setTab] = useState('DbRequest');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [currencyCodes, setCurrencyCodes] = useState([]);
  const[fileInfo, setFileInfo]= useState({
      fileName: '',
      uploading: false,
    })
    const [uploaded, setUploaded] = useState(false);
    const [file, setFile] = useState();
  const [formValues, setFormValues] = useState({
    currencyOfFunds: customData.DEFAULT_CURRENCY,
    fundSize : '',
    utilisationReason: '',
    requiredByDate: '',
    otherRemarks: ''
  });
  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    callCurrencyCodeLookup();
  }, [])
  const callCurrencyCodeLookup = () => {
    const currencyCodesdata = {
      "Currency": [],
      "CurrencyCode": customData.ISO_CURRENCY_CODES,
    }
    const storedCurrencyCodes = JSON.parse(localStorage.getItem('isoCurrencyCodes'));
    if (storedCurrencyCodes) {
      setCurrencyCodes(storedCurrencyCodes);
    }
    else {
      apiManager.getISOCurrencyCodes(currencyCodesdata)
        .then((response) => {
          setCurrencyCodes(response.data);
          localStorage.setItem('isoCurrencyCodes', JSON.stringify(response.data));
        })
        .catch((error) => { });
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === true) {

    }
    else{
      setValidated(true);
    }

  }
  
  const fileSelectHandler = (event, id) => {
    const {files} = event.target;
    setFileInfo({fileName: files[0].name,  uploading: false});
    setUploaded(false);
    setFile(files[0]);
  }
  const handleFileUpload = async (e, id) => {
    e.preventDefault();
    setFileInfo({...fileInfo, uploading: true});
    const formData = new FormData()
    // to do 
    formData.append('files', file)
    formData.append('ExternalId', externalId);
    formData.append('ToUpdate', 'FALSE')
    formData.append('RequiredArtefactId', id);
    formData.append('InstrumentId', '101');
      // formData.append('ApplicationId', applicationId);
    await axios
      .post(`${url}/Application/Documents/Upload/`, formData, {
        headers: {
          'Content-Type':
            'multipart/form-data; boundary=<calculated when request is sent>',
          'x-ccx-api-key':
            'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbqEAVxItCetPaj541Od+TgY=',
          'x-ccx-api-usr' : uid
          },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          //   let temp_files = [...files];
          // let temp_file = { ...temp_files.find(file_ => file_.id === id) };
          // const index = temp_files.findIndex(file_ => file_.id === id);
          // temp_file.progress = Math.round((100 * data.loaded) / data.total);
          // temp_files[index] = temp_file;
          // const progress = Math.round((100 * data.loaded) / data.total);
          // setProgress(progress);
        },
      })

      .then((response) => {
        console.log(response)
        setFileInfo({...fileInfo, uploading: false}); 
        setUploaded(true);
        setError(true);
      })
      .catch((error) => {
        console.log(error);
        // clearInterval(interval)
        setUploaded(false);
        setFileInfo({...fileInfo, uploading: false});
        setError(true);
      })
  }
  const { currencyOfFunds, fundSize, utilisationReason, requiredByDate, otherRemarks} = formValues;
  const { fileName, uploading} = fileInfo;
  return (
    <FundRequestFormStyleWrapper>
    <FormStyleWrapper>
      <div className="fundWrapper">
        <div className="header_ text-start">
          <h4>Funds Request</h4>
          
        </div>
        <div className="back_"><span onClick={() => navigate(-1)}>{'< Back'}</span></div>
        {loading && <Loading />}
        <Tabs activeKey={tab} id="uncontrolled-tab-example" className="mb-3" onSelect={setTab}>
          <Tab eventKey="DbRequest" title="Disbursement Request">
          <Row>
      <Col className="d-flex text-start mb-5 pt-3">
        <label>Sukuk Name: &nbsp;</label>
      <h5 className='text-start' style={{ marginRight: 10 }}>{applicationName}</h5>
      </Col>
      </Row>
            {/* <Info isApplicationInfo={isApplicationInfo} setTab={setTab} /> */}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
              <Form.Group as={Col} md="6" className="mb-4" controlId="validationFundsRequired">
                <Form.Label>Funds Required<span className='mandatory'>*</span></Form.Label>
                <div className="d-flex">
                  <Form.Select
                    // disabled={true}
                    required
                    name="currencyOfFunds"
                    value={currencyOfFunds}
                    onChange={handleChange}
                    className={currencyOfFunds && currencyOfFunds.length > 0 ? 'highlight' : ''}
                    style={{ width: 112, marginRight: 10 }}
                  >
                    <option value="" disabled defaultValue>
                      Select Currency
                    </option>
                    {currencyCodes.length > 0 && currencyCodes.map((country) => (
                      <option key={country.CurrencyCode} value={country.CurrencyCode}>{country.CurrencyCode}</option>
                    ))}
                  </Form.Select>
                  <Form.Control
                    // disabled={true}
                    name="fundSize" value={fundSize} onChange={handleChange} required type="number" placeholder="0.00"
                    className={fundSize && fundSize.length > 0 ? 'highlight text-end' : 'text-end'} />
                </div>
                <Form.Control.Feedback type="invalid" className="text-start">
                  Please enter the value.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-4" controlId="validationFundsUtilisationReason">
                <Form.Label>Funds Utilisation Reason<span className='mandatory'>*</span></Form.Label>
                <Form.Control
                    // disabled={true}
                    name="utilisationReason" value={utilisationReason} onChange={handleChange} required type="text" placeholder=""
                    className={utilisationReason && utilisationReason.length > 0 ? 'highlight text-start' : 'text-start'} />
                <Form.Control.Feedback type="invalid" className="text-start">
                  Please enter the value.
                </Form.Control.Feedback>
              </Form.Group>
              </Row>
              <Row>
              <Form.Group as={Col} md="6" className="mb-4" controlId="validationRequiredByDate">
            <Form.Label>Required by Date<span className='mandatory'>*</span></Form.Label>
            <Form.Control
              type="date"
              name="requiredByDate"
              placeholder="01/May/2022"
              onChange={handleChange}
              value={requiredByDate} 
              required
              />
            <Form.Control.Feedback type="invalid"  className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
            <Form.Group as={Col} md="6" className="mb-4">
              <Form.Label>Supporting Document</Form.Label>
                
            <div className='d-flex align-items-center justify-content-between'>
                              <div className="d-flex inputWrapper position-relative">
                                <Form.Control
                                  className="form-control sukuk-document-input"
                                  type="file"
                                  // id={doc.RequiredArtefactId}
                                  // ref={inputFile}
                                  display="none"
                                  style={{ marginLeft: '0px' }}
                                  onChange={(e) => fileSelectHandler(e, 4101)}
                                />
                                <input
                                  className="form-control sukuk-document-input-text"
                                  type="text"
                                  // id={doc.id}
                                  // ref={inputFileName}
                                  value={fileName}
                                // placeholder={fileName1101 ? fileName1101 : ''}
                                />
                                {!uploaded && uploading && <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className='uploadingLoader'
                                  variant="primary"
                                />}
                                {uploaded && (
                                  <BiCheckCircle className="virus-icon virus-check-icon" />
                                )}
                                {!uploaded && !uploading && error && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip-2">Something went wrong, please try again</Tooltip>}
                                  >
                                    <BiXCircle className="virus-icon virus-error-icon" />
                                  </OverlayTrigger>
                                )}
                              </div>
                              <div className='d-flex align-items-center'>
                                {fileName === '' ? <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip id="button-tooltip-2">Please choose the file</Tooltip>}
                                >
                                  <button
                                    className="upload-button ml-2"
                                    disabled={fileName === ''}
                                  // ref={inputFile}
                                  >
                                    Upload
                                  </button>
                                </OverlayTrigger>
                                  :

                                  <button
                                    className="upload-button ml-2"
                                    // ref={inputFile}
                                    onClick={(e) =>
                                      handleFileUpload(e, 4101)
                                    }
                                  >
                                    Upload
                                  </button>
                                }
                              </div>
                            </div>
            </Form.Group>
            </Row>
            <Row>
            <Form.Group as={Col} md="6" className="mb-4">
                <Form.Label>Other Remarks</Form.Label>
                <Form.Control
                            as="textarea"
                    // disabled={true}
                    name="otherRemarks" value={otherRemarks} onChange={handleChange} type="text" placeholder=""
                    className={otherRemarks && otherRemarks.length > 0 ? 'highlight text-start w-100' : 'text-start w-100'} />
              </Form.Group>
            </Row>
              <button className="custom-button" type="submit">Submit</button>
            </Form>
          </Tab>
          <Tab eventKey="history" title="History">
          <Row>
      <Col className="d-flex text-start mb-5 pt-3">
        <label>Sukuk Name: &nbsp;</label>
      <h5 className='text-start' style={{ marginRight: 10 }}>{applicationName}</h5>
      </Col>
      </Row>
            {/* <OtherInfo isApplicationInfo={isApplicationInfo} /> */}
            <FundsHistoryTable parent="Business" />
          </Tab>
        </Tabs> 
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={serverError}
          onHide={() => setServerError(false)}>
          <Modal.Body>
            <div className="center-title text-center">
              <br></br>
              <h4> {'Something went wrong, Please refresh!!'}</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </FormStyleWrapper>
    </FundRequestFormStyleWrapper>
  )
}

export default FundsRequest;