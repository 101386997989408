import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const ProfileFormStyleWrapper = styled.div`
input{
    width: 400px;
    height: 45px;
    border-radius: 5px;
    font-size: 15px;
    border: 1px solid #949391;
    opacity: 1;
    padding: 5px;
}
select{
    // width: 400px;
    // border-radius: 5px;
    // font-size: 15px;   
    // padding: 5px;
    // font-size: 16px;
    // border: 1px solid #949391;
    // border-radius: 5px;
    height: 45px;
    // background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
    // background-position-x: 364px;
    // background-color: white;
    // -webkit-appearance: none;
    // opacity: 1;
}
select:focus-visible, input:focus-visible{
    outline : none !important;
}
label span{
    color: red;
}

.door{
    width: 90px;
    height: 45px;
    border-radius: 5px;
    font-size: 15px;
    margin-right: 10px;
}
.street{
    width: 300px;
    height: 45px;
    border-radius: 5px;
    font-size: 15px;
}
.profile-form-container{
    width: 100%;
    max-width: 996px;
    margin: 0 auto;
}
.profile-form{
    color: #000;
    display: block;
    /* position: absolute; */
    /* top: 100px; */
    /* left: 300px; */
    width: 100%;
    /* max-width:70%;
    min-width: 40%;
    height: 56px;
    margin-left: 300px; */
    label{
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 5px;
    }
  }
.form-field {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* display: inline-block; */
    width: 100%;
    height: 100%;
    margin-bottom: 15px; 
    justify-content: space-between;
    > .form-inline{
        /* display: flex;
        flex-direction: column; */
        max-width: 400px;
        margin: 10px 0 10px 0px;
        /* align-items: flex-start; */
        text-align: left;
        width: 50%;
        &:first-child{
            margin-right: 10px;
        }
        &:last-child{
            margin-left: 10px;
        }
    }
}
.highlight:not(:disabled){
    background-color: #e8f0fe !important;
}
input:disabled{
    cursor: not-allowed;
}
.phoneCountryCode{
    width: 90px;
    margin-right: 10px;
    padding-left: 25px
}
.errorText{
    color: red;
    position:absolute;
    font-size: 14px;
    bottom: -23px;
    left:0;
}

`;

export default WithDirection(ProfileFormStyleWrapper);