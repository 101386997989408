import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const SidebarWrapper = styled.div`
.side-menu{
    background: ${APPCOLORS.bgColor}; /* fallback for old browsers */
    background: -webkit-${APPCOLORS.sidebarBg};/* Chrome 10-25, Safari 5.1-6 */
    background: ${APPCOLORS.sidebarBg}; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    // width: 345px;
    width: 320px;
    height: 100vh;
    box-sizing: border-box;
    /* padding: 30px 20px; */
    transition: width 0.5s ease-in;
    overflow-y: auto;
    &.inactive{ 
        .main-menu .menu-item{
            &:hover{
                background: #ffffff;
                color: ${APPCOLORS.bgColor} ;
                display: block;
                border: #ffffff 2px solid;
                border-radius: 15px;
            }
            .menu-icon{
                text-align: left;
                transition: 0.2s ease;
            }
        }
    }
    .top-section{
        position: relative;
        text-align: center;
    }
    .pro-sidebar{
        color: white;
        width: auto;
        > .pro-sidebar-inner{
            background: transparent;
        }
        .pro-menu {
            .pro-menu-item{
                padding: 6px 20px;
                &.active{
                    background: hsl(0deg 0% 0% / 31%);
                    color:#ffffff;
                    padding-left: 20px !important;
                }
                &.pro-sub-menu{
                    > .pro-inner-list-item{
                        padding-left: 20px;
                        .pro-inner-item{
                            padding-left: 0;
                        }
                    }
                    .pro-sub-menu > .pro-inner-list-item{
                        padding-left: 0;
                    }
                    .pro-menu-item{
                        padding: 6px 0px;
                        &.active{
                            margin-left: -20px;
                        }
                    }

                }
                &.inactive{
                    opacity: 0.6;
                    cursor: not-allowed
                }
                .pro-inner-item{
                    .pro-icon-wrapper{
                        background-color: transparent;  
                    }
                    .pro-arrow-wrapper{
                        .pro-arrow{
                            border-color: #ffffff;
                            padding: 3px;
                            border-width: 0 3px 3px 0;
                        }
                    }
                }
            }
            > ul > .pro-sub-menu > .pro-inner-list-item{
                background-color: transparent;
            }
        }
        
    }
    .top-section{ 
        .logo{
            width: 60px;
            height: 60px;
            overflow: hidden;
            margin: 0 auto;
            img{
                max-width: 100%;
                max-height:100%;
            }
        }
        .logoName{
            width: 60px;
            height: 30px;
            overflow: hidden;
            margin: 0 auto;
            img{
                max-width: 100%;
                max-height:100%;
            }
        }
        .toggle-menu-btn{
            color:${APPCOLORS.bgColor};
            font-size: 30px;
            position: absolute;
            top: 50%;
            right: -60px;
            transform: translateY(-50%);
        }
    }
    .main-menu{ 
        margin: 20px 0;
        color: ${APPCOLORS.bgColor};
        .menu-item{
            text-decoration: none;
            font-size: 16px;
            display: block;
            text-align: left;
            font-weight: 400;
            margin-left: -27px;
            a{
                display: flex;
                justify-content: start;
                align-items: center;
                &.inactive{
                    background-color: transparent !important;
                    color: #ffffff !important;
                    &:hover{
                        background-color: transparent !important;
                        color: #ffffff !important;
                    }
                }
            }
            span{
                display: inline-block;
                line-height: 55px;
                opacity: 1;
                transition: opacity 0.5s ease-in;
            }
            .menu-icon{
                display: inline-block;
                width: 40px;
                height: 30px;
                text-align: center;
                margin: 0 20px 0 20px;
                > span{
                    width: 40px;
                    height: 30px;
                    display: inline-block;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    /* margin-left: -25px; */
                }
            }
        }
    }
} 
a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
}
li{
    list-style: none;
}
`;

export default WithDirection(SidebarWrapper);
