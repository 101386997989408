import React, { lazy, Suspense } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import { PRIVATE_ROUTE } from '../../helpers/routerConstants';
import StructuringRequest from './requests';
// import ProfileForm from '../../components/ProfileForm/profileForm';
import BlankPage from '../../publicPages/blankPage';
import ErrorBoundary from '../../ErrorBoundary';
import StructuringInfoForm from './structuringInfoForm';

const routes = [
  {
    path: PRIVATE_ROUTE.DASHBOARD,
    exact: 'true',
    component: <BlankPage/>,
  },
  {
    path: PRIVATE_ROUTE.NEWSUKUKREQUEST,
    exact: 'true',
    component: <StructuringRequest/>,
  },
  {
    path: PRIVATE_ROUTE.SUKUKREQUESTINFO,
    exact: 'true',
    component: <StructuringInfoForm/>,
  },
//   {
//     path: PRIVATE_ROUTE.BUSINESSPROFILE,
//     exact: 'true',
//     component: <ProfileForm/>,
//   },
];
export default function StructuringRoutes() {
  return (
    <Suspense fallback={<ErrorBoundary />}>
    <Routes>
       {routes.map((route, idx) => (
          <Route key={idx} path={route.path} exact={route.exact} element={route.component} />
       )
       )}
    </Routes>
    </Suspense>
  );
}
