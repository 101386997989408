import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import apiManager from '../../../helpers/apiManager';
import Loading from '../../../helpers/Loader/loader';
import Document from '../../../helpers/images/sidebarMenu/business/documentIcon.svg';
import DocumentDark from '../../../helpers/images/assets/documentDark.svg';
// import SubscriptionListStyleWrapper from './subscriptionList.style'
import { OverlayTrigger, Tooltip, Spinner, Modal } from 'react-bootstrap';
import axios from 'axios';
import { config } from '../../../helpers/constants';
import SukukCard from '../../../components/SukukCard/sukukCard';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';
import CreateProspectusStyleWrapper from './createProspectus.style'
import utility from '../../../helpers/utility';


const url = config.url.API_URL;
// import FileDownloadGrey from '../../helpers/images/assets/File_Download_Icon_grey.svg';
const CreateProspectus = () => {
  const externalId = useSelector(state => state.app.externalId);
  // const airTableUrl = useSelector(state => state.app.airTableUrl);
  const profileRole = useSelector(state => state.app.profileRoleDescription);
  const uid = useSelector(state => state.app.uid);
  const [loading, setLoading] = useState(true);
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [sukukList, setSukukList] = useState([]);
  const [error, setError] = useState(false);
  const [sortedValue, setSortedValue] = useState('StatusDateAscending');
  const [showList, setShowList] = useState(true);
  const [sukukName, setSukukName] = useState('');
  const [serverError, setServerError] = useState('');
  const [successMsgShow, setSuccessMsgShow] = useState(false);
  // const [fileName, setFileName] = useState('');
  // const [file, setFile] = useState('');
  const [fileInfo, setFileInfo] = useState({
    fileName: '',
    uploading: false,
  })
  const [uploaded, setUploaded] = useState(false);
  const [file, setFile] = useState();
  const [applicationExternalId, setApplicationExternalId] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [airTableUrl, setAirTableUrl] = useState('');
  const [approveValidated, setApproveValidated] = useState(false);
  const [rejectionValidated, setRejectionValidated] = useState(false);
  const filterOptions = [{ label: 'All' },
  { label: 'Received' },
  { label: 'Acknowledged' },
  { label: 'Approved' },
  { label: 'Rejected' }]
  const sortOptions = [{ label: 'Subscription Date', key: 'StatusDateAscending' },
  { label: 'Subscription Date', key: 'StatusDateDescending' },
  { label: 'Subscription Amount', key: 'SubscriptionAmountAscending' },
  { label: 'Subscription Amount', key: 'SubscriptionAmountDescending' },
  { label: 'Status', key: 'Status' }]

  function getAccessToken() {
    // If you're just testing, you can paste in a token
    // and uncomment the following line:
    // return 'paste-your-token-here'

    // In a real app, it's better to read an access token from an
    // environement variable or other configuration that's kept outside of
    // your code base. For this to work, you need to set the
    // WEB3STORAGE_TOKEN environment variable before you run your code.
    // return process.env.WEB3STORAGE_TOKEN
    return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDc1MTQ3ZjkyMDhBODE5MTAwQmVkNURBZWQ4OUNlMjg0OTJmNDcwRDIiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NjYyMzQ0MTA3OTcsIm5hbWUiOiJ0ZXN0IHRva2VuIn0.LpgCFgpHQTE5h1tX4EnYYjCWtV_AmutGmP4aZALMdoc';
  }
  useEffect(() => {
    getStructuringApprovedApplications();
    apiManager.getBlockChainUrl()
    .then((response) => {
      let blockChainUrl = '';
      if(response.fields.useAirtable === 'yes'){
        blockChainUrl = response.fields.airtableUrl;
    }
    else{
        blockChainUrl = response.fields.firebaseURL;
    }
    setAirTableUrl(blockChainUrl);
    });
  }, []);
  const getStructuringApprovedApplications = () => {
    const data = { ExternalId: externalId };
    // if(sukukStatus === 'listed'){
    apiManager
      .getSukukListed(data, uid)
      .then((response) => {
        const tempSukukList = response.data.length > 0 ? response.data.filter(application => application.CurrentStatus !== 1) : [];
        setSukukList(tempSukukList);
        setLoading(false);
        // localStorage.setItem('categories', JSON.stringify(response.data.data));
      })
      .catch((error) => {
        setServerError(true);
        setLoading(false);
      });
  }
  const fileSelectHandler = (event, id) => {
    const { files } = event.target;
    setFileInfo({ fileName: files[0].name, uploading: false });
    setUploaded(false);
    setFile(files[0]);
  }
  const handleFileUpload = async (e, id) => {
    e.preventDefault();
    setFileInfo({ ...fileInfo, uploading: true });
    const formData = new FormData()
    // to do 
    formData.append('files', file)
    formData.append('ExternalId', applicationExternalId);
    formData.append('ToUpdate', 'FALSE')
    formData.append('RequiredArtefactId', 5101);
    formData.append('InstrumentId', '101');
    formData.append('ApplicationId', applicationId);
    await axios
      .post(`${url}/Application/Documents/Upload/`, formData, {
        headers: {
          'Content-Type':
            'multipart/form-data; boundary=<calculated when request is sent>',
          'x-ccx-api-key':
            'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbqEAVxItCetPaj541Od+TgY=',
          'x-ccx-api-usr': uid
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          //   let temp_files = [...files];
          // let temp_file = { ...temp_files.find(file_ => file_.id === id) };
          // const index = temp_files.findIndex(file_ => file_.id === id);
          // temp_file.progress = Math.round((100 * data.loaded) / data.total);
          // temp_files[index] = temp_file;
          // const progress = Math.round((100 * data.loaded) / data.total);
          // setProgress(progress);
        },
      })

      .then((response) => {
        console.log('coming inside then',response);
        return response.data
      })
      .then(async (response) => {
        console.log('coming inside then async',response);
        // const blob = new Blob([file], { type: 'image/png' })
        const file_ = [
          new File([file], fileInfo.fileName),
          file
        ]
        const cid = await utility.documentUploadToWebstorag(file_);
        const data= {artefacts :[{
          artefactId: response.data[0].ApplicationDocumentId,
          documentId:""+response.data[0].RequiredArtefactId,
          fileName: fileInfo.fileName,
          url: 'ipfs://'+cid+'/'+fileInfo.fileName,
          description: '',
          submittedDate: response.data[0].SubmittedDate,
          fileType: response.data[0].FileType,
          fileSize: ""+response.data[0].FileSize,
          entityType: profileRole
        }],
        externalId : externalId
      }
        apiManager.callNodeToBlockChain(data, airTableUrl);
        await updateResponse(response);
        // const id = response.data[0].RequiredArtefactId
        // console.log(id,"idd")

      })
      .catch((error) => {
        console.log(error);
        // clearInterval(interval)
        setUploaded(false);
        setFileInfo({ ...fileInfo, uploading: false });
        setError(true);
      })

  }
   const updateResponse = (res) => {
    // setProgress(100);
    setUploaded(true);
    setFileInfo({ ...fileInfo, uploading: false });
    setError(false);
    setSuccessMsgShow(true);
  }
  const resetFileData = () =>{
    setFileInfo({
      fileName: '',
      uploading: false,
    })
  }
  const handleSukukSelection = (applicationName, appId, appExternalId) => {
    setShowList(false);
    setSukukName(applicationName);
    setApplicationExternalId(appExternalId);
    setApplicationId(appId);
  }
  

  //   const subStartDate = subscriptionDetails ? utility.getFormattedDate(subscriptionDetails.SubscriptionStartDate) : '';
  //   const subEndDate = subscriptionDetails ? utility.getFormattedDate(subscriptionDetails.SubscriptionEndDate) : '';
  const { fileName, uploading } = fileInfo;
  return (
    <CreateProspectusStyleWrapper>
      {loading && <Loading />}
      <div className="container_">
        <div className="header_ text-start">
          <h4>Create Prospectus</h4>
        </div>
        {showList ? <div>
          {sukukList.length > 0 && sukukList.map((sukuk, idx) => {
            const info = {
              SubmittedOn: sukuk?.CreatedDate,
              ApplicationName: sukuk?.Application?.ApplicationName,
              Account: sukuk.Account ? sukuk.Account : '',
              CompanyInfo: sukuk.CompanyInfo ? sukuk.CompanyInfo : {},
              IssuanceSize: sukuk.IssuanceSize ? sukuk.IssuanceSize : '',
              TenureInMonths: sukuk.TenureInMonths ? sukuk.TenureInMonths : '',
              Currency: sukuk.IssuanceCurrency ? sukuk.IssuanceCurrency : '',
            }
            return (
              <div key={idx} style={{ marginTop: 15, marginBottom: 35, maxWidth: 950 }} className="d-flex align-items-start" >
                <div className='cardWrapper' style={{ width: 'calc(100% - 300px)' }} onClick={() => handleSukukSelection(info.ApplicationName, sukuk.ApplicationId, sukuk.Application.ExternalId)}>
                  <SukukCard info={info} />
                  {/* <span className="successMsg" >{'Succesfuly updated'}</span> */}
                </div>
                <div className="documentProofBlock mx-3 d-flex justify-content-around" style={{ width: '300px' }}>
                  <div className='documentBlock d-flex flex-column'>
                    <label>Prospectus</label>
                    <span className="mt-3"><img style={{ width: 50 }} src={DocumentDark} /></span>
                  </div>
                  <div className='documentBlock d-flex flex-column'>
                    <label>Status</label>
                    <span className="mt-4">Yet to Upload</span>
                  </div>
                </div>
              </div>
            )
          })
          }
          {sukukList.length === 0 && <div className="noDataText">There is no Sukuk</div>}
        </div>
          :
          <div className='formWrapper'>

            <div className="back_ mb-4"><span onClick={() => [setShowList(true), resetFileData()]}>{'< Back'}</span></div>
            <div className="title_ text-start">
              <label>Sukuk Name: &nbsp;</label>
              <span>{sukukName}</span>
            </div>
            <div className="header_bar text-center my-5 py-2">
              <label>Download Prospectus Template</label>
              <img className="mx-4" style={{ width: 20 }} src={Document} />
            </div>
            <div className='uploadForm'>
              <div className='d-flex flex-column align-items-center justify-content-between'>
                <div className="d-flex align-items-center inputWrapper position-relative mt-4">
                  <label>Upload Prospectus<span>*</span>: &nbsp;</label>
                  <div className='d-flex' style={{ minWidth: 450 }}>
                    <input
                      className="form-control sukuk-document-input"
                      type="file"
                      // id={doc.RequiredArtefactId}
                      // ref={inputFile}
                      display="none"
                      style={{ marginLeft: '0px' }}
                      onChange={(e) => fileSelectHandler(e, 4101)}
                    />
                    <input
                      className="form-control sukuk-document-input-text"
                      type="text"
                      // id={doc.id}
                      // ref={inputFileName}
                      value={fileName}
                    // placeholder={fileName1101 ? fileName1101 : ''}
                    />
                  </div>
                  {!uploaded && uploading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className='uploadingLoader'
                    variant="primary"
                  />}
                  {uploaded && (
                    <BiCheckCircle className="virus-icon virus-check-icon" />
                  )}
                  {!uploaded && !uploading && error && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="button-tooltip-2">Something went wrong, please try again</Tooltip>}
                    >
                      <BiXCircle className="virus-icon virus-error-icon" />
                    </OverlayTrigger>
                  )}
                </div>
                <div className='mt-5 mb-4'>
                  {fileName === '' ? <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-2">Please choose the file</Tooltip>}
                  >
                    <button
                      className="upload-button mx-2"
                      disabled={fileName === ''}
                    // ref={inputFile}
                    >
                      Upload
                    </button>
                  </OverlayTrigger>
                    :

                    <button
                      className="upload-button mx-2"
                      // ref={inputFile}
                      onClick={(e) =>
                        handleFileUpload(e, 4101)
                      }
                    >
                      Upload
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        }
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={successMsgShow}
          onHide={() => [setSuccessMsgShow(false), setShowList(true)]}
        >
          {/* Sucesss Modal  */}

          <Modal.Body closeButton>
            <div className="center-title">
              <br></br>
              <h4>Uploaded Successfully</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>


    </CreateProspectusStyleWrapper>
  )
}

export default CreateProspectus;