import { PROFILE_COMPLETED, SIGN_IN, SIGN_OUT, PROFILE_ROLE, EXTERNAL_ID, BUSINESS_ROLE,BUSINESS_ROLES, UID, APPLICATION_ID, APPLICATION_NAME, SELECTED_SUKUK_INFO, EMAIL_ID, LOGGED_IN, SPV_NAME, RESET, DOCUMENT, DOCUMENTNAME, DOCUMENTTYPE, PROFILE_ROLE_DESCRIPTION, AIRTABLEURL } from "./types";
export const signIn = () =>{
    return{
    type: SIGN_IN
    }
};

export const signOut = () => {
    return{
        type: SIGN_OUT
    }
}

export const setProfileCompleted = (profileCompleted) => {
    return{
        type: PROFILE_COMPLETED,
        profileCompleted
    }
}
export const setProfileRole = (profileRole) => {
    return{
        type: PROFILE_ROLE,
        profileRole
    }
}
export const setProfileRoleDescription = (profileRoleDescription) => {
    return{
        type: PROFILE_ROLE_DESCRIPTION,
        profileRoleDescription
    }
}
export const setExternalId = (externalId) => {
    return{
        type: EXTERNAL_ID,
        externalId
    }
}
export const setBusinessRole = (businessRole) => {
    return{
        type: BUSINESS_ROLE,
        businessRole
    }
}
export const setBusinessRoles = (businessRoles) => {
    return{
        type: BUSINESS_ROLES,
        businessRoles
    }
}
export const setUid = (uid) => {
    return{
        type: UID,
        uid
    }
}
export const setApplicationId = (applicationId) => {
    return{
        type: APPLICATION_ID,
        applicationId
    }
}
export const setApplicationName = (applicationName) => {
    return{
        type: APPLICATION_NAME,
        applicationName
    }
}
export const setSelectedSukukInfo = (selectedSukukInfo) => {
    return{
        type: SELECTED_SUKUK_INFO,
        selectedSukukInfo
    }
}
export const setEmailId = (emailId) => {
    return{
        type: EMAIL_ID,
        emailId
    }
}
export const setLoggedIn = (loggedIn) =>{
    return{
        type:LOGGED_IN,
        loggedIn
    }
}

export const setSPVName = (spvName) => {
    return {
        type: SPV_NAME,
        spvName
    }
}
export const reset = () => {
    return {
        type: RESET
    }
}
export const setDocument = (document) => {
    return {
        type: DOCUMENT,
        document
    }
}
export const setDocumentName = (documentName) => {
    return {
        type: DOCUMENTNAME,
        documentName
    }
}
export const setDocumentType = (documentType) => {
    return {
        type: DOCUMENTTYPE,
        documentType
    }
}
export const setAirtableUrl = (airTableUrl) => {
    return {
        type: AIRTABLEURL,
        airTableUrl
    }
}