import React, { useState, useEffect } from 'react'
import NewSukukStyleWrapper from './newSukuk.style';
// import '../ProfileForm/ProfileForm.css'
import { Button, Card, Container, Dropdown, Check, Modal } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import SukukForm from '../../components/NewSukuk/sukukForm';
import { useSelector, useDispatch } from 'react-redux';
import apiManager from '../../helpers/apiManager';
import Loading from '../../helpers/Loader/loader';
import { setApplicationId, setApplicationName } from '../../actions';

const NewSukuk = () => {
  const url = window.location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const uid = useSelector(state => state.app.uid);
  const externalId = useSelector(state => state.app.externalId);
  const applicationId = useSelector(state => state.app.applicationId);
  const [formFields, setFormFields] = useState([]);
  const [serverError, setServerError] = useState(false);
  const [isRequiredFieldsForm, setIsRequiredFieldsFormChecked] = useState(true);
  const [isRequiredFieldsChecked, setIsRequiredFieldsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isApplicationDocuments, setApplicationDocuments] = useState({});
  const [isApplicationInfo, setApplicationInfo] = useState({});
  const [requiredArtefacts, setRequiredArtefacts] = useState([]);
  const [checks, setCheck] = useState({
    BusinessConfirms: false,
    noGuarantee: false,
    rightToDecline: false,
    payFeeUpfront: false
  })


  useEffect(() => {
    getSukukForm();
    if (url.includes('saved')) {
      setIsRequiredFieldsFormChecked(false);
      getSukukFormData();
    }
    else{
      setIsRequiredFieldsFormChecked(true);
      dispatch(setApplicationId(''));
            setApplicationInfo({});
            dispatch(setApplicationName(''))
    }
  }, [location])
  useEffect(() => {
    if (Object.keys(checks).every(check => checks[check])) {
      setIsRequiredFieldsChecked(true);
    }
    else {
      setIsRequiredFieldsChecked(false);
    }
  }, [checks]);
  const getSukukForm = () =>{
    setLoading(true);
    const data_ = {
      ExternalId: externalId,
      InstrumentId: 101,
    };
    apiManager
      .getFormList(data_)
      .then((response) => {
        const form_Fields = response.data.find(instrument => instrument.InstrumentId === '101').FormFields;
        const required_Artefacts = response.data.find(instrument => instrument.InstrumentId === '101').RequiredArtefacts;
        setFormFields(form_Fields);
        setRequiredArtefacts(required_Artefacts);
        setLoading(false);
        // localStorage.setItem('categories', JSON.stringify(response.data.data));
      })
      .catch((error) => {
        setServerError(true);
        setLoading(false);
      });
  }
  const getSukukFormData = () => {
    setLoading(true);
    const data = {
      ApplicationId: location.state.applicationId,
      InstrumentId: 101,
    };
    apiManager
      .searchApplication(data, uid)
      .then((response) => {
        // const savedSukukRequests = response.data && response.data.length > 0 ? response.data.filter()
        if (response.data && response.data.length > 0) { // check if the response has data, don't show the "confirm to proceed" form
          const savedApplication = response.data[0];
          if (savedApplication) {
            const applicationInfo = savedApplication && savedApplication.ApplicationInfo;
            const applicationDocuments = savedApplication && savedApplication.ApplicationDocuments;
            const applicationName = savedApplication && savedApplication.ApplicationName ? savedApplication.ApplicationName : '';
            applicationInfo['ApplicationName'] = applicationName;
            const tempApplicationId = savedApplication && savedApplication.ApplicationId ? savedApplication.ApplicationId : '';
            dispatch(setApplicationId(tempApplicationId));
            setApplicationInfo(applicationInfo);
            dispatch(setApplicationName(applicationName));
            setApplicationDocuments(applicationDocuments === null ? [] : applicationDocuments);
            // setIsRequiredFieldsFormChecked(false);

            // if (url.includes('saved')) {
            //   setIsRequiredFieldsFormChecked(false);
            // }
          }
          setLoading(false);
        }
        // else {
        //   setIsRequiredFieldsFormChecked(true);
        //   setLoading(false);
        // }
        // localStorage.setItem('categories', JSON.stringify(response.data.data));
      })
      .catch((error) => {
        setServerError(true)
      });

  }
  const goAhead = () => {
    navigate('info');
  }
  const setRequiredFields = (e) => {
    const { name, checked } = e.target;
    setCheck(prev => ({ ...prev, [name]: checked }));
  }
  return (
    <NewSukukStyleWrapper>
      <div className="new-sukuk">
        <div className="agreement">
          <div className="header_">
            <h4 className="section-title text-start">New Sukuk</h4>
          </div>
          {/* to do */}
          {loading && <Loading />}
          {isRequiredFieldsForm ? <Card className="sukuk-card agreement">
            <Card.Body>
              <IconContext.Provider
                value={{ color: 'red', className: 'info-icon' }}
              >
                <div>
                  <FaInfoCircle />
                </div>
              </IconContext.Provider>
              <br />
              <h3
                style={{ background: 'black', color: 'white', textAlign: 'center', padding: '10px 0px ', }}
              >
                IMPORTANT:{' '}
                <span style={{ fontSize: '25px' }}>
                  Please Read And Confirm To Proceed
                </span>
              </h3>{' '}
              <div className="sukuk-div">
                <p>
                  Qiam commits to keep the information and data related to the new Sukuk application confidential, until the Sukuk is launched
                </p>

                <span style={{ fontWeight: 600 }}> Please Confirm:</span>
                <br />
                <br />

                <p>
                  <input name="BusinessConfirms" id="check" type="checkbox" onChange={setRequiredFields} />

                  Business confirms that the information provided is accurate. Any
                  inaccuracy or false information may result in sukuk request
                  getting declined.
                </p>
                <p>
                  <input name="noGuarantee" id="check0" type="checkbox" onChange={setRequiredFields} />

                  Qiam cannot provide any guarantee that the sukuk will get funded.
                </p>

                <p>
                  <input name="rightToDecline" id="check" type="checkbox" onChange={setRequiredFields} />
                  Qiam has the right to decline the sukuk request based on the assessment through the due-diligence process
                </p>

                <p>
                  <input name="payFeeUpfront" id="check" type="checkbox" onChange={setRequiredFields} />
                  Applying for new sukuk requires the business to pay the due diligence fee upfront. The fee is determined based on the sukuk requirement and varies for each sukuk request.
                </p>
              </div>
              <div
                className="layout layout-horizontal new-sukuk-btn justify-content-around mx-5"
              >
                <Button className="custom-button" disabled={!isRequiredFieldsChecked} onClick={() => setIsRequiredFieldsFormChecked(false)}>Let's Go Ahead</Button> <br></br>
                <Link to="/business/">
                  <Button className="custom-button"> I'll Do It Later</Button>{' '}
                </Link>
              </div>
            </Card.Body>
          </Card>
            :
            <SukukForm isApplicationDocuments={isApplicationDocuments} isApplicationInfo={isApplicationInfo} formFields={formFields} requiredArtefacts={requiredArtefacts} />}
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="error-handling-modal"
            show={serverError}
            onHide={() => setServerError(false)}>
            <Modal.Body>
              <div className="center-title text-center">
                <br></br>
                <h4> {'Network error, Please refresh!!'}</h4>
                <br></br>
                {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {/* <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="checkbox-error-handling-modal"
      show={errorNewSukuk}
      onHide={() => setErrorNewSukuk(false)}
      >
      <Modal.Body closeButton>
          <div className="center-title">
            <br></br>

            <h3> Please select required checkboxs to proceed...</h3>
          
          </div>
        </Modal.Body>
 
      </Modal> */}
      </div>
    </NewSukukStyleWrapper>
  )
}

export default NewSukuk
