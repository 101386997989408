import React, { useEffect, useState } from 'react'
import qiamLogoWhite from '../../helpers/images/assets/qiam-white-shadow-logo.png'
import qiamWords from '../../helpers/images/assets/qiamWords.png'
import {useDispatch, useSelector} from 'react-redux';
import options from './sidebarMenu';
// import './sidebar.css'
import {Tooltip, OverlayTrigger,Modal} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { setProfileRole } from '../../actions';
import SidebarWrapper from './sidebar.style'
import { customData } from '../../helpers/constants';


const inactiveMenu = ['New Sukuk Request', 'Sukuk Pending Updates', 'Sukuk Under Approval', 'Sukuk Outstanding'];
const SideMenu = () => {
  const profileCompleted = useSelector((state) => state.app.profileCompleted);
  const profileRole = useSelector((state) => state.app.profileRole);
  const [role , setRole] = useState(profileRole);
  const [roleMessage, setRoleMessage] = useState('');
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    console.log(options);
    const tempProfile = options[customData.BUSINESS_ROLES[profileRole]] ? customData.BUSINESS_ROLES[profileRole] : 'Customer';
    setRole(tempProfile);
  }, [profileRole])

  const createRoleSubMenu = (menu, index) => {  
    if(menu.subMenu && menu.subMenu.length > 0){
    return (
    <SubMenu icon={menu.icon} key={index} title={menu.label}>
    {menu.subMenu.map((subMenu, subkey) => { 
      return(
        createRoleSubMenu(subMenu, subkey)
      )
    })}
    </SubMenu>) }
    else{
     return (<MenuItem onClick={ () => changeRole(menu.label)} active={menu.label === profileRole} key={index}><Link to={menu.key} />{menu.label}</MenuItem>)
    }
  }
  const createSubMenu = (menu, index) => {  
    if(menu.subMenu && menu.subMenu.length > 0){
    return (
    <SubMenu icon={menu.icon} key={index} title={menu.label}>
    {menu.subMenu.map((subMenu, subkey) => { 
      return(
        createSubMenu(subMenu, subkey)
      )
    })}
    </SubMenu>) }
    else{
     return (
      ( !profileCompleted && (inactiveMenu.includes(menu.label) || (menu.label !== 'Update Profile' || menu.label !== "Update Profile" && profileRole === 'SPV')) ? 
      <OverlayTrigger
    placement="bottom"
    overlay={<Tooltip id="button-tooltip-2">Please complete the profile</Tooltip>}>
          <MenuItem  icon={menu.icon} className='inactive' active={false}>{menu.label}<Link to='#' /></MenuItem>
          </OverlayTrigger> :
          <MenuItem icon={menu.icon} active={menu.key === window.location.pathname}>{menu.label}<Link to={menu.key} /></MenuItem>
      )
    //  <MenuItem  active={menu.key === window.location.pathname} key={index}>{menu.label}<Link to={menu.key} /></MenuItem>
     )
    }
  }
  const changeRole =(role) =>{
    dispatch(setProfileRole(role));
    // navigate('/common/profileForm');
    setRoleMessage(role);
    setShowMessagePopup(true);
    setTimeout(() =>{
      setShowMessagePopup(false);
    }, 2000)
  }
  return (
    <SidebarWrapper>
    <div className='side-menu'>
<ProSidebar>
<SidebarHeader>
<div className="top-section my-3">
<div className="logo">
          <img src={qiamLogoWhite} className="logo-image" alt="logo" />
        </div>
        <div className='logoName'>
          <img src={qiamWords} className="logo-name" /></div>
        </div>
</SidebarHeader>
<SidebarContent>
  <Menu iconShape="square">
  {options[role] && options[role].length > 0 && options[role].map((menu, index) => {
    return menu.subMenu && menu.subMenu.length > 0 ?
    (
    createSubMenu(menu, index)
    ) : 
    ( !profileCompleted && (inactiveMenu.includes(menu.label) || (menu.label !== 'Update Profile' && profileRole === 'SPV')) ? 
    <OverlayTrigger
  placement="bottom"
  overlay={<Tooltip id="button-tooltip-2">Please complete the profile</Tooltip>}>
        <MenuItem  icon={menu.icon} className='inactive' active={false}>{menu.label}<Link to='#' /></MenuItem>
        </OverlayTrigger> :
        <MenuItem icon={menu.icon} active={menu.key === window.location.pathname}>{menu.label}<Link to={menu.key} /></MenuItem>
    )
    
  })}
  </Menu>
  </SidebarContent>
  
{/* <SidebarFooter>
<Menu>
{options['profileRoles'].map((role, index) => {
    return role.subMenu && role.subMenu.length > 0 ?
    (
      createRoleSubMenu(role, index)
    ) :
    (
      <MenuItem key={index}><Link to={role.key} />{role.label}</MenuItem>
    )
  })}
  </Menu>
</SidebarFooter> */}
</ProSidebar>
<Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="checkbox-error-handling-modal"
      show={showMessagePopup}
      onHide={() => setShowMessagePopup(false)}
      >
      <Modal.Body>
          <div className="center-title">
            <br></br>

            <h3 className="text-center">Role Changed to {roleMessage}</h3>
          
          </div>
        </Modal.Body>
 
      </Modal>
</div>
</SidebarWrapper>
  )
}

export default SideMenu;