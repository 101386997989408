import React, { useEffect, memo } from 'react'
import { useSelector } from 'react-redux';
import FundsHistoryTableStyleWrapper from './fundsHistoryTable.style';
import menuOption from '../../helpers/images/assets/VerticalDotsMenu-Grey.svg';
import {Dropdown} from 'react-bootstrap';
import { useNavigate } from 'react-router';

const FundsHistoryTable = (props) => {
  const uid = useSelector(state => state.app.uid);
  const navigate = useNavigate();
  useEffect(() =>{
    if(props){
  }
  },[props])
  const gotoForm = () =>{
    navigate("form");
  }
return(
  <FundsHistoryTableStyleWrapper>
      <table>
        <tr>
            <th>Requested Date</th>
            <th>Funds Required For</th>
            <th style={{textAlign: 'right'}}>Currency</th>
            <th>Amount</th>
            <th>Status Date</th>
            <th>Status</th>
            <th></th>
            <th></th>
        </tr>
        <tr onClick={() => props.parent === 'SPV' ? gotoForm() : {}}>
            <td>20-Apr-2022</td>
            <td>Purchasing Assets</td>
            <td style={{textAlign: 'right'}}>USD</td>
            <td>100,000.00</td>
            <td>30-Apr-2022</td>
            <td>Received</td>
            <td><span className="circle_ received"></span></td>
            <td><span className='options_'>
                <Dropdown align="end">
                    <Dropdown.Toggle variant="success" id="">
                      <img src={menuOption} className="header-icon" alt="MenuOption" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Received</Dropdown.Item>
                      <Dropdown.Item>Not Received</Dropdown.Item>
                      <Dropdown.Item>Re-Submitted</Dropdown.Item>
                      <Dropdown.Item>Others</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown></span></td>
        </tr>
        <tr  onClick={() => props.parent === 'SPV' ? gotoForm() : {}}>
            <td>02-May-2022</td>
            <td>License and Operations Expenses</td>
            <td style={{textAlign: 'right'}}>USD</td>
            <td>250,000.00</td>
            <td>10-May-2022</td>
            <td>Received</td>
            <td><span className="circle_ received"></span></td>
            <td>  <span className='options_'>
                <Dropdown align="end">
                    <Dropdown.Toggle variant="success" id="">
                      <img src={menuOption} className="header-icon" alt="MenuOption" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Received</Dropdown.Item>
                      <Dropdown.Item>Not Received</Dropdown.Item>
                      <Dropdown.Item>Re-Submitted</Dropdown.Item>
                      <Dropdown.Item>Others</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown></span></td>
        </tr>
        <tr onClick={() => props.parent === 'SPV' ? gotoForm() : {}}>
            <td>04-May-2022</td>
            <td>Purchasing Assets</td>
            <td style={{textAlign: 'right'}}>USD</td>
            <td>150,000.00</td>
            <td>20-May-2022</td>
            <td>Declined</td>
            <td><span className="circle_ declined"></span></td>
            <td><span className='options_'>
                <Dropdown align="end">
                    <Dropdown.Toggle variant="success" id="">
                      <img src={menuOption} className="header-icon" alt="MenuOption" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Received</Dropdown.Item>
                      <Dropdown.Item>Not Received</Dropdown.Item>
                      <Dropdown.Item>Re-Submitted</Dropdown.Item>
                      <Dropdown.Item>Others</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown></span></td>
        </tr>
        <tr onClick={() => props.parent === 'SPV' ? gotoForm() : {}}>
            <td>01-Aug-2022</td>
            <td>Purchasing Assets</td>
            <td style={{textAlign: 'right'}}>USD</td>
            <td>2,200,000.00</td>
            <td></td>
            <td>Pending</td>
            <td><span className="circle_ pending"></span></td>
            <td>
              <span className='options_'>
                <Dropdown align="end">
                    <Dropdown.Toggle variant="success" id="">
                      <img src={menuOption} className="header-icon" alt="MenuOption" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Received</Dropdown.Item>
                      <Dropdown.Item>Not Received</Dropdown.Item>
                      <Dropdown.Item>Re-Submitted</Dropdown.Item>
                      <Dropdown.Item>Others</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown></span></td>
        </tr>
      </table>
      </FundsHistoryTableStyleWrapper>
      )}

      export default memo(FundsHistoryTable);