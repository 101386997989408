import styled from 'styled-components';
import { APPCOLORS } from '../helpers/constants';
import WithDirection from '../helpers/rtl';

const SimpleCardStyleWrapper = styled.div`
width: 30%;
height: auto;
.side-card{
    background: ${APPCOLORS.bgColor}; /* fallback for old browsers */
    background: -webkit-${APPCOLORS.sidebarBg}; /* Chrome 10-25, Safari 5.1-6 */
    background: ${APPCOLORS.sidebarBg};
    width: 100% !important;
    margin: 0px 10px 0px 10px;
    padding: 10px;
    color: white;
    height: 100%;

}
.side-card .card-title{
    font-size: 20px;
    text-align: center;
    font-weight: 800;
}
`;

export default WithDirection(SimpleCardStyleWrapper);
