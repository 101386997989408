import React from 'react';
import Sidebar from './components/Sidebar/sidebar_';
import Topbar from './components/Topbar/topbar';
import BusinessRoutes from './ProfileRoles/Business/businessRoutes';
import DDRoutes from './ProfileRoles/DueDiligence/ddRoutes';
import { PRIVATE_ROUTE } from './helpers/routerConstants';
import CommonRoutes from './ProfileRoles/commonRoutes';
import StructuringRoutes from './ProfileRoles/Structuring/structuringRoutes';
import QadminRoutes from './ProfileRoles/Admin/adminRoutes';
import ContainerStyleWrapper from './container.style';
import SPVRoutes from './ProfileRoles/SPV/spvRoutes';


const routes ={[PRIVATE_ROUTE.BUSINESS] : <BusinessRoutes />, 
               [PRIVATE_ROUTE.DueDiligencePartner] : <DDRoutes />, 
               [PRIVATE_ROUTE.COMMON] : <CommonRoutes /> ,
               [PRIVATE_ROUTE.STRUCTURING] : <StructuringRoutes /> ,
               [PRIVATE_ROUTE.QADMIN] : <QadminRoutes /> ,
               [PRIVATE_ROUTE.SPV] : <SPVRoutes /> ,
            }
const Container = ({customPath}) =>{
    return (
        <ContainerStyleWrapper>
       <div>
           <div className='container_ d-flex'>
               <Sidebar />
               <div className="right_content">
                   <Topbar/>
                   <div className="body">
                    {routes[customPath]}
                    </div>
               </div>
           </div>
       </div>
       </ContainerStyleWrapper>
    )
}
export default Container;