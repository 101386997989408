import React, { useEffect, useState } from 'react'
import './../App.css';
import FormStyleWrapper from '../publicPages/form.style';
import { Button, Card, Container, Modal, Form } from 'react-bootstrap';
import qiamLogoWhite from '../helpers/images/assets/qiam-white-logo.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileRole, setExternalId, setBusinessRole, setBusinessRoles, setProfileRoleDescription } from '../actions'
import { customData } from '../helpers/constants';
import { PRIVATE_ROUTE } from '../helpers/routerConstants';


let associations = JSON.parse(localStorage.getItem('associations'));
const {BUSINESS_ROLES_FOR_DESCRIPTION, BUSINESS_ROLES, BUSINESSROLE_BASED_URL} = customData;
const Associations = () => {
  const businessRoles = useSelector(state => state.app.businessRoles);
  const [accounts, setAccounts] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showRoleSelectionOptions, isShowRoleSelectionOptions] = useState(false);
  const [isBusinessRole, setIsBusinessRole] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() =>{
    associations = JSON.parse(localStorage.getItem('associations'));
    const tempAccounts = [];
      if(associations.length > 0){
        associations.map(association =>{
            tempAccounts.push(association.Account);
        })
      }
      setAccounts(tempAccounts);
      if(tempAccounts.length === 1){
        const tempCompany = tempAccounts[0];
        setSelectedAccount(tempCompany);
        isShowRoleSelectionOptions(tempCompany);
        
    const tempExternalId = associations.find(association => association.Account === tempAccounts[0]).ExternalId;
    const tempRoles = associations.find(association => association.Account === tempAccounts[0]).BusinessRoles;
        updateRole(tempExternalId, tempRoles);
      }
  },[])
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setSelectedAccount(value);
    const tempExternalId = associations.find(association => association.Account === value).ExternalId;
    const tempRoles = associations.find(association => association.Account === value).BusinessRoles;
    updateRole(tempExternalId, tempRoles);
    // setRoles(tempRoles);
    // if(tempRoles === null || tempRoles.length === 0){
    //   handleRoleForRoutes(customData.BUSINESS_ROLES['QAdmin']);
    //   dispatch(setBusinessRoles(customData.BUSINESS_ROLES['QAdmin']));
    // }
    // else if(tempRoles.length === 1){
    //   handleRoleForRoutes(tempRoles[0]);
    //   dispatch(setBusinessRoles(tempRoles));
    // }
    // else{
    //   // dispatch(setBusinessRole(tempBusinessRole));
    //   // dispatch(setProfileRole(tempBusinessRole));
    //   dispatch(setBusinessRoles(tempRoles));
    //   isShowRoleSelectionOptions(true);
    // }
    // dispatch(setExternalId(tempExternalId));
  }
  const updateRole = (tempExternalId, tempRoles) =>{
    setRoles(tempRoles);
    if(tempRoles === null || tempRoles.length === 0){
      handleRoleForRoutes(BUSINESS_ROLES['QAdmin']);
      dispatch(setBusinessRoles(BUSINESS_ROLES['QAdmin']));
    }
    else if(tempRoles.length === 1){
      handleRoleForRoutes(tempRoles[0]);
      dispatch(setBusinessRoles(tempRoles));
    }
    else{
      // dispatch(setBusinessRole(tempBusinessRole));
      // dispatch(setProfileRole(tempBusinessRole));
      dispatch(setBusinessRoles(tempRoles));
      isShowRoleSelectionOptions(true);
    }
    dispatch(setExternalId(tempExternalId));
  }
  const handleRoleChange = (event) => {
    const { name, value } = event.target;
    setSelectedRole(value);
    handleRoleForRoutes(value);
  }
  const handleRoleForRoutes = (role) => {
    dispatch(setBusinessRole(role));
    dispatch(setProfileRole(role));
    
    dispatch(setProfileRoleDescription(BUSINESS_ROLES_FOR_DESCRIPTION[role]));
    setIsBusinessRole(role);
  }
  return (
    <FormStyleWrapper>
    <Container className='d-flex justify-content-center align-items-center' style={{ padding: '40px', height: '100vh' }}>
      <div className="watermark right" style={{right:'19%'}}></div>

      <div className="d-flex align-center center-title" style={{flexDirection: 'column'}}>
        <div>
          {/* <img
            src={qiamLogoWhite}
            alt="Logo"
            style={{ width: '50px', height: '50px' }}
            className="center-title"
          /> */}
          {accounts.length > 1 && <h2>Select Company</h2>}
          {accounts.length === 1 && <h2>Select Role</h2>}
        </div>
              <Form>
                {accounts.length > 1 && <Form.Group className="mb-4 position-relative">
                  <Form.Select style={{margin : '0 auto'}} name="association" placeholder="Your Email Id" value={selectedAccount} onChange={handleChange} >
                            <option value={'selectAccount'}>Select Account</option>
                     {accounts.map(account =>{
                         return(
                            <option value={account}>{account}</option>
                         )
                     })} 
                  </Form.Select>
                </Form.Group>}
                {showRoleSelectionOptions && <Form.Group className="mb-4 position-relative">
                  <Form.Select style={{margin : '0 auto'}} name="association" placeholder="Your Email Id" value={selectedRole} onChange={handleRoleChange} >
                            <option value={'selectAccount'}>Select Role</option>
                     {roles.map(role =>{
                         return(
                            <option value={role}>{role}</option>
                         )
                     })} 
                  </Form.Select>
                </Form.Group>}
                <br></br>
                <Button disabled={isBusinessRole === ''} className="light-button custom-button mt-2" onClick={() => navigate(PRIVATE_ROUTE[BUSINESSROLE_BASED_URL[isBusinessRole]])}>OK</Button>
                <br></br>

              </Form>
      </div>
    </Container>
    </FormStyleWrapper>
  )
}

export default Associations
