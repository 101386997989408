


import React, { useEffect, useState, useRef } from 'react'
import { Card, Modal, Form, Row, Col } from 'react-bootstrap'
import Loading from '../../helpers/Loader/loader';
import apiManager from './../../helpers/apiManager';
import { AiOutlineInfoCircle } from 'react-icons/ai'
// import './requests.css';
// import CustomCard from '../simpleCard';
import {useSelector} from 'react-redux';
import { customData } from '../../helpers/constants';
import { useNavigate } from 'react-router';
import {PRIVATE_ROUTE} from '../../helpers/routerConstants'



const OtherInfo = (props) => {
  const {partners} = props;
    const navigate = useNavigate();
    const externalId = useSelector((state) => state.app.externalId);
    const applicationId = useSelector((state) => state.app.applicationId);
    const uid = useSelector((state) => state.app.uid);
    const [saveLoading, setSaveLoading] = useState(false);
    const [successInfo, setSuccessInfo] = useState('');
    const [errorInfo, setErrorInfo] = useState('');
    const [successInfoShow, setSuccessInfoShow] = useState('');
    const [serverError, setServerError] = useState('');
    const [currencyCodes, setCurrencyCodes] = useState([]);
    const [remarks, setRemarks] = useState('');
    const [formValues, setFormValues] = useState({
        dueDiligencePartner: '',
        nextStep: 'Proceed to Due Diligence',
        adminRemarks: ''
    })
    
    useEffect(() => {
      // populateSukukData()
      if(props.isApplicationInfo && Object.keys(props.isApplicationInfo).length !== 0 ){
      const {AdditionalRemarks} = props.isApplicationInfo;
      // setFormValues({ ...formValues, [name]: value });
      setRemarks(AdditionalRemarks);
      setFormValues({
        ...formValues,
      })
    }
    
    const currencyCodesdata = {
      "Currency": [],
      "CurrencyCode": customData.ISO_CURRENCY_CODES,
    }
    const storedCurrencyCodes = JSON.parse(localStorage.getItem('isoCurrencyCodes'));
    if (storedCurrencyCodes) {
      setCurrencyCodes(storedCurrencyCodes);
    }
    else {
      apiManager.getISOCurrencyCodes(currencyCodesdata)
        .then((response) => {
          setCurrencyCodes(response.data);
          localStorage.setItem('isoCurrencyCodes', JSON.stringify(response.data));
        })
        .catch((error) => { });
    }
    }, [props.isApplicationInfo])
  
  
    useEffect(() =>{
      if(partners.length > 0){
        setFormValues({
          ...formValues,
          dueDiligencePartner: partners[0].externalId
        })
    }
    },[partners])
    const handleChange = (event) => {
      const {name, value} = event.target;
      setFormValues({ ...formValues, [name]: value })
    }
    // populate file data
    // console.log( sukukData.ApplicationInfo, 'country code')
  
    const submitInfo = (event) => {
      event.preventDefault();
      const {dueDiligencePartner, adminRemarks} = formValues;
      setSaveLoading(true);
      const data ={
        "DuediligenceExternalId": dueDiligencePartner,
        "ApplicationId": applicationId,
        "DuediligenceAssignment":{
          // "AssignedDate": "2022-06-28 23:07:00",
          // "StatusDate": "2022-06-28 23:07:00",
          "Status" : 1,
          "Remarks": adminRemarks,
          "AssignedBy" : externalId
        }
        };
      apiManager.associateDueDiligencePartner(data, uid)
      .then((res) => {
        setSaveLoading(false);
        setSuccessInfoShow(true);
        setTimeout(()=>{
          setSuccessInfoShow(false)
          navigate('/qadmin'+PRIVATE_ROUTE.NEWSUKUKREQUEST);
        }, 2000);
      })
      .catch((err) => {
        setServerError(true);
        setSaveLoading(false);
        setErrorInfo(err.res.data.Error.errorMessage);
        setTimeout(()=>{
          setServerError(false)
        }, 2000);
      })
    }
    const {dueDiligencePartner, nextStep, adminRemarks} =  formValues;
    return (
      <div className="layout layout-horizontal sukuk-info-tab">
        <div className="info-card">
            <Card className="sukuk-info-card border-0">
              <Card.Body>
                  <Card.Text>
                          <>
                          {saveLoading && <Loading />}
                        <div className="px-3">
                            <Row className="mx-3 mb-3">
                                <Col>
                                    <label className='w-100 text-start form-label'>Additional Remarks</label>
                                    <textarea className='w-100' rows={5} defaultValue={remarks} disabled={true}/>
                                </Col>
                            </Row>
                            <p className="sukuk-document-title">{'Next Action'}</p>
                          <Form style={{maxWidth: '600px', margin: '0 auto'}}>
                            <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail" size="lg">
                              <Form.Label column sm={12} className="text-start m-0 form-label">
                              Next Steps
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                                <Form.Select aria-label="Default select example" onChange={handleChange} 
                                name="nextStep" style={{ height: '45px', width: '100%', borderColor: '#ced4da' }} 
                                value={nextStep}
                                className={JSON.stringify(nextStep).length > 0 ? 'highlight' : ''}
                                >
                                  <option key ="Revert to Business" >Revert to Business</option>
                                  <option key ="Proceed to Due Diligence" >Proceed to Due Diligence</option>
                                  <option key ="Close Sukuk Request" >Close Sukuk Request</option>
                                  <option key="Decline Sukuk Request (No-go)" >Decline Sukuk Request (No-go)</option>
                                </Form.Select>
                                
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail" size="lg">
                              <Form.Label column sm={12} className="text-start m-0 form-label">
                              Select Due Diligence Partner
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                                <Form.Select aria-label="Default select example" onChange={(e) => handleChange(e)} 
                                name="dueDiligencePartner" style={{ height: '45px', width: '100%', borderColor: '#ced4da' }} 
                                value={dueDiligencePartner}
                                className={JSON.stringify(dueDiligencePartner).length > 0 ? 'highlight' : ''}
                                >
                                {partners.length > 0 && partners.map(partner => {
                                  return(
                                    <option value={partner.externalId}>{partner.value}</option>
                                  )
                                })}
                                  {/* <option key ="Ennovate LLC" >Ennovate LLC</option> */}
                                  {/* <option key ="rtb" >Revert to Business</option>
                                  <option key ="pts" >Proceed to Structuring</option>
                                  <option key="dsr" >Decline Sukuk Request (No-go)</option> */}
                                </Form.Select>
                                
                              </Col>
                            </Form.Group>
                            
                            <Form.Group>
                              <Form.Label as="legend" column sm={12} className="form-label">
                              Remarks
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                              <Form.Control
                                as="textarea"
                                placeholder=""
                                name="adminRemarks"
                                value={adminRemarks}
                                onChange={handleChange}
                                className={adminRemarks.length > 0 ? 'highlight' : ''} 
                                style={{height: '100px'}}
                              />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                                {/* <Link to="/dashboard/new-sukuk/info"> */}
                                {/* </Link> */}
                                {/* <Link to="/dashboard/new-sukuk/documents"> */}
                                  <button className="custom-button" onClick={submitInfo}>Submit</button>
                                {/* </Link> */}
                              </Col>
                            </Form.Group>
                          </Form>
  
                        </div>
                        </>
  
                  </Card.Text>
              </Card.Body>
  
              {/* <div
                className="layout layout-horizontal"
                style={{ justifyContent: 'space-around' }}
              >
                <div>
                  <button className="custom-button" onClick={() => saveSukukInfo()}>
                    Save
                  </button>
                </div>
                <div>
                  <Link to="/dashboard/new-sukuk/documents">
                    <button className="custom-button">Next</button>
                  </Link>
                </div>
              </div> */}
            </Card>
        </div>
  
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={successInfoShow}
          onHide={() => setSuccessInfoShow(false)}
        >
          {/* Sucesss Modal  */}
  
          <Modal.Body closeButton>
            <div className="center-title">
              <br></br>
              <h4>Submitted Successfully</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
  
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={serverError}
          onHide={() => setServerError(false)}
        >
          {/* Error Modal  */}
  
          <Modal.Body>
            <div className="center-title">
              <br></br>
              <h4> {errorInfo}</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }


  export default OtherInfo;