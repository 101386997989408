import React, { useEffect, useState, useRef, useCallback } from 'react'
// import '../ProfileForm/ProfileForm.css'
import { Button, Card, Modal, Dropdown, Row, Col, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { BiCheckCircle, BiXCircle } from 'react-icons/bi'
import Loading from './../../helpers/Loader/loader';
import { ProgressBar } from 'react-bootstrap'
import CustomCard from './../simpleCard';
import { useDispatch, useSelector } from 'react-redux';
import apiManager from '../../helpers/apiManager';
import SukukTabStyleWrapper from './sukukTab.style';
import { config, apiEndPoints } from './../../helpers/constants';
import DocumentIcon from '../../helpers/images/assets/documentDark.svg';
import FileDownloadDark from '../../helpers/images/assets/File_Download_Icon.svg';
import FileDownloadGrey from '../../helpers/images/assets/File_Download_Icon_grey.svg';
import { useNavigate } from 'react-router';
import { setDocument, setDocumentName, setDocumentType } from '../../actions';
import pdf from '../../../src/test.pdf';
import fileDownload from 'js-file-download';
import axios from 'axios';

const url = config.url.API_URL;
const {FILEDOWNLOAD
} = apiEndPoints
const Documents = (props) => {
  let interval;
  const dispatch = useDispatch();
  const externalId = useSelector((state) => state.app.externalId);
  const uid = useSelector((state) => state.app.uid);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const requiredArtefacts = props.required_Artefacts;
  const [warningPopup, setWarningPopup] = useState(false);
  const [warning, setWarning] = useState(false);
  // const tempFiles = props.required_Artefacts.map(doc => (
  //   { id: doc.RequiredArtefactId, 
  //     label: doc.RequiredArtefactName, 
  //     isMandatory: doc.isMandatory, 
  //     Type: doc.Type,
  //     progress: 0,
  //     uploading: false
  //   }
  //   ));
  const [files, setFiles] = useState([]);
  const [initialFiles, setInitialFiles] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [progress, setProgress] = useState(0);
  // const [fileName, setFileName] = useState();
  const types = Array.from(new Set(requiredArtefacts.map((item) => item.Type)));
  const fileSelectHandler = (event, id) => {
    let temp_files = [...files];
    let temp_file = { ...temp_files.find(file_ => file_.id === id) };
    const index = temp_files.findIndex(file_ => file_.id === id);
    temp_file.fileName = event.target.files[0].name;
    temp_file.file = event.target.files[0];
    temp_files[index] = temp_file;
    setFiles(temp_files);
    setWarning(true);
  }
  const handleDownload = (id, filename, docId, appId) => {
    const formData = new FormData();
    if (docId) {
      formData.append('ApplicationDocumentId', docId);
    }
    else {
      formData.append('ApplicationId', appId);
      formData.append('ExternalId', externalId);
      formData.append('RequiredArtefactId', id);
      formData.append('file', filename);
    }
    axios.post(`${url+FILEDOWNLOAD}`, formData, {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>',
        'x-ccx-api-key':
          'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbqEAVxItCetPaj541Od+TgY=',
        'x-ccx-api-usr' : uid,
        // 'Access-Control-Allow-Origin': '*'
        },
        responseType: 'blob'
    })
    // apiManager.fileDownload(formData, uid)
      .then((response) => {
        fileDownload(response.data, filename);
      })
      .catch((error) => {
      })
    // const response = axios({
    //   method: "get",
    //   url: `${url+FILEDOWNLOAD}`,
    //   headers: {
    //     'Content-Type':
    //           'multipart/form-data; boundary=<calculated when request is sent>',
    //         'x-ccx-api-key':
    //           'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbqEAVxItCetPaj541Od+TgY=',
    //         'x-ccx-api-usr' : uid,
    //         'Access-Control-Allow-Origin': '*',
    //         'origin': '*'
    //   },
    //   data: formData,
    // })
    // axios.get(url, {
    //   responseType: 'blob',
    // })
    // .then((res) => {
    //   fileDownload(res.data, filename)
    // })
  }
  useEffect(() => {
    if (props.isApplicationDocuments && props.required_Artefacts) {
      if (Object.keys(props.isApplicationDocuments).length !== 0 || Object.keys(props.required_Artefacts).length !== 0) {
        const tempFiles = props.required_Artefacts.map(doc => (
          {
            id: doc.RequiredArtefactId,
            label: doc.RequiredArtefactName,
            isMandatory: doc.isMandatory,
            Type: doc.Type,
            applicationDocId: doc.ApplicationDocumentId,
            applicationId: doc.ApplicationId
          }
        ));
        setFiles(tempFiles);
        setInitialFiles(tempFiles);
        setDocuments();
      }
    }
  }, [props.isApplicationDocuments, props.required_Artefacts])

  useEffect(() => {
    if (initialFiles.length > 0)
      setDocuments();
  }, [initialFiles])
  const setDocuments = () => {
    if (props.isApplicationDocuments.length > 0) {
      let temp_files = [...initialFiles];
      var result = temp_files.map(o1 => {
        const tempFileName = props.isApplicationDocuments.find((o2) => (o1.id === o2.RequiredArtefactId)) ? 
                             props.isApplicationDocuments.find((o2) => (o1.id === o2.RequiredArtefactId)).FileName ? 
                             props.isApplicationDocuments.find((o2) => (o1.id === o2.RequiredArtefactId)).FileName : '' : '';
        const applicationDocId =  props.isApplicationDocuments.find((o2) => (o1.id === o2.RequiredArtefactId))?.ApplicationDocumentId; 
        const applicationId =  props.isApplicationDocuments.find((o2) => (o1.id === o2.RequiredArtefactId))?.ApplicationId; 
        return (
          { ...o1, 'fileName': tempFileName, applicationDocId: applicationDocId, applicationId: applicationId }
        )
      }
        // filter out (!) items in result2

        // if(o1.id === o2.RequiredArtefactId){
        //   o1.FileName = o2.FileName
        // } // assumes unique id
      );
      setFiles(result);
    }
  }
  const openFileViewer = (docId, fileName) => {
    const tempFileTypeIndex = fileName.lastIndexOf(".");
    const tempFileType = fileName.substring(tempFileTypeIndex + 1);
    const tempFileName = fileName.substring(0, tempFileTypeIndex);
    const formData = new FormData();
    if (docId) {
      formData.append('ApplicationDocumentId', docId);
    }
    axios.post(`${url+FILEDOWNLOAD}`, formData, {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>',
        'x-ccx-api-key':
          'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbqEAVxItCetPaj541Od+TgY=',
        'x-ccx-api-usr' : uid,
        // 'Access-Control-Allow-Origin': '*'
        },
        responseType: 'blob'
    })
    // apiManager.fileDownload(formData, uid)
    .then((response) => {
      const objectURL = URL.createObjectURL(response.data);
      dispatch(setDocumentType(tempFileType));
    dispatch(setDocument(objectURL));
    dispatch(setDocumentName(tempFileName));
    navigate('/common/preview');
      })
      .catch((error) => {
      })

  }
  const goNext = (e) => {
    props.setTab('other');
  }
  return (
    <SukukTabStyleWrapper>
      <div className="layout layout-horizontal">
        <Card className="document border-0 w-100" style={{ maxWidth: 760 }}>
          <Card.Body className='pt-2'>
            {/* <Card.Title>Documents</Card.Title> */}
            <>
              {requiredArtefacts && loading && <Loading />}
              <Card.Text>
                {types.length > 0 && types.map((type) => {
                  return (
                    <div>
                      <p className="sukuk-document-title">{type + ' Documents'}</p>
                      {files.filter((doc) => doc.Type === type).sort((a, b) => a.id - b.id).map((doc) => {
                        return (
                          <Row className={`align-items-center mb-3 px-4`} key={doc.id}>
                            <Col sm={12} className="text-start d-flex align-items-center">
                              {/* <img className="doc_icon" src={documentImg} /> */}
                              <span className="docIcon"> {doc.fileName ? <img src={DocumentIcon} /> : <img src={DocumentIcon} />}</span>
                              <label onClick={() => openFileViewer(doc.applicationDocId, doc.fileName)} className={`mx-3 my-0 ${doc.fileName ? 'underline' : 'gradeOut'}`}>{doc.label}</label>
                              <span className={`downloadIcon ${doc.fileName ? '' : 'grey'}`} >
                                {doc.fileName ?
                                  <img onClick={() => {
                                    handleDownload(doc.id, doc.fileName, doc.applicationDocId, doc.applicationId)
                                  }} src={FileDownloadDark} />
                                  :
                                  <img src={FileDownloadGrey} />
                                }
                              </span>
                            </Col>
                            {/* <Col sm={8}>
                            <label>{' '+doc.fileName}</label>
                          </Col> */}
                          </Row>
                        )
                      }
                      )}
                    </div>
                  )
                }
                )}

                <Row as={Row} className="mb-3">
                  <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                    {/* <Link to="/dashboard/new-sukuk/info"> */}
                    {/* <button className="custom-button" 
                                // onClick={(e) => saveSukukDoc(e)}
                                >
                                  Save
                                </button> */}
                    {/* </Link> */}
                    {/* <Link to="/dashboard/new-sukuk/documents"> */}
                    <button className="custom-button" onClick={goNext} >Next</button>
                    {/* </Link> */}
                  </Col>
                </Row>
              </Card.Text>
            </>
          </Card.Body>
        </Card>
      </div>
    </SukukTabStyleWrapper>
  )
}

export default Documents
