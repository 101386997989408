import {Link} from 'react-router-dom';
import home from '../../helpers/images/header-logos/Home_Icon.svg'
import help from '../../helpers/images/header-logos/Help_Icon.svg'
import notification from '../../helpers/images/header-logos/Notification.svg'
import settings from '../../helpers/images/header-logos/Settings.svg'
import user from '../../helpers/images/header-logos/user.svg'
import {customData} from './../../helpers/constants';
import {useDispatch, useSelector} from 'react-redux';
import TopbarStyleWrapper from './topbar.style';
import {Dropdown} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { reset, setLoggedIn } from '../../actions';

const Topbar = (props) => {
  const profileRole = useSelector((state) => state.app.profileRole);
  const profileRoleDescription = useSelector((state) => state.app.profileRoleDescription);
  const emailId = useSelector(state => state.app.emailId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signOut = () =>{
    dispatch(reset());
    localStorage.clear();
    dispatch(setLoggedIn(false));
    navigate('/signin');
;  }
    return (
      <TopbarStyleWrapper>
      <div className="header-container">
        <div className="qiam-title-black mb-0">{emailId}</div>
        <div></div>
        <div></div>
        <div>Role: {profileRoleDescription}</div>
  
        <div>
          <Link to="/profile">
            <img src={home} className="header-icon" id="home" alt="home" />
          </Link>
          <img src={help} className="header-icon" alt="help" />
          <img src={settings} className="header-icon" alt="settings" />
          <img src={notification} className="header-icon" alt="notification" />
          
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
            <img src={user} className="header-icon" alt="user" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={signOut}>Sign out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      </TopbarStyleWrapper>
    )
  }

  export default Topbar;