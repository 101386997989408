import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const TopbarStyleWrapper = styled.div`
.header-icon{
    width: 30px;
    height: 30px;
    margin-left: 20px;
  }
  
  .header-container{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 30px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;
    box-shadow: 10px -1px 12px 5px #eaeaea;
    color: #000000;
    align-items: center;
  }
  .dropdown{
    display: inline-block;
    &.show{
      display: inline-block;
    }
    &-toggle{
      background: transparent !important;
      border: none !important;
      padding: 0;
      width: auto;
      box-shadow: none !important;
    }
  }
`;

export default WithDirection(TopbarStyleWrapper);
