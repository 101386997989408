


import React, { useEffect, useState, useRef } from 'react'
import { Card, Modal, Form, Row, Col, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import Loading from '../../helpers/Loader/loader';
import apiManager from './../../helpers/apiManager';
import { AiOutlineInfoCircle } from 'react-icons/ai'
// import './requests.css';
// import CustomCard from '../simpleCard';
import {useSelector} from 'react-redux';
import { customData } from '../../helpers/constants';
import { useNavigate } from 'react-router';
import {PRIVATE_ROUTE} from '../../helpers/routerConstants'
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

import axios from 'axios';
import { config } from './../../helpers/constants';


const url = config.url.API_URL;

const OtherInfo = (props) => {
  const navigate = useNavigate();
  const uid = useSelector((state) => state.app.uid);
  const applicationId = useSelector((state) => state.app.applicationId);
    const externalId = useSelector((state) => state.app.externalId);
    const [saveLoading, setSaveLoading] = useState(false);
  const [successInfoShow, setSuccessInfoShow] = useState(false);
  const [error, setError] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [serverError, setServerError] = useState(false);
    const [currencyCodes, setCurrencyCodes] = useState([]);
    const [remarks, setRemarks] = useState('');
    const[fileInfo, setFileInfo]= useState({
      fileName: '',
      uploading: false,
    })
    const [uploaded, setUploaded] = useState(false);
    const [file, setFile] = useState();
    const [formValues, setFormValues] = useState({
        dueDiligenceAction: 'ddi',
        dueDiligenceCurrency: customData.DEFAULT_CURRENCY,
        dueDiligenceFee: '', // need validation
        ddRemarks: ''
    })
  
    useEffect(() => {
      // populateSukukData()
      if(props.isApplicationInfo && Object.keys(props.isApplicationInfo).length !== 0 ){
      const {AdditionalRemarks} = props.isApplicationInfo;
      // setFormValues({ ...formValues, [name]: value });
      setRemarks(AdditionalRemarks);
      setFormValues({
        ...formValues,
      })
    }
    
    const currencyCodesdata = {
      "Currency": [],
      "CurrencyCode": customData.ISO_CURRENCY_CODES,
    }
    const storedCurrencyCodes = JSON.parse(localStorage.getItem('isoCurrencyCodes'));
    if (storedCurrencyCodes) {
      setCurrencyCodes(storedCurrencyCodes);
    }
    else {
      apiManager.getISOCurrencyCodes(currencyCodesdata)
        .then((response) => {
          setCurrencyCodes(response.data);
          localStorage.setItem('isoCurrencyCodes', JSON.stringify(response.data));
        })
        .catch((error) => { });
    }
    }, [props.isApplicationInfo])
  
  
    const handleChange = (event) => {
      const {name, value} = event.target;
      setFormValues({ ...formValues, [name]: value })
    }
    // populate file data
    // console.log( sukukData.ApplicationInfo, 'country code')
  
    const submitDDInfo = (event) => {
      event.preventDefault();
      setSaveLoading(true);
      const data ={
        "ApplicationId": applicationId,
        "DuediligenceApproval": {
            "Remarks" : ddRemarks,
            "Payment" : dueDiligenceFee === '' ? 0 : parseInt(dueDiligenceFee),
            "PaymentCurrency": dueDiligenceCurrency
            }
    };
      apiManager.ddApprove(data, uid)
      .then((res) => {
        setSaveLoading(false);
        setSuccessInfoShow(true);
        setTimeout(()=>{
          setSuccessInfoShow(false);
          navigate('/dd'+PRIVATE_ROUTE.NEWSUKUKREQUEST);
        }, 2000);
      })
      .catch((err) => {
        setServerError(true);
        setSaveLoading(false);
        setErrorInfo(err.res.data.Error.errorMessage);
        setTimeout(()=>{
          setServerError(false)
        }, 2000);
      })
    }
    const fileSelectHandler = (event, id) => {
      const {files} = event.target;
      setFileInfo({fileName: files[0].name,  uploading: false});
      setUploaded(false);
      setFile(files[0]);
    }
    const handleFileUpload = async (e, id) => {
      e.preventDefault();
      setFileInfo({...fileInfo, uploading: true});
      const formData = new FormData()
      // to do 
      formData.append('files', file)
      formData.append('ExternalId', props.applicationExternalId);
      formData.append('ToUpdate', 'FALSE')
      formData.append('RequiredArtefactId', id);
      formData.append('InstrumentId', '101');
        formData.append('ApplicationId', applicationId);
      await axios
        .post(`${url}/Application/Documents/Upload/`, formData, {
          headers: {
            'Content-Type':
              'multipart/form-data; boundary=<calculated when request is sent>',
            'x-ccx-api-key':
              'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbqEAVxItCetPaj541Od+TgY=',
            'x-ccx-api-usr' : uid
            },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            //   let temp_files = [...files];
            // let temp_file = { ...temp_files.find(file_ => file_.id === id) };
            // const index = temp_files.findIndex(file_ => file_.id === id);
            // temp_file.progress = Math.round((100 * data.loaded) / data.total);
            // temp_files[index] = temp_file;
            // const progress = Math.round((100 * data.loaded) / data.total);
            // setProgress(progress);
          },
        })
  
        .then((response) => {
          console.log(response)
          return response.data
        })
        .then(async (response) => {
          await updateResponse(response);
          // const id = response.data[0].RequiredArtefactId
          // console.log(id,"idd")
  
        })
        .catch((error) => {
          console.log(error);
          // clearInterval(interval)
          setUploaded(false);
          setFileInfo({...fileInfo, uploading: false});
          setError(true);
        })
    }
    const updateResponse = (res) => {
      // setProgress(100);
      setUploaded(true);
      setFileInfo({...fileInfo, uploading: false});
      setError(false);
    }
    const {dueDiligenceAction, dueDiligenceCurrency, dueDiligenceFee, ddRemarks} =  formValues;
    const {uploading, fileName} = fileInfo;
    return (
      <div className="layout layout-horizontal sukuk-info-tab">
        <div className="info-card">
            <Card className="sukuk-info-card border-0">
              <Card.Body>
                  <Card.Text>
                          <>
                          {saveLoading && <Loading />}
                        <div className="px-3">
                            <Row className="mx-3 mb-3">
                                <Col>
                                    <label className='w-100 text-start form-label'>Additional Remarks</label>
                                    <textarea className='w-100' rows={5} defaultValue={remarks} disabled={true}/>
                                </Col>
                            </Row>
                            <p className="sukuk-document-title">{'Due Diligence Action'}</p>
                          <Form style={{maxWidth: '600px', margin: '0 auto'}}>
                            <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail" size="lg">
                              <Form.Label column sm={12} className="text-start m-0 form-label">
                              Due Diligence Action
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                                <Form.Select aria-label="Default select example" onChange={handleChange} 
                                name="dueDiligenceAction" style={{ height: '45px', width: '100%', borderColor: '#ced4da' }} 
                                value={dueDiligenceAction}
                                className={JSON.stringify(dueDiligenceAction).length > 0 ? 'highlight' : ''}
                                >
                                  <option key ="ddi" >Due Diligence Initiated</option>
                                  <option key ="rtb" >Revert to Business</option>
                                  <option key ="pts" >Proceed to Structuring</option>
                                  <option key="dsr" >Decline Sukuk Request (No-go)</option>
                                </Form.Select>
                                
                              </Col>
                            </Form.Group>
  
                            <Form.Group>
                            <Row className='align-items-center mb-3'>
                          <Col sm={12} className="text-start">
                            <label className="form-label">Due Diligence Assessment<span>*</span>:</label>
                          </Col>
                          <Col sm={12}>
                            <div className='d-flex align-items-center justify-content-between'>
                              <div className="d-flex inputWrapper position-relative">
                                <input
                                  className="form-control sukuk-document-input"
                                  type="file"
                                  // id={doc.RequiredArtefactId}
                                  // ref={inputFile}
                                  display="none"
                                  style={{ marginLeft: '0px' }}
                                  onChange={(e) => fileSelectHandler(e, 4101)}
                                />
                                <input
                                  className="form-control sukuk-document-input-text"
                                  type="text"
                                  // id={doc.id}
                                  // ref={inputFileName}
                                  value={fileName}
                                // placeholder={fileName1101 ? fileName1101 : ''}
                                />
                                {!uploaded && uploading && <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className='uploadingLoader'
                                  variant="primary"
                                />}
                                {uploaded && (
                                  <BiCheckCircle className="virus-icon virus-check-icon" />
                                )}
                                {!uploaded && !uploading && error && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip-2">Something went wrong, please try again</Tooltip>}
                                  >
                                    <BiXCircle className="virus-icon virus-error-icon" />
                                  </OverlayTrigger>
                                )}
                              </div>
                              <div className='d-flex align-items-center'>
                                {fileName === '' ? <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip id="button-tooltip-2">Please choose the file</Tooltip>}
                                >
                                  <button
                                    className="upload-button mx-2"
                                    disabled={fileName === ''}
                                  // ref={inputFile}
                                  >
                                    Upload
                                  </button>
                                </OverlayTrigger>
                                  :

                                  <button
                                    className="upload-button mx-2"
                                    // ref={inputFile}
                                    onClick={(e) =>
                                      handleFileUpload(e, 4101)
                                    }
                                  >
                                    Upload
                                  </button>
                                }
                                <AiOutlineInfoCircle className="info-icon-ai" />
                              </div>
                            </div>
                          </Col>
                          </Row>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalPassword" size="lg">
                              <Form.Label column sm={12} className="text-start m-0 form-label">
                              Due Diligence Fee
                              </Form.Label>
                              <Col sm={12} className="d-flex  align-items-center">
                              <Form.Select aria-label="Default select example" onChange={handleChange} 
                                name="dueDiligenceCurrency" style={{ marginRight: 10, width: '115px', height: '45px', borderColor: '#ced4da' }} 
                                value={dueDiligenceCurrency}
                                className={JSON.stringify(dueDiligenceCurrency).length > 0 ? 'highlight' : ''}
                                >
                                {currencyCodes.length > 0 && currencyCodes.map((country) => (
                                  <option key={country.CurrencyCode} value={country.CurrencyCode}>{country.CurrencyCode}</option>
                                ))}
                                </Form.Select>
                                <Form.Control name="dueDiligenceFee" value={dueDiligenceFee} onChange={handleChange} type="number" placeholder="0.00" style={{ width: 'calc(100% - 110px)'}}  
                                className={JSON.stringify(dueDiligenceFee).length > 0 ? 'highlight' : ''} 
                                /> 
                              </Col>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label as="legend" column sm={12} className="form-label" >
                              Due Diligence Remarks
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                              <Form.Control
                                as="textarea"
                                placeholder=""
                                name="ddRemarks"
                                value={ddRemarks}
                                onChange={handleChange}
                                className={ddRemarks.length > 0 ? 'highlight' : ''} 
                                style={{height: '100px'}}
                              />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                                {/* <Link to="/dashboard/new-sukuk/info"> */}
                                {/* </Link> */}
                                {/* <Link to="/dashboard/new-sukuk/documents"> */}
                                {fileName === '' ? <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip id="button-tooltip-2">Please choose the file</Tooltip>}
                                >
                                <button disabled={true} className="custom-button">Submit</button>
                                </OverlayTrigger>
                                  :
                                  <button onClick={(e) => submitDDInfo(e)} className="custom-button">Submit</button>
                                }

                                {/* </Link> */}
                              </Col>
                            </Form.Group>
                          </Form>
  
                        </div>
                        </>
  
                  </Card.Text>
              </Card.Body>
  
              {/* <div
                className="layout layout-horizontal"
                style={{ justifyContent: 'space-around' }}
              >
                <div>
                  <button className="custom-button" onClick={() => saveSukukInfo()}>
                    Save
                  </button>
                </div>
                <div>
                  <Link to="/dashboard/new-sukuk/documents">
                    <button className="custom-button">Next</button>
                  </Link>
                </div>
              </div> */}
            </Card>
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={successInfoShow}
          onHide={() => setSuccessInfoShow(false)}
        >
          {/* Sucesss Modal  */}
  
          <Modal.Body closeButton>
            <div className="center-title">
              <br></br>
              <h4>The Application is submitted successfully</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
  
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={serverError}
          onHide={() => setServerError(false)}
        >
          {/* Error Modal  */}
  
          <Modal.Body>
            <div className="center-title">
              <br></br>
              <h4> {errorInfo}</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
  
      </div>
    )
  }


  export default OtherInfo;