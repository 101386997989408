import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const SukukTabStyleWrapper = styled.div`
.docIcon{
    width: 25px;
    height: 30px;
    // background-image: url('../../helpers/images/assets/documentDark.svg');
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: contain;
}
.gradeOut{
    color: gray;
    opacity: 0.7;
    /* cursor: not-allowed; */
}
.downloadIcon{  
    width: 25px;
    height: 30px;
    cursor:pointer;
    &.grey{
        cursor: default;
    }
}
.underline{
    text-decoration: underline;
    cursor: pointer;
}
`;

export default WithDirection(SukukTabStyleWrapper);
