
import styled from 'styled-components';
// import { APPCOLORS } from './helpers/constants';
import WithDirection from '../../helpers/rtl';

const AdminStyleWrapper = styled.div`
.layout-horizontal{
    width: 100%;
    max-width: 900px;
    margin-right: auto;
    margin-left: 0px;
    >div{
        width: 100%;
    }
}
.fieldsSpacer{
    width: 670px;
    margin: 0 auto;
    text-align: left;
}`;

export default WithDirection(AdminStyleWrapper);