import React, { useState, useEffect } from 'react'
// import './requests.css'
import SukukCard from '../../components/SukukCard/sukukCard';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Modal } from 'react-bootstrap';
import apiManager from '../../helpers/apiManager';
import { useDispatch, useSelector } from 'react-redux';
import { setApplicationId, setSelectedSukukInfo } from '../../actions';
import Loading from '../../helpers/Loader/loader';
import utility from '../../helpers/utility';
import { FcCheckmark } from 'react-icons/fc';


const sortOptions = [{ label: 'Date Received' },
{ label: 'Location' },
{ label: 'Sukuk Size' },
{ label: 'Tenure' }];
const SukukRequests = () => {
  const uid = useSelector(state => state.app.uid);
  const urlRole = window.location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submittedApplications, setSubmittedApplications] = useState([]);
  const [accountRole, setAccountRole] = useState('');
  const [airTableUrl, setAirTableUrl] = useState('');
  const [loader, setLoader] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [popupMessage1, setPopupMessage1] = useState('');
  const [popupMessage2, setPopupMessage2] = useState('');
  const [popupMessage3, setPopupMessage3] = useState('This may take a while. Please wait...');
  const [popupMessage4, setPopupMessage4] = useState('');
  const [showCancel, setShowCancel] = useState(false);
  const details = {
    SubmittedOn: '05/Mar/2022',
    ApplicationName: 'Ennovate LLC',
    Account: 'Pied Piper Private Limited',
    size: '$1,000,000,000',
    place: 'Dubai, UAE',
    CompanyInfo: { Country: 'Dubai, UAE' },
    tenure: '40 months'
  }

  useEffect(() => {
    if (urlRole.includes('newSukukRequest')) {
      getSubmittedApplications();
      setAccountRole('DueDiligencePartner');
    }
    else if (urlRole.includes('ddRequests')) {
      getDueDiligenceData();
      setAccountRole('StructuringPartner');
    }
    else if (urlRole.includes('structuringRequests')) {
      getStructuringData();
      setAccountRole('Structuring');
    }
    apiManager.getBlockChainUrl()
      .then((response) => {
        let blockChainUrl = '';
        if (response.fields.useAirtable === 'yes') {
          blockChainUrl = response.fields.airtableUrl;
        }
        else {
          blockChainUrl = response.fields.firebaseURL;
        }
        setAirTableUrl(blockChainUrl);
      });
  }, [urlRole])
  const getSubmittedApplications = () => {
    setLoader(true);
    const data = {};
    apiManager.getSubmittedApplications(data)
      .then((response) => {
        if (response.data) {
          setSubmittedApplications(response.data);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });

  }
  const getDueDiligenceData = () => {
    setLoader(true);
    const data = {};
    apiManager.getDDApprovedApplications(data, uid)
      .then((response) => {
        if (response.data) {
          setSubmittedApplications(response.data);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  }
  const getStructuringData = (isShow = false, initializedData, id) => {
    if (!isShow) {
      setLoader(true);
    }
    else {
      initializedData.id = id;
    }
    const data = {};
    apiManager.getStructuringApprovedApplications(data)
      .then((response) => {
        let structuringData = [];
        if (response.data) {
          structuringData = response.data;
          setSubmittedApplications(response.data);
        }
        apiManager.getInitializedSukuks({})
          .then(res => {
            const newData = [];
            structuringData.map(sukuk => {
              const sukukInitializedStatus = res.records.find(record => record.fields.applicationId === sukuk.ApplicationId)?.fields?.status;
              sukuk['sukukInitializedStatus'] = sukukInitializedStatus ? sukukInitializedStatus : 'Pending';
              newData.push(sukuk);
            })
            if (isShow) {

            }
            setSubmittedApplications(newData);
            console.log(structuringData);
          })
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  }
  const gotoInfo = (id, app) => {
    navigate("info", { state: { applicationId: id, accountRole: accountRole } });
    dispatch(setApplicationId(id));
    dispatch(setSelectedSukukInfo(app));
  }
  const updateFunction = (app) => {
    if (app.sukukInitializedStatus !== 'initiated') {
      // setLoader(true);
      let sukukDetail = {};
      console.log('app', app);
      const data_ = {
        ApplicationId: app.ApplicationId
      }
      setLoadingPopup(true);
      setPopupMessage1('Getting Sukool Token Id...');
      setPopupMessage2(app.ApplicationName);
      apiManager.getSukukListed(data_, uid) //
        .then(resp => {
          sukukDetail = resp.data[0];
          console.log(sukukDetail);
          apiManager.getSukukId({}, airTableUrl) // get id  ---> 1
            .then(async (response) => {
              const id = response.idToUse;
              const data = {
                "id": id,
                "name": app.ApplicationName,
                "image": "",
                "description": sukukDetail.Application.ApplicationInfo.ReasonForSukuk,
                "issuingCompany": sukukDetail.Customer.Name,
                "issuanceCurrency": sukukDetail.IssuanceCurrency,
                "issuanceSize": sukukDetail.Application.ApplicationInfo.SukukIssuanceSize,
                "faceValue": sukukDetail?.Subscription?.FaceValueAmount,
                "tenureInMonths": sukukDetail.Application.ApplicationInfo.SukukTenureInMonths,
                "sukukStructingModel": sukukDetail.InstrumentStructuringType,
                "projectedYield": sukukDetail?.InstrumentYield?.Value,
                "projectedYieldUoM": sukukDetail?.InstrumentYield?.ValueUOM,
                "yieldShare": sukukDetail?.InvestorShare?.Value,
                "yieldShareUoM": sukukDetail?.InvestorShare?.ValueUOM
              };
              const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
              const files = [
                new File([blob], id + '.json')
              ]
              setPopupMessage1('Uploading to web storage...');
              const cid = await utility.documentUploadToWebstorag(files); // web3storage ---> 2
              const initiateData = {
                sukukName: app.ApplicationName,  //Sukuk Name
                totalSupply: sukukDetail.Application.ApplicationInfo.SukukIssuanceSize,  //Sukuk Issuance Size
                faceValue: sukukDetail?.Subscription?.FaceValueAmount, // Subscription Face Value
                uri: "ipfs://" + cid + '/'
              }
              setPopupMessage1('Initializing New Contracts for...');
              setPopupMessage2(app.ApplicationName);
              initiateData.id = id;
              apiManager.InstantiateNewContract(initiateData, airTableUrl) // node or airtable ---> 5
                .then(contractResponse => {
                  console.log('last response : ', contractResponse)
                  setLoadingPopup(false);
                  setSuccessPopup(true);
                  const businessDataContractAddress = contractResponse.businessDataContractAddress;
                  const entityDataContractAddress = contractResponse.entityDataContractAddress;
                  // const responseStatus = contractResponse.status;
                  apiManager.intitiateSukuk(initiateData, airTableUrl) // node ---> 3
                    .then((response) => {
                      const date = new Date();
                      const initializeData =
                      {
                        records: [
                          {
                            fields: {
                              externalId: app.ExternalId,
                              applicationId: app.ApplicationId,
                              sukukName: app.ApplicationName,
                              id: id,
                              dateUpdated: date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear(),
                            }
                          }
                        ]
                      }
                      apiManager.initializedSukuk(initializeData) // node or airtable ---> 4
                        .then(response => {
                          debugger;
                          const idForRef = response.records[0].id
                          const patchData = {
                            records: [
                              {
                                id: idForRef,
                                fields: {
                                  status: 'initiated',
                                  entityDataContractAddress,
                                  businessDataContractAddress
                                }
                              }
                            ]
                          }
                          apiManager.airtablePatch(patchData)
                            .then(res => {
                              getStructuringData(true, initiateData, id);
                            })
                            .catch(err => {
                              console.log('error', err)
                            })
                        })
                    })
                    .catch(initiateSukukError => {
                      setPopupMessage3('Error while creating sukool Token Id');
                      setPopupMessage4(`Reason: ${initiateSukukError?.res?.data?.message}`);
                      setShowCancel(true);
                    });
                })
                .catch(contractErr => {
                  setPopupMessage3('Error while initializing data contracts');
                  setPopupMessage4(`Reason: ${contractErr?.res?.data?.message}`);
                  setShowCancel(true);
                })

            })
            .catch((err) => {
              console.log(err);
            })

        })
    }
  }
  const closewithRefresh = () => {
    setLoadingPopup(false);
    setPopupMessage3('This may take a while. Please wait...')
    setPopupMessage4('');
    showCancel(false);
  }
  // to do check with the url and add the array according with api response
  return (

    <>
      {loader && <Loading />}
      <div className="container_">
        <div className="header_ text-start">
          <h4>New Sukuk Requests</h4>
        </div>
        <Row className={"justify-content-end"} style={{ width: '100%' }}>
          <Col sm={5} style={{ paddingRight: 0 }}>
            {submittedApplications.length > 0 && <Form>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3" className='text-end'>Sort by:</Form.Label>
                <Col sm={9} style={{ paddingRight: 0 }}><Form.Select>
                  {sortOptions.length > 0 && sortOptions.map((sort, idx) =>
                    <option key={idx} value={sort.label}>{sort.label}</option>
                  )}
                </Form.Select>
                </Col>
              </Form.Group>
            </Form>}
          </Col>
        </Row>
        {submittedApplications.length > 0 && submittedApplications.map(application => {
          return (

            accountRole !== 'Structuring' ? <div onClick={() => gotoInfo(application.ApplicationId, application)}>
              <SukukCard info={application} showOptions={false} />
            </div> :
              <div>
                <SukukCard info={application} showOptions={true} updateFunction={updateFunction} />
              </div>

          )
        })}
        {submittedApplications.length === 0 && <div className="noDataText">There are no Requests</div>}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={loadingPopup}
        >
          {/* Error Modal  */}

          <Modal.Body style={{ position: 'relative' }}>
            {showCancel && <span className="close_" onClick={() => closewithRefresh()}>X</span>}
            <div className="text-center">
              <br></br>
              <Loading className="light" top={20} />
              <br></br>
              <br></br>
              <br></br>
              <h5 style={{ fontWeight: 400 }}>{popupMessage1}</h5>
              <br></br>
              <h4>{popupMessage2}</h4>
              <br></br>
              <span style={{ textTransform: 'capitalize' }}>{popupMessage3}</span>
              <br></br>
              <span>{popupMessage4}</span>
              <br></br>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={successPopup}
        >
          {/* Error Modal  */}

          <Modal.Body style={{ position: 'relative' }}>
            <span className="close_" onClick={() => setSuccessPopup(false)}>X</span>
            <div className="text-center">
              <br></br>
              {/* <Loading className="light" top={20}/> */}
              <span className='tick check_'><FcCheckmark /></span>
              <br></br>
              <br></br>
              <br></br>
              <h3 style={{ marginBottom: 40 }}>{popupMessage2}</h3>
              <h4 style={{ fontWeight: 400 }}>Sukuk Initialized</h4>
              <br></br>
              <span></span>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default SukukRequests;