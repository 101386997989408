import React, { useState, useEffect } from 'react'
// import './requests.css'
import SukukCard from '../../components/SukukCard/spvSukukCard';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setApplicationId } from '../../actions';
import apiManager from '../../helpers/apiManager';
import Loading from '../../helpers/Loader/loader';
import FundsHistoryTable from '../../components/fundsHistory/fundsHistoryTable';
import FundDisbursementStyleWrapper from './fundDisbursement.style';

const FundDisbursement = () => {
  const externalId = useSelector(state => state.app.externalId);
  const uid = useSelector(state => state.app.uid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
  }, []);
  return (

    <FundDisbursementStyleWrapper>
      {loading && <Loading />}
      <div className="container_">
        <div className="header_ text-start">
          <h4>Fund Disbursement</h4>
        </div>
        <div className="summaryRow mb-3 d-flex justify-content-start">
            <div className="box_ d-inline-block border px-5 pt-3">
                <label>Total Subscribed Amount</label>
                <p><span>USD </span> 110,500,000</p>
            </div>
            <div className="box_ d-inline-block border px-5 pt-3 mx-3">
                <label>Funds Transferred/Utilised</label>
                <p className="red"><span>USD </span> 50,500,000</p>
            </div>
            <div className="box_ d-inline-block border px-5 pt-3 mr-2">
                <label>Amount Available With SPV</label>
                <p className="green"><span>USD </span> 60,000,000</p>
            </div>
        </div>
        <div className='table_'>
            <FundsHistoryTable parent="SPV"/>
        </div>
      </div>
    </FundDisbursementStyleWrapper>
  )
}

export default FundDisbursement;