import React, { useEffect, useState } from 'react';
import {Tabs, Tab, Modal} from 'react-bootstrap';
import Documents from './documentForm';
import SukukInfo from './sukukInfoForm';
import { useSelector } from 'react-redux';
import SukukSubmit from './sukukSubmit';
import FormStyleWrapper from './form.style';
import { customData } from '../../helpers/constants';


const SukukForm = (props) => {
    const [tab, setTab] = useState('SukukInfo');
    const [currentKey, setCurrentKey] = useState('SukukInfo');
  const [warningPopup, setWarningPopup] = useState(false);
  const [warning, setWarning] = useState(false);
  const [sukukInfoValues, setSukukInfoValues] = useState({
    sukukName: '',
    sukukIssuanceCurrency: customData.DEFAULT_CURRENCY,
    sukukIssuanceSize: '',
    sukukMonths: '',
    sukukESG: 'yes',
    sukukGreen: 'yes',
    sukukRemarks: ''
  });
  const goNext = (e, key) => {
    e.preventDefault();
    setCurrentKey(key);
    if (warning) {
      setWarningPopup(true);
    }
    else {
      setTabForce(key);
    }
  }
  const goToTab = (key) =>{
    setCurrentKey(key);
    if (warning) {
      setWarningPopup(true);
    }
    else {
      setTabForce(key);
    }
  }
  const setTabForce = (key) => {
    const tempKey = key === undefined ? currentKey : key;
    setWarningPopup(false);
    setWarning(false);
    setTab(tempKey);
  }
    const {isApplicationDocuments, isApplicationInfo, requiredArtefacts, formFields} = props;
        return(
          <FormStyleWrapper>
            <div className="forms_">
                
                <Tabs activeKey={tab} id="uncontrolled-tab-example" className="mb-3" onSelect={goToTab}>
                    <Tab eventKey="SukukInfo" title="Sukuk Info">
                        <SukukInfo setSukukInfoValues={setSukukInfoValues} form_Fields = {formFields} isApplicationInfo={isApplicationInfo} setTab={setTab} setWarning={setWarning} setWarningPopup={setWarningPopup} warningPopup={warningPopup} setTabForce={setTabForce} goNext={goNext}  />
                    </Tab>
                    <Tab eventKey="Documents" title="Documents">
                        <Documents required_Artefacts={requiredArtefacts} isApplicationDocuments={isApplicationDocuments} setTab={setTab} goNext={goNext} setTabForce={setTabForce} />
                    </Tab>
                    <Tab eventKey="other" title="Submit">
                        <SukukSubmit isApplicationInfo={isApplicationInfo} sukukInfoValues = {sukukInfoValues} />
                    </Tab>
                </Tabs>
               
            </div>
           
            </FormStyleWrapper>
    )
}

export default SukukForm;