import React, { lazy, Suspense } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import { PRIVATE_ROUTE } from '../../helpers/routerConstants';
import NewSukuk from './sukukRequests';
import ProfileForm from '../../components/ProfileForm/profileForm';
import BlankPage from '../../publicPages/blankPage';
import ErrorBoundary from '../../ErrorBoundary';
import SukukInfo from './sukukInfo';
import DDSubmitInfo from './ddSubmitInfo';
import StructuringInfo from './structuringInfo';
import FinanceData from './financeData';
import ProcessData from './processData';

const routes = [
  {
    path: PRIVATE_ROUTE.DASHBOARD,
    exact: 'true',
    component: <BlankPage/>,
  },
  {
    path: PRIVATE_ROUTE.NEWSUKUKREQUEST,
    exact: 'true',
    component: <NewSukuk/>,
  },
  {
    path: PRIVATE_ROUTE.NEWDDREQUESTS,
    exact: 'true',
    component: <NewSukuk/>,
  },
  {
    path: PRIVATE_ROUTE.NEWSTRUCTURINGREQUESTS,
    exact: 'true',
    component: <NewSukuk/>,
  },
  {
    path: PRIVATE_ROUTE.BUSINESSPROFILE,
    exact: 'true',
    component: <ProfileForm/>,
  },{
    path: PRIVATE_ROUTE.SUKUKREQUESTINFO,
    exact: 'true',
    component: <SukukInfo/>,
  },
  {
    path: PRIVATE_ROUTE.STRUCTURINGREQUESTINFO,
    exact: 'true',
    component: <SukukInfo/>,
  },
  {
    path: PRIVATE_ROUTE.DDREQUESTSINFO,
    exact: 'true',
    component: <DDSubmitInfo/>,
  },{
    path: PRIVATE_ROUTE.STRUCTURINGREQUESTSINFO,
    exact: 'true',
    component: <StructuringInfo/>,
  },{
    path: PRIVATE_ROUTE.FINANCEDATA,
    exact: 'true',
    component: <FinanceData/>,
  },{
    path: PRIVATE_ROUTE.PROCESSDATA,
    exact: 'true',
    component: <ProcessData/>,
  },
];
export default function BusinessRoutes() {
  return (
    <Suspense fallback={<ErrorBoundary />}>
    <Routes>
       {routes.map((route, idx) => (
          <Route key={idx} path={route.path} exact={route.exact} element={route.component} />
       )
       )}
    </Routes>
    </Suspense>
  );
}
