
import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const NewSukukStyleWrapper = styled.div`
.sukuk-card{
    background-color: #ffffff;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 8px 7px 24px 0px rgb(0 0 0 / 20%);
    font-family: 'Roboto', sans-serif;
    margin-left: 0px;
    }
    
    .info-icon{
        color: red;
        font-size: 50px;
    }
    
    .sukuk-div{
        text-align: left;
        padding: 20px 80px;
    }
    .new-sukuk {
        #check{
            width: 20px;
            height: 20px;
            margin-left: -20px;
            position: relative;
            top: 15px;
            right: 10px;
        }
        #check0{
            width: 20px;
            height: 20px;
            margin-left: -20px;
            position: relative;
            top: 5px;
            right: 10px;
        }
        .agreement{
            .card{
                max-width: 910px;
                margin: 0 auto;
                width: 100%;
              }
              .card-body{
                padding :1rem 0rem;
                .new-sukuk-btn a{
                    margin-top: 0px;
                    width: 100%;
                    max-width: 300px;
                    &.disable{
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
    
    
    /* error handling  */
    
    
    .error-handling-modal .modal-content{
        background: ${APPCOLORS.bgColor}; /* fallback for old browsers */
        background: -webkit-${APPCOLORS.bgLinear}; /* Chrome 10-25, Safari 5.1-6 */
        background: ${APPCOLORS.bgLinear};  
        color: black;
        /* height: 150px; */
        border: 2px white;
        border-radius: 20px;
        color: white ;
        width: 90%;
        margin-left: 5%;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
      }
      .error-handling-modal h4{
        text-align: center;
        font-weight: 300;
      }
      .checkbox-error-handling-modal .modal-content{
        background: ${APPCOLORS.bgColor}; /* fallback for old browsers */
        background: -webkit-${APPCOLORS.bgLinear}; /* Chrome 10-25, Safari 5.1-6 */
        background: ${APPCOLORS.bgLinear};   
        color: black;
        /* height: 120px; */
        border: 2px white;
        border-radius: 20px;
        color: white ;
        width: 90%;
        margin-left: 5%;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
      }
      .checkbox-error-handling-modal h4{
        text-align: center;
        font-weight: 300;
      }
     .modal-content .custom-button{
        background: #ffffff;
        color: ${APPCOLORS.bgColor};
      }
      `;

export default WithDirection(NewSukukStyleWrapper);