import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const SubscriptionListStyleWrapper = styled.div`
    .cardWrapper{
        width:calc(100% - 340px);
        max-width: 700px;
        position: relative;
        .successMsg{
            display: block;
            text-align: right;
            font-weight: 600;
            font-size: 18px;
            margin-top: 5px;
            right: 12px;
            position: absolute;
        }
    }
    .SubscriptionDetailsRow{
        border-color: #adadad !important;
        label{
            font-size: 16px;
            color: #4e4e4e;
        }
    }
    .documentProofBlock{
        width:300px;
        display:flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        .documentBlock{
            display:flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 10px;
            margin-top: 10px;
            span{
                margin-top: 5px;
                img{
                    width: 35px;
                }
            }
        }
    }
    .buttonsWrapper{
        width: 40px;
        .custom-button{
            width: 100px;
            font-size: 14px;
            &:first-child{
                margin-bottom: 15px;
            }
            &:last-child{
                margin-top: 15px;
            }
        }
    }
    .options_{
        z-index: 9;
        .btn-success{
            background-color: transparent !important;
            border-color: transparent !important;
            padding-right: 0;
            padding-left: 0;
            width: 40px;
            &:focus{
                box-shadow: none !important;
            }
        }
        .dropdown-toggle::after{
            opacity: 0;
        }
        img{
            width: 40px;
        }
        .dropdown-menu{
            width: 200px;
            box-shadow:2px 5px 12px #000;
        }
        .dropdown-item:hover{
            background-color: #6767F0;
            color: #ffffff;
        }
        .dropdown-item.disable{
            background-color: #e9ecef !important;
            color: #212529 !important;
            cursor: not-allowed;
        }
    }
    .upArrow:after{
            content: "↓";
            display: block;
    }
    .statusIndicatorCircle{
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-right: 6px;
    }
`;

export default WithDirection(SubscriptionListStyleWrapper);
