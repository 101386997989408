


import React, { useEffect, useState, useRef } from 'react'
import { Card, Modal, Form, Row, Col } from 'react-bootstrap'
import Loading from '../../helpers/Loader/loader';
import apiManager from './../../helpers/apiManager';
import { AiOutlineInfoCircle } from 'react-icons/ai'
import FormStyleWrapper from './form.style';
import CustomCard from '../simpleCard';
import {useSelector, useDispatch} from 'react-redux';
import { setApplicationId, setApplicationName } from '../../actions';
import {useNavigate } from 'react-router';


const SukukSubmit = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const applicationId = useSelector((state) => state.app.applicationId);
    const applicationName = useSelector((state) => state.app.applicationName);
    const externalId = useSelector((state) => state.app.externalId);
    const uid = useSelector(state => state.app.uid);
    const [saveLoading, setSaveLoading] = useState(false);
    const [additionalRemarks, setAdditionalRemarks] = useState('');
    const [successInfoShow, setSuccessInfoShow] = useState(false);
    const [successInfo, setSuccessInfo] = useState('');
    const [errorInfo, setErrorInfo] = useState('');
    const [serverError, setServerError] = useState(false);
    // const[pendingTask, setPendingTask] = useState('');
  
  
    useEffect(() => {
      // populateSukukData()
      if(props.isApplicationInfo && Object.keys(props.isApplicationInfo).length !== 0 ){
        const {AdditionalRemarks} = props.isApplicationInfo;
        setAdditionalRemarks(AdditionalRemarks ? AdditionalRemarks : '');
        setSaveLoading(false);
    }
    }, [props.isApplicationInfo])
  
  
    // populate file data
    // console.log( sukukData.ApplicationInfo, 'country code')
  
    const saveSukukInfo = (event, status) => {
      event.preventDefault();
      setSaveLoading(true);
      const {sukukIssuanceCurrency, sukukRemarks, sukukIssuanceSize, sukukMonths, sukukGreen,sukukESG, sukukName} = props.sukukInfoValues;
      const data ={
        "ApplicationName": sukukName,
        "ExternalId": externalId,
        "InstrumentId": "101",
        "ApplicationInfo": {
          "SukukIssuanceSize": sukukIssuanceSize,
          "SukukIssuanceCurrency": sukukIssuanceCurrency,
          "SukukTenureInMonths": sukukMonths,
          "ReasonForSukuk": sukukRemarks,
          "ESGSukuk": sukukESG,
          "GreenSukuk": sukukGreen,
          "AdditionalRemarks": additionalRemarks
        }
        };
      apiManager.updateInfoApplication(data, uid)
      .then((res) => {
        const tasks = res.data[0].ApplicationTasks;
        dispatch(setApplicationId(res.data[0].ApplicationId));
        setSaveLoading(false);
        if(status === 'save'){
          const info = 'Application Information Data Saved Successfully';
        setSaveSuccessPopup(info);
        }
      })
      .catch((err) => {
        setSaveLoading(false);
        setServerError(true);
        setErrorInfo(err.res.data.Error.errorMessage);
        setTimeout(()=>{
          setServerError(false)
        }, 2000);
      })
    }
    const setSaveSuccessPopup =(info) =>{
      setSuccessInfo(info);
      setSuccessInfoShow(true);
      setTimeout(()=>{
        setSuccessInfoShow(false)
      }, 2000);
    }
    const submitSukukRequest = (e) => {
      setSaveLoading(true);
      const {sukukIssuanceCurrency, sukukRemarks, sukukIssuanceSize, sukukMonths, sukukGreen,sukukESG, sukukName} = props.sukukInfoValues;
      const data ={
        "ExternalId": externalId,
        "ApplicationName": sukukName,
        "InstrumentId": "101",
        "ApplicationInfo": {
          "SukukIssuanceSize": sukukIssuanceSize,
          "SukukIssuanceCurrency": sukukIssuanceCurrency,
          "SukukTenureInMonths": sukukMonths,
          "ReasonForSukuk": sukukRemarks,
          "ESGSukuk": sukukESG,
          "GreenSukuk": sukukGreen,
          "AdditionalRemarks": additionalRemarks
        }
        };
        // if(applicationId !== ''){
        //   data['ApplicationId'] = applicationId;
        // }
      apiManager.updateInfoApplication(data, uid)
      .then((res) => {
        const tasks = res.data[0].ApplicationTasks;
        dispatch(setApplicationId(res.data[0].ApplicationId));
        dispatch(setApplicationName(res.data[0].ApplicationName));
        const temptask = tasks && tasks.length > 0 ? tasks.filter( m => m.IsCompleted === false).map(filteredObj => filteredObj.TaskName) : [];
        // setPendingTask(temptask);
        setSaveLoading(false);
          if(temptask.length > 0 && temptask.includes("Application Info Completion")){
            setErrorInfo("Please save the info in the 'Sukuk Info' tab");
            setServerError(true);
          }
          else if(temptask.length > 0 && temptask.includes("Documents Submission Completion")){
            setErrorInfo("Please upload all the mandatory documents before Submit");
            setServerError(true);
          }
        else{
          const data = {
            // "ExternalId": externalId,
            // "CurrentPhase": "Submission", 
            // "InstrumentId": "101",
            "ApplicationId": applicationId
            }
          apiManager.submitApplicationRequest(data, uid)
          .then((res) => {
            setSuccessInfo('Sukuk Request submitted successfully');
            setSuccessInfoShow(true);
            setTimeout(()=>{
              setServerError(false)
            }, 2000);
          })
          .catch((err) => {
            setServerError(true);
            setErrorInfo(err.res.data.Error.errorMessage);
            setTimeout(()=>{
              setServerError(false)
            }, 2000);
          })
        }
      })
      .catch((err) => {
        setSaveLoading(false);
        setServerError(true);
        setErrorInfo(err.res.data.Error.errorMessage);
        setTimeout(()=>{
          setServerError(false)
        }, 2000);
      })
      e.preventDefault();     
    }
    // const goNext = (e) =>{
    //   e.preventDefault();
    //   if(warning){
    //     setWarningPopup(true);
    //   }
    //   else{
    //     setTabForce();
    //   }
    // }
    return (
      <FormStyleWrapper>
      <div className="layout layout-horizontal sukuk-info-tab">
        <div className="info-card">
            <Card className="sukuk-info-card border-0">
              <Card.Body>
                  <Card.Text>
                     <>
                          {saveLoading && <Loading />}
                        <div className="px-3">
                          <Form>
                            <Form.Group>
                              <Form.Label as="legend" column sm={12} className="form-label">
                                Additional Remarks
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                              <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                name="sukukRemarks"
                                value={additionalRemarks}
                                onChange={(e) => setAdditionalRemarks(e.target.value)}
                                style={{height: '150px'}}
                                className={additionalRemarks.length > 0 ? 'highlight' : ''}
                              />
                              {/* <AiOutlineInfoCircle className="info-icon-ai" /> */}
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                                {/* <Link to="/dashboard/new-sukuk/info"> */}
                                <button className="custom-button" 
                                onClick={(e) => saveSukukInfo(e, 'save')}
                                >
                                  Save
                                </button>
                                {/* </Link> */}
                                {/* <Link to="/dashboard/new-sukuk/documents"> */}
                                  <button className="custom-button" onClick={submitSukukRequest}>Submit</button>
                                {/* </Link> */}
                              </Col>
                            </Form.Group>
                          </Form>
  
                        </div>
  </>
                  </Card.Text>
              </Card.Body>
  
              {/* <div
                className="layout layout-horizontal"
                style={{ justifyContent: 'space-around' }}
              >
                <div>
                  <button className="custom-button" onClick={() => saveSukukInfo()}>
                    Save
                  </button>
                </div>
                <div>
                  <Link to="/dashboard/new-sukuk/documents">
                    <button className="custom-button">Next</button>
                  </Link>
                </div>
              </div> */}
            </Card>
        </div>
  
        
      <CustomCard title= "" info={''} />
  
  
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={successInfoShow}
          onHide={() => setSuccessInfoShow(false)}
        >
          {/* Sucesss Modal  */}
  
          <Modal.Body closeButton>
            <div className="center-title">
              <br></br>
              <h4>{successInfo}</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
  
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={serverError}
          onHide={() => setServerError(false)}
        >
          {/* Error Modal  */}
  
          <Modal.Body>
            <div className="center-title">
              <br></br>
              <h4> {errorInfo}</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>

      </div>
      </FormStyleWrapper>
    )
  }


  export default SukukSubmit;