import React, { useEffect, useState, useRef } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image'
import './loader.css'


const Loading =(props)=>
<div className={`loaderMask ${props.className}`}>
<div style={{width: '100%', height: '100%', backgroundColor: '#000000', opacity: 0.1}}></div>
  <div className="loading" style={{top: props.top}}>
    <div className={props.className}></div>
    <div className={props.className}></div>
  </div>  
</div>

export default Loading