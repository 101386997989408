import { useState } from "react";
import { Form, Col, Button , Modal} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import apiManager from '../../helpers/apiManager';
import {PRIVATE_ROUTE} from '../../helpers/routerConstants'
import Loading from '../../helpers/Loader/loader';




const SPVInfo = () =>{
    const applicationName= useSelector(state => state.app.applicationName);
    const uid= useSelector(state => state.app.uid);
    const applicationId= useSelector(state => state.app.applicationId);
    const navigate = useNavigate();
    const [saveLoading, setSaveLoading] = useState(false);
  const [successInfoShow, setSuccessInfoShow] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [serverError, setServerError] = useState(false);
const [formValues, setFormValues] = useState({
    spvName: '',
    spvUser: ''
})
const [validated, setValidated] = useState(false);
const handleChange = (e) =>{
    const {name, value} = e.target;
    setFormValues({
        ...formValues,
        [name] : value
    })
}
const handleSubmit =(event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === true) {
      const {spvUser, spvName} = formValues;
        const data={
          ApplicationId: applicationId,
          SpvUserEmail: spvUser,
          SpvName: spvName
        }
        apiManager.initiateSPV(data, uid)
      .then((res) => {
        setSaveLoading(false);
        setSuccessInfoShow(true);
        setTimeout(()=>{
          setSuccessInfoShow(false);
          navigate('/business'+PRIVATE_ROUTE.SPVINITIATION);
        }, 2000);
      })
      .catch((err) => {
        setServerError(true);
        setSaveLoading(false);
        setErrorInfo(err.res.data.Error.errorMessage);
        setTimeout(()=>{
          setServerError(false)
        }, 2000);
      })
    }
}
const {spvName, spvUser} = formValues;
return(
<div className='Container_'>
      {saveLoading && <Loading />}
      <div className="header_ text-start">
        <h4>Initiate SPV</h4>
      </div>
      <div style={{marginTop: 50, marginLeft: 50, maxWidth: 550}}>
        <label className="form-label mb-4">Sukuk Name: {applicationName}</label>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group as={Col} md="12" className="mb-4" controlId="validationspvName">
            <Form.Label>SPV Name<span className='mandatory'>*</span></Form.Label>
            <Form.Control 
                disabled={false} name="spvName" value={spvName} onChange={handleChange} required type="text" placeholder="Name of the SPV"
            //   className={sukukTenure && sukukTenure.length > 0 ? 'highlight text-end' : 'text-end'}
               />
            <Form.Control.Feedback type="invalid"  className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-4" controlId="validationspvUser">
            <Form.Label>Invite SPV User<span className='mandatory'>*</span></Form.Label>
            <Form.Control 
                disabled={false} name="spvUser" value={spvUser} onChange={handleChange} required type="text" placeholder="Email id of SPV User"
            //   className={sukukTenure && sukukTenure.length > 0 ? 'highlight text-end' : 'text-end'} 
              />
            <Form.Control.Feedback type="invalid"  className="text-start">
              Please enter the value.
            </Form.Control.Feedback>
          </Form.Group>
          <Button  className="custom-button" type="submit" style={{ marginTop: '20px' }}>Submit</Button>
        </Form>
      </div>
      <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={successInfoShow}
          onHide={() => setSuccessInfoShow(false)}
        >
          {/* Sucesss Modal  */}
  
          <Modal.Body closeButton>
            <div className="center-title">
              <br></br>
              <h4>SPV is Initiated</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={serverError}
          onHide={() => setServerError(false)}
        >
          {/* Error Modal  */}
  
          <Modal.Body>
            <div className="center-title">
              <br></br>
              <h4> {errorInfo}</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>

      )

      }

      export default SPVInfo;