import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const FundsHistoryTableStyleWrapper = styled.div`
    table{
        border-collapse: collapse;
        width: 100%;
        th{
            background-color: #000000;
            color: #ffffff;
            font-weight: 400;
        }
        tr{
            cursor: pointer;
        }
        th, td{
            border-bottom: 1px solid #949391;
            text-align: left;
            padding: 15px 8px;
        }
        .circle_{
            display: block;
            width: 15px;
            height: 15px;
            background-color: #eaeaea;
            border-radius: 50%;
            &.received{
                background-color: green;
            }
            &.declined{
                background-color: red;
            }
            &.pending{
                background-color: #eaeaea;
            }
        }
        .options_{
            z-index: 9;
            cursor: pointer;
            .btn-success{
                background-color: transparent !important;
                border-color: transparent !important;
                padding-right: 0;
                padding-left: 0;
                width: 30px;
                &:focus{
                    box-shadow: none !important;
                }
            }
            .dropdown-toggle::after{
                opacity: 0;
            }
            img{
                width: 26px;
            }
            .dropdown-menu{
                width: 200px;
                box-shadow:2px 5px 12px #000;
            }
            .dropdown-item:hover{
                background-color: #6767F0;
                color: #ffffff;
            }
            .dropdown-item.disable{
                background-color: #e9ecef !important;
                color: #212529 !important;
                cursor: not-allowed;
            }
        }
        .highlightedBg{
            background-color: aquamarine;
        }
    }
`;

export default WithDirection(FundsHistoryTableStyleWrapper);
