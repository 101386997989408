import React, { useEffect, useState } from 'react'
import {Card} from 'react-bootstrap'
import { useSelector } from 'react-redux';
import apiManager from '../../helpers/apiManager';
import SukukCardStyleWrapper from './sukukCard.style';
import menuOption from '../../helpers/images/assets/VerticalDotsMenu.svg';
import {Dropdown} from 'react-bootstrap';
import utility from '../../helpers/utility';

const SukukCard = (props) => {
  const uid = useSelector(state => state.app.uid);
  // const [companyInfo, setCompanyInfo] = useState({
  //   sukukSubmittedDate : '',
  //   ApplicationName: '',
  //   Account: '',
  //   Country: '',
  //   IssuanceSize: '',
  //   TenureInMonths: ''
  // })
  useEffect(() =>{
    if(props){
      // const {id, name, size, tenure, Account, SubmittedOn, } = props;
      // setCompanyInfo({...companyInfo,
      //   ApplicationName: name,
      //   IssuanceSize: size,
      //   TenureInMonths: tenure
      // })
    // const data = {
    //   "CompanyInfo": {
    //     "ExternalId":id 
    //   }
    // }
    // apiManager.getProfileData(data,uid)
    // .then((response) =>{
    //   const profileData = response.data[0].CompanyInfo; 
    //   let tempSukukSubmittedDate = new Date(profileData.ServiceEnrollmentDate);
    //   tempSukukSubmittedDate = tempSukukSubmittedDate.getDate() + '/'+ months[tempSukukSubmittedDate.getMonth()] + '/'+ tempSukukSubmittedDate.getFullYear();
    //   setCompanyInfo({...companyInfo,
    //     sukukSubmittedDate : tempSukukSubmittedDate,
    //     Account: profileData.Name,
    //     Country: profileData.Address.Country,
    //   })
     
    // })
    // .catch((error) => {

    // })
  }
  },[props])
  const {SubmittedOn, ApplicationName, Account, CompanyInfo, IssuanceSize, TenureInMonths, CurrentStatus, Currency, sukukInitializedStatus} = props.info;
  console.log(sukukInitializedStatus);
  const {months, currency, status} = utility;
  let sukukSubmittedDate = new Date(SubmittedOn.replace(/ /g,"T"));
  sukukSubmittedDate = sukukSubmittedDate.getDate() + '-'+ months[sukukSubmittedDate.getMonth()] + '-'+ sukukSubmittedDate.getFullYear();
  const currentStatus = CurrentStatus !== '' ? status[CurrentStatus] ? status[CurrentStatus] : '' : '';
  const nf = new Intl.NumberFormat("en-"+Currency ? currency[Currency] : 'US', {
    // style: "currency",
    currency: Currency ? Currency : 'USD',
  });
return(
  <SukukCardStyleWrapper>
      <Card className="request-card">
        <Card.Body>
          <div style={{display: 'flex', alignItems: 'center'}}>
        <div className="layout layout-vertical layout-item-start-align mb=2 px-2" style={{width: props.showOptions ? '100%' : 'calc(100% - 40px)'}}>
          <div className="layout layout-horizontal layout-justify-space-between style-card w-100 mb-2" style={{alignItems: 'baseline', paddingRight: 45}}>
            <div className='mb-2 px-2'>{sukukSubmittedDate}</div>
              <div className='sukuk-name'>{ApplicationName}</div>
              <div className='company-name'>{Account}</div>
              <div>{CompanyInfo.Country ? CompanyInfo.Country : ''}</div>
            </div>
            <div className="layout layout-horizontal justify-content-between w-100">
              <div className="d-flex">
            <div className='px-2'>Sukuk Size: {Currency ? Currency : ''}{ ' '+nf.format(IssuanceSize)} </div>
            <div className='px-3'>Sukuk Tenure: {TenureInMonths ? TenureInMonths + ' months' : ''}</div>
            </div>
            {currentStatus && <div className='px-3'>Status: {currentStatus}</div>}
            {sukukInitializedStatus !== undefined && <div className='pl-3 highlight__'>Status: <b className="">{sukukInitializedStatus}</b></div>}
            </div>
            
            
          </div>
          {props.showOptions && 
              <div className="options_">
              <Dropdown align="end">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={menuOption} className="header-icon" alt="MenuOption" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                 {sukukInitializedStatus === 'initiated' ? <Dropdown.Item className='disable'>Initialized smart contract</Dropdown.Item>
                 :
                  <Dropdown.Item  onClick={() => props.updateFunction(props.info)}>Initialized smart contract</Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>
            </div>}
        </div>
        </Card.Body>
      </Card>
      </SukukCardStyleWrapper>
      )}

      export default SukukCard;