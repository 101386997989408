
import {ReactComponent as Dashboard} from '../../helpers/images/sidebarMenu/Dashboard_Icon_White.svg';
import {ReactComponent as NewSukuk} from '../../helpers/images/sidebarMenu/business/sukukRequest.svg';
import {ReactComponent as Profile} from '../../helpers/images/sidebarMenu/profileIcon.svg';
import {ReactComponent as SukukInitiated} from '../../helpers/images/sidebarMenu/business/sukukPendingApproval.svg';
import {ReactComponent as SukukOutstanding} from '../../helpers/images/sidebarMenu/business/sukukOutstanding.svg';
import {ReactComponent as Document} from '../../helpers/images/sidebarMenu/business/documentIcon.svg';
import {ReactComponent as Financial} from '../../helpers/images/sidebarMenu/business/financial.svg';
import {ReactComponent as DDRequest} from '../../helpers/images/sidebarMenu/dueDiligence/ddRequest.svg';
import {ReactComponent as DDInProgress} from '../../helpers/images/sidebarMenu/dueDiligence/ddInProgress.svg';
import {ReactComponent as DDClosed} from '../../helpers/images/sidebarMenu/dueDiligence/ddClosed.svg';
import {ReactComponent as StructuringRequest} from '../../helpers/images/sidebarMenu/structuring/structuringRequest.svg';
import {ReactComponent as StructuringInProgress} from '../../helpers/images/sidebarMenu/structuring/structuringInProgress.svg';
import {ReactComponent as StructuringClosed} from '../../helpers/images/sidebarMenu/structuring/structuringClosed.svg';
import {ReactComponent as PendingApprovals} from '../../helpers/images/sidebarMenu/qadmin/pendingApprovals.svg';
import {ReactComponent as Platform} from '../../helpers/images/sidebarMenu/qadmin/platform.svg';
import {ReactComponent as ProcessFlow} from '../../helpers/images/sidebarMenu/qadmin/processFlow.svg';
import { customData } from '../../helpers/constants';
// import {ReactComponent as Approvals} from '../../helpers/images/sidebarMenu/Approvals_Icon_White.svg';
const options = {
  [customData.BUSINESS_ROLES.Business]: [
    {
      key: '/business/*',
      label: 'Dashboard',
      icon: <Dashboard />,
    }, {
        // key: '/business/newSukukRequest',
        label: 'Sukuk',
        icon: <NewSukuk />,
        subMenu:[
          {
            key: '/business/newSukukRequest',
            label: 'New Sukuk Request',
          },
          {
            key: '/business/savedSukukRequests',
            label: 'Saved Sukuk Requests',
          },
          {
            key: '/business/sukukPendingApprovals',
            label: 'Sukuk Under Approval',
          },
          {
            key: '/business/sukukPendingUpdates',
            label: 'Sukuk Pending Updates',
          },
          {
            key: '#',
            label: 'Sukuk Outstanding',
          },
        ]
      }, 
      // {
      //   key: '/business/sukukPendingApprovals',
      //   label: 'Sukuk Under Approval',
      //   icon: <SukukInitiated />,
      // }, {
      //   key: '/business/sukukPendingUpdates',
      //   label: 'Sukuk Pending Updates',
      //   icon: <NewSukuk />,
      // },
      // {
      //   key: '/business/outstanding',
      //   label: 'Sukuk Outstanding',
      //   icon: <SukukOutstanding />,
      // }, 
      {
        key: '',
        label: 'SPV',
        icon: <NewSukuk />,
        subMenu:[
          {
            key: '/business/SPVinitiation',
            label: 'Initiate SPV',
          },
          {
            key: '/business/fundRequest',
            label: 'Request Funds',
          },
          {
            key: '/business/yieldDisbursment',
            label: 'Yield Disbursment',
          },
        ]
      },
      {
        key: '',
        label: 'Prospectus',
        icon: <NewSukuk />,
        subMenu:[
          {
            key: '/business/createProspectus',
            label: 'Create Prospectus',
          },
          {
            key: '#',
            label: 'Update Prospectus',
          },
          {
            key: '#',
            label: 'Remove Prospectus',
          },
        ]
      }, {
        key: '/business/financials',
        label: 'Financials',
        icon: <Financial />,
        subMenu:[
          {
            key: '/business/payments',
            label: 'Payments',
          },
          // {
          //   key: '/business/fundRequest',
          //   label: 'Funds Request',
          // },
          // {
          //   key: '/business/yieldDisbursment',
          //   label: 'Yield Disbursment',
          // },
        ]
      }, {
        key: '#',
        label: 'Documents',
        icon: <Document />,
      },
      {
        key: '/common/profileForm',
        label: 'Profile',
        icon: <Profile />,
      },
      {
        label: 'Business Data',
        icon: <Document />,
        subMenu:[
          {
            key: '/business/financeData',
            label: 'Finance Data',
          },
          {
            key: '/business/processData',
            label: 'Process Data',
          }
        ]
      }
  ],
  [customData.BUSINESS_ROLES.DueDiligencePartner]: [
    {
      key: '/dd/*',
      label: 'Dashboard',
      icon: <Dashboard />,
    },  {
      key: '#',
      label: 'Use Case Consideration',
      icon: <Profile />,
      subMenu:[
        {
          key: '/dd/userCaseToBeDone',
          label: 'To Be Done',
        },
        {
          key: '/dd/userCaseCompleted',
          label: 'Completed',
        },
      ]
    },{
        key: '/dd/newSukukRequest/',
        label: 'Due Diligence Request',
        icon: <DDRequest />,
      }, {
        key: '#',
        label: 'Due Diligence In Progress',
        icon: <DDInProgress />,
      }, {
        key: '#',
        label: 'Due Diligence Closed',
        icon: <DDClosed />,
      }, {
        key: '/common/profileForm',
        label: 'Profile',
        icon: <Profile />
      }
  ],
  [customData.BUSINESS_ROLES.StructuringPartner]: [
    {
      key: '/structuring/*',
      label: 'Dashboard',
      icon: <Dashboard />,
    }, {
        key: '/structuring/newSukukRequest/',
        label: 'New Structuring Request',
        icon: <StructuringRequest />,
      }, {
        key: '#',
        label: 'Structuring In Progress',
        icon: <StructuringInProgress />,
      }, {
        key: '#',
        label: 'Structuring Closed',
        icon: <StructuringClosed />,
      }, {
        key: '/common/profileForm',
        label: 'Profile',
        icon: <Profile/>
      }
  ],
  [customData.BUSINESS_ROLES.SPV]:[
    {
      key: '/spv/*',
      label: 'Dashboard',
      icon: <Dashboard />,
    }, 
    {
      key: '#',
      label: 'Sukuk',
      icon: <StructuringRequest />,
      subMenu:[
        {
          key: '/spv/sukukList',
          label: 'Sukuk Details',
        },
        {
          key: '/spv/subscriptionList',
          label: 'Subscription Details',
        },
        {
          key: '/spv/fundDisbursement',
          label: 'Funds Disbursement',
        },
        {
          key: '#',
          label: 'Yield Management',
        },
        {
          key: '#',
          label: 'Sukuk Closure',
        },
      ]
    }, 
    {
      key: '#',
      label: 'Assets',
      icon: <StructuringInProgress />,
    }, 
    {
        key: '#',
        label: 'Close Sukuk',
        icon: <StructuringClosed />,
      }, 
      {
        label: 'Profile',
        icon: <Profile/>,
        subMenu:[
          {
            key: '/common/profileForm',
            label: 'Update Profile',
          },
          {
            key: '/spv/bankDetails',
            label: 'Update Bank Details',
          }
        ]
      }
  ],
  [customData.BUSINESS_ROLES.QAdmin]: [
    {
      key: '/qadmin/*',
      label: 'Dashboard',
      icon: <Dashboard />
    }, {
      key: '/pendingApprovals/',
      label: 'Pending Approvals',
      icon: <PendingApprovals />,
      subMenu : [
        {
          key: '/qadmin/newSukukRequest',
          label: 'Sukuk Requests',
        },
        {
          key: '/qadmin/ddRequests',
          label: 'Due Diligence',
        },
        {
          key: '/qadmin/structuringRequests',
          label: 'Structuring',
        },
        {
          key: '/pendingApprovals/spv',
          label: 'SPV',
        },
        {
          key: '/pendingApprovals/smartContract',
          label: 'Smart Contract',
        }
      ]
    },
    {
      key: '/platformSetup/',
      label: 'Platform Setup',
      icon: <Platform />
    },
    {
      key: '/processFlow/',
      label: 'Process Flow',
      icon: <ProcessFlow />
    },
    {
      label: 'Business Data',
      icon: <Document />,
      subMenu:[
        {
          key: '/qadmin/financeData',
          label: 'Finance Data',
        },
        {
          key: '/qadmin/processData',
          label: 'Process Data',
        }
      ]
    }
  ],
  [customData.BUSINESS_ROLES.Investor]: [
    {
      key: '/qadmin/',
      label: 'Dashboard',
      icon: <Dashboard />
    }, {
      key: '/pendingApprovals/',
      label: 'Pending Approvals',
      icon: <PendingApprovals />,
      subMenu : [
        {
          key: '/qadmin/newSukukRequest',
          label: 'Sukuk Requests',
        },
        {
          key: '/qadmin/ddRequests',
          label: 'Due Diligence',
        },
        {
          key: '/qadmin/structuringRequests',
          label: 'Structuring',
        },
        {
          key: '/pendingApprovals/spv',
          label: 'SPV',
        },
        {
          key: '/pendingApprovals/smartContract',
          label: 'Smart Contract',
        }
      ]
    },
    {
      key: '/platformSetup/',
      label: 'Platform Setup',
      icon: <Platform />
    },
    {
      key: '/processFlow/',
      label: 'Process Flow',
      icon: <ProcessFlow />
    }
  ],
  'profileRoles' : [
    {label: 'Select Role', subMenu: [ {label: 'Business', key:'/business/', subMenu: []},
    {label: 'SPV',key:'#', subMenu: []},
    {label: 'Investor',key:'#', subMenu: []},
    {label: 'Partner', subMenu: [{label:'DueDiligence',key:'/dd/'},{label:'Structuring',key:'/structuring/'},{label:'Financial Compliance',key:'#'},
    {label:'Financial Consulting', key:"#"},{label:'Shariah Compliance',key:'#'},{label:'Sukuk Rating',key:'#'},{label:'Legal',key:'#',}]},
    {label: 'QAdmin', key:'/qadmin/', subMenu: []}]}
  ],
}
  export default options;
  