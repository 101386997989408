

import React, { useState, useEffect } from 'react'
import { config, customData } from './../../helpers/constants';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Loading from '../../helpers/Loader/loader';
import { useDispatch, useSelector } from 'react-redux';
import apiManager from '../../helpers/apiManager';
import { setProfileCompleted, setExternalId, setApplicationName } from '../../actions';
import utility from '../../helpers/utility';


const url = config.url.API_URL;
const BankDetails = () => {
    const dispatch = useDispatch();
    const externalId = useSelector(state => state.app.externalId);
    const uid = useSelector(state => state.app.uid);
    const [loading, setLoading] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const profileCompleted = useSelector(state => state.app.profileCompleted);
    const [validated, setValidated] = useState(false);
    const [profileData, setProfileData] = useState();
    const [values, setValues] = useState({
        Country: '',
        BankName: '',
        AccountName: '',
        AccountNumber: '',
        IbanNumber: '',
        SwiftCode: '',
        BankAddress: ''
    })
    useEffect(() => {
        if (profileCompleted) {
            const data = {
                "CompanyInfo": {
                    "ExternalId": externalId
                }
            }
            apiManager.getProfileData(data, uid)
                .then((response) => {
                    // localStorage.setItem(customData.BUSINESS_ROLES[profileRole]+'-profileData', response.data.length > 0 ? JSON.stringify(response.data[0]) : null );
                    const tempProfileData = response.data.length > 0 && response.data[0];
                    const filteredData = {...tempProfileData};
                    filteredData.GovernmentId = filteredData.GovernmentId.length > 0 && filteredData.GovernmentId.filter(govArray => govArray.UIdType !== "TMPGID")[0];
                    setProfileData(filteredData);
                    setValues(tempProfileData.CompanyInfo.SpvBankDetails ? tempProfileData.CompanyInfo.SpvBankDetails : values);
                    dispatch(setApplicationName(tempProfileData.CompanyInfo.Name));
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                });
        }
        const data_ = {
            "PhoneCountryCode": [],
            "ISOCountryCode": customData.ISO_COUNTRY_CODES,
            "Country": [],
            "ShortISOCountryCode": [],
            "FetchStrategy": {}
        }
        const storedCountryCodes = JSON.parse(localStorage.getItem('isoCountryCodes'));
        if (storedCountryCodes) {
            setCountryCodes(storedCountryCodes);
        }
        else {
            apiManager.getISOCountryCodes(data_)
                .then((response) => {
                    setCountryCodes(response.data);
                    localStorage.setItem('isoCountryCodes', JSON.stringify(response.data));
                })
                .catch((error) => { });
        }
    }, [])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === true) {
            setLoading(true);
            const tempProfileData = {...profileData};
            tempProfileData.CompanyInfo['SpvBankDetails'] = values;
            const data = tempProfileData;
            apiManager.updateProfile(data, uid)
          .then((response) => {
            const responseData = response.data[0];
            setLoading(false);
          })
          .catch(err =>{
            console.log('error --> ', err);
            setLoading(false);
          })
        }
        else {
            setValidated(true);
        }
    }


    const { Country,
        BankName,
        AccountName,
        AccountNumber,
        IbanNumber,
        SwiftCode,
        BankAddress } = values;
    return (
        <>
            {loading && <Loading />}
            <div className="container_">
                <div className="header_ text-start">
                    <h4>Update Bank Details</h4>
                </div>
                <div style={{ maxWidth: '1024px' }}>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                        <Form.Group as={Col} md="6" className="mb-4" controlId="validationCountry">
                            <Form.Label>Country<span className='mandatory'>*</span></Form.Label>
                            <Form.Select
                                value={Country}
                                name="Country"
                                onChange={handleChange}
                                required
                                className={Country?.length > 0 ? 'highlight' : ''}
                            >
                                <option value="" disabled defaultValue>
                                    Select Country
                                </option>
                                {countryCodes.map((country) => (
                                    <option value={country.Country}>{country.Country}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid" className="text-start">
                                Please enter the value.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-4" controlId="validationBankName">
                            <Form.Label>Bank Name<span className='mandatory'>*</span></Form.Label>
                            <Form.Control
                                // disabled={true}
                                name="BankName" value={BankName} onChange={handleChange} required type="text" placeholder=""
                                className={BankName && BankName.length > 0 ? 'highlight' : ''} />
                            <Form.Control.Feedback type="invalid" className="text-start">
                                Please enter the value.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-4" controlId="validationAccountName">
                            <Form.Label>Account Name<span className='mandatory'>*</span></Form.Label>
                            <Form.Control
                                // disabled={true}
                                name="AccountName" value={AccountName} onChange={handleChange} required type="text" placeholder=""
                                className={AccountName && AccountName.length > 0 ? 'highlight' : ''} />
                            <Form.Control.Feedback type="invalid" className="text-start">
                                Please enter the value.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-4" controlId="validationAccountNumber">
                            <Form.Label>Account Number<span className='mandatory'>*</span></Form.Label>
                            <Form.Control
                                // disabled={true}
                                name="AccountNumber" value={AccountNumber} onChange={handleChange} required type="number" placeholder=""
                                className={AccountNumber && AccountNumber.length > 0 ? 'highlight' : ''} />
                            <Form.Control.Feedback type="invalid" className="text-start">
                                Please enter the value.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-4" controlId="validationIbanNumber">
                            <Form.Label>IBAN Number<span className='mandatory'>*</span></Form.Label>
                            <Form.Control
                                // disabled={true}
                                name="IbanNumber" value={IbanNumber} onChange={handleChange} required type="number" placeholder=""
                                className={IbanNumber && IbanNumber.length > 0 ? 'highlight' : ''} />
                            <Form.Control.Feedback type="invalid" className="text-start">
                                Please enter the value.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-4" controlId="validationSwiftCode">
                            <Form.Label>Swift Code<span className='mandatory'>*</span></Form.Label>
                            <Form.Control
                                // disabled={true}
                                name="SwiftCode" value={SwiftCode} onChange={handleChange} required type="text" placeholder=""
                                className={SwiftCode && SwiftCode.length > 0 ? 'highlight' : ''} />
                            <Form.Control.Feedback type="invalid" className="text-start">
                                Please enter the value.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-4" controlId="validationBankAddress">
                            <Form.Label>Bank Address<span className='mandatory'>*</span></Form.Label>
                            <Form.Control
                                // disabled={true}
                                as="textarea"
                                name="BankAddress" value={BankAddress} onChange={handleChange} required type="text" placeholder=""
                                className={BankAddress && BankAddress.length > 0 ? 'highlight' : ''} />
                            <Form.Control.Feedback type="invalid" className="text-start">
                                Please enter the value.
                            </Form.Control.Feedback>
                        </Form.Group>
                        </Row>
                        <Col sm={12}><Button className="custom-button" type="submit" style={{ marginTop: '20px' }}>Submit</Button></Col>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default BankDetails;