

import { Web3Storage } from 'web3.storage';
const utility = {
    

statusColors : ['grey', '', '#EE220C', '#fff709', 'grey', '#1DB100'],
statuses : ['', '', 'Rejected', 'Acknowledged', 'Received', 'Approved'],
 months : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
 status : {1: 'Not Submitted', 3 : 'Pending Due Diligence', 11: 'Pending Due Diligence', 16 : 'Due Diligence Completed', 21 : 'Pending Structuring', 26: 'Pending Structuring'},
currency : {USD: 'US', INR : 'IN', GBP : 'UK'},
    searchBusinessRoleInRolesList: (role, list) => {
        if(list.find(listRole => listRole === role))
        return true;
        else
        return false;
    },
    getNumberUnit: (num, round = 2) => {
        const unit = Math.floor(Math.round(num / 1.0e+1).toLocaleString().replaceAll(',', '').length),
            wunit = ["K","M","B","T","Q"][Math.floor(unit / 3) - 1],
            funit = Math.abs(Number(num))/Number('1.0e+'+(unit-unit%3));
        return {
            number : wunit ? funit.toFixed(round).toLocaleString() : num.toFixed(round).toString(),
            unit : wunit ? wunit : ''
        }
    },
    getFormattedDate: (rawDate) => {
        let date = typeof(rawDate) !== 'object' ? new Date(rawDate.replace(/ /g,"T")) : rawDate;
        date = (date.getDate() > 9 ? date.getDate() : '0'+date.getDate())  + '-'+ utility.months[date.getMonth()] + '-'+ date.getFullYear();
        return date;
    },
    getFormattedTime: (rawDate) => {
        let date = new Date(rawDate.replace(/ /g,"T"));
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        const strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    },
    getBlockChainKeys: () =>{
        var ethers = require('ethers');  
        var crypto = require('crypto');
        var id = crypto.randomBytes(32).toString('hex');
        var privateKey = "0x"+id;
        console.log("SAVE BUT DO NOT SHARE THIS:", privateKey);
        
        var publicKey = new ethers.Wallet(privateKey);
        console.log("Address: " + publicKey.address);
        const wallet ={
            privateKey : privateKey,
            publicKey: publicKey.address
        }
        return wallet;
    },
    getAccessToken: () => {
        // If you're just testing, you can paste in a token
        // and uncomment the following line:
        // return 'paste-your-token-here'
    
        // In a real app, it's better to read an access token from an
        // environement variable or other configuration that's kept outside of
        // your code base. For this to work, you need to set the
        // WEB3STORAGE_TOKEN environment variable before you run your code.
        // return process.env.WEB3STORAGE_TOKEN
        return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGFmMmUyYzZiRjY2MDEyNTlDRkVkNjNmOGU0ZjVkOUI3NkVEOUQwYTUiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NjYxOTY3NDg4NTIsIm5hbWUiOiJibG9ja2NoYWluIn0.bD4vEO43vZTcUOcB-pGsChz9Rf5qdmTxMW_FDnLEo6w';
        // return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDc1MTQ3ZjkyMDhBODE5MTAwQmVkNURBZWQ4OUNlMjg0OTJmNDcwRDIiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NjYyMzQ0MTA3OTcsIm5hbWUiOiJ0ZXN0IHRva2VuIn0.LpgCFgpHQTE5h1tX4EnYYjCWtV_AmutGmP4aZALMdoc';
    },
    
    makeStorageClient: () => {
        return new Web3Storage({ token: utility.getAccessToken() })
      },
    documentUploadToWebstorag: async (files) =>{
        const client = utility.makeStorageClient();
        const cid = await client.put(files);
        console.log('stored files with cid:', cid);
        return cid;
    }
};
export default utility;