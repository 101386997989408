import React from 'react'
import '../App.css';
import FormStyleWrapper from './form.style';
import { Button, Card, Container, Dropdown, Form } from 'react-bootstrap';
import FileViewer from 'react-file-viewer-extended';
import pdf from '../../src/test.pdf';
import xlsx from '../../src/xltest1.xlsx';
import doc from '../../src/testdoc.docx';


const file = 'https://www.clickdimensions.com/links/TestPDFfile.pdf';
const type = 'pdf';

const type2 = 'xlsx';
const type3 = 'docx';
// const file1 = '../../test.pdf';
const type1 = 'pdf'; 
const BlankPage = () => {
  return (
    <FormStyleWrapper>
    {/* <Container> */}
       <div className="container_">
       <div className="header_ text-start">
      <h4>Dashboard</h4>
    </div>
      </div>
    {/* </Container> */}
    </FormStyleWrapper>
  )
}

export default BlankPage
