import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const FilePreviewStyleWrapper = styled.div`

.documentWrapper{
    padding: 20px 5px;
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        h3{
            font-size: 24px;
            font-weight: 500;
            text-align: left;
        }
        .custom-button{
            max-width: 150px;
        }
    }
    .pg-viewer-wrapper{
        max-height: calc(100vh - 100px);
        overflow: auto;
        .pg-viewer{
            color: black;
        }
        .photo-viewer-container{
            min-height: calc(100vh - 120px) !important;
            min-width: 100%;
            img{
                width: 100% !important;
                height: 100% !important;
                object-fit: contain;
            }
        }
    }
    .errorMsg{
        font-size: 21px;
        margin-top: 10%;
    }
}
`;

export default WithDirection(FilePreviewStyleWrapper);
