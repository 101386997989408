


import React, { useEffect, useState, useRef } from 'react'
import { Card, Modal, Form, Row, Col } from 'react-bootstrap'
import Loading from '../../helpers/Loader/loader';
import apiManager from './../../helpers/apiManager';
import { AiOutlineInfoCircle } from 'react-icons/ai'
import FormStyleWrapper from './form.style';
import CustomCard from '../simpleCard';
import { useSelector, useDispatch } from 'react-redux';
import {customData} from './../../helpers/constants';
import { setApplicationId, setApplicationName } from '../../actions';

const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach((val) => {
    if (val.length === 0 || val !== 'blank') {
      valid = false;
    }
  });
  return valid;
};
const SukukInfo = (props) => {
  const {setWarning, setWarningPopup, warningPopup, setTabForce, goNext, setSukukInfoValues } = props;
  const dispatch = useDispatch();
  const externalId = useSelector((state) => state.app.externalId);
  const uid = useSelector((state) => state.app.uid);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const formFields = props.form_Fields;
  const [formValues, setFormValues] = useState({
    sukukName: '',
    sukukIssuanceCurrency: customData.DEFAULT_CURRENCY,
    sukukIssuanceSize: '',
    sukukMonths: '',
    sukukESG: 'Yes',
    sukukGreen: 'Yes',
    sukukRemarks: ''
  });
  const [currencyCodes, setCurrencyCodes] = useState([]);
  const [successInfoShow, setSuccessInfoShow] = useState(false);
  const [error, setError] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [serverError, setServerError] = useState(false);
  const [formError, setFormError] = useState({
    sukukName: 'blank',
    sukukIssuanceCurrency: 'blank',
    sukukIssuanceSize: '',
    sukukMonths: '',
    sukukESG: 'blank',
    sukukGreen: 'blank',
    sukukRemarks: ''
  });


  useEffect(() => {
    // populateSukukData()
    if (props.isApplicationInfo && Object.keys(props.isApplicationInfo).length !== 0) {
      const {ApplicationName, SukukIssuanceCurrency, ReasonForSukuk, SukukIssuanceSize, SukukTenureInMonths, GreenSukuk, ESGSukuk } = props.isApplicationInfo;
      // setFormValues({ ...formValues, [name]: value });
      setFormValues({
        ...formValues,
        sukukName: ApplicationName ? ApplicationName : '',
        sukukIssuanceCurrency: SukukIssuanceCurrency ? SukukIssuanceCurrency : customData.DEFAULT_CURRENCY,
        sukukIssuanceSize: SukukIssuanceSize ? SukukIssuanceSize : '',
        sukukMonths: SukukTenureInMonths ? SukukTenureInMonths : '',
        sukukESG: ESGSukuk ? ESGSukuk : 'Yes',
        sukukGreen: GreenSukuk ? GreenSukuk : 'Yes',
        sukukRemarks: ReasonForSukuk ? ReasonForSukuk : ''
      })
      setSukukInfoValues({
        ...formValues,
        sukukName: ApplicationName ? ApplicationName : '',
        sukukIssuanceCurrency: SukukIssuanceCurrency ? SukukIssuanceCurrency : customData.DEFAULT_CURRENCY,
        sukukIssuanceSize: SukukIssuanceSize ? SukukIssuanceSize : '',
        sukukMonths: SukukTenureInMonths ? SukukTenureInMonths : '',
        sukukESG: ESGSukuk ? ESGSukuk : 'Yes',
        sukukGreen: GreenSukuk ? GreenSukuk : 'Yes',
        sukukRemarks: ReasonForSukuk ? ReasonForSukuk : ''
      })
      setFormError({
        ...formError,
        sukukName: ApplicationName ?  JSON.stringify(ApplicationName).length > 0 ? 'blank' : '' : '',
        sukukIssuanceSize: SukukIssuanceSize ? JSON.stringify(SukukIssuanceSize).length > 0 ? 'blank' : '' : '',
        sukukMonths: SukukTenureInMonths ? JSON.stringify(SukukTenureInMonths).length > 0 ? 'blank' : '' : '',
        sukukRemarks: ReasonForSukuk ? ReasonForSukuk.length > 0 ? 'blank' : '' : '',
      })
    }

    const currencyCodesdata = {
      "Currency": [],
      "CurrencyCode": customData.ISO_CURRENCY_CODES,
    }
    const storedCurrencyCodes = JSON.parse(localStorage.getItem('isoCurrencyCodes'));
    if (storedCurrencyCodes) {
      setCurrencyCodes(storedCurrencyCodes);
    }
    else {
      apiManager.getISOCurrencyCodes(currencyCodesdata)
        .then((response) => {
          setCurrencyCodes(response.data);
          localStorage.setItem('isoCurrencyCodes', JSON.stringify(response.data));
        })
        .catch((error) => { });
    }
  }, [props.isApplicationInfo])


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    setSukukInfoValues({ ...formValues, [name]: value });
    setFormError({ ...formError, [name]: value.length > 0 ? 'blank' : '' });
    setWarning(true);
  }
  // console.log( sukukData.ApplicationInfo, 'country code')

  const saveSukukInfo = (event) => {
    event.preventDefault();
    if (formValid(formError)) {
      setSaveLoading(true);
      const data = {
        "ExternalId": externalId,
        "InstrumentId": "101",
        "ApplicationName": sukukName,
        "ApplicationInfo": {
          "SukukIssuanceSize": sukukIssuanceSize,
          "SukukIssuanceCurrency": sukukIssuanceCurrency,
          "SukukTenureInMonths": sukukMonths,
          "ReasonForSukuk": sukukRemarks,
          "ESGSukuk": sukukESG,
          "GreenSukuk": sukukGreen,
        }
      };
      apiManager.updateInfoApplication(data, uid)
        .then((res) => {
          setSaveLoading(false);
          setWarningPopup(false);
          setSuccessInfoShow(true);
          dispatch(setApplicationId(res.data[0].ApplicationId));
          dispatch(setApplicationName(res.data[0].ApplicationName));
          setTimeout(() => {
            setSuccessInfoShow(false)
          }, 2000);
          setWarning(false);
        })
        .catch((err) => {
          setServerError(true);
          setSaveLoading(false);
          setWarningPopup(false);
          setErrorInfo(err.res.data.Error.errorMessage);
          setTimeout(() => {
            setServerError(false)
          }, 2000);
        })
    }
    else {
      setError(true);
    }
    //   const data = {
    //     ExternalId: '74e20871-5e2b-4665-ad8f-d2dc4a24dfd8',
    //     CurrentPhase: 'Submission',
    //     InstrumentId: '101',
    //     ApplicationInfo: {
    //       ...infoData,
    //       CommentDate: '2022-04-28 10:10:10',
    //     },
    //   }
    //   console.log(data, 'data')
    //   axios
    //     .post(`${API}/Application/Submission/Save/`, data, QIAM_HEADERS_AXIOS)
    //     .then((res) => {
    //       console.log(res, 'res')
    //       setSaveLoading(false)
    //       setSuccessInfoShow(true)
    //       setTimeout(() => {
    //         setSuccessInfoShow(false)
    //       }, 3000)
    //     })
    //     .catch((err) => {
    //       console.log(err, 'err')
    //       setSaveLoading(false)
    //       setErrorInfo(true)
    //       setTimeout(() => {
    //         setErrorInfo(false)
    //       }, 3000)

    //     })
  }
  
  const { sukukName, sukukIssuanceCurrency, sukukIssuanceSize, sukukMonths, sukukESG, sukukGreen, sukukRemarks} = formValues;
  return (<FormStyleWrapper>
    <div className="layout layout-horizontal sukuk-info-tab" style={{marginRight: -30}}>
      <div className="info-card">
        <Card className="sukuk-info-card border-0">
          <Card.Body>
            {formFields && (
              <Card.Text>
                <>
                  {saveLoading && <Loading />}
                  <div className="px-3">
                    <Form>
                    <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalPassword" size="lg">
                        <Form.Label column sm={4} className="text-end m-0 form-label">
                          Sukuk Name<span>*</span>:
                        </Form.Label>
                        <Col sm={8} className="d-flex align-items-center">
                          <Form.Control type="text" name="sukukName" onChange={handleChange} placeholder="Enter Sukuk Name"
                            value={sukukName} className={JSON.stringify(sukukName).length > 0 ? 'highlight' : ''} style={error && formError.sukukMonths === '' ? { borderColor: 'red' } : {}} />
                          <AiOutlineInfoCircle className="info-icon-ai" />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail" size="lg">
                        <Form.Label column sm={4} className="text-end m-0 form-label">
                          Sukuk Issuance Size<span>*</span>:
                        </Form.Label>
                        <Col sm={8} className="d-flex align-items-center">
                          <Form.Select aria-label="Default select example" onChange={handleChange}
                            name="sukukIssuanceCurrency" style={{ marginRight: 10, width: '115px', height: '45px', borderColor: '#ced4da' }}
                            value={sukukIssuanceCurrency}
                            className={JSON.stringify(sukukIssuanceCurrency).length > 0 ? 'highlight' : ''}
                          >
                            {currencyCodes.length > 0 && currencyCodes.map((country) => (
                              <option key={country.CurrencyCode} value={country.CurrencyCode}>{country.CurrencyCode}</option>
                            ))}
                          </Form.Select>
                          <Form.Control name="sukukIssuanceSize" value={sukukIssuanceSize} onChange={handleChange} type="number" placeholder="" style={error && formError.sukukIssuanceSize === '' ? { borderColor: 'red', width: 'calc(100% - 110px)' } : { width: 'calc(100% - 110px)' }}
                            className={JSON.stringify(sukukIssuanceSize).length > 0 ? 'highlight' : ''} />

                          <AiOutlineInfoCircle className="info-icon-ai" />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalPassword" size="lg">
                        <Form.Label column sm={4} className="text-end m-0 form-label">
                          Sukuk Tenure<span>*</span>:
                        </Form.Label>
                        <Col sm={8} className="d-flex  align-items-center">
                          <Form.Control type="number" name="sukukMonths" onChange={handleChange} placeholder="Number of Months"
                            value={sukukMonths} className={JSON.stringify(sukukMonths).length > 0 ? 'highlight' : ''} style={error && formError.sukukMonths === '' ? { borderColor: 'red' } : {}} />
                          <AiOutlineInfoCircle className="info-icon-ai" />
                        </Col>
                      </Form.Group>
                      <fieldset>
                        <Form.Group as={Row} className="mb-3 align-items-center">
                          <Form.Label as="legend" column sm={4} className="text-end form-label">
                            ESG Sukuk<span>*</span>:
                          </Form.Label>
                          <Col sm={8} className="d-flex align-items-center">
                            <Form.Select name="sukukESG" value={sukukESG} onChange={handleChange} aria-label="Default select example"
                              style={{ width: '115px', height: '45px', borderColor: '#ced4da' }} className={JSON.stringify(sukukESG).length > 0 ? 'highlight' : ''} >
                              <option key="Yes">Yes</option>
                              <option key="No">No</option>
                              <option key="Notsure">Not sure</option>
                            </Form.Select>
                            <AiOutlineInfoCircle className="info-icon-ai" />
                          </Col>
                        </Form.Group>
                      </fieldset>
                      <Form.Group as={Row} className="mb-3 align-items-center">
                        <Form.Label as="legend" column sm={4} className="text-end m-0 form-label">
                          Green Sukuk<span>*</span>:
                        </Form.Label>
                        <Col sm={8} className="d-flex align-items-center">
                          <Form.Select name="sukukGreen" onChange={handleChange} value={sukukGreen}
                            className={JSON.stringify(sukukGreen).length > 0 ? 'highlight' : ''} aria-label="Default select example"
                            style={{ width: '115px', height: '45px', borderColor: '#ced4da' }}>
                            <option key="Yes">Yes</option>
                            <option key="No">No</option>
                            <option key="Notsure">Not sure</option>
                          </Form.Select>
                          <AiOutlineInfoCircle className="info-icon-ai" />
                        </Col>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label as="legend" column sm={12} className="form-label" >
                          Remarks (Why this sukuk is required)<span>*</span>:
                        </Form.Label>
                        <Col sm={12} className="d-flex align-items-center">
                          <Form.Control
                            as="textarea"
                            placeholder="Leave a comment here"
                            name="sukukRemarks"
                            value={sukukRemarks}
                            onChange={handleChange}
                            className={JSON.stringify(sukukRemarks).length > 0 ? 'highlight' : ''}
                            style={error && formError.sukukRemarks === '' ? { borderColor: 'red', height: '100px' } : { height: '100px' }}
                          />
                          <AiOutlineInfoCircle className="info-icon-ai" />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                          {/* <Link to="/dashboard/new-sukuk/info"> */}
                          <button className="custom-button"
                            onClick={(e) => saveSukukInfo(e)}
                          >
                            Save
                          </button>
                          {/* </Link> */}
                          {/* <Link to="/dashboard/new-sukuk/documents"> */}
                          <button className="custom-button" onClick={(e) => [e.preventDefault() , goNext(e, 'Documents')]}>Next</button>
                          {/* </Link> */}
                        </Col>
                      </Form.Group>
                    </Form>

                  </div>
                </>

              </Card.Text>
            )}
          </Card.Body>

          {/* <div
                className="layout layout-horizontal"
                style={{ justifyContent: 'space-around' }}
              >
                <div>
                  <button className="custom-button" onClick={() => saveSukukInfo()}>
                    Save
                  </button>
                </div>
                <div>
                  <Link to="/dashboard/new-sukuk/documents">
                    <button className="custom-button">Next</button>
                  </Link>
                </div>
              </div> */}
        </Card>
      </div>


      <CustomCard title="" info={''} />


      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="error-handling-modal"
        show={successInfoShow}
        onHide={() => setSuccessInfoShow(false)}
      >
        {/* Sucesss Modal  */}

        <Modal.Body closeButton>
          <div className="text-center">
            <br></br>
            <h4> Application Information Data Saved Successfully.</h4>
            <h4> User can start with Document Upload </h4>
            <br></br>
            {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="error-handling-modal"
        show={warningPopup}
        onHide={() => setWarningPopup(false)}
      >
        {/* Sucesss Modal  */}

        <Modal.Body>
          <div className="text-center">
            <br></br>
            <h4>Are you sure you want to move to next section? </h4>
            <h4>Your recently entered data will be lost! Please SAVE
              before proceeding</h4>
            <br></br>
            {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
            <Row>
              <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                {/* <Link to="/dashboard/new-sukuk/info"> */}
                <button className="custom-button light-btn"
                  onClick={(e) => saveSukukInfo(e)}
                >
                  Save
                </button>
                {/* </Link> */}
                {/* <Link to="/dashboard/new-sukuk/documents"> */}
                <button className="custom-button light-btn" onClick={() => setTabForce()}>It's Alright</button>
                {/* </Link> */}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="error-handling-modal"
        show={serverError}
        onHide={() => setServerError(false)}
      >
        {/* Error Modal  */}

        <Modal.Body>
          <div className="center-title">
            <br></br>
            <h4> {errorInfo}</h4>
            <br></br>
            {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
          </div>
        </Modal.Body>
      </Modal>

      

    </div></FormStyleWrapper>
  )
}


export default SukukInfo;