


import React, { useEffect, useState, useRef } from 'react'
import { Card, Modal, Form, Row, Col } from 'react-bootstrap'
import Loading from '../../helpers/Loader/loader';
import FormStyleWrapper from '../../components/NewSukuk/form.style';
import CustomCard from '../../components/simpleCard';
import {useSelector, useDispatch} from 'react-redux';


const SukukSubmit = (props) => {
    const [saveLoading, setSaveLoading] = useState(false);
    const [additionalRemarks, setAdditionalRemarks] = useState('');
    const [successInfoShow, setSuccessInfoShow] = useState(false);
    const [successInfo, setSuccessInfo] = useState('');
    const [errorInfo, setErrorInfo] = useState('');
    const [serverError, setServerError] = useState(false);
  
  
    useEffect(() => {
      // populateSukukData()
      if(props.isApplicationInfo && Object.keys(props.isApplicationInfo).length !== 0 ){
        const {AdditionalRemarks} = props.isApplicationInfo;
        setAdditionalRemarks(AdditionalRemarks ? AdditionalRemarks : '');
        setSaveLoading(false);
    }
    }, [props.isApplicationInfo])
  
    return (
      <FormStyleWrapper>
      <div className="layout layout-horizontal sukuk-info-tab">
        <div className="info-card">
            <Card className="sukuk-info-card border-0">
              <Card.Body>
                  <Card.Text>
                     <>
                          {saveLoading && <Loading />}
                        <div className="px-3">
                          <Form>
                            <Form.Group>
                              <Form.Label as="legend" column sm={12} className="form-label">
                                Additional Remarks
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                              <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                name="sukukRemarks"
                                disabled = {true}
                                value={additionalRemarks}
                                onChange={(e) => setAdditionalRemarks(e.target.value)}
                                style={{height: '150px'}}
                                className={additionalRemarks.length > 0 ? 'highlight' : ''}
                              />
                              {/* <AiOutlineInfoCircle className="info-icon-ai" /> */}
                              </Col>
                            </Form.Group>
                            {/* <Form.Group as={Row} className="mb-3">
                              <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                                <button className="custom-button" 
                                onClick={(e) => saveSukukInfo(e, 'save')}
                                >
                                  Save
                                </button>
                                  <button className="custom-button" onClick={submitSukukRequest}>Submit</button>
                                
                              </Col>
                            </Form.Group> */}
                          </Form>
  
                        </div>
  </>
                  </Card.Text>
              </Card.Body>
  
              {/* <div
                className="layout layout-horizontal"
                style={{ justifyContent: 'space-around' }}
              >
                <div>
                  <button className="custom-button" onClick={() => saveSukukInfo()}>
                    Save
                  </button>
                </div>
                <div>
                  <Link to="/dashboard/new-sukuk/documents">
                    <button className="custom-button">Next</button>
                  </Link>
                </div>
              </div> */}
            </Card>
        </div>
  
  
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={successInfoShow}
          onHide={() => setSuccessInfoShow(false)}
        >
          {/* Sucesss Modal  */}
  
          <Modal.Body closeButton>
            <div className="center-title">
              <br></br>
              <h4>{successInfo}</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
  
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={serverError}
          onHide={() => setServerError(false)}
        >
          {/* Error Modal  */}
  
          <Modal.Body>
            <div className="center-title">
              <br></br>
              <h4> {errorInfo}</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>

      </div>
      </FormStyleWrapper>
    )
  }


  export default SukukSubmit;