


import React, { useEffect, useState, useRef } from 'react'
import { Card, Modal, Form, Row, Col } from 'react-bootstrap'
import Loading from '../../helpers/Loader/loader';
import { AiOutlineInfoCircle } from 'react-icons/ai'
import SukukTabStyleWrapper from './sukukTab.style';
import {useSelector} from 'react-redux';

const Info = (props) => {
    const externalId = useSelector((state) => state.app.externalId);
    const [loading, setLoading] = useState(false);
  
const goNext = (e) =>{
    e.preventDefault();
    props.setTab('Documents');
    }
    const {ApplicationName, SukukIssuanceCurrency, SukukIssuanceSize, SukukTenureInMonths, ESGSukuk, GreenSukuk, ReasonForSukuk} =  props.isApplicationInfo;
    return (
      <SukukTabStyleWrapper>
      <div className="layout layout-horizontal">
        <div className="info_ mt-4">
                          {loading && <Loading />}
                          <Row className="align-items-center">
                          <Col sm={{ span: 3 }} className="text-end my-2">
                          <label className='form-label text-end'>
                          Sukuk Name:
                              </label>
                        </Col>
                        <Col sm={{ span: 9 }} className="text-start m-0 mb-2">
                          <label className='form-value'>
                          {' '+ApplicationName ? ApplicationName: ''}
                              </label>
                        </Col>
                        <Col sm={{ span: 3 }} className="text-end my-2">
                          <label className='form-label text-end'>
                                Sukuk Issuance Size:
                              </label>
                        </Col>
                        <Col sm={{ span: 9 }} className="text-start m-0 mb-2">
                          <label className='form-value'>
                                {`${SukukIssuanceCurrency ? ' '+SukukIssuanceCurrency+' '+ SukukIssuanceSize : ''}`}
                              </label>
                        </Col>
                        <Col sm={{ span: 3 }} className="text-end my-2">
                          <label className='form-label text-end'>
                          Sukuk Tenure(months):
                              </label>
                        </Col>
                        <Col sm={{ span: 9 }} className="text-start m-0 mb-2">
                          <label className='form-value'>
                          {' '+SukukTenureInMonths ? SukukTenureInMonths: ''}
                              </label>
                        </Col>
                        <Col sm={{ span: 3 }} className="text-end my-2">
                          <label className='form-label text-end'>
                          ESG Sukuk:
                              </label>
                        </Col>
                        <Col sm={{ span: 9 }} className="text-start m-0 mb-2">
                          <label className='form-value'>
                          {' '+ESGSukuk ? ESGSukuk: ''}
                              </label>
                        </Col>
                        <Col sm={{ span: 3 }} className="text-end my-2">
                          <label className='form-label text-end'>
                          Green Sukuk:
                              </label>
                        </Col>
                        <Col sm={{ span: 9 }} className="text-start m-0 mb-2">
                          <label className='form-value'>
                          {' '+GreenSukuk ? GreenSukuk: ''}
                              </label>
                        </Col>
                        <Col sm={{ span: 12 }} className="text-start my-2">
                          <label className='w-100 form-label'>
                          Remarks (Why this sukuk is required)
                              </label>
                              <textarea style={{minWidth: '660px'}} className='w-75' rows={5} defaultValue={ReasonForSukuk} disabled={true}/>
                        </Col>
                              <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                                {/* </Link> */}
                                {/* <Link to="/dashboard/new-sukuk/documents"> */}
                                  <button className="custom-button" onClick={goNext}>Next</button>
                                {/* </Link> */}
                              </Col>
                              </Row>
                        </div>
        </div>
        </SukukTabStyleWrapper>
    )
  }


  export default Info;