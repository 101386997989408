import React, { useState, useEffect} from 'react'
// import './requests.css'
import SukukCard from '../../components/SukukCard/sukukCard';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setApplicationId } from '../../actions';
import apiManager from '../../helpers/apiManager';
import Loading from '../../helpers/Loader/loader';

const SavedSukukRequests = () => {
    const externalId = useSelector(state => state.app.externalId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [savedApplicationsList, setsavedApplicationsList] = useState([]);

  useEffect(() =>{
    const data = {"ExternalId" : externalId, "InstrumentId": 101};
    apiManager
            .getApplicationData(data)
            .then((response) => {
              const savedApplications = response.data.length > 0 && response.data.filter(application => application.CurrentStatus === 1);
              setsavedApplicationsList(savedApplications);
              setLoading(false);
              // localStorage.setItem('categories', JSON.stringify(response.data.data));
            })
            .catch((error) => {
              setServerError(true);
              setLoading(false);
            });
  },[]);


const gotoInfo = (id, app) => {
    navigate("info", {state:{ applicationId: id, app : app }});
    dispatch(setApplicationId(id));
  }
return(

<>
    {loading && <Loading />}
    <div className="container_">
      <div className="header_ text-start">
        <h4>Saved Sukuk Requests</h4>
      </div>
      {savedApplicationsList.map(app =>{
        const info ={
          SubmittedOn: app.SubmittedOn ? app.SubmittedOn : app.ApplicationDate, 
          ApplicationName: app.ApplicationName, 
          Account: app.Account ? app.Account : '', 
          CompanyInfo: app.CompanyInfo ? app.CompanyInfo : {}, 
          IssuanceSize: app.ApplicationInfo.SukukIssuanceSize ? app.ApplicationInfo.SukukIssuanceSize : '', 
          TenureInMonths : app.ApplicationInfo.SukukTenureInMonths ? app.ApplicationInfo.SukukTenureInMonths : '', 
          Currency: app.ApplicationInfo.SukukIssuanceCurrency ? app.ApplicationInfo.SukukIssuanceCurrency : '',
        }
          return(
            <div onClick={() => gotoInfo(app.ApplicationId, app)}>
            {/* <div> */}
      <SukukCard info={info} />
  </div>
          )
      })}
    </div>
    </>
      )
    }

      export default SavedSukukRequests;