import React, { lazy, Suspense } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import { PRIVATE_ROUTE } from '../../helpers/routerConstants';
import ProfileForm from '../../components/ProfileForm/profileForm';
import BlankPage from '../../publicPages/blankPage';
import ErrorBoundary from '../../ErrorBoundary';
import SukukList from './sukukList';
import SubscriptionList from './subscriptionList';
import Dashboard from './Dashboard';
import FundDisbursement from './fundDisbursement';
import FundDisbursementForm from './fundDisbursementForm';
import BankDetails from './bankDetails';

const routes = [
  {
    path: PRIVATE_ROUTE.DASHBOARD,
    exact: 'true',
    component: <Dashboard />,
  },
  {
    path: PRIVATE_ROUTE.SUKUKLIST,
    exact: 'true',
    component: <SukukList />,
  },
  {
    path: PRIVATE_ROUTE.SUBSCRIPTIONLIST,
    exact: 'true',
    component: <SubscriptionList />,
  },
  {
    path: PRIVATE_ROUTE.FUNDDISBURSEMENT,
    exact: 'true',
    component: <FundDisbursement />,
  },
  {
    path: PRIVATE_ROUTE.FUNDDISBURSEMENTFORM,
    exact: 'true',
    component: <FundDisbursementForm />,
  },
  {
    path: PRIVATE_ROUTE.BANKDETAILS,
    exact: 'true',
    component: <BankDetails />,
  },
];
export default function spvRoutes() {
  return (
    <Suspense fallback={<ErrorBoundary />}>
      <Routes>
        {routes.map((route, idx) => (
          <Route key={idx} path={route.path} exact={route.exact} element={route.component} />
        )
        )}
      </Routes>
    </Suspense>
  );
}
