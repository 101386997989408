import React, { useState, useEffect } from 'react'
// import './requests.css'
import CustomCard from '../../components/SukukCard/spvSubcriptionCard';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setApplicationId } from '../../actions';
import apiManager from '../../helpers/apiManager';
import Loading from '../../helpers/Loader/loader';
import Document from '../../helpers/images/assets/documentDark.svg';
import SubscriptionListStyleWrapper from './subscriptionList.style'
import { Form, Row, Col, Button, Card, Modal, Dropdown } from 'react-bootstrap';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import axios from 'axios';
import { config } from './../../helpers/constants';
import menuOption from './../../helpers/images/assets/VerticalDotsMenu-Grey.svg';
import utility from '../../helpers/utility';
// import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { BiUpArrow, BiDownArrow } from 'react-icons/bi';


const url = config.url.API_URL;
// import FileDownloadGrey from '../../helpers/images/assets/File_Download_Icon_grey.svg';
const statusText = ['', '', 'Rejected','Acknowledged', 'Received', 'Approved'];
const statusColors = ['grey', '', '#EE220C', '#fff709', 'grey', '#1DB100'];
const SubscriptionList = () => {
  const externalId = useSelector(state => state.app.externalId);
  const uid = useSelector(state => state.app.uid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [subscriptionDetail, setSubscriptionDetail] = useState();
  const [spvSubscriptionList, setSPVSubscriptionList] = useState([]);
  const [approvalModal, setApproveModal] = useState(false);
  const [rejectionModal, setRejectionModal] = useState(false);
  const [successInfoShow, setSuccessInfoShow] = useState(false);
  const [error, setError] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [successInfoMessage, setSuccessInfoMessage] = useState('');
  const [showTick, setShowTick] = useState(false);
  const [filteredValue, setFilteredValue] = useState('All');
  const [airTableUrl, setAirTableUrl] = useState('');
  const [sortedValue, setSortedValue] = useState('StatusDateAscending');
  // const [fileName, setFileName] = useState('');
  // const [file, setFile] = useState('');
  const [formValues, setFormValues] = useState({
    remarks: '',
    fileName: '',
    file: {}
  });

  const [approveValidated, setApproveValidated] = useState(false);
  const [rejectionValidated, setRejectionValidated] = useState(false);
  const filterOptions = [{label: 'All'},
  {label: 'Received'},
  {label: 'Acknowledged'},
  {label: 'Approved'},
  {label: 'Rejected'}]
  const sortOptions = [{label: 'Subscription Date', key:'StatusDateAscending'},
  {label: 'Subscription Date', key:'StatusDateDescending'},
  {label: 'Subscription Amount', key:'SubscriptionAmountAscending'},
  {label: 'Subscription Amount', key:'SubscriptionAmountDescending'},
  {label: 'Status', key:'Status'}]
  useEffect(() => {
    getSPVSubscriptionList('new');
    apiManager.getBlockChainUrl()
    .then((response) => {
      let blockChainUrl = '';
      if(response.fields.useAirtable === 'yes'){
        blockChainUrl = response.fields.airtableUrl;
    }
    else{
        blockChainUrl = response.fields.firebaseURL;
    }
    setAirTableUrl(blockChainUrl);
  })
  }, []);
  const getSPVSubscriptionList = (currentStatus) => {
    const data = { "SpvId": externalId };
    setLoading(true);
    // if(sukukStatus === 'listed'){
    apiManager
      .getSPVSubscriptionList(data, uid)
      .then((response) => {
        const tempList = response.data.length > 0 ? response.data : [];
        setSubscriptionDetails(tempList[0]);
        setSPVSubscriptionList(tempList[0].ListOfSubscriptions);
        setLoading(false);
        // setFilteredValue('All');
        if(currentStatus == 'update')
         filter('', filteredValue, tempList[0].ListOfSubscriptions);
         else{
          sort('', sortedValue, tempList[0].ListOfSubscriptions)
         }
        // localStorage.setItem('categories', JSON.stringify(response.data.data));
      })
      .catch((error) => {
        setServerError(true);
        setLoading(false);
      });
  }

  const gotoInfo = (id, app) => {
    navigate("info", { state: { app: app } });
    dispatch(setApplicationId(id));
  }
  // const updateFunction = (id, status) =>{
  //   if(status === 'Active'){
  //   const data = {"ApplicationId" : id};
  //   setLoading(true);
  //   apiManager
  //           .setSukukToListed(data, uid)
  //           .then((response) => {
  //           //   const tempSukukList = response.data.length > 0 && response.data.filter(application => application.CurrentStatus !== 1);
  //           //   setSukukList(tempSukukList);
  //           setLoading(false);
  //           getSPVSubscriptionList();
  //           //  setModalShow(true);
  //            setTimeout(() =>{
  //               // setModalShow(false);
  //            })
  //             // localStorage.setItem('categories', JSON.stringify(response.data.data));
  //           })
  //           .catch((error) => {
  //           //   setServerError(true);
  //             setLoading(false);
  //           });
  // }
  // }
  const fileSelectHandler = (event) => {
    const { files } = event.target;
    setFormValues({ ...formValues, fileName: files[0].name, file: files[0] })
    // setFileName(files[0].name);
    // setFile(files[0]);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  }
  const handleApproveSubmit = (event) => {
    const form = event.currentTarget;
    const successMessage = 'Successfully Approved';
    setShowTick(true);
    event.preventDefault();

    if (form.checkValidity() === true) {
      setLoading(true);
      const formData = new FormData();
      // to do 
      formData.append('proofOfApproval', formValues.file)

      formData.append('subscriptionData', JSON.stringify({
        SubscriptionId: subscriptionId,
        SubscriptionApprovalComments: formValues.remarks
      }));
      axios
        .post(`${url}/Prospectus/SPV/Subscription/Approved/`, formData, {
          headers: {
            'Content-Type':
              'multipart/form-data; boundary=<calculated when request is sent>',
            'x-ccx-api-key':
              'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbqEAVxItCetPaj541Od+TgY=',
            'x-ccx-api-usr': uid,
            // 'origin': 'https://qiam-31988.web.app/'
          },
        })

        .then((response) => {
          setLoading(false);
          setApproveModal(false);
          setSuccessInfoMessage(successMessage);
          showMessageForInstance();
          setFormValues({ remarks: '', fileName: '', file: '' })
          getSPVSubscriptionList('update');
          updateDataToBlockChain(response.data.data[0].Subscriptions);
        })
        .catch((error) => {
          setServerError(true);
          setLoading(false);
        })
    }
    else {
      setApproveValidated(true);
    }
  }
  const updateDataToBlockChain = (subscriptionList) => {
    const tempSubscription = subscriptionList.find(sub => sub.SubscriptionId === subscriptionId);
    const data = {
      externalId: tempSubscription.InvestorExternalId,
      subscriptionId: subscriptionId,
      subcrsAddress: "0x2144F775c9f8f824Fdfc9C7d6579Bd9ECc6582b2",  // it should be to investor valid address
      subscriptionDate: tempSubscription.StatusDate,
      subscriptionCurrency: tempSubscription.SubscriptionCurrencyCode,
      subscriptionAmount: ""+tempSubscription.SubscriptionAmount,
      subscriptionStatusCode: ""+tempSubscription.Status,
      subscriptionStatus: statusText[tempSubscription.Status]
    }
    apiManager.setSubscriptionDataToBlockChain(data, airTableUrl)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log('failed to set to block chain');
      })
  }
  const showMessageForInstance =() =>{
    setSuccessInfoShow(true);
    setTimeout(() =>{
      setSuccessInfoShow(false);
    },3000);
  }
  const handleRejectionSubmit = (event) => {
    const form = event.currentTarget;
    const successMessage = 'Successfully Rejected';
    setShowTick(false);
    event.preventDefault();
    if (form.checkValidity() === true) {
      setLoading(true);
      const formData = new FormData();
      // to do 
      formData.append('proofOfApproval', formValues.file)
      formData.append('subscriptionData', JSON.stringify({
        SubscriptionId: subscriptionId,
        SubscriptionApprovalComments: formValues.remarks
      }));
      apiManager.rejectSubscription(formData, uid)
        .then((res) => {
          setLoading(false);
          setSuccessInfoMessage(successMessage);
          showMessageForInstance();
          setRejectionModal(false);
          setFormValues({ remarks: '', fileName: '', file: '' });
          getSPVSubscriptionList('update');
          updateDataToBlockChain(res.data[0].Subscriptions);
        })
        .catch((err) => {
          setServerError(true);
          setLoading(false);
        })
    }
    else {
      setRejectionValidated(true);
    }
  }
  const setModal = (task, id, details) => {
    setSubscriptionId(id);
    setSubscriptionDetail(details);
    if (task === 'approve') {
      setApproveModal(true);
    }
    else {
      setRejectionModal(true);
    }
  }
  const callReceivedSub = (id) => {
    setLoading(true);
    const successMessage = 'Successfully Acknowledged';
    setShowTick(true);
    const data = {
      SubscriptionId: id
    }
    setSubscriptionId(id);
    apiManager.receivedSub(data, uid)
      .then((res) => {
        setLoading(false);
        setSuccessInfoMessage(successMessage);
        showMessageForInstance();
        getSPVSubscriptionList('update');
      })
      .catch((err) => {
        setServerError(true);
        setLoading(false);
      })
  }
  const filter =(e, filterVal, list=[]) =>{
    const tempFilterVal = e !== '' ? e.target.value : filterVal;
    setFilteredValue(tempFilterVal);
    let filteredList = e !== '' ? subscriptionDetails.ListOfSubscriptions : list;
    if(tempFilterVal === 'All'){
      filteredList = filteredList;
    }
    else
    { filteredList = filteredList.filter(sub => statusText[sub.Status] === tempFilterVal);}
    setSPVSubscriptionList(filteredList);
    sort('', sortedValue, filteredList);
  }
  const sort =(e, recentValue, list=[]) =>{
    const sortVal = e !== '' ?  e.target.value : recentValue;
    let subList = e !== '' ? spvSubscriptionList : list;
    if(sortVal !== ''){
      setSortedValue(sortVal);
      let sortedList = [];
      if(sortVal.includes('Date')){
         sortedList = subList.sort((a, b) =>  {
          const prevDate = new Date(a['StatusDate']);
          const nextDate = new Date(b['StatusDate']);
          return (
            sortVal.includes('Descend') ? nextDate.getTime() - prevDate.getTime() : prevDate.getTime() - nextDate.getTime()
          )
        });
      }
      else if(sortVal.includes('Status')){
        sortedList = subList.sort((a, b) =>  statusText[a.Status] > statusText[b.Status] ? 1 : -1);
      }
      else if(sortVal.includes('Amount')){
         sortedList = subList.sort((a, b) =>  sortVal.includes('Descend') ? b['SubscriptionAmount'] - a['SubscriptionAmount'] : a['SubscriptionAmount'] - b['SubscriptionAmount']);
      }
      setSPVSubscriptionList(sortedList);
    }
    
  }
  const subStartDate = subscriptionDetails ? utility.getFormattedDate(subscriptionDetails.SubscriptionStartDate) : '';
  const subEndDate = subscriptionDetails ? utility.getFormattedDate(subscriptionDetails.SubscriptionEndDate) : '';
  return (
    <SubscriptionListStyleWrapper>
      {loading && <Loading />}
      <div className="container_">
        <div className="header_ text-start">
          <h4>Subscription Details</h4>
        </div>
        <div className="SubscriptionDetailsRow px-4 border-bottom">
          <Row>
            <Col xs={4}>
              <label className='text-start d-block'>Sukuk Name</label>
              <h6 className='text-start mb-2'>{subscriptionDetails && subscriptionDetails.ApplicationName}</h6>
            </Col>
            <Col xs={4}>
              <label className=''>Subscription Start</label>
              <h6 className='mb-2'>{subStartDate}</h6>
            </Col>
            <Col xs={4}>
              <label className=''>Subscription End</label>
              <h6 className='mb-2'>{subEndDate}</h6>
            </Col>
          </Row>
        </div>
        <div className="mt-4">
          <Row className="justify-content-end">
            <div className='w-auto'>
              <div className="d-flex align-items-center">
              <label style={{width: '130px'}}>Filter by Status:</label>
              <div style={{width: 'calc(100% - 120px)', maxWidth: '180px'}}>
              <Form.Select onChange={(e) => filter(e, '')} value={filteredValue}>
                {filterOptions.length > 0 && filterOptions.map((val, idx) =>
                  <option key={idx} value={val.label}>{val.label}</option>
                )}
              </Form.Select>
              </div>
              </div>
            </div>
            <div className='w-auto'>
            <div className="d-flex align-items-center">
              <label style={{width: '90px'}}>Sort by:</label>
              <div>
              <Form.Select  onChange={(e) => sort(e, '')} value={sortedValue}>
                {sortOptions.length > 0 && sortOptions.map((val, idx) =>
                {
                  return(
                  <option  key={val.key} value={val.key}>{val.label}{val.key.includes('Ascend') && ' (Asc)'}{val.key.includes('Descend') && ' (Desc)'} </option>
                  )}
                  )}
                </Form.Select>
            </div>
            </div>
            </div>
          </Row>
          <div className="d-flex mt-3">
                   {subscriptionDetails && subscriptionDetails.SubscriptionInStages.map((stage, index) =>{
                    return(
                      <span key={index} className="px-2 d-flex align-items-center"><span className="statusIndicatorCircle" style={{backgroundColor: statusColors[stage.Status]}}></span>{statusText[stage.Status]+': '+ stage.NoOfSubscriptions}</span>
                    )
                   })} 
          </div>
        </div>
        {spvSubscriptionList.length > 0 && spvSubscriptionList.map(prospectus => {
          return (
            <div key={prospectus.SubscriptionId} style={{ marginTop: 15, marginBottom: 35 }} className="d-flex align-items-center" >
              <div className='cardWrapper'>
                <CustomCard prospectus={prospectus} />
                {(prospectus.SubscriptionId === subscriptionId && successInfoShow ) && <span className="successMsg" style={{color: prospectus.Status !== 2 ? '#1DB100' : '#EE220C'}}>{successInfoMessage}</span>}
                {/* <span className="successMsg" >{'Succesfuly updated'}</span> */}
              </div>
              <div className="documentProofBlock mx-3">
                <div className='documentBlock'>
                  <label>Subscription Proof</label>
                  <span><img src={Document} /></span>
                </div>
                <div className='documentBlock'>
                  <label>Approval Proof</label>
                  <span><img src={Document} /></span>
                </div>
              </div>
              <div className="buttonsWrapper">
                {/* <button className="custom-button"
                disabled={prospectus.Status === 5}
                  onClick={() => setModal('approve', prospectus.SubscriptionId, prospectus.Status)}
                >
                  Approve
                </button>
                <button className="custom-button"
                disabled={prospectus.Status === 2}
                  onClick={() => setModal('reject', prospectus.SubscriptionId, prospectus.Status)}
                >
                  Reject
                </button> */}
                <div className="options_">
                  <Dropdown align="end">
                    <Dropdown.Toggle variant="success" id="">
                      <img src={menuOption} className="header-icon" alt="MenuOption" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {prospectus.Status === 4 ? <Dropdown.Item onClick={() => callReceivedSub(prospectus.SubscriptionId)}>Acknowledge</Dropdown.Item> :
                      <Dropdown.Item className={'disable'}>Acknowledge</Dropdown.Item>}
                      {prospectus.Status === 3 ? 
                      <>
                      <Dropdown.Item onClick={() => setModal('approve', prospectus.SubscriptionId, prospectus)}>Approve</Dropdown.Item>
                      <Dropdown.Item onClick={() => setModal('reject', prospectus.SubscriptionId, prospectus)}>Reject</Dropdown.Item>
                      </> 
                      : 
                      <>
                      <Dropdown.Item className={'disable'}>Approve</Dropdown.Item>
                      <Dropdown.Item className={'disable'}>Reject</Dropdown.Item>
                      </>}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          )
        })}
        {spvSubscriptionList.length === 0 && <div className="noDataText">There are no Subscriptions</div>}
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="light-bg"
        show={approvalModal}
        onHide={() => setApproveModal(false)}
      >
        <Modal.Body>
          <div className="center-title">
            <Form noValidate validated={approveValidated} onSubmit={handleApproveSubmit}>
              <Form.Group as={Col} md="12" className="mb-4">
                <Form.Label>Approval Remarks</Form.Label>
                <textarea
                  name="remarks"
                  onChange={handleChange}
                  value={formValues.remarks}
                  style={{ width: '100%' }}
                  rows={3}
                  required
                  className={formValues.remarks.length > 0 ? 'highlight  form-control' : 'form-control'}
                />
                <Form.Control.Feedback type="invalid" className="text-start">
                  Please enter the value.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label>Upload Proof of Approval</Form.Label>
                <Col sm={12}>
                  <div className="d-flex inputWrapper position-relative w-100">
                    <input
                      className="form-control sukuk-document-input"
                      type="file"
                      // id={doc.RequiredArtefactId}
                      // ref={inputFile}
                      display="none"
                      style={{ marginLeft: '0px', width: '107px' }}
                      onChange={(e) => fileSelectHandler(e)}
                    />
                    <input
                      className="form-control sukuk-document-input-text"
                      type="text"
                      // id={doc.id}
                      required
                      // ref={inputFileName}
                      value={formValues.fileName}
                      style={{ width: 'calc(100% - 107px)' }}
                    // placeholder={fileName1101 ? fileName1101 : ''}
                    />
                  </div>
                </Col>
                <Form.Control.Feedback type="invalid" className="text-start">
                  Please upload the file.
                </Form.Control.Feedback>
              </Form.Group>
              <Button className="custom-button text-border-button green" type="submit" style={{ marginTop: '20px' }}>Approve</Button>
            </Form>
          </div>
        </Modal.Body>

      </Modal>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="light-bg"
        show={rejectionModal}
        onHide={() => setRejectionModal(false)}
      >
        <Modal.Body>
          <div className="center-title">
            <Form noValidate validated={rejectionValidated} onSubmit={handleRejectionSubmit}>
              <Form.Group as={Col} md="12" className="mb-4">
                <Form.Label>Reason For Rejection</Form.Label>
                <textarea
                  name="remarks"
                  onChange={handleChange}
                  value={formValues.remarks}
                  style={{ width: '100%' }}
                  rows={3}
                  required
                  className={formValues.remarks.length > 0 ? 'highlight  form-control' : 'form-control'}
                />
                <Form.Control.Feedback type="invalid" className="text-start">
                  Please enter the value.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label>Upload Proof of Rejection</Form.Label>
                <Col sm={12}>
                  <div className="d-flex inputWrapper position-relative w-100">
                    <input
                      className="form-control sukuk-document-input"
                      type="file"
                      // id={doc.RequiredArtefactId}
                      // ref={inputFile}
                      display="none"
                      style={{ marginLeft: '0px', width: '107px' }}
                      onChange={(e) => fileSelectHandler(e)}
                    />
                    <input
                      className="form-control sukuk-document-input-text"
                      type="text"
                      // id={doc.id}
                      required
                      // ref={inputFileName}
                      value={formValues.fileName}
                      style={{ width: 'calc(100% - 107px)' }}
                    // placeholder={fileName1101 ? fileName1101 : ''}
                    />
                  </div>
                </Col>
                <Form.Control.Feedback type="invalid" className="text-start">
                  Please upload the file.
                </Form.Control.Feedback>
              </Form.Group>
              <Button className="custom-button text-border-button danger" type="submit" style={{ marginTop: '20px' }}>Reject</Button>
            </Form>
          </div>
        </Modal.Body>

      </Modal>
      {/* <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="checkbox-error-handling-modal"
        show={successInfoShow}
        onHide={() => setSuccessInfoShow(false)}
      >
        <Modal.Body>
          <div className='text-center'>
            <div className="center-title">
              <br></br>

              <span className="tick">{showTick ? <FcCheckmark /> : <FcCancel />}</span><h3>{successInfoMessage}</h3>

            </div>


            <Button onClick={() => setSuccessInfoShow(false)} className="custom-button light-btn" style={{ marginTop: '0px' }}>OK</Button>
          </div>
        </Modal.Body>

      </Modal> */}
    </SubscriptionListStyleWrapper>
  )
}

export default SubscriptionList;