import React, { lazy, Suspense } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import { PRIVATE_ROUTE } from '../../helpers/routerConstants';
import DDRequest from './Requests';
// import ProfileForm from '../../components/ProfileForm/profileForm';
import BlankPage from '../../publicPages/blankPage';
import ErrorBoundary from '../../ErrorBoundary';
import SukukInfo from './sukukInfo';
import FilePreview from '../../components/FilePreview/filePreview';
import UserCaseToBeDone from './userCaseToBeDone';

const routes = [
  {
    path: PRIVATE_ROUTE.DASHBOARD,
    exact: 'true',
    component: <BlankPage/>,
  },
  {
    path: PRIVATE_ROUTE.NEWSUKUKREQUEST,
    exact: 'true',
    component: <DDRequest/>,
  },
  {
    path: PRIVATE_ROUTE.SUKUKREQUESTINFO,
    exact: 'true',
    component: <SukukInfo/>,
  },
  {
    path: PRIVATE_ROUTE.COMMONDOCPREVIEW,
    exact: 'true',
    component: <FilePreview/>,
  },
  {
    path: PRIVATE_ROUTE.USERCASETOBEDONE,
    exact: 'true',
    component: <UserCaseToBeDone/>,
  },
//   {
//     path: PRIVATE_ROUTE.BUSINESSPROFILE,
//     exact: 'true',
//     component: <ProfileForm/>,
//   },
];
export default function ddRoutes() {
  return (
    <Suspense fallback={<ErrorBoundary />}>
    <Routes>
       {routes.map((route, idx) => (
          <Route key={idx} path={route.path} exact={route.exact} element={route.component} />
       )
       )}
    </Routes>
    </Suspense>
  );
}
