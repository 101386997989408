import React, { useState } from 'react'
import '../App.css';
import FormStyleWrapper from './form.style';
import { Button, Card, Container, Modal, Form } from 'react-bootstrap';
import backgroundImage from '../helpers/images/assets/qiam-logo-without-name.png';
import qiamLogoWhite from '../helpers/images/assets/qiam-white-logo.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { customData } from '../helpers/constants';
import { setProfileCompleted, setUid, setExternalId, setProfileRole, setProfileRoleDescription, setEmailId, setLoggedIn, setSPVName, setBusinessRole, setBusinessRoles, setAirtableUrl } from '../actions';
import apiManager from './../helpers/apiManager';
import {PRIVATE_ROUTE} from './../helpers/routerConstants';
import fileDownload from 'js-file-download';
import axios from 'axios'
// import DocViewer, { PNGRenderer } from "react-doc-viewer";
// import FileViewer from 'react-file-viewer-extended';
// import { DocumentViewer } from 'react-documents';


const {BUSINESSROLE_BASED_URL, BUSINESS_ROLES_FOR_DESCRIPTION, BUSINESS_ROLES} = customData;
const emailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const passwordRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])[A-Za-z\d@#$%^&+=]{8,30}$/);
const formValid = formErrors => {
  let valid = true;
  Object.values(formErrors).forEach(val => {
    if (val.length === 0 || val !== 'blank') {
      valid = false;
    }
  });
  return valid;
}


const SignIn = () => {
  const docs = [
    { uri: "https://i.imgur.com/GtolBwWb.jpg" } // Local File
  ];
  const [showServerError, setShowServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: false
  })
  const [formErrors, setFormErrors] = useState({
    email_: "",
    password_: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const tempFormErrors = { ...formErrors };
    switch (name) {
      case 'email':
        if (value.length === 0) {
          tempFormErrors.email_ = '';
        }
        else if (!emailRegex.test(value) && value.length > 0) {
          tempFormErrors.email_ = 'false';
        }
        else {
          tempFormErrors.email_ = 'blank';
        }
        break;
      case 'password':
        if (value.length === 0) {
          tempFormErrors.password_ = '';
        }

        else if (!passwordRegex.test(value) && value.length > 0) {
          tempFormErrors.password_ = 'false';
        } else {
          tempFormErrors.password_ = 'blank';
        }
        break;
      default:
        break;
    }

    setValues({ ...values, [name]: value })
    setFormErrors({ ...tempFormErrors })
  }
  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
    .catch((err) =>{
      console.log(err);
    })
  }
  const signIn = (e) => {
    e.preventDefault();
    if (formValid(formErrors)) {
      const {email, password} = values;
      let errorTxt = "something went wrong, please try again after sometime.";
      const data={
          "PWD" : password,
   "Personas": { "ID": [email] }

      }
      apiManager.signIn(data)
      .then((response) => {
                // temparorily investor role has been neglected.
        const tempAssociationWithoutInvestor = response.data[0].Associations.filter(association => association.BusinessRoles !== 'Investor');
        localStorage.setItem('associations' , JSON.stringify(tempAssociationWithoutInvestor));
        // if(tempAssociationWithoutInvestor.length === 0 || response.data && response.data[0].UserName === null){
        dispatch(setEmailId(email));
        if(tempAssociationWithoutInvestor.length === 0){
          if(response.data[0].UserName && response.data[0].UserName.length > 0 && response.data[0].UserName.includes('Invited SPV User')){
            dispatch(setProfileRole(BUSINESS_ROLES['SPV']));
            dispatch(setProfileRoleDescription(BUSINESS_ROLES_FOR_DESCRIPTION['SPV']));
            dispatch(setBusinessRole(BUSINESS_ROLES['SPV']));
            dispatch(setBusinessRoles([BUSINESS_ROLES['SPV']]));
            dispatch(setProfileCompleted(false));
            navigate('/common/profileForm');
          }
          else{
            navigate("/profile");
          }
        }
        else if(tempAssociationWithoutInvestor.length === 1){
          if(response.data[0].UserName && response.data[0].UserName.length > 0 && response.data[0].UserName.includes('Invited SPV User')){
            dispatch(setProfileRole(BUSINESS_ROLES['SPV']));
            dispatch(setProfileRoleDescription(BUSINESS_ROLES_FOR_DESCRIPTION['SPV']));
            dispatch(setSPVName(tempAssociationWithoutInvestor[0].Account));
            dispatch(setExternalId(tempAssociationWithoutInvestor[0].ExternalId)); 
            dispatch(setBusinessRole(BUSINESS_ROLES['SPV']));
            dispatch(setBusinessRoles([BUSINESS_ROLES['SPV']]));
            dispatch(setProfileCompleted(false));
            navigate('/common/profileForm');
          }
          else{
            const tempRoles =tempAssociationWithoutInvestor[0].BusinessRoles ? tempAssociationWithoutInvestor[0].BusinessRoles : ['QAdmin'];
            dispatch(setProfileCompleted(true));
            dispatch(setExternalId(tempAssociationWithoutInvestor[0].ExternalId)); 
            if(tempRoles.length > 1){   //check if business roles is more than one navigate to association page where they can select the role
              dispatch(setBusinessRoles(tempRoles));
              navigate("/selectAssociations");
            }
            else{ // if business role is only one then take them to dashboard
              dispatch(setBusinessRole(tempRoles[0]));
              dispatch(setProfileRole(tempRoles[0]));
              dispatch(setProfileRoleDescription(BUSINESS_ROLES_FOR_DESCRIPTION[tempRoles[0]]));
              dispatch(setBusinessRoles([tempRoles[0]]));
              navigate(PRIVATE_ROUTE[BUSINESSROLE_BASED_URL[tempRoles[0]]]);
            }
          }
        }
        else{
          console.log('a4');
          //dispatch(setBusinessRoles(tempRole));
          dispatch(setProfileCompleted(true));
          navigate("/selectAssociations");
        }
        dispatch(setUid(response.data[0].UId));
        dispatch(setLoggedIn(response.data[0].UId ? true : false));
        })
        .catch((error) => {
          errorTxt = error.res?.data?.Error?.errorMessage;
          setShowServerError(true);
          setServerErrorMsg(errorTxt ? errorTxt : 'Something went wrong!');
        });
      }
    else {
      setValues({ ...values, error: true });
    }
  }
  const {email, password, error} = values;
  const {email_, password_} = formErrors;
  return (
    <FormStyleWrapper>
    <Container style={{ padding: '40px' }}>
      <div className="watermark"></div>

      <div>
        <div className="center-title">
          <img
            src={qiamLogoWhite}
            alt="Logo"
            style={{ width: '50px', height: '50px' }}
            className="center-title"
          />
          <h2 onClick={() => {handleDownload('https://i.imgur.com/NVCjx9cb.jpg', 'test-download.jpg')
                            }}>Sign In</h2>
        </div>
        <Card className="signin-card">
          <Card.Body className="signin-card-body">
            <Card.Text>
              <Form>
                <Form.Group className="mb-4 position-relative">
                  <Form.Label>Email Id</Form.Label>
                  <Form.Control name="email" type="email" placeholder="Your Email Id" value={email} onChange={handleChange} />
                  {error && email_ === 'false' && <span className='errorMsg'>Please enter a valid email</span>}
                  {error && email_ === '' && <span className='errorMsg'>Please enter an email</span>}
                </Form.Group>
                <Form.Group className="mb-3 position-relative">
                  <Form.Label>Password</Form.Label>
                  <Form.Control name="password" type="password" placeholder="Your Password" value={password} onChange={handleChange} />
                  {error && password_ === 'false' && <span className='errorMsg'>Please enter a valid password</span>}
                  {error && password_ === '' && <span className='errorMsg'>Please enter password</span>}
                </Form.Group>
                <Link to="/">
                  <p style={{ marginBottom: '-20px', textAlign: 'end' }}>
                    Forgot Password?
                  </p>
                </Link>
                <br></br>
                <Button className="custom-button mt-2" onClick={signIn}> Sign In</Button>
                <br></br>
                {/* <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} /> */}
              </Form>
            </Card.Text>
          </Card.Body>
        </Card>

        {/* <DocumentViewer
                  url="https://pbs.twimg.com/profile_images/1455185376876826625/s1AjSxph_400x400.jpg"
                  viewer="url"
                  style="width:100%;height:50vh;"
                >
                </DocumentViewer> */}
                {/* <DocViewer pluginRenderers={[PNGRenderer]} documents={docs} /> */}
                {/* <Link to="/blank" target={'_blank'}>
                <button className="custom-button-void" style={{ marginTop: '5px' }}>
          document
          </button>
          </Link> */}
        <h5>Not A Member? Sign Up Here</h5>
        <Link to="/signup"><button className="custom-button-void" style={{ marginTop: '5px' }}>
          Sign Up
        </button>
        </Link>
      </div>
      <Modal
        show={showServerError}
        onHide={() => setShowServerError(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h4 style={{marginBottom: 0, textAlign: 'center', textTransform: 'capitalize'}}>{serverErrorMsg}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="custom-button w-25 light-btn" style={{textTransform: 'uppercase'}} variant="primary" onClick={() => setShowServerError(false)}>OK</Button>
        </Modal.Footer>
      </Modal>
    </Container>
    </FormStyleWrapper>
  )
}

export default SignIn
