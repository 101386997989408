import { PROFILE_COMPLETED, PROFILE_ROLE, EXTERNAL_ID, BUSINESS_ROLE, UID, APPLICATION_ID, APPLICATION_NAME, SELECTED_SUKUK_INFO, EMAIL_ID, LOGGED_IN, BUSINESS_ROLES, SPV_NAME, RESET, DOCUMENT, DOCUMENTNAME, DOCUMENTTYPE, PROFILE_ROLE_DESCRIPTION, AIRTABLEURL } from "../actions/types";


const INTIAL_STATE ={
    loggedIn : false,
    profileCompleted: false,
    profileRole: 'Customer',
    profileRoleDescription : 'Business',
    // externalId: 'db30419f-2148-4693-a170-2e72e0cbe75e'
    externalId: '',
    applicationName: '',
    applicationId: '',
    businessRole: '',
    businessRoles: [],
    selectedSukukInfo : {},
    uid: '',
    emailId : '',
    spvName:'',
    document: null,
    documentName: '',
    documentType: '',
    airTableUrl: ''
}

export default (state = INTIAL_STATE, action) => {
    switch(action.type){
        case PROFILE_COMPLETED:
            return { ...state, profileCompleted: action.profileCompleted };
        case PROFILE_ROLE:
            return { ...state, profileRole: action.profileRole };
        case PROFILE_ROLE_DESCRIPTION:
            return { ...state, profileRoleDescription: action.profileRoleDescription };
        case EXTERNAL_ID:
            return { ...state, externalId: action.externalId };
        case BUSINESS_ROLE:
            return { ...state, businessRole: action.businessRole };
        case BUSINESS_ROLES:
            return { ...state, businessRoles: action.businessRoles };
        case UID:
            return { ...state, uid: action.uid };
        case APPLICATION_NAME:
            return { ...state, applicationName: action.applicationName };
        case APPLICATION_ID:
            return { ...state, applicationId: action.applicationId };
        case SELECTED_SUKUK_INFO:
            return { ...state, selectedSukukInfo: action.selectedSukukInfo };
        case EMAIL_ID:
            return { ...state, emailId: action.emailId };
        case LOGGED_IN:
            return { ...state, loggedIn: action.loggedIn };
        case SPV_NAME:
            return { ...state, spvName: action.spvName };
        case RESET:
            return INTIAL_STATE;
        case DOCUMENT:
            return { ...state, document: action.document };
        case DOCUMENTNAME:
            return { ...state, documentName: action.documentName };
        case DOCUMENTTYPE:
            return { ...state, documentType: action.documentType };
        case AIRTABLEURL:
            return { ...state, airTableUrl: action.airTableUrl };
        default:
            return state
    }
};


