import React, { useEffect, useState } from 'react';
import {Tabs, Tab, Modal} from 'react-bootstrap';
import Documents from '../../components/sukukTabComponents/documents';
import Info from '../../components/sukukTabComponents/Info';
import apiManager from '../../helpers/apiManager';
import Loading from '../../helpers/Loader/loader';
import { useSelector } from 'react-redux';
import OtherInfo from './otherInfo';

import FormStyleWrapper from '../../components/NewSukuk/form.style';
import { useLocation, useNavigate } from 'react-router';


const SukukInfo = () => {
  const location = useLocation();
  const urlRole = window.location.pathname;
  const uid = useSelector(state => state.app.uid);
    // const [formFields, setFormFields] = useState([]);
    // const [requiredArtefacts, setRequiredArtefacts] = useState([]);
    const [tab, setTab] = useState('SukukInfo');
    const [loading, setLoading] = useState(true);
    const [formFields, setFormFields] = useState([]);
    const [requiredArtefacts, setRequiredArtefacts] = useState([]);
    const [isApplicationDocuments, setApplicationDocuments] = useState({});
    const [isApplicationInfo, setApplicationInfo] = useState({});
    const [serverError, setServerError] = useState(false);
    const [partners, setPartners] = useState([]);
    const navigate = useNavigate();
    useEffect(()=>{
        getSukukData();
    },[])
    const getSukukData = () =>{
        const data = {
          ApplicationId: location.state.applicationId, 
            InstrumentId: 101
          };
          const data_ = {"BusinessRoles": [location.state.accountRole]}
          apiManager
            .getFormList(data)
            .then((response) => {
              const tempSukukData = response.data.find(instrument => instrument.InstrumentId === '101').FormFields;
              const required_Artefacts = response.data.find(instrument => instrument.InstrumentId === '101').RequiredArtefacts;
              setFormFields(tempSukukData);
              setRequiredArtefacts(required_Artefacts);
              setLoading(false);
              // localStorage.setItem('categories', JSON.stringify(response.data.data));
            })
            .catch((error) => {
              setServerError(true);
            });
            apiManager
            .getApplicationData(data)
            .then((response) => {
              const applicationInfo = response.data.length > 0 && response.data.find(instrument => instrument.InstrumentId === 101).ApplicationInfo;
              const applicationDocuments = response.data.length > 0 && response.data.find(instrument => instrument.InstrumentId === 101).ApplicationDocuments;
              applicationInfo['ApplicationName'] = response.data.length > 0 && response.data.find(instrument => instrument.InstrumentId === 101).ApplicationName;
              setApplicationInfo(applicationInfo);
              setApplicationDocuments(applicationDocuments === null ? [] : applicationDocuments);
              setLoading(false);
              // localStorage.setItem('categories', JSON.stringify(response.data.data));
            })
            .catch((error) => {
              setServerError(true);
              setLoading(false);
            });
            apiManager
            .getProfileData(data_, uid)
            .then((response) => {
              const tempPartners = response.data.length > 0 && response.data.map((comp, idx) =>{
                return(
                  {
                    value: comp.CompanyInfo.Name,
                    externalId: comp.CompanyInfo.ExternalId
                  }
                )
              })
              // const tempPartners = response.data.find(instrument => instrument.InstrumentId === '101').FormFields;
              setPartners(tempPartners);
              setLoading(false);
              // localStorage.setItem('categories', JSON.stringify(response.data.data));
            })
            .catch((error) => {
              setServerError(true);
            });
    }
        return(
          <FormStyleWrapper>
            <div className="forms_">
                <div className="header_ text-start">
                    <h4>New Sukuk Request</h4>
                </div>
                <div className="back_"><span onClick={() => navigate(-1)}>{'< Back'}</span></div>
                {loading && <Loading />}
                <Tabs activeKey={tab} id="uncontrolled-tab-example" className="mb-3" onSelect={setTab}>
                    <Tab eventKey="SukukInfo" title="Sukuk Info">
                        <Info isApplicationInfo={isApplicationInfo} setTab={setTab} />
                    </Tab>
                    <Tab eventKey="Documents" title="Documents">
                        <Documents required_Artefacts={requiredArtefacts} isApplicationDocuments={isApplicationDocuments} setTab={setTab} />
                    </Tab>
                    {!urlRole.includes('structuringRequests') && <Tab eventKey="other" title="Submit">
                        <OtherInfo isApplicationInfo={isApplicationInfo} partners={partners} />
                    </Tab>}
                </Tabs>
                <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="error-handling-modal"
                show={serverError}
                onHide={() => setServerError(false)}>
                <Modal.Body>
            <div className="center-title text-center">
              <br></br>
              <h4> {'Something went wrong, Please refresh!!'}</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
            </div>
            </FormStyleWrapper>
    )
}

export default SukukInfo;