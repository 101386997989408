import React, { useEffect, useState, useRef, useCallback } from 'react'
// import '../ProfileForm/ProfileForm.css'
import { Button, Card, Modal, Dropdown, Row, Col, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { BiCheckCircle, BiXCircle } from 'react-icons/bi'
import Loading from './../../helpers/Loader/loader';
import { ProgressBar } from 'react-bootstrap'
import CustomCard from './../simpleCard';
import { useSelector } from 'react-redux';
import apiManager from '../../helpers/apiManager';
import FormStyleWrapper from './form.style';
import axios from 'axios';
import { config } from './../../helpers/constants';


const url = config.url.API_URL;

const Documents = (props) => {
  let interval;
  const {goNext, setTabForce} = props;
  const externalId = useSelector((state) => state.app.externalId);
  const applicationName = useSelector((state) => state.app.applicationName);
  const applicationId = useSelector((state) => state.app.applicationId);
  const uid = useSelector(state => state.app.uid);
  const [loading, setLoading] = useState(false);
  const requiredArtefacts = props.required_Artefacts;
  const [warningPopup, setWarningPopup] = useState(false);
  const [warning, setWarning] = useState(false);
  // const tempFiles = props.required_Artefacts.map(doc => (
  //   { id: doc.RequiredArtefactId, 
  //     label: doc.RequiredArtefactName, 
  //     isMandatory: doc.isMandatory, 
  //     Type: doc.Type,
  //     progress: 0,
  //     uploading: false
  //   }
  //   ));
  const [files, setFiles] = useState([]);
  const [initialFiles, setInitialFiles] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [progress, setProgress] = useState(0);
  // const [fileName, setFileName] = useState();
  const [types, setTypes] = useState([]);
  const fileSelectHandler = (event, id) => {
    let temp_files = [...files];
    let temp_file = { ...temp_files.find(file_ => file_.id === id) };
    const index = temp_files.findIndex(file_ => file_.id === id);
    temp_file.fileName = event.target.files[0].name;
    temp_file.file = event.target.files[0];
    temp_file.uploaded = false;
    temp_file.uploading = false;
    temp_files[index] = temp_file;
    setFiles(temp_files);
    setWarning(true);
  }
  useEffect (() =>{
    if(props.required_Artefacts && Object.keys(props.required_Artefacts).length !== 0){
      const tempTypes = Array.from(new Set(requiredArtefacts.map((item) => item.Type)));
      setTypes(tempTypes);
    }
  },[props.required_Artefacts])
  useEffect(() => {
    if(props.isApplicationDocuments && props.required_Artefacts){
    if (Object.keys(props.isApplicationDocuments).length !== 0 || Object.keys(props.required_Artefacts).length !== 0) {
      const tempFiles = props.required_Artefacts.map(doc => (
        {
          id: doc.RequiredArtefactId,
          label: doc.RequiredArtefactName,
          isMandatory: doc.isMandatory,
          Type: doc.Type,
          progress: 0,
          uploading: false,
          fileName: ''
        }
      ));
      setFiles(tempFiles);
      setInitialFiles(tempFiles);
      setDocuments();
    }
    }
  }, [props.isApplicationDocuments, props.required_Artefacts])

  // useEffect(() =>{
  //   let tempFiles_ = [];
  //   if(Object.keys(props.required_Artefacts).length !== 0 ){
  //     tempFiles_ = props.required_Artefacts.map(doc => (
  //       { id: doc.RequiredArtefactId, 
  //         label: doc.RequiredArtefactName, 
  //         isMandatory: doc.isMandatory, 
  //         Type: doc.Type,
  //         progress: 0,
  //         uploading: false
  //       }
  //       ));
  //       setInitialFiles(tempFiles_);
  //       setFiles(tempFiles_);
  //   }

  // },[props.required_Artefacts])
  useEffect(() => {
    if (initialFiles.length > 0)
      setDocuments();
  }, [initialFiles])
  const setDocuments = () => {
    if (props.isApplicationDocuments.length > 0) {
      let temp_files = [...initialFiles];
      var result = temp_files.map(o1 => {
        const tempFileName = props.isApplicationDocuments.find((o2) => (o1.id === o2.RequiredArtefactId)) ? props.isApplicationDocuments.find((o2) => (o1.id === o2.RequiredArtefactId)).FileName ? props.isApplicationDocuments.find((o2) => (o1.id === o2.RequiredArtefactId)).FileName : '' : '';
        return (
          { ...o1, 'fileName': tempFileName, 'uploaded': tempFileName !== '' ? true : false, 'error': false, 'errorMsg': 'error' }
        )
      }
      );
      setFiles(result);
    }
  }
  const handleFileUpload = async (id) => {
    let tempFiles = [...files];
    const index = tempFiles.findIndex(file_ => file_.id === id);
    let tempFile = { ...tempFiles.find(file_ => file_.id === id) };
    tempFile.uploading = true;
    tempFiles[index] = tempFile;
    let file = tempFiles[index].file;
    setFiles(tempFiles);
    const formData = new FormData()
    // to do 
    formData.append('files', file)
    formData.append('ExternalId', externalId)
    formData.append('ToUpdate', 'FALSE')
    formData.append('RequiredArtefactId', id);
    formData.append('InstrumentId', '101');
    if(applicationId !== ''){
      formData.append('ApplicationId', applicationId);
    }
    else{
      formData.append("ApplicationName", applicationName);
    }
    await axios
      .post(`${url}/Application/Documents/Upload/`, formData, {
        headers: {
          'Content-Type':
            'multipart/form-data; boundary=<calculated when request is sent>',
          'x-ccx-api-key':
            'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbqEAVxItCetPaj541Od+TgY=',
          'x-ccx-api-usr' : uid,
          // 'origin': 'https://qiam-31988.web.app/'
          },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          //   let temp_files = [...files];
          // let temp_file = { ...temp_files.find(file_ => file_.id === id) };
          // const index = temp_files.findIndex(file_ => file_.id === id);
          // temp_file.progress = Math.round((100 * data.loaded) / data.total);
          // temp_files[index] = temp_file;
          const progress = Math.round((100 * data.loaded) / data.total);
          setProgress(progress);
        },
      })

      .then((response) => {
        console.log(response)
        return response.data
      })
      .then(async (response) => {
        setWarning(false);
        await updateResponse(response);
        // const id = response.data[0].RequiredArtefactId
        // console.log(id,"idd")

      })
      .catch((error) => {
        console.log(error);
        // clearInterval(interval)
        let temp_files = [...files];
        const idx = temp_files.findIndex(file_ => file_.id === id);
        let temp_file = { ...temp_files.find(file_ => file_.id === id) };
        temp_file.uploaded = false;
        temp_file.uploading = false;
        temp_file.error = true;
        temp_file.errorMsg = "error";
        temp_files[idx] = temp_file;
        setFiles(temp_files);
      })
  }
  const updateResponse = (res) => {
    let temp_files = [...files];
    const idx = temp_files.findIndex(file_ => file_.id === res.data[0].RequiredArtefactId);
    // setProgress(100);
    let temp_file = { ...temp_files.find(file_ => file_.id === res.data[0].RequiredArtefactId) };
    temp_file.uploaded = true;
    temp_file.uploading = false;
    temp_file.error = false;
    temp_file.errorMsg = "";
    temp_files[idx] = temp_file;
    setFiles(temp_files);
  }
  return (
    <FormStyleWrapper>
    <div className="layout layout-horizontal">
      <div className="info-card">
        <Card className="document border-0">
          <Card.Body className='pt-2'>
            {/* <Card.Title>Documents</Card.Title> */}
            <>
              {requiredArtefacts && loading && <Loading />}
              <Card.Text>
                {types.length > 0 && types.map((type) => {
                  return (
                    <div>
                      <p className="sukuk-document-title">{type + ' Documents'}</p>
                      {files.filter((doc) => doc.Type === type).sort((a, b) => a.id - b.id).map((doc) => (
                        <Row className='align-items-center mb-3' key={doc.id}>
                          <Col sm={4} className="text-end">
                            <label>{doc.label}{doc.isMandatory && <span>*</span>}:</label>
                          </Col>
                          <Col sm={8}>
                            <div className='d-flex align-items-center'>
                              <div className="d-flex inputWrapper position-relative">
                                <input
                                  className="form-control sukuk-document-input"
                                  type="file"
                                  // id={doc.RequiredArtefactId}
                                  // ref={inputFile}
                                  display="none"
                                  style={{ width: '110px', minWidth: '110px' }}
                                  onChange={(e) => fileSelectHandler(e, doc.id)}
                                />
                                <input
                                  className="form-control sukuk-document-input-text"
                                  type="text"
                                  // id={doc.id}
                                  // ref={inputFileName}
                                  value={doc.fileName}
                                // placeholder={fileName1101 ? fileName1101 : ''}
                                />
                                {!doc.uploaded && doc.uploading && <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className='uploadingLoader'
                                  variant="primary"
                                />}
                                {doc.uploaded && (
                                  <BiCheckCircle className="virus-icon virus-check-icon" />
                                )}
                                {!doc.uploaded && !doc.uploading && doc.error && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip-2">{doc.errorMsg}</Tooltip>}
                                  >
                                    <BiXCircle className="virus-icon virus-error-icon" />
                                  </OverlayTrigger>
                                )}
                              </div>
                              <div className='d-flex align-items-center'>
                                {doc.fileName === '' ? <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip id="button-tooltip-2">Please choose the file</Tooltip>}
                                >
                                  <button
                                    className="upload-button mx-2"
                                  // ref={inputFile}
                                  disabled={true}
                                  >
                                    Upload
                                  </button>
                                </OverlayTrigger>
                                  :

                                  <button
                                    className="upload-button mx-2"
                                    // ref={inputFile}
                                    onClick={() =>
                                      handleFileUpload(doc.id)
                                    }
                                  >
                                    Upload
                                  </button>
                                }
                                <AiOutlineInfoCircle className="info-icon-ai" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  )
                }
                )}

                <Row as={Row} className="mb-3">
                  <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                    <button className="custom-button" onClick={(e) => goNext(e, 'other')} >Next</button>
                  </Col>
                </Row>
              </Card.Text>
            </>
          </Card.Body>
        </Card>
      </div>
      <CustomCard title="" info={""} />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="error-handling-modal"
        show={warningPopup}
        onHide={() => setWarningPopup(false)}
      >
        {/* Sucesss Modal  */}

        <Modal.Body>
          <div className="text-center">
            <br></br>
            <p>Are you sure you want to move to next section. Your data will be lost!!</p>
            <br></br>
            {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
            <Row>
              <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                {/* <Link to="/dashboard/new-sukuk/info"> */}
                <button className="custom-button light-btn"
                  onClick={() => setWarningPopup(false)}
                >
                  Cancel
                </button>
                {/* </Link> */}
                {/* <Link to="/dashboard/new-sukuk/documents"> */}
                <button className="custom-button light-btn" onClick={() => setTabForce('Submit')}>ok</button>
                {/* </Link> */}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
    </FormStyleWrapper>
  )
}

export default Documents
