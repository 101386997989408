export const dev = {
    url: {
      API_URL: process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT: "https://gw-closeconnexions.com/ft/api/v1/ccxfintech/d3f12ddf-b156-4aad-bd0f-43437e062e7b",
    },
  };
  
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  console.log("========================")
  console.log(baseUrl);
  console.log("========================")
  
  export const config = dev;
  
  // export const QIAM_HEADERS = {
  //   Accept: 'application/json',
  //   'Content-Type': 'application/json',
  //   'x-ccx-api-key':
  //     'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbp3jbxBmszA7mTmgBOprpj0=',

  //   }

export const QIAM_HEADERS_AXIOS =  {
  headers: {
    'Content-Type': 'application/json',
    'x-ccx-api-key':
      'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbqEAVxItCetPaj541Od+TgY=',
      // 'Access-Control-Allow-Origin': 'https://qiam-31988.web.app/',
      // 'access-control-allow-origin': 'https://qiam-31988.web.app/'
      // crossorigin:'https://qiam-31988.web.app/'
      // 'Access-Control-Allow-Origin':  'https://qiam-31988.web.app/',
      // 'Access-Control-Allow-Methods': 'POST',
      // 'Access-Control-Allow-Headers': 'Content-Type, Authorization'
      // origin: 'https://qiam-31988.web.app/'
  },
  formHeaders: {
    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
    'x-ccx-api-key':
    //old key --->
    // 'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbp3jbxBmszA7mTmgBOprpj0=',
      'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbqEAVxItCetPaj541Od+TgY='
    
    
    // 'Access-Control-Allow-Origin': 'https://qiam-31988.web.app/',
    // 'access-control-allow-origin': 'https://qiam-31988.web.app/'
    // origin: 'https://qiam-31988.web.app/'
  }
}


export const apiEndPoints = {
    REQUIRED_DOCUMENTS: '/Application/GetRequiredFormElements/',
    ISO_COUNTRY_CODES: '/Lookup/ISOCountryCodes/',
    ISO_CURRENCY_CODES: '/Lookup/ISOCurrencyCode/',
    CREATE_PROFILE: '/BusinessEntity/Create/',
    UPDATE_PROFILE: '/BusinessEntity/Update/',
    INDUSTRY_LOOKUP: '/Lookup/BusinessArea/',
    GET_PROFILE_DATA: '/BusinessEntity/Search/',
    SAVE_INFO_APPLICATION: '/Application/Submission/Save/',
    GET_APPLICATION_DATA: '/Application/Get/',
    SEARCH_APPLICATION: '/Application/Search/',
    UPLOAD_FILES: '/Application/Documents/Upload/',
    SUBMIT_APPLICATION_REQUEST: '/Application/Submission/Submit/',
    SIGNUP: '/User/SignUp/',
    SIGNIN: '/User/SignIn/',
    UPDATEUSER: '/User/Update/',
    SUBMITTED_APPLICATION: '/Admin/Applications/Get/Submitted/',
    DUEDILIGENCEAPPROVEDREQUESTS : '/Admin/Applications/Get/DuediligenceApproved/',
    STRUCTURINGAPPROVEDREQUESTS : '/Admin/Applications/Get/StructuringApproved/',
    ASSOCIATEDUEDILIGENCEPARTNER: '/Application/Duediligence/Partner/Associate/',
    SEARCHDUEDILIGENCEREQUESTS: '/Application/Duediligence/Search/',
    APPROVEFROMDD: '/Application/DueDiligence/Approve/',
    ASSOCIATESTRUCTURINGPARTNER: '/Application/Structuring/Partner/Associate/',
    SEARCHSTRUCTURINGREQUESTS: '/Application/Structuring/Search/',
    APPROVEFROMSTRUCTURING: '/Application/Structuring/Approve/',
    INITIATESPV: '/Application/SPV/Invite/',
    INITIATESPVLIST: '/Prospectus/Search/NoSpv/',
    LISTOFREADYSPVS: '/Prospectus/SPV/Search/ReadyToBeListed/',
    SETSUKUKTOLISTED: '/Prospectus/Set/Listed/',
    GETSUKUKLISTED: '/Prospectus/Search/All/',
    GETSPVSUBSCRIPTIONLIST: '/Prospectus/Status/Get/',
    APPROVESUBSCRIPTION: '/Prospectus/SPV/Subscription/Approved/',
    REJECTSUBSCRIPTION: '/Prospectus/SPV/Subscription/Rejected/',
    RECEIVESUBSCRIPTION: '/Prospectus/SPV/Subscription/Received/',
    FILEDOWNLOAD: '/Application/Documents/Download/',
}


export const customData = {
    ISO_COUNTRY_CODES: [], //we need to get this from another api call as supported currency
    // PROFILE_ROLES: {business: 'Business', dd: 'Due Diligence', structuring: 'Structuring'},
    ISO_CURRENCY_CODES: ["INR", "USD", "GBP", "AED", "MYR"] ,
    PROFILE_ROLES_OPTIONS : ['Business', 'SPV', 'Investor', 'Partner', 'QAdmin'],
    DEFAULT_CURRENCY: 'USD',
    PROFILEUPDATED: true,
    BUSINESS_ROLES : {'Business': 'Customer',
    'Customer': 'Customer',
     'SPV': 'SPV',
     'Investor':'Investor', 
     'StructuringPartner':'Structuring',
     'DueDiligencePartner' : 'DueDiligence',
      'QAdmin': 'QAdmin',
      'Financial Compliance' : 'Financial Compliance'},
  BUSINESS_ROLES_FOR_API : {'Business': 'Customer',
    'Customer': 'Customer',
     'SPV': 'SPV',
     'Investor':'Investor', 
     'Structuring':'StructuringPartner',
     'DueDiligence' : 'DueDiligencePartner',
     'StructuringPartner':'StructuringPartner',
     'DueDiligencePartner' : 'DueDiligencePartner',
      'QAdmin': 'QAdmin',
      'Financial Compliance' : 'Financial Compliance'},
    BUSINESSROLE_BASED_URL:{
      'Customer': 'BUSINESS',
      'SPV': 'SPV',
      'Investor':'Investor',
      'StructuringPartner':'STRUCTURING',
      'DueDiligencePartner' : 'DueDiligencePartner',
       'QAdmin': 'QADMIN',
       'Financial Compliance' : 'FINANCIALCOMPLIANCE'
    },
    BUSINESS_ROLES_FOR_DESCRIPTION :{
      'Customer': 'Business',
      'SPV': 'SPV',
      'Investor':'Investor',
      'StructuringPartner':'Structuring Partner',
      'DueDiligencePartner' : 'Due Diligence Partner',
       'QAdmin': 'Admin',
       'Financial Compliance' : 'Financial Compiance'
    }
}

export const APPCOLORS = {
  text: 'black',
  containerBg: 'white',
  sidebarBg: 'linear-gradient( to bottom, #6767F0, #6F318A)',
  bgLinear: 'linear-gradient( to right, #6767F0, #6F318A)',
  bgColor: '#6767F0',
};