import React, { useState, useEffect} from 'react'
// import './requests.css'
import SukukCard from '../../components/SukukCard/sukukCard';
import { Link } from 'react-router-dom';
import apiManager from '../../helpers/apiManager';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setApplicationId } from '../../actions';
import Loading from '../../helpers/Loader/loader';

const DDRequest = () => {
   const externalId = useSelector(state => state.app.externalId);
   const uid = useSelector(state => state.app.uid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [ddRequestApplicationsList, setDDRequestApplicationsList] = useState([]);

  useEffect(() =>{
    const data = {"DuediligenceExternalId" : externalId};
    apiManager
            .searchddRequests(data, uid)
            .then((response) => {
              const ddRequestApplications = response.data.length > 0 ? response.data.filter(application => application.ApplicationStatus === 11) : [];
              setDDRequestApplicationsList(ddRequestApplications);
              setLoader(false);
              // localStorage.setItem('categories', JSON.stringify(response.data.data));
            })
            .catch((error) => {
              setServerError(true);
              setLoader(false);
            });
  },[]);
const details ={
  SubmittedOn: '05/Mar/2022',
  ApplicationName: 'Ennovate LLC',
  Account: 'Pied Piper Private Limited',
  size: '$1,000,000,000',
  place: 'Dubai, UAE',
  tenure: '40 months',
  CompanyInfo : {Country: 'Dubai, UAE'},
}
const gotoInfo = (id) =>{
  navigate("info", {state:{ applicationId: id }});
  dispatch(setApplicationId(id));
}
return(

<>
  {loader && <Loading />}
  <div className="container_">
    <div className="header_ text-start">
      <h4>Due Diligence Requests</h4>
    </div>
    {ddRequestApplicationsList.length > 0 && ddRequestApplicationsList.map(app =>{
      const info = {
        SubmittedOn: app.AssignedDate, // since submittedOn is not present in the response
        ApplicationName: app.ApplicationName, 
        Account: app.Account, 
        CompanyInfo: {Country : ''}, // since Company is not present in the response
        IssuanceSize: app.ApplicationInfo.SukukIssuanceSize, 
        TenureInMonths: app.ApplicationInfo.SukukTenureInMonths,
        Currency: app.ApplicationInfo.SukukIssuanceCurrency ? app.ApplicationInfo.SukukIssuanceCurrency : '',
      }
        return(
          <div onClick={() => gotoInfo(app.ApplicationId)} >
            <SukukCard info= {info}/>
          </div>
        )
    })}
    {ddRequestApplicationsList.length === 0 && <div className="noDataText">There are no Requests</div>}
  </div>
</>
      )}

      export default DDRequest;