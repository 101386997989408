import React, { useEffect, useState } from 'react'
import {Card} from 'react-bootstrap'
import { useSelector } from 'react-redux';
import apiManager from '../../helpers/apiManager';
import SukukCardStyleWrapper from './sukukCard.style';
import menuOption from '../../helpers/images/assets/VerticalDotsMenu.svg';
import {Dropdown} from 'react-bootstrap';
import utility from '../../helpers/utility';

const SPVSukukCard = (props) => {
  const uid = useSelector(state => state.app.uid);
  // const [companyInfo, setCompanyInfo] = useState({
  //   sukukSubmittedDate : '',
  //   ApplicationName: '',
  //   Account: '',
  //   Country: '',
  //   IssuanceSize: '',
  //   TenureInMonths: ''
  // })
  useEffect(() =>{
    if(props){
      // const {id, name, size, tenure, Account, SubmittedOn, } = props;
      // setCompanyInfo({...companyInfo,
      //   ApplicationName: name,
      //   IssuanceSize: size,
      //   TenureInMonths: tenure
      // })
    // const data = {
    //   "CompanyInfo": {
    //     "ExternalId":id 
    //   }
    // }
    // apiManager.getProfileData(data,uid)
    // .then((response) =>{
    //   const profileData = response.data[0].CompanyInfo; 
    //   let tempSukukSubmittedDate = new Date(profileData.ServiceEnrollmentDate);
    //   tempSukukSubmittedDate = tempSukukSubmittedDate.getDate() + '/'+ months[tempSukukSubmittedDate.getMonth()] + '/'+ tempSukukSubmittedDate.getFullYear();
    //   setCompanyInfo({...companyInfo,
    //     sukukSubmittedDate : tempSukukSubmittedDate,
    //     Account: profileData.Name,
    //     Country: profileData.Address.Country,
    //   })
     
    // })
    // .catch((error) => {

    // })
  }
  },[props])
  const {SubmittedOn, ApplicationName, Account, CompanyInfo, IssuanceSize, TenureInMonths, status} = props.info;
  const sukukSubmittedDate = utility.getFormattedDate(SubmittedOn);
return(
  <SukukCardStyleWrapper>
      <Card className="request-card spvCard">
        <Card.Body>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div className="layout layout-vertical layout-item-start-align mb=2 px-2" style={{width: 'calc(100% - 40px)'}}>
          <div className="layout layout-horizontal layout-justify-space-between style-card w-100 mb-2" style={{alignItems: 'baseline'}}>
            <div className='sukuk-name'>{ApplicationName}</div>
            </div>
            <div className="layout layout-horizontal style-card w-100 mb-2" style={{alignItems: 'baseline'}}>
            <div className='mb-2 pr-2'>{sukukSubmittedDate}</div>
              <div className='company-name'>{Account}</div>
              <div style={{marginLeft: 20}}>{CompanyInfo.Country ? CompanyInfo.Country : ''}</div>
            </div>
            <div className="layout layout-horizontal">
            <div className='pr-3'>Sukuk Size: {IssuanceSize ? utility.getNumberUnit(IssuanceSize).number + utility.getNumberUnit(IssuanceSize).unit : ''}</div>
            <div className='px-5'>Sukuk Tenure: {TenureInMonths ? TenureInMonths + ' months' : ''}</div>
            </div>
            
          </div>
          <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexFlow : 'column'}}>
          <div className="options_">
              <Dropdown align="end">
                <Dropdown.Toggle variant="success" id="">
                <img src={menuOption} className="header-icon" alt="MenuOption" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item className={status === 'Active' ? '' : 'disable'} onClick={props.updateFunction}>List / Float Sukuk</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="statusTitle">
                <label>Status:</label>
                <p>{status}</p>
            </div>
            </div>
            </div>
        </Card.Body>
      </Card>
      </SukukCardStyleWrapper>
      )}

      export default SPVSukukCard;