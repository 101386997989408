
import styled from 'styled-components';
import { APPCOLORS } from '../../../helpers/constants';
import WithDirection from '../../../helpers/rtl';

const CreateProspectusStyleWrapper = styled.div`
    .formWrapper{
        .title_{
            label{
                font-size: 18px;
            }
            span{
                font-size: 18px;
                font-weight: 600;
            }
        }
        .header_bar{
            background-color: #03ac13;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .uploadForm{
            label{
                font-size: 18px;
                span{
                    color: red;
                }
            }
            .sukuk-document-input{
                height: 40px;
                width: 110px;
                margin-left: -5px;
                background-color: #e9ecef;
            
            }
            .sukuk-document-input-text{
                height: 40px;
                width: calc(100% - 110px);
                margin-left: 0px;
                margin-right: 5px;
                padding-right: 28px;
            }
            .virus-check-icon{
                color: green;
            }
            .virus-error-icon{
                color: red;
            }
            .uploadingLoader, .virus-icon{
                position: absolute;
                right: 12px;
                top: 14px;
            }
            .virus-icon{
                font-size: 20px;
                right: 10px;
                top: 13px;
            }

            button:disabled{
                cursor: not-allowed;
            }
            .upload-button{
                border: 2px solid #ffffff;
                border-radius: 5px;
                background: ${APPCOLORS.bgColor}; /* fallback for old browsers */
                background: -webkit-${APPCOLORS.sidebarBg}; /* Chrome 10-25, Safari 5.1-6 */
                background: ${APPCOLORS.sidebarBg};
                border: none;
                color: white;
                padding: 0px 50px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 14px;
                position: relative;
                /* margin-left:  -20px;
                margin-right: -15px; */
                height: 45px;
                /* margin-bottom: 36px; */

                /* left: 20px; */
            }
        }
    }
      `;

export default WithDirection(CreateProspectusStyleWrapper);