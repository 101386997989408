import axios from 'axios';
import { config, apiEndPoints, QIAM_HEADERS_AXIOS } from './constants';


const url = config.url.API_URL;
const nodeSubscripionUrl = "https://026b-2401-4900-2778-8855-85a-e2f1-bb03-8e1c.in.ngrok.io/nodeapp-a591d/us-central1/webApp/";
const {
    REQUIRED_DOCUMENTS,
    ISO_COUNTRY_CODES,
    ISO_CURRENCY_CODES,
    CREATE_PROFILE,
    UPDATE_PROFILE,
    INDUSTRY_LOOKUP,
    GET_PROFILE_DATA,
    SAVE_INFO_APPLICATION,
    GET_APPLICATION_DATA,
    SEARCH_APPLICATION,
    UPLOAD_FILES,
    SUBMIT_APPLICATION_REQUEST,
    SIGNUP,
    SIGNIN,
    UPDATEUSER,
    SUBMITTED_APPLICATION,
    DUEDILIGENCEAPPROVEDREQUESTS,
    STRUCTURINGAPPROVEDREQUESTS,
    ASSOCIATEDUEDILIGENCEPARTNER,
    SEARCHDUEDILIGENCEREQUESTS,
    APPROVEFROMDD,
    ASSOCIATESTRUCTURINGPARTNER,
    SEARCHSTRUCTURINGREQUESTS,
    APPROVEFROMSTRUCTURING,
    INITIATESPV,
    INITIATESPVLIST,
    LISTOFREADYSPVS,
    SETSUKUKTOLISTED,
    GETSUKUKLISTED,
    GETSPVSUBSCRIPTIONLIST,
    APPROVESUBSCRIPTION,
    REJECTSUBSCRIPTION,
    RECEIVESUBSCRIPTION,
    FILEDOWNLOAD
} = apiEndPoints

let blockChainUrl = nodeSubscripionUrl;
let smartContractAccessType =  "local";

const apiManager = {
    getISOCountryCodes: (data) => {
        const source = url + ISO_COUNTRY_CODES;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: QIAM_HEADERS_AXIOS.headers,
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getISOCurrencyCodes: (data) => {
        const source = url + ISO_CURRENCY_CODES;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: QIAM_HEADERS_AXIOS.headers,
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getIndustryList: (data) => {
        const source = url + INDUSTRY_LOOKUP;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: QIAM_HEADERS_AXIOS.headers,
                })
                .then(function (response) {
                    resolve(response.data);
                    console.log(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    console.log(error);
                    reject({ res });
                });
        });
    },
    createProfileForm: (data, apiUsr) => {
        const source = url + CREATE_PROFILE;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    // updateProfile: (data, apiUsr) => {
    //     const source = url + UPDATE_PROFILE;
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .post(source, data, {
    //                 headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
    //             })
    //             .then(function (response) {
    //                 resolve(response.data);
    //             })
    //             .catch(function (error) {
    //                 let res = error.response;
    //                 reject({ res });
    //             });
    //     });
    // },
    updateProfile: (data, apiUsr) => {
        const source = url + UPDATE_PROFILE;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getFormList: (data) => {
        const source = url + REQUIRED_DOCUMENTS;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: QIAM_HEADERS_AXIOS.headers,
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getApplicationData: (data) => {
        const source = url + GET_APPLICATION_DATA;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: QIAM_HEADERS_AXIOS.headers,
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    searchApplication: (data, apiUsr) => {
        const source = url + SEARCH_APPLICATION;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getProfileData: (data, apiUsr) => {
        const source = url + GET_PROFILE_DATA;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers:{...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    updateInfoApplication: (data) => {
        const source = url + SAVE_INFO_APPLICATION;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: QIAM_HEADERS_AXIOS.headers,
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    updateFiles: (data) => {
        const source = url + UPLOAD_FILES;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data,  {
                    headers:  QIAM_HEADERS_AXIOS.formHeaders
                    })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    submitApplicationRequest: (data, apiUsr) => {
        const source = url + SUBMIT_APPLICATION_REQUEST;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    signUp: (data) =>{
        const source = url + SIGNUP;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: QIAM_HEADERS_AXIOS.headers,
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    signIn: (data) =>{
        const source = url + SIGNIN;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: QIAM_HEADERS_AXIOS.headers,
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    updateUser: (data) =>{
        const source = url + UPDATEUSER;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: QIAM_HEADERS_AXIOS.headers,
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getSubmittedApplications: (data) =>{
        const source = url + SUBMITTED_APPLICATION;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: QIAM_HEADERS_AXIOS.headers,
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getDDApprovedApplications: (data, apiUsr) =>{
        const source = url + DUEDILIGENCEAPPROVEDREQUESTS;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getStructuringApprovedApplications: (data) =>{
        const source = url + STRUCTURINGAPPROVEDREQUESTS;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: QIAM_HEADERS_AXIOS.headers,
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    associateDueDiligencePartner: (data, apiUsr) =>{
        const source = url + ASSOCIATEDUEDILIGENCEPARTNER;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    searchddRequests : (data, apiUsr) =>{
        const source = url + SEARCHDUEDILIGENCEREQUESTS;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    ddApprove : (data, apiUsr) =>{
        const source = url + APPROVEFROMDD;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    strucutingApprove : (data, apiUsr) =>{
        const source = url + APPROVEFROMSTRUCTURING;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    
    associateStructuringPartner: (data, apiUsr) =>{
        const source = url + ASSOCIATESTRUCTURINGPARTNER;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    
    searchStructuringRequests: (data, apiUsr) =>{
        const source = url + SEARCHSTRUCTURINGREQUESTS;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    initiateSPV: (data, apiUsr) => {
        const source = url + INITIATESPV;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    initiateSpvList:(data, apiUsr) => {
        const source = url + INITIATESPVLIST;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getSukukListforSPVs:(data, apiUsr) => {
        const source = url + LISTOFREADYSPVS;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    setSukukToListed:(data, apiUsr) => {
        const source = url + SETSUKUKTOLISTED;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getSukukListed:(data, apiUsr) => {
        const source = url + GETSUKUKLISTED;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getSPVSubscriptionList: (data, apiUsr) =>{
        const source = url + GETSPVSUBSCRIPTIONLIST;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    
    approveSubscription: (data, apiUsr) =>{
        const source = url + APPROVESUBSCRIPTION;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.formHeaders, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    
    rejectSubscription: (data, apiUsr) =>{
        const source = url + REJECTSUBSCRIPTION;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    receivedSub: (data, apiUsr) =>{
        const source = url + RECEIVESUBSCRIPTION;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.headers, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    fileDownload: (data, apiUsr) =>{
        const source = url + FILEDOWNLOAD;
        return new Promise((resolve, reject) => {
            axios
                .post(source, data, {
                    headers: {...QIAM_HEADERS_AXIOS.formHeaders, 'x-ccx-api-usr' : apiUsr},
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getBlockChainUrl:() =>{
        const source = 'https://api.airtable.com/v0/appFicRZOMra8OxT3/Table%201?api_key=keyHejWLttM1m2Ljd';
        return new Promise((resolve, reject) => {
            axios
                .get(source, {
                    headers:{
                        'Content-Type': 'application/json'
                    }
                })
                .then(function (response) {
                    const tempResponse = response.data.records[0];
                    smartContractAccessType = tempResponse.fields['smart-contract-access-type'];
                    resolve(response.data.records[0]);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    setSubscriptionDataToBlockChain:(data, blockUrl) => {
        // let source = nodeSubscripionUrl;
        // let smartContractAccessType =  "local";
        // apiManager.getBlockChainUrl()
        // .then(response => {
        //     smartContractAccessType = response.fields['smart-contract-access-type'];
        //     if(response.fields.useAirtable === 'yes'){
        //         source = response.fields.airtableUrl;
        //     }
        //     else{
        //         source = response.fields.firebaseURL;
        //     }
        const source = blockUrl + 'subscription';
            return new Promise((resolve, reject) => {
                axios
                    .post(source, data, {
                        headers: {...QIAM_HEADERS_AXIOS.headers, 'smart-contract-access-type': smartContractAccessType },
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        let res = error.response;
                        reject({ res });
                    });
            });
        // })
        // const source = "https://us-central1-nodeapp-a591d.cloudfunctions.net/webApp/webhook";
        
    },
    getSukukId:(data, blockUrl) => {
        // let source = nodeSubscripionUrl;
        // let smartContractAccessType =  "local";
        // apiManager.getBlockChainUrl()
        // .then(response => {
        //     smartContractAccessType = response.fields['smart-contract-access-type'];
        //     if(response.fields.useAirtable === 'yes'){
        //         source = response.fields.airtableUrl;
        //     }
        //     else{
        //         source = response.fields.firebaseURL;
        //     }
        const source = blockUrl + 'newSukukIdToUse';
            return new Promise((resolve, reject) => {
                axios
                    .post(source, data, {
                        headers: {...QIAM_HEADERS_AXIOS.headers, 'smart-contract-access-type': smartContractAccessType },
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        let res = error.response;
                        reject({ res });
                    });
            });
        // })
        // const source = "https://us-central1-nodeapp-a591d.cloudfunctions.net/webApp/webhook";
        
    },
    
    callNodeToBlockChain:(data, url) => {
        let source = url + 'saveArtefacts';
        // let smartContractAccessType =  "local";
        // apiManager.getBlockChainUrl()
        // .then(response => {
        //     smartContractAccessType = response.fields['smart-contract-access-type'];
        //     if(response.fields.useAirtable === 'yes'){
        //         source = response.fields.airtableUrl;
        //     }
        //     else{
        //         source = response.fields.firebaseURL;
        //     }
            return new Promise((resolve, reject) => {
                axios
                    .post(source, data, {
                        headers: {...QIAM_HEADERS_AXIOS.headers,  'smart-contract-access-type': smartContractAccessType },
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        let res = error.response;
                        reject({ res });
                    });
        })
    },
    intitiateSukuk:(data, blockUrl) => {
        const source = blockUrl + 'initiateNewSukuk';
            return new Promise((resolve, reject) => {
                axios
                    .post(source, data, {
                        headers: {...QIAM_HEADERS_AXIOS.headers, 'smart-contract-access-type': smartContractAccessType },
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        let res = error.response;
                        reject({ res });
                    });
            });
        // })
        // const source = "https://us-central1-nodeapp-a591d.cloudfunctions.net/webApp/webhook";
        
    },
    getInitializedSukuks:(data) => {
        const source =  'https://api.airtable.com/v0/appcxMJgjcdZzYlWn/Table%201?api_key=keyHejWLttM1m2Ljd';
            return new Promise((resolve, reject) => {
                axios
                    .get(source, data, {
                        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer keyHejWLttM1m2Ljd' },
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        let res = error.response;
                        reject({ res });
                    });
            });
        // })
        // const source = "https://us-central1-nodeapp-a591d.cloudfunctions.net/webApp/webhook";
        
    },
    InstantiateNewContract:(data, blockUrl) => {
        const source = blockUrl + 'instantiateNewContracts';
            return new Promise((resolve, reject) => {
                axios
                    .post(source, data, {
                        headers: {...QIAM_HEADERS_AXIOS.headers, 'smart-contract-access-type': smartContractAccessType },
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        let res = error.response;
                        reject({ res });
                    });
            });
        // })
        // const source = "https://us-central1-nodeapp-a591d.cloudfunctions.net/webApp/webhook";
        
    },
    initializedSukuk:(data) => {
        const source =  'https://api.airtable.com/v0/appcxMJgjcdZzYlWn/Table%201';
            return new Promise((resolve, reject) => {
                axios
                    .post(source, data, {
                        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer keyHejWLttM1m2Ljd' },
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        let res = error.response;
                        reject({ res });
                    });
            });
        // })
        // const source = "https://us-central1-nodeapp-a591d.cloudfunctions.net/webApp/webhook";
        
    },
    airtablePatch: (data) =>{
        const source = 'https://api.airtable.com/v0/appcxMJgjcdZzYlWn/Table%201';
        return new Promise((resolve, reject) => {
            axios
                .patch(source, data, {
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer keyHejWLttM1m2Ljd' },
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getRecords:() =>{
        const source = 'https://api.airtable.com/v0/appKHlC9hB5CqGMkg/Table%201?api_key=keyHejWLttM1m2Ljd';
        return new Promise((resolve, reject) => {
            axios
                .get(source, {}, {
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer keyHejWLttM1m2Ljd' },
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    getCompanyRecords:() =>{
        const source = 'https://api.airtable.com/v0/appaNSoHBuym4Vd2x/Table%201?api_key=keyHejWLttM1m2Ljd';
        return new Promise((resolve, reject) => {
            axios
                .get(source, {}, {
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer keyHejWLttM1m2Ljd' },
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    let res = error.response;
                    reject({ res });
                });
        });
    },
    addBusinessData:(data, blockUrl) => {
        const source = blockUrl + 'addBusinessData';
            return new Promise((resolve, reject) => {
                axios
                    .post(source, data, {
                        headers: {...QIAM_HEADERS_AXIOS.headers, 'smart-contract-access-type': smartContractAccessType },
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        let res = error.response;
                        reject({ res });
                    });
            });
        // })
        // const source = "https://us-central1-nodeapp-a591d.cloudfunctions.net/webApp/webhook";
        
    },
};
export default apiManager;