import React, { useState, useEffect } from 'react'
// import './requests.css'
import SukukCard from '../../components/SukukCard/spvSukukCard';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import{ Row, Col, Button, Form, Tooltip,OverlayTrigger, Spinner} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { setApplicationId } from '../../actions';
import apiManager from '../../helpers/apiManager';
import Loading from '../../helpers/Loader/loader';
import FundsHistoryTable from '../../components/fundsHistory/fundsHistoryTable';
import FundDisbursementStyleWrapper from './fundDisbursement.style';
import DocumentIcon from '../../helpers/images/assets/documentDark.svg';
import FileDownloadDark from '../../helpers/images/assets/File_Download_Icon.svg';
import {PRIVATE_ROUTE} from '../../helpers/routerConstants'
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';
import FormStyleWrapper from './../../components/NewSukuk/form.style';

import axios from 'axios';
import { config } from './../../helpers/constants';


const url = config.url.API_URL;

const FundDisbursementForm = () => {
  const externalId = useSelector(state => state.app.externalId);
  const applicationId = useSelector(state => state.app.applicationId);
  const uid = useSelector(state => state.app.uid);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [action, setAction] = useState('action');
  const[fileInfo, setFileInfo]= useState({
    fileName: '',
    uploading: false,
  })
  const [uploaded, setUploaded] = useState(false);
  const [file, setFile] = useState();

  useEffect(() => {
  }, []);

  const clearForm = () =>{
    setFileInfo({
      fileName: '',
      uploading: false,
    })
    setUploaded(false);
    setFile();
  }
  const fileSelectHandler = (event, id) => {
    const {files} = event.target;
    setFileInfo({fileName: files[0].name,  uploading: false});
    setUploaded(false);
    setFile(files[0]);
  }
  const handleFileUpload = async (e, id) => {
    e.preventDefault();
    setFileInfo({...fileInfo, uploading: true});
    const formData = new FormData()
    // to do 
    formData.append('files', file)
    formData.append('ExternalId', externalId);
    formData.append('ToUpdate', 'FALSE')
    formData.append('RequiredArtefactId', id);
    formData.append('InstrumentId', '101');
      formData.append('ApplicationId', applicationId);
    await axios
      .post(`${url}/Application/Documents/Upload/`, formData, {
        headers: {
          'Content-Type':
            'multipart/form-data; boundary=<calculated when request is sent>',
          'x-ccx-api-key':
            'w8vwkSDdz425aW0QwgsP2taN77OcEUmUjxFC7Zwogpn0xfxVC+yrC0jnlBxRC8AYdB9VVt3PsCjQYLGe8l8eiKxWsYTxUDbmDrzkx6Lv35NZJIOU0cA2Ad9/FUbo17tkGh53y0jm0KkA5bo+9FaVbqEAVxItCetPaj541Od+TgY=',
          'x-ccx-api-usr' : uid
          },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          //   let temp_files = [...files];
          // let temp_file = { ...temp_files.find(file_ => file_.id === id) };
          // const index = temp_files.findIndex(file_ => file_.id === id);
          // temp_file.progress = Math.round((100 * data.loaded) / data.total);
          // temp_files[index] = temp_file;
          // const progress = Math.round((100 * data.loaded) / data.total);
          // setProgress(progress);
        },
      })

      .then((response) => {
        console.log(response)
        return response.data
      })
      .then(async (response) => {
        await updateResponse(response);
        // const id = response.data[0].RequiredArtefactId
        // console.log(id,"idd")

      })
      .catch((error) => {
        console.log(error);
        // clearInterval(interval)
        setUploaded(false);
        setFileInfo({...fileInfo, uploading: false});
        setError(true);
      })
  }
  const updateResponse = (res) => {
    // setProgress(100);
    setUploaded(true);
    setFileInfo({...fileInfo, uploading: false});
    setError(false);
  }
  const submitAction = (tempAction) =>{
    clearForm();
    setShowAction(true);
    setAction(tempAction);
  }
  const {uploading, fileName} = fileInfo;
  return (

    <FundDisbursementStyleWrapper>
      {loading && <Loading />}
      <div className="container_">
        <div className="header_ text-start">
          <h4>Fund Disbursement</h4>
        </div>
        <div className="back_"><span onClick={() => navigate(-1)}>{'< Back'}</span></div>
        <div className='form_ mt-5'>
            {/* <FundsHistoryTable parent="SPV"/> */}
            <Row className="mb-4">
              <Col>
                <Row>
                <Col className='text-end'>
                <label>
                Requested Date:
                </label>
                </Col>
                <Col>
                  <p className='text-start'>20-Apr-2022</p>
                </Col>
                </Row>
              </Col>
              <Col>
              <Row>
                <Col className='text-end'>
                <label>
                Funds Required By Date:
                </label>
                </Col>
                <Col>
                  <p className='text-start'>20-Apr-2022</p>
                </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Row>
                <Col className='text-end'>
                <label>
                Amount:
                </label>
                </Col>
                <Col>
                  <p className='text-start'>USD 100,000</p>
                </Col>
                </Row>
              </Col>
              <Col>
              <Row>
                <Col className='text-end'>
                <label>
                Funds Utilisation Reason:
                </label>
                </Col>
                <Col>
                  <p className='text-start'>Purchasing Retail Assets</p>
                </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Row>
                <Col className='text-end'>
                <label>
                Supporting Document: 
                </label>
                </Col>
                <Col>
                  <div className='text-start'>
                    <span className='mx-2 icon_'><img src={DocumentIcon}/></span>
                    <span className='mx-2 icon_'><img src={FileDownloadDark}/></span>
                  </div>
                </Col>
                </Row>
              </Col>
              <Col>
              <Row>
                <Col className='text-end'>
                <label>
                Other Remarks:
                </label>
                </Col>
                <Col className='text-start'>
                    <textarea className='w-100' rows={2} defaultValue={''} disabled={false}/>
                </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col className="text-end mt-4"><Button className="custom-button" onClick={() => submitAction('accept')}>Accept</Button></Col>
              <Col className="text-start mt-4"><Button className="custom-button" onClick={() => submitAction('reject')}>Reject</Button></Col>
            </Row>
            { showAction &&
              <div>
                <FormStyleWrapper>
                <div className='sukuk-document-title text-center px-3 my-3'>Funds Disbursement Action</div>
                { action === 'accept' ? 
                    <Form>
                      <Form.Group style={{maxWidth: '770px'}} as={Row} className="align-items-center mt-4">
                          <Form.Label as={Col} sm={4} className="text-end">Disbursement Proof:</Form.Label>
                          <Col sm={8}><div className='d-flex align-items-center justify-content-between'>
                              <div className="d-flex inputWrapper position-relative" style={{width: 'calc(100% - 95px)'}}>
                                <input
                                  className="form-control sukuk-document-input"
                                  type="file"
                                  // id={doc.RequiredArtefactId}
                                  // ref={inputFile}
                                  display="none"
                                  style={{ marginLeft: '0px', minWidth: 111 }}
                                  onChange={(e) => fileSelectHandler(e, 4101)}
                                />
                                <input
                                  className="form-control sukuk-document-input-text"
                                  type="text"
                                  // id={doc.id}
                                  // ref={inputFileName}
                                  value={fileName}
                                // placeholder={fileName1101 ? fileName1101 : ''}
                                />
                                {!uploaded && uploading && <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className='uploadingLoader'
                                  variant="primary"
                                />}
                                {uploaded && (
                                  <BiCheckCircle className="virus-icon virus-check-icon" />
                                )}
                                {!uploaded && !uploading && error && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip-2">Something went wrong, please try again</Tooltip>}
                                  >
                                    <BiXCircle className="virus-icon virus-error-icon" />
                                  </OverlayTrigger>
                                )}
                              </div>
                              <div className='d-flex align-items-center'>
                                {fileName === '' ? <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip id="button-tooltip-2">Please choose the file</Tooltip>}
                                >
                                  <button
                                    className="upload-button mx-2"
                                    disabled={fileName === ''}
                                  // ref={inputFile}
                                  >
                                    Upload
                                  </button>
                                </OverlayTrigger>
                                  :

                                  <button
                                    className="upload-button mx-2"
                                    // ref={inputFile}
                                    onClick={(e) =>
                                      handleFileUpload(e, 4101)
                                    }
                                  >
                                    Upload
                                  </button>
                                }
                              </div>
                            </div>
                            </Col>
                            
                      </Form.Group>
                      <Form.Group style={{maxWidth: '770px'}} as={Row} className="align-items-center mt-4">
                          <Form.Label as={Col} sm={4} className="text-end">Remarks:</Form.Label>
                          <Col sm={8}>
                          <Form.Control as={'textarea'} rows={1}>

                          </Form.Control>
                          </Col>
                          </Form.Group>
                          <Button className="custom-button mt-4">Submit</Button>
                    </Form>

                                : <Form>
                                <Form.Group style={{maxWidth: '770px'}} as={Row} className="align-items-center mt-4">
                                    <Form.Label as={Col} sm={4} className="text-end">Disbursement Proof:</Form.Label>
                                    <Col sm={8}><div className='d-flex align-items-center justify-content-between'>
                                        <div className="d-flex inputWrapper position-relative"  style={{width: 'calc(100% - 95px)'}}>
                                          <input
                                            className="form-control sukuk-document-input"
                                            type="file"
                                            // id={doc.RequiredArtefactId}
                                            // ref={inputFile}
                                            display="none"
                                            style={{ marginLeft: '0px', minWidth: 111 }}
                                            onChange={(e) => fileSelectHandler(e, 4101)}
                                          />
                                          <input
                                            className="form-control sukuk-document-input-text"
                                            type="text"
                                            // id={doc.id}
                                            // ref={inputFileName}
                                            value={fileName}
                                          // placeholder={fileName1101 ? fileName1101 : ''}
                                          />
                                          {!uploaded && uploading && <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className='uploadingLoader'
                                            variant="primary"
                                          />}
                                          {uploaded && (
                                            <BiCheckCircle className="virus-icon virus-check-icon" />
                                          )}
                                          {!uploaded && !uploading && error && (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id="button-tooltip-2">Something went wrong, please try again</Tooltip>}
                                            >
                                              <BiXCircle className="virus-icon virus-error-icon" />
                                            </OverlayTrigger>
                                          )}
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {fileName === '' ? <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2">Please choose the file</Tooltip>}
                                          >
                                            <button
                                              className="upload-button mx-2"
                                              disabled={fileName === ''}
                                            // ref={inputFile}
                                            >
                                              Upload
                                            </button>
                                          </OverlayTrigger>
                                            :
          
                                            <button
                                              className="upload-button mx-2"
                                              // ref={inputFile}
                                              onClick={(e) =>
                                                handleFileUpload(e, 4101)
                                              }
                                            >
                                              Upload
                                            </button>
                                          }
                                        </div>
                                      </div>
                                      </Col>
                                      
                                </Form.Group>
                                <Form.Group style={{maxWidth: '770px'}} as={Row} className="align-items-center mt-4">
                                    <Form.Label as={Col} sm={4} className="text-end">Rejection Reason:</Form.Label>
                                    <Col sm={8}>
                                    <Form.Control as={'textarea'} rows={1}>
          
                                    </Form.Control>
                                    </Col>
                                    </Form.Group>
                                    <Button className="custom-button mt-4">Submit</Button>
                              </Form>
                }
                </FormStyleWrapper>
            </div> }
        </div>
      </div>
    </FundDisbursementStyleWrapper>
  )
}

export default FundDisbursementForm;