import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form, Modal } from 'react-bootstrap'
import ProfileFormStyleWrapper from './profileForm.style';
import { FcCheckmark } from 'react-icons/fc';
import apiManager from '../../helpers/apiManager'
// import { profile } from '../../../API/profile'
import { customData } from '../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileCompleted, setExternalId, setApplicationName } from '../../actions';
import Loading from '../../helpers/Loader/loader';
import utility from './../../helpers/utility';
// import Wallet from 'ethereumjs-wallet';


const industryOptions = [];
const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach((val) => {
    if (val.length === 0 || val !== 'blank') {
      valid = false;
    }
  });
  return valid;
};
const ProfileForm = () => {
  const dispatch = useDispatch();
  const profileCompleted = useSelector(state => state.app.profileCompleted);
  const externalId = useSelector(state => state.app.externalId);
  const emailId = useSelector(state => state.app.emailId);
  const profileRole = useSelector(state => state.app.profileRole);
  const businessRole = useSelector(state => state.app.businessRole);
  const spvName = useSelector(state => state.app.spvName);
  const uid = useSelector(state => state.app.uid);
  const showAsMandatory = customData.BUSINESS_ROLES[profileRole] === customData.BUSINESS_ROLES['Customer'];
  const [profileData, setProfileData] = useState({
    companyName: '',
    brandName: '',
    businessEmail: '',
    businessMobileCountryCode: '91',
    businessMobile: '',
    yearOfEstablishment: '',
    industry: '',
    door: '',
    street: '',
    registeredAddress2: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    companyHeadquartersCity: '',
    companyHeadquartersCountry: '',
    website: '',
    companyDUNS: '',
    currencyOfBusiness: customData.DEFAULT_CURRENCY,
    companyRegistrationNumber: '',
    tradeLicenseNumber: '',
    disableField: false,
    disableDUNSField: false,
    mobileCountryCode: '91',
    mobile: '',
    firstName: '',
    lastName: '',
    wallet: null
  })
  const [formError, setFormError] = useState({
    companyName: '',
    businessEmail: '',
    businessMobile: '',
    door: '',
    street: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    companyHeadquartersCity: showAsMandatory ? '' : 'blank',
    companyRegistrationNumber: '',
    tradeLicenseNumber: '',
    // mobile: '',
    // firstName:'',
    // lastName: ''
  })

  const [countryCodes, setCountryCodes] = useState([]);
  const [currencyCodes, setCurrencyCodes] = useState([]);
  const [industries, setIndustries] = useState(false);
  const [isInfoModalOn, setIsInfoModalOn] = useState(false);
  const [isProfileCompleteModalOn, setProfileCompleteModalOn] = useState(false);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [duplicateError, showDuplicateError] = useState(false);
  useEffect(() => {
    if (profileRole) {
      const localAccountDetails = localStorage.getItem('accountDetails') ? JSON.parse(localStorage.getItem('accountDetails')) : null;
      const data = {
        "CompanyInfo": {
          "ExternalId": externalId
        }
      }

      clearForm();
      if (profileCompleted) {
        setLoader(true);
        // const storedProfileData = JSON.parse(localStorage.getItem(customData.BUSINESS_ROLES[profileRole]+'-profileData'));
        // if(storedProfileData){
        //   setFormFieldsWithData(storedProfileData);
        //   setLoader(false);
        // }
        // else{
        apiManager.getProfileData(data, uid)
          .then((response) => {
            // localStorage.setItem(customData.BUSINESS_ROLES[profileRole]+'-profileData', response.data.length > 0 ? JSON.stringify(response.data[0]) : null );
            const tempProfileData = response.data.length > 0 && response.data;
            setFormFieldsWithData(tempProfileData[0]);
            dispatch(setApplicationName(tempProfileData[0].CompanyInfo.Name));
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
          });
        // }
      }
      else {
        setIsInfoModalOn(true);
        setTimeout(() => {
          setIsInfoModalOn(false);
        }, 2000);
      }
      callLookupsData();

      if (businessRole === customData.BUSINESS_ROLES['SPV']) {
        setProfileData({
          ...profileData,
          companyName: spvName,
        });
        setFormError({
          ...formError,
          companyName: spvName.length > 0 ? 'blank' : '',
          mobile: profileCompleted ? 'blank' : '',
          firstName: profileCompleted ? 'blank' : '',
          lastName: profileCompleted ? 'blank' : '',
        });
      }
    }
  }, [profileRole]);
  const callLookupsData = () => {

    const data = {
      "PhoneCountryCode": [],
      "ISOCountryCode": customData.ISO_COUNTRY_CODES,
      "Country": [],
      "ShortISOCountryCode": [],
      "FetchStrategy": {}
    }
    const storedCountryCodes = JSON.parse(localStorage.getItem('isoCountryCodes'));
    if (storedCountryCodes) {
      setCountryCodes(storedCountryCodes);
    }
    else {
      apiManager.getISOCountryCodes(data)
        .then((response) => {
          setCountryCodes(response.data);
          localStorage.setItem('isoCountryCodes', JSON.stringify(response.data));
        })
        .catch((error) => { });
    }
    const currencyCodesdata = {
      "Currency": [],
      "CurrencyCode": customData.ISO_CURRENCY_CODES,
    }
    const storedCurrencyCodes = JSON.parse(localStorage.getItem('isoCurrencyCodes'));
    if (storedCurrencyCodes) {
      setCurrencyCodes(storedCurrencyCodes);
    }
    else {
      apiManager.getISOCurrencyCodes(currencyCodesdata)
        .then((response) => {
          setCurrencyCodes(response.data);
          localStorage.setItem('isoCurrencyCodes', JSON.stringify(response.data));
        })
        .catch((error) => { });
    }
    const industryData = {
      "BusinessAreaId": [],
      "Code": [],
      "Name": [],
      "Sector": [11]
    };
    const storedIndustryList = JSON.parse(localStorage.getItem('industryList'));
    if (storedIndustryList) {
      setIndustries(storedIndustryList);
    }
    else {
      apiManager.getIndustryList(industryData)
        .then((response) => {
          setIndustries(response.data);
          localStorage.setItem('industryList', JSON.stringify(response.data));
          // setLoading(false);
          // localStorage.setItem('categories', JSON.stringify(response.data.data));
        })
        .catch((error) => { });
    }
  }
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if(name === 'companyRegistrationNumber'){
      showDuplicateError(false);
    }
    setProfileData({ ...profileData, [name]: value })
    setFormError({ ...formError, [name]: value.length > 0 ? 'blank' : '' });
    setError(false);
  }
  const checkForDuplicate = (e) =>{
e.preventDefault();
const data = {
  GovernmentId :{
    UId : profileData.companyRegistrationNumber
  }
}
apiManager.getProfileData(data, uid)
          .then((response) => {
            // localStorage.setItem(customData.BUSINESS_ROLES[profileRole]+'-profileData', response.data.length > 0 ? JSON.stringify(response.data[0]) : null );
            if(response.data && response.data.length > 0){
              showDuplicateError(true);
            }
          })
          .catch((error) => {
            setLoader(false);
          });
  }
  const clearForm = () => {
    setProfileData({
      ...profileData,
      companyName: '',
      brandName: '',
      businessEmail: '',
      businessMobileCountryCode: '91',
      businessMobile: '',
      yearOfEstablishment: '',
      industry: '',
      door: '',
      street: '',
      registeredAddress2: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
      companyHeadquartersCity: '',
      companyHeadquartersCountry: '',
      website: '',
      companyDUNS: '',
      currencyOfBusiness: customData.DEFAULT_CURRENCY,
      companyRegistrationNumber: '',
      tradeLicenseNumber:  '',
      disableField: false,
      disableDUNSField: false
    })
  }
  const setFormFieldsWithData = (data) => {
    const { CompanyInfo, GovernmentId, } = data;
    const tempGovernmentDetails = GovernmentId.length > 0 && GovernmentId.filter(govArray => govArray.UIdType !== "TMPGID")[0];
    let tempPhone = [];
    let tempCurrency = '';
    let tempMobileNumber = '';
    if (CompanyInfo.BusinessPhone) {
      tempPhone = CompanyInfo.BusinessPhone.split('-');
      [tempCurrency, ...tempMobileNumber] = CompanyInfo.BusinessPhone.split("-");
    }
    setProfileData({
      ...profileData,
      companyName: CompanyInfo.Name ? CompanyInfo.Name : '',
      brandName: CompanyInfo.DoingBusinessAs ? CompanyInfo.DoingBusinessAs : '',
      businessEmail: CompanyInfo.BusinessEmail && CompanyInfo.BusinessEmail ? CompanyInfo.BusinessEmail : '',
      businessMobileCountryCode: tempPhone.length > 0 ? tempCurrency.replace('+', '') : '',
      businessMobile: tempPhone.length > 0 ? tempMobileNumber.join("-") : '',
      yearOfEstablishment: CompanyInfo.EstablishmentYear ? CompanyInfo.EstablishmentYear : '',
      industry: '',
      door: CompanyInfo.Address?.DoorNumber,
      street: CompanyInfo.Address?.StreetName,
      registeredAddress2: CompanyInfo.Address?.Locatity,
      city: CompanyInfo.Address?.City,
      state: CompanyInfo.Address?.State,
      country: CompanyInfo.Address?.Country,
      pincode: CompanyInfo.Address?.PostalCode,
      companyHeadquartersCity: CompanyInfo.HQLocation?.City,
      companyHeadquartersCountry: CompanyInfo.HQLocation?.Country,
      website: CompanyInfo.WebSiteAddress,
      companyDUNS: CompanyInfo.DunsId,
      currencyOfBusiness: CompanyInfo.BusinessCurrency,
      companyRegistrationNumber: profileCompleted ? tempGovernmentDetails.UId : ' ',
      tradeLicenseNumber: profileCompleted ? tempGovernmentDetails.TradeLicenseNumber ? tempGovernmentDetails.TradeLicenseNumber : '' : '',
      disableField: true,
      disableDUNSField: CompanyInfo.DunsId?.length > 0 ? true : false,
      UIdIssuingAuthority: profileCompleted && tempGovernmentDetails.UIdIssuingAuthority ? tempGovernmentDetails.UIdIssuingAuthority : ' ',
      UIdCity: profileCompleted && tempGovernmentDetails.UIdCity ? tempGovernmentDetails.UIdCity : ' ',
      UIdType: profileCompleted && tempGovernmentDetails.UIdType ? tempGovernmentDetails.UIdType : ' ',
      UIdValidUntil: profileCompleted && tempGovernmentDetails.UIdValidUntil ? tempGovernmentDetails.UIdValidUntil : ' ',
      UIdEnrollmentDate: profileCompleted && tempGovernmentDetails.UIdEnrollmentDate ? tempGovernmentDetails.UIdEnrollmentDate : ' ',
      wallet: profileCompleted && CompanyInfo.wallet ? {publicKey: CompanyInfo.wallet.publicKey , privateKey: CompanyInfo.wallet.privateKey}: null
    })
    setFormError({
      ...formError,
      companyName: CompanyInfo.Name.length > 0 ? 'blank' : '',
      businessEmail: CompanyInfo.BusinessEmail && CompanyInfo.BusinessEmail.length > 0 ? 'blank' : '',
      businessMobileCountryCode: tempPhone.length > 0 ? 'blank' : '',
      businessMobile: tempPhone.length > 0 ? 'blank' : '',
      door: CompanyInfo.Address?.DoorNumber?.length > 0 ? 'blank' : '',
      street: CompanyInfo.Address?.StreetName?.length > 0 ? 'blank' : '',
      city: CompanyInfo.Address?.City?.length > 0 ? 'blank' : '',
      state: CompanyInfo.Address?.State?.length > 0 ? 'blank' : '',
      country: CompanyInfo.Address?.Country?.length > 0 ? 'blank' : '',
      pincode: CompanyInfo.Address?.PostalCode?.length > 0 ? 'blank' : '',
      companyHeadquartersCity: showAsMandatory ? CompanyInfo.HQLocation?.City?.length > 0 ? 'blank' : '' : 'blank',
      companyRegistrationNumber: (profileCompleted && tempGovernmentDetails.UId.length > 0) ? 'blank' : '',
      tradeLicenseNumber: (profileCompleted && tempGovernmentDetails.TradeLicenseNumber && tempGovernmentDetails.TradeLicenseNumber.length > 0) ? 'blank' : '',
    })

    dispatch(setProfileCompleted(true));
  }
  const onSubmit = (event) => {
    setLoader(true);
    event.preventDefault();
    if (formValid(formError)) {
      setProfileData({ ...profileData })
      let walletkeys = profileData.wallet ? profileData.wallet : null;
      if (!profileData.wallet){
        // const EthWallet = Wallet.default.generate();
        // console.log("DO NOT SHARE THIS: PrivateKey: " + 
        // EthWallet.getPrivateKeyString());
        // console.log("Address: " + EthWallet.getAddressString());
        walletkeys = utility.getBlockChainKeys();
      }
      const data = {
        GovernmentId: {
          UId: profileData.companyRegistrationNumber, 
          TradeLicenseNumber : profileData.tradeLicenseNumber,
          UIdIssuingAuthority: "AUTHORITY", 
          UIdCity: "CITY",
          UIdEnrollmentDate: "2022-01-01", 
          UIdType: "GOVTID", 
          UIdValidUntil: "2022-09-09"
        },
        CompanyInfo: {
          wallet: walletkeys,
          // "ExternalId": externalId,
          BusinessArea: profileData.industry,
          IsPrimaryGovernmentId: true,
          ServiceEnrollmentDate: "2022-01-01",
          Name: profileData.companyName,
          Address: {
            DoorNumber: profileData.door, StreetName: profileData.street,
            Locatity: profileData.registeredAddress2, City: profileData.city,
            State: profileData.state, Country: profileData.country, PostalCode: profileData.pincode
          },

          DoingBusinessAs: profileData.brandName,
          DunsId: profileData.companyDUNS,
          EstablishmentYear: profileData.yearOfEstablishment,
          WebSiteAddress: profileData.website,
          BusinessPhone: profileData.businessMobileCountryCode + '-' + profileData.businessMobile,
          BusinessEmail: profileData.businessEmail,
          AdditionalInfo: {
            NearestPortLocation: "New York, US", SupportedCurrency: ["INR", "SDR", "YEN", "USD", "GBP"],
            ExportId: "BE3N67D8", IsExportRestricted: true, RestrictedCountriesList: ["USA", "UK", "JAPAN"]
          },
          BusinessCurrency: profileData.currencyOfBusiness,
          HQLocation: {
            City: profileData.companyHeadquartersCity, Country: profileData.companyHeadquartersCountry
          }
        },
        BusinessRoles: [customData.BUSINESS_ROLES_FOR_API[profileRole]]
      }
      if (customData.BUSINESS_ROLES[profileRole] === customData.BUSINESS_ROLES['SPV']) {
        data.CompanyInfo.ExternalId = externalId;
        apiManager.updateProfile(data, uid)
          .then((response) => {
            const responseData = response.data[0];
            const responseAccountDetails = {
              name: responseData.Account ? responseData.Account.Name : responseData.CompanyInfo.Name,
              govUid: responseData.GovernmentId.UId
            }
            localStorage.setItem('accountDetails', JSON.stringify(responseAccountDetails));
            dispatch(setExternalId(responseData.Account ? responseData.Account.ExternalId : responseData.CompanyInfo.ExternalId));
            if(!profileCompleted){
              updateUser();
            }
            setLoader(false);
          })
          .catch((res) => {
            console.log('Error in Profile Update');
            setLoader(false);
          })
      }
      else {
        if (externalId === '') {
          apiManager.createProfileForm(data, uid)
            .then((response) => {
              const responseData = response.data[0];
              const responseAccountDetails = {
                name: responseData.Account ? responseData.Account.Name : responseData.CompanyInfo.Name,
                govUid: responseData.GovernmentId.UId
              }
              localStorage.setItem('accountDetails', JSON.stringify(responseAccountDetails));
              dispatch(setExternalId(responseData.Account ? responseData.Account.ExternalId : responseData.CompanyInfo.ExternalId));
              setProfileCompleteStatus();
            })
            .catch((res) => {
              console.log('Error in signup');
              setLoader(false);
            })
        }
        else {
          data.CompanyInfo.ExternalId = externalId;
          apiManager.updateProfile(data, uid)
            .then((response) => {
              const responseData = response.data[0];
              const responseAccountDetails = {
                name: responseData.Account ? responseData.Account.Name : responseData.CompanyInfo.Name,
                govUid: responseData.GovernmentId.UId
              }
              localStorage.setItem('accountDetails', JSON.stringify(responseAccountDetails));
              dispatch(setExternalId(responseData.Account ? responseData.Account.ExternalId : responseData.CompanyInfo.ExternalId));
              setProfileCompleteStatus();
            })
            .catch((res) => {
              console.log('Error in signup');
              setLoader(false);
            })
        }
      }
    }
    else {
      setError(true);
      setLoader(false);
    }

  }
  const setProfileCompleteStatus = () => {
    dispatch(setProfileCompleted(true));
    setLoader(false);
    setProfileCompleteModalOn(true);
    setTimeout(() => {
      setProfileCompleteModalOn(false)
    }, 2000)
  }
  const updateUser = () => {
    const data = {
      Status: "Active",
      FirstName: firstName,
      LastName: lastName,
      Personas: [
        {
          ID: emailId,
          Type: "Email"
        }
      ]
    }
    apiManager.updateUser(data)
      .then((response) => {
        setProfileCompleteStatus();
      })
      .catch((error) => {
        setError(true);
        setLoader(false);
      })
  }
  const currentdate = new Date();
  const currentYear = currentdate.getFullYear();

  const {
    companyName,
    brandName,
    businessEmail,
    businessMobileCountryCode,
    businessMobile,
    yearOfEstablishment,
    industry,
    door,
    street,
    registeredAddress2,
    city,
    state,
    country,
    pincode,
    companyHeadquartersCity,
    companyHeadquartersCountry,
    website,
    companyDUNS,
    currencyOfBusiness,
    companyRegistrationNumber,
    tradeLicenseNumber,
    disableField, disableDUNSField,
    mobileCountryCode,
    mobile,
    firstName,
    lastName
  } = profileData
  return (
    <ProfileFormStyleWrapper>
      {loader && <Loading />}
      <div className="profile-form">
        <div className="header_ text-start">
          <h4>Profile</h4>
        </div>
        <form className="profile-form-container">
          <div className="form-field flex-wrap">
            <div className="form-inline">
              <label>
                {customData.BUSINESS_ROLES[profileRole] === 'SPV' ? 'SPV' : 'Company'} Name <span>*</span>
              </label>
              <input
                type="text"
                value={companyName}
                name="companyName"
                onChange={handleChange}
                required
                className={companyName.length > 0 ? 'highlight' : ''}
                disabled={businessRole === customData.BUSINESS_ROLES['SPV']}
                style={error && formError.companyName === '' ? { borderColor: 'red' } : {}}
              />
            </div>
            {customData.BUSINESS_ROLES[profileRole] !== 'SPV' && 
            // <div className="form-inline">
            //   <label>SPV Registration Number <span>*</span></label>

            //   <input
            //     type="text"
            //     placeholder=""
            //     value={companyRegistrationNumber}
            //     name="companyRegistrationNumber"
            //     onChange={handleChange}
            //     className={companyRegistrationNumber && companyRegistrationNumber.length > 0 ? 'highlight' : ''}
            //     style={error && formError.companyRegistrationNumber === '' ? { borderColor: 'red' } : {}}
            //     disabled={disableField && companyRegistrationNumber.length !== 0}
            //   />
            // </div> :
              <div className="form-inline">
                <label>
                  Brand Name
                </label>
                <input
                  type="text"
                  value={brandName}
                  name="brandName"
                  className={brandName.length > 0 ? 'highlight' : ''}
                  onChange={handleChange}
                />
              </div>}
              <div className="form-field">
              <div className="form-inline position-relative">
                  <label>Company Registration Number <span>*</span></label>

                  <input
                    type="text"
                    placeholder=""
                    value={companyRegistrationNumber}
                    name="companyRegistrationNumber"
                    onChange={handleChange}
                    onBlur={checkForDuplicate}
                    className={companyRegistrationNumber?.length > 0 ? 'highlight' : ''}
                    style={error && formError.companyRegistrationNumber === '' ? { borderColor: 'red' } : {}}
                    disabled={disableField && companyRegistrationNumber?.length > 0}
                  />
                  {duplicateError && <span className="errorText">Register number is already present</span>}
                </div>
                <div className="form-inline">
                  <label>Trade License Number <span>*</span></label>

                  <input
                    type="text"
                    placeholder=""
                    value={tradeLicenseNumber}
                    name="tradeLicenseNumber"
                    onChange={handleChange}
                    className={tradeLicenseNumber?.length > 0 ? 'highlight' : ''}
                    style={error && formError.tradeLicenseNumber === '' ? { borderColor: 'red' } : {}}
                    // disabled={disableField && companyRegistrationNumber?.length > 0}
                  />

                </div>
              </div>
            <div className="form-field">
              <div className="form-inline">
                <label>
                  {customData.BUSINESS_ROLES[profileRole] === 'SPV' ? 'SPV' : 'Business'} Email <span>*</span>
                </label>
                <input
                  type="text"
                  value={businessEmail}
                  name="businessEmail"
                  onChange={handleChange}
                  required
                  className={businessEmail.length > 0 ? 'highlight' : ''}
                  style={error && formError.businessEmail === '' ? { borderColor: 'red' } : {}}
                />
              </div>
              <div className="form-inline">
                <label>
                  {customData.BUSINESS_ROLES[profileRole] === 'SPV' ? 'SPV' : 'Business'} Mobile <span>*</span>
                </label>
                <div className='d-flex position-relative'>
                  <span className='position-absolute' style={{ left: '8px', top: '8px', fontSize: 20 }}>+</span>
                  <Form.Select
                    value={businessMobileCountryCode}
                    name="businessMobileCountryCode"
                    onChange={handleChange}
                    className={businessMobileCountryCode ? 'highlight phoneCountryCode' : 'phoneCountryCode'}
                    style={error && formError.businessMobileCountryCode === '' ? { borderColor: 'red' } : {}}
                  >
                    {countryCodes.length > 0 && countryCodes.map((country) => (
                      <option key={country.PhoneCountryCode} value={country.PhoneCountryCode}>{country.PhoneCountryCode}</option>
                    ))}
                  </Form.Select>
                  <input
                    type="text"
                    value={businessMobile}
                    name="businessMobile"
                    onChange={handleChange}
                    required
                    className={businessMobile.length > 0 ? 'highlight' : ''}
                    style={error && formError.businessMobile === '' ? { borderColor: 'red' } : {}}
                  />
                </div>
              </div>
            </div>
            {customData.BUSINESS_ROLES[profileRole] !== 'SPV' && <div className="form-field">
              <div className="form-inline position-relative">
                <label>Year Established</label>
                <input
                  type="text"
                  placeholder=" YYYY"
                  value={yearOfEstablishment}
                  name="yearOfEstablishment"
                  onChange={handleChange}
                  className={yearOfEstablishment.length > 0 ? 'highlight' : ''}
                />
                {yearOfEstablishment.length > 0 && yearOfEstablishment > currentYear && <span className='errorText'>Please Enter the Established year</span>}
              </div>
              <div className="form-inline">
                <label>Industry</label>
                <Form.Select
                  value={industry}
                  name="industry"
                  onChange={handleChange}
                  className={industry.length > 0 ? 'highlight' : ''}
                >
                  <option value="" disabled defaultValue>
                    Select Industry
                  </option>
                  {industries.length > 0 && industries.map((industry) => (
                    <option key={industry.Code} value={industry.Code}>{industry.Name}</option>
                  ))}
                </Form.Select>
                {/* <Select options={industryOptions} /> */}
              </div>
            </div>}
            <div className="form-field">
              <div className="form-inline">
                <label>
                  Registered Address 1 <span>*</span>
                </label>
                <div className="layout layout-horizontal">
                  <input
                    type="text"
                    placeholder="Door #"
                    value={door}
                    name="door"
                    onChange={handleChange}
                    required
                    className={door?.length > 0 ? 'highlight door' : 'door'}
                    style={error && formError.door === '' ? { borderColor: 'red' } : {}}
                  />
                  <input
                    type="text"
                    placeholder="Street"
                    value={street}
                    name="street"
                    onChange={handleChange}
                    required
                    className={street?.length > 0 ? 'highlight street' : 'street'}
                    style={error && formError.street === '' ? { borderColor: 'red' } : {}}
                  />
                </div>
              </div>
              <div className="form-inline">
                <label>Registered Address 2</label>
                <input
                  type="text"
                  placeholder="Area and Locality"
                  value={registeredAddress2}
                  name="registeredAddress2"
                  onChange={handleChange}
                  className={registeredAddress2?.length > 0 ? 'highlight' : ''}
                />
              </div>
            </div>
            <div className="form-field">
              <div className="form-inline">
                <label>
                  City <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  value={city}
                  name="city"
                  onChange={handleChange}
                  required
                  className={city?.length > 0 ? 'highlight' : ''}
                  style={error && formError.city === '' ? { borderColor: 'red' } : {}}
                />
              </div>
              <div className="form-inline">
                <label>
                  {' '}
                  State <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  value={state}
                  name="state"
                  onChange={handleChange}
                  required
                  className={state?.length > 0 ? 'highlight' : ''}
                  style={error && formError.state === '' ? { borderColor: 'red' } : {}}
                />
              </div>
            </div>
            <div className="form-field">
              <div className="form-inline">
                <label>
                  {' '}
                  Country <span>*</span>
                </label>
                <Form.Select
                  value={country}
                  name="country"
                  onChange={handleChange}
                  required
                  className={country?.length > 0 ? 'highlight' : ''}
                  style={error && formError.country === '' ? { borderColor: 'red' } : {}}
                >
                  <option value="" disabled defaultValue>
                    Select Country
                  </option>
                  {countryCodes.map((country) => (
                    <option value={country.Country}>{country.Country}</option>
                  ))}
                </Form.Select>
              </div>
              <div className="form-inline">
                <label>
                  {' '}
                  Zip Code <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  value={pincode}
                  name="pincode"
                  onChange={handleChange}
                  required
                  className={pincode?.length > 0 ? 'highlight' : ''}
                  style={error && formError.pincode === '' ? { borderColor: 'red' } : {}}
                />
              </div>
            </div>

            {customData.BUSINESS_ROLES[profileRole] !== 'SPV' && <><div className="form-field">
              <div className="form-inline">
                <label>
                  {' '}
                  Company Headquarter City <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  value={companyHeadquartersCity}
                  name="companyHeadquartersCity"
                  onChange={handleChange}
                  required
                  className={companyHeadquartersCity?.length > 0 ? 'highlight' : ''}
                  style={error && formError.companyHeadquartersCity === '' ? { borderColor: 'red' } : {}}
                />
              </div>
              <div className="form-inline">
                <label>Company Headquarter Country</label>
                <Form.Select
                  name="companyHeadquartersCountry"
                  value={companyHeadquartersCountry}
                  onChange={handleChange}
                  className={companyHeadquartersCountry?.length > 0 ? 'highlight' : ''}
                >
                  <option value="" disabled defaultValue>
                    Select Country
                  </option>
                  {countryCodes.map((country) => (
                    <option value={country.Country}>{country.Country}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
              <div className="form-field">
                <div className="form-inline">
                  <label>Website </label>
                  <input
                    type="text"
                    placeholder=""
                    value={website}
                    name="website"
                    onChange={handleChange}
                    className={website && website?.length > 0 ? 'highlight' : ''}
                  />
                </div>
                <div className="form-inline">
                  <label>Company DUNS</label>
                  <input
                    type="text"
                    placeholder=""
                    value={companyDUNS}
                    name="companyDUNS"
                    onChange={handleChange}
                    className={companyDUNS?.length > 0 ? 'highlight' : ''}
                    disabled={disableDUNSField && (companyDUNS?.length > 0)}
                  />
                </div>
              </div>
              <div className="form-field">
                <div className="form-inline mx-0">
                  <label>Primary Currency of Business</label>
                  <Form.Select
                    name="currencyOfBusiness"
                    value={currencyOfBusiness}
                    onChange={handleChange}
                    className={currencyOfBusiness?.length > 0 ? 'highlight' : ''}
                  >

                    {currencyCodes?.length > 0 && currencyCodes.map((country) => (
                      <option key={country.CurrencyCode} value={country.CurrencyCode}>{country.CurrencyCode}</option>
                    ))}
                  </Form.Select>
                </div>
                
              </div>
            </>}

            {customData.BUSINESS_ROLES[profileRole] === 'SPV' && !profileCompleted &&
              <>
                <div className='sukuk-document-title text-center w-100 px-3 my-3'>User Details</div>
                <div className="form-field">
                  <div className="form-inline">
                    <label>
                      First Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      value={firstName}
                      name="firstName"
                      onChange={handleChange}
                      required
                      className={firstName?.length > 0 ? 'highlight' : ''}
                      style={error && formError.firstName === '' ? { borderColor: 'red' } : {}}
                    />
                  </div>
                  <div className="form-inline">
                    <label>
                      Last Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      value={lastName}
                      name="lastName"
                      onChange={handleChange}
                      required
                      className={lastName?.length > 0 ? 'highlight' : ''}
                      style={error && formError.lastName === '' ? { borderColor: 'red' } : {}}
                    />
                  </div>
                </div>
                <div className="form-inline mx-0">
                  <label>
                    Mobile <span>*</span>
                  </label>
                  <div className='d-flex position-relative'>
                    <span className='position-absolute' style={{ left: '8px', top: '8px', fontSize: 20 }}>+</span>
                    <Form.Select
                      value={mobileCountryCode}
                      name="mobileCountryCode"
                      onChange={handleChange}
                      className={mobileCountryCode ? 'highlight phoneCountryCode' : 'phoneCountryCode'}
                      style={error && formError.mobileCountryCode === '' ? { borderColor: 'red' } : {}}
                    >
                      {countryCodes?.length > 0 && countryCodes.map((country) => (
                        <option key={country.PhoneCountryCode} value={country.PhoneCountryCode}>{country.PhoneCountryCode}</option>
                      ))}
                    </Form.Select>
                    <input
                      type="text"
                      value={mobile}
                      name="mobile"
                      onChange={handleChange}
                      required
                      className={mobile?.length > 0 ? 'highlight' : ''}
                      style={error && formError.mobile === '' ? { borderColor: 'red' } : {}}
                    />
                  </div>
                </div>
              </>
            }
            {/* <div className="form-inline">
            <label>Primary Currency of Business</label>
            <input type="text" placeholder="Name" value={} onChange={handleChange('')}/>
          </div> */}
          </div>
          {error && <div style={{ color: 'red' }}>Please fill all the mandatory fields/text box highlighted in red color</div>}
          <Button className="custom-button mt-4" onClick={onSubmit} disabled={duplicateError}>
            Submit
          </Button>
          <br></br>
        </form>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="checkbox-error-handling-modal"
          show={isInfoModalOn}
          onHide={() => setIsInfoModalOn(false)}
        >
          <Modal.Body>
            <div className="center-title">
              <br></br>

              <p>Please complete your profile</p>

            </div>
          </Modal.Body>

        </Modal>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="checkbox-error-handling-modal"
          show={isProfileCompleteModalOn}
          onHide={() => setProfileCompleteModalOn(false)}
        >
          <Modal.Body>
            <div className="center-title">
              <br></br>

              <span className="tick"><FcCheckmark /></span><h3> Profile Saved Successfully</h3>

            </div>
          </Modal.Body>

        </Modal>
      </div>
    </ProfileFormStyleWrapper>
  )
}

export default ProfileForm
