import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const SukukCardStyleWrapper = styled.div`
.request-card{
    overflow: inherit;
    background: ${APPCOLORS.bgColor}; /* fallback for old browsers */
    background: -webkit-${APPCOLORS.bgLinear}; /* Chrome 10-25, Safari 5.1-6 */
    background: ${APPCOLORS.bgLinear};
    width: 100%;
    // max-width: 1024px;
    margin: 0 auto 40px;
    border-radius: 20px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    overflow: inherit;
    margin-left: 0px;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
    box-shadow: 5px 10px 18px #888888;
    td{
        display: inline-block;
        padding: 10px;
        inset-inline-start: 0px;
    }
    .style-card > div{
        display: block;
        text-align: left;
    }
    &.spvCard{
        .sukuk-name{
            font-size: 26px;
        }
        .company-name{
            font-size: 22px;
            margin-left: 20px;
        }
    }
}
.options_{
    z-index: 9;
    position: absolute;
    right: 25px;
    top: 20px;
    .btn-success{
        background-color: transparent !important;
        border-color: transparent !important;
        padding-right: 0;
        width: 46px;
        &:focus{
            box-shadow: none !important;
        }
    }
    // .dropdown-toggle::after{
    //     opacity: 0;
    // }
    // img{
    //     width: 40px;
    // }
    // .dropdown-menu{
    //     width: 200px;
    //     box-shadow:2px 5px 12px #000;
    // }
    // .dropdown-item.disable{
    //     background-color: #e9ecef;
    //     cursor: not-allowed;
    // }
}

.options_{
    z-index: 9;
    .btn-success{
        background-color: transparent !important;
        border-color: transparent !important;
        padding-right: 0;
        width: 46px;
        &:focus{
            box-shadow: none !important;
        }
    }
    .dropdown-toggle::after{
        opacity: 0;
    }
    img{
        width: 40px;
    }
    .dropdown-menu{
        width: 225px;
        box-shadow:2px 5px 12px #000;
    }
    .dropdown-item.disable{
        background-color: #e9ecef;
        cursor: not-allowed;
    }
}
.highlight__{
    font-size: 21px;
    text-transform: capitalize;
}
.statusTitle{
    display: flex;
    align-items: center;
    label{
        font-size: 18px;
    }
    p{
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        margin-left: 10px;
    }
}
.cardLabel{
    width: 160px;
    text-align: left;
}
.statusBlock{
    display:flex;
    flex-direction: column;
    align-items: end;
    label{
        font-weight: 600;
        font-size: 18px;
    }
    .statusCircle{
        margin-top: 10px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: grey;
    }
}
.dropdown-toggle::after{
    opacity: 0;
}
img{
    width: 40px;
}
.dropdown-menu{
    width: 225px;
    box-shadow:2px 5px 12px #000;
}
.dropdown-item.disable{
    background-color: #e9ecef;
    cursor: not-allowed;
    color: black;
}
`;

export default WithDirection(SukukCardStyleWrapper);
