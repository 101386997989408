import styled from 'styled-components';
import { APPCOLORS } from '../helpers/constants';
import WithDirection from '../helpers/rtl';

const FormStyleWrapper = styled.div`
input{
    // width: 400px;
    height: 45px;
    border-radius: 5px;
    font-size: 15px;
    opacity: 1;
    border:1px solid #949391;
}
select{
    width: 400px;
    border-radius: 5px;
    font-size: 15px;
    border: #000000 1.5px solid;    
    padding: 5px;
    font-size: 16px;
    border: 1px solid #949391;
    border-radius: 5px;
    height: 45px;
    // background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
    // background-position-x: 364px;
    background-color: white;
    -webkit-appearance: none;

    opacity: 1;
}
label{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 5px;
    span{
        color: red;
    }
}
.door{
    width: 90px;
    height: 45px;
    border-radius: 5px;
    font-size: 15px;
    margin-right: 10px;
}
.street{
    width: 300px;
    height: 45px;
    border-radius: 5px;
    font-size: 15px;
}
.profile-form-container{
    width: 100%;
    max-width: 996px;
    margin: 0 auto;
}
.profile-form{
    color: #000;
    display: block;
    /* position: absolute; */
    /* top: 100px; */
    /* left: 300px; */
    width: 100%;
    /* max-width:70%;
    min-width: 40%;
    height: 56px;
    margin-left: 300px; */
    
  }

.form-field{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* display: inline-block; */
    width: 100%;
    height: 100%;
    margin-bottom: 15px; 
    justify-content: space-between;
}
.form-inline{
    /* display: flex;
    flex-direction: column; */
    max-width: 400px;
    margin: 10px 0 10px 0px;
    /* align-items: flex-start; */
    text-align: left;
}

.profile-form-header{
    /* position: static; */
    color: ${APPCOLORS.bgColor};
    display: block;
    /* margin: 20px 900px 0px 100px; */
    h3{
        font-size: 30px;
        font-weight: 700;
    }
}
.phoneCountryCode{
    width: 110px;
    margin-right: 10px;
    text-align: left;
    padding: 0px 30px 0px 25px
}
.form-group{
    position: relative;
}
.errorMsg{
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 14px;
    color: red;
}
.light-button{
    background: #ffffff !important;
    background-color: #ffffff !important;
    color: ${APPCOLORS.bgColor} !important;
}
a{
    color: ${APPCOLORS.bgColor}
}
`;

export default WithDirection(FormStyleWrapper);