import React from 'react';
import ReactDOM from 'react-dom/client';
// import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
// import {createStore} from 'redux';
// import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import reducers from './reducers'
import { configureStore } from '@reduxjs/toolkit'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
// import App from './App';

// var ReactDOM = require('react-dom');
const persistConfig = {
   key: 'root',
   storage,
 }
 const persistedReducer = persistReducer(persistConfig, reducers)
const store = configureStore({ reducer: persistedReducer })
const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));
// ReactDOM.render(<Provider store={store}>
//    <PersistGate loading={null} persistor={persistor}>
//    <App />
//    </PersistGate>
//    </Provider>, document.getElementById('root'));
root.render(
   <Provider store={store}>
   <PersistGate loading={null} persistor={persistor}>
   <App />
   </PersistGate>
   </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
