


import React, { useEffect, useState, useRef } from 'react'
import { Card, Modal, Form, Row, Col } from 'react-bootstrap'
import Loading from '../../helpers/Loader/loader';
import apiManager from './../../helpers/apiManager';
import { AiOutlineInfoCircle } from 'react-icons/ai'
import {useSelector} from 'react-redux';
import { customData } from '../../helpers/constants';
import AdminStyleWrapper from './admin.style';


const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach((val) => {
    if (val.length === 0 || val !== 'blank') {
      valid = false;
    }
  });
  return valid;
};
const StructuringInfo = () => {
    const externalId = useSelector((state) => state.app.externalId);
    const applicationId = useSelector((state) => state.app.applicationId);
    const selectedSukukInfo = useSelector((state) => state.app.selectedSukukInfo);
    const [currencyCodes, setCurrencyCodes] = useState([]);
    const [structuringRemarks, setStructuringRemarks] = useState('');
    const [formValues, setFormValues] = useState({
        structuringPartner: 'Ennovate LLC',
        nextStep: 'Proceed to Structuring',
        adminRemarks: ''
    })
     const [loading, setLoading] = useState(false);
    const [successInfo, setSuccessInfo] = useState('');
    const [errorInfo, setErrorInfo] = useState('');
    const [successInfoShow, setSuccessInfoShow] = useState('');
    const [serverError, setServerError] = useState('');
  
    useEffect(() =>{
      const data = {
        ApplicationId: applicationId, 
          InstrumentId: 101
        };
      apiManager
            .getApplicationData(data)
            .then((response) => {
              const structuringInfo = response.data.length > 0 && response.data.find(instrument => instrument.InstrumentId === 101).StructuringInfo;
              setStructuringRemarks(structuringInfo.Remarks ? structuringInfo.Remarks : '');
              setLoading(false);
              // localStorage.setItem('categories', JSON.stringify(response.data.data));
            })
            .catch((error) => {
              setServerError(true);
              setLoading(false);
            });
    },[])
    const handleChange = (event) => {
      const {name, value} = event.target;
      setFormValues({ ...formValues, [name]: value })
    }
    // populate file data
    // console.log( sukukData.ApplicationInfo, 'country code')
    
    const submitInfo = (event) => {
      event.preventDefault();
      setLoading(true);
      const data ={
        "ExternalId": externalId,
        "InstrumentId": "101",
        };
      apiManager.updateInfoApplication(data)
      .then((res) => {
        setLoading(false);
        setSuccessInfoShow(true);
        setTimeout(()=>{
          setSuccessInfoShow(false)
        }, 2000);
      })
      .catch((err) => {
        setServerError(true);
        setLoading(false);
        setErrorInfo(err.res.data.Error.errorMessage);
        setTimeout(()=>{
          setServerError(false)
        }, 2000);
      })
    }
    const {structuringPartner, nextStep, adminRemarks} =  formValues;
    return (
        <AdminStyleWrapper>
        <div className="header_ text-start">
          <h4>Structuring</h4>
        </div>
      <div className="layout layout-horizontal">
        <div>
            <div className="text-start fieldsSpacer"><p>Structuring Partner: Ennovate LLC</p></div>
            <Card className="sukuk-info-card border-0">
              <Card.Body>
                  <Card.Text>
                          <>
                          {loading && <Loading />}
                        <div className="px-3">
                            <Row className="mb-3 fieldsSpacer">
                                <Col className="p-0">
                                    <label className='w-100 text-start form-label'>Remarks from  Structuring Partner</label>
                                    <textarea className='w-100' rows={5} defaultValue={structuringRemarks} disabled={true}/>
                                </Col>
                            </Row>
                            <p className="sukuk-document-title">{'Next Action'}</p>
                          <Form className="fieldsSpacer">
                            <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail" size="lg">
                              <Form.Label column sm={12} className="text-start m-0">
                              Next Steps
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                                <Form.Select aria-label="Default select example" onChange={handleChange} 
                                name="nextStep" style={{ height: '45px', width: '100%', borderColor: '#ced4da' }} 
                                value={nextStep}
                                className={JSON.stringify(nextStep).length > 0 ? 'highlight' : ''}
                                >
                                  <option key ="Revert to Business" >Revert to Business</option>
                                  <option key ="Revert to Due Diligence" >Revert to Due Diligence</option>
                                  <option key ="Revert to Structuring" >Revert to Structuring</option>
                                  <option key ="Proceed to Sukuk Contract Creation" >Proceed to Sukuk Contract Creation</option>
                                  <option key ="Close Sukuk Request" >Close Sukuk Request</option>
                                  <option key="Decline Sukuk Request (No-go)" >Decline Sukuk Request (No-go)</option>
                                </Form.Select>
                                
                              </Col>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label as="legend" column sm={12} className="form-label" >
                              Remarks
                              </Form.Label>
                              <Col sm={12} className="d-flex align-items-center">
                              <Form.Control
                                as="textarea"
                                placeholder=""
                                name="adminRemarks"
                                value={adminRemarks}
                                onChange={handleChange}
                                className={adminRemarks.length > 0 ? 'highlight' : ''} 
                                style={{height: '100px'}}
                              />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                              <Col sm={{ span: 12 }} className="justify-content-around d-flex mt-3">
                                {/* <Link to="/dashboard/new-sukuk/info"> */}
                                {/* </Link> */}
                                {/* <Link to="/dashboard/new-sukuk/documents"> */}
                                  <button onClick = {submitInfo} className="custom-button">Submit</button>
                                {/* </Link> */}
                              </Col>
                            </Form.Group>
                          </Form>
  
                        </div>
                        </>
  
                  </Card.Text>
              </Card.Body>
            </Card>
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={successInfoShow}
          onHide={() => setSuccessInfoShow(false)}
        >
          {/* Sucesss Modal  */}
  
          <Modal.Body closeButton>
            <div className="center-title">
              <br></br>
              <h4>Submitted Successfully</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button.
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
  
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={serverError}
          onHide={() => setServerError(false)}
        >
          {/* Error Modal  */}
  
          <Modal.Body>
            <div className="center-title">
              <br></br>
              <h4> {errorInfo.length > 0 ? errorInfo : 'Something went wrong, please reload the page.'}</h4>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
      </AdminStyleWrapper>
    )
  }


  export default StructuringInfo;