export const PUBLIC_ROUTE = {
    LANDING: '/',
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    PROFILE: '/profile',
    FORGET_PASSWORD: '/forgotpassword',
    RESET_PASSWORD: '/resetpassword',
    PAGE_404: '/404',
    PAGE_500: '/500',
  };
  
  export const PRIVATE_ROUTE = {
    DASHBOARD: '/*',
    COMMON: '/common/*',
    BUSINESS: '/business/*',
    DueDiligencePartner: '/dd/*',
    STRUCTURING: '/structuring/*',
    SPV: '/spv/*',
    QADMIN: '/qadmin/*',
    NEWSUKUKREQUEST: '/newSukukRequest',
    SAVEDSUKUKREQUESTS: '/savedSukukRequests',
    SUKUKPENDINGUPDATES: '/sukukPendingUpdates',
    SUKUKPENDINGAPPROVALS: '/sukukPendingApprovals',
    FUNDSREQUESTS: '/fundRequest',
    NEWDDREQUESTS: '/ddRequests',
    USERCASETOBEDONE: '/userCaseToBeDone',
    NEWSTRUCTURINGREQUESTS: 'structuringRequests',
    BUSINESSPROFILE: '/profileform',
    SUKUKREQUESTINFO: '/newSukukRequest/info',
    STRUCTURINGREQUESTINFO: '/structuringRequests/info',
    SAVEDSUKUKREQUESTINFO: '/savedSukukRequests/info',
    DDREQUESTSINFO: '/ddRequests/info',
    SUKUKPENDINGUPDATESINFO: '/sukukPendingUpdates/info',
    SUKUKPENDINGAPPROVALINFO: '/sukukPendingApprovals/info',
    FUNDSREQUESTSINFO: '/fundRequest/info',
    ASSOCIATION: '/selectAssociations',
    SPVINITIATION: '/SPVinitiation',
    SPVINITIATIONINFO: 'SPVinitiation/info',
    SUKUKLIST: '/sukukList',
    SUBSCRIPTIONLIST: '/subscriptionList',
    FUNDDISBURSEMENT: '/fundDisbursement',
    FUNDDISBURSEMENTFORM: '/fundDisbursement/form',
    COMMONDOCPREVIEW:'/preview',
    BANKDETAILS: '/bankDetails',
    CREATEPROSPECTUS: '/createProspectus',
    FINANCEDATA: '/financeData',
    PROCESSDATA: '/processData'
  }
  