import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../App.css'
import {
  Button,
  Card,
  Container,
  Dropdown,
  Image,
  Modal,
} from 'react-bootstrap'

import backgroundImage from '../helpers/images/assets/qiam-logo-without-name.png'
import qiamLogoWhite from '../helpers/images/assets/qiam-white-logo.png'
import Business from '../helpers/images/role-logos/Business.png'
import SPV from '../helpers/images/role-logos/SPV_gradient.png'
import Investor from '../helpers/images/role-logos/Investor.png'
import Partner from '../helpers/images/role-logos/Partner_gradient.png'
import QAdmin from '../helpers/images/role-logos/Qiam Admin_gradient.png'

// partner roles

import PartnerDDP from '../helpers/images/dark-partner/DueDiligence PartnerIcon.png'
import PartnerStru from '../helpers/images/dark-partner/StructuringPartner.png'
import PartnerFiCom from '../helpers/images/dark-partner/FinancialCompliance.png'
import PartnerFiCon from '../helpers/images/dark-partner/FinancialConsulting.png'
import PartnerShariah from '../helpers/images/dark-partner/ShariahCompliancePartner.png'
import PartnerSR from '../helpers/images/dark-partner/SukukRating.png'
import PartnerLegal from '../helpers/images/dark-partner/LegalPartnerIcon.png'
import { setBusinessRoles, setProfileCompleted, setProfileRole, setBusinessRole } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import {customData} from './../helpers/constants';
import apiManager from '../helpers/apiManager'
import { PRIVATE_ROUTE } from '../helpers/routerConstants'

const {BUSINESS_ROLES, BUSINESSROLE_BASED_URL}  = customData;
const roleConfirmationText = {
 [BUSINESS_ROLES.Customer]: 'Do you want to sign-up as a Business seeking to raise funds?',
  ['DueDiligencePartner'] : 'Do you want to sign-up as a Due Diligence Partner?',
  ['StructuringPartner']: 'Do you want to sign-up as a Structuring Partner?',
  [BUSINESS_ROLES.QAdmin] : 'Do you want to sign-up as a QAdmin?',
  [BUSINESS_ROLES.SPV] : 'Do you want to sign-up as a SPV?'
}
const PartnerModal = (props) => {
  const {selectedRole, profile} = props;
  return (
    <>
      <Modal
      {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="partner-modal light-bg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="center-title">Please Select Partner </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body closeButton>
          <br></br>

          <div className="profile-role">
            <div className="layout layout-vertical layout-item-center mb-4"  onClick={() => selectedRole('DueDiligencePartner')}>
              <img
                src={PartnerDDP}
                className="profile-icon"
              />
              <label className="role-lable">Due Diligence</label>
            </div>

            <div className="layout layout-vertical layout-item-center mb-4"  onClick={() => selectedRole('StructuringPartner')}>
              <img
                src={PartnerStru}
                className="profile-icon"
              />

              <label className="role-lable"> Structuring</label>
            </div>

            <div className="layout layout-vertical layout-item-center mb-4">
                <img src={PartnerFiCom} className="profile-icon" />
              <label className="role-lable">
                {' '}
                Financial <br /> Compliance
              </label>
            </div>
            <div className="layout layout-vertical layout-item-center mb-4">
                <img src={PartnerFiCon} className="profile-icon" />
              <label className="role-lable">
                {' '}
                Financial <br /> Consulting{' '}
              </label>
            </div>
          </div>

          <br />
          <br />

          <div className="profile-role">
            <div className="layout layout-vertical layout-item-center mb-4">
                <img src={PartnerShariah} className="profile-icon" />
              <label className="role-lable">
                {' '}
                Shariah <br /> Compliance
              </label>
            </div>
            <div className="layout layout-vertical layout-item-center mb-4">
                <img src={PartnerSR} className="profile-icon" />
              <label className="role-lable"> Sukuk Rating</label>
            </div>
            <div className="layout layout-vertical layout-item-center mb-4">
                <img src={PartnerLegal} className="profile-icon" />
              <label className="role-lable"> Legal</label>
            </div>
            {/* <div className="layout layout-vertical" style={{display:"none"}}>
            <Link to="/investor" >
            <img src={PartnerSR} className="profile-icon" />
            </Link>
            <label className="role-lable">
            {' '}
            Sukuk Rating
            </label>
          </div> */}
          </div>

          <br />

          {/* <Button className='upload-button' onClick={props.onHide}>Close</Button> */}
        </Modal.Body>
      </Modal>

      {/* dd  */}

      {/* <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="partner-child-modal"
        show={modalDDShow}
        onHide={() => setModalDDShow(false)}
      >

        <Modal.Body closeButton>
          <div className="center-title">
            <br></br>
            <h3> Do you want to sign-up as  </h3>
            <h3> a {roleConstants[profile]} Partner?</h3>
            <br></br>
            <Button className="custom-button" onClick={() => setModalDDShow(0)}>
              No
            </Button>
            <Link to="/partner/ddp/dashboard">
              <Button className="custom-button">Yes</Button>
            </Link>
          </div>
        </Modal.Body>
        <Modal.Body closeButton>
          <br></br>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="partner-child-modal"
        show={modalStruShow}
        onHide={() => setModalStruShow(false)}
      >

        <Modal.Body closeButton>
          <div className="center-title">
            <br></br>
            <h3> Do you want to sign-up as a  </h3>
            <h3> Structuring Partner?</h3>
            <br></br>
            <Button
              className="custom-button"
              onClick={() => setModalStruShow(0)}
            >
              No
            </Button>
            <Link to="/partner/structuring/dashboard">
              <Button className="custom-button">Yes</Button>
            </Link>
          </div>
        </Modal.Body>
        <Modal.Body closeButton>
          <br></br>
        </Modal.Body>
      </Modal> */}
    </>
  )
}

// const BusinessModal = (props) => {
//   return (
//   <Modal
//     {...props}
//     size="lg"
//     aria-labelledby="contained-modal-title-vcenter"
//     centered
//     className="business-modal"
//     show={props.businessModalShow}
//     onHide={() => props.setConfirmationModalShow(false)}
//   >
//     <Modal.Header closeButton>
//       <Modal.Title id="contained-modal-title-vcenter">
//               <h4 className='center-title'>Please Select Partner </h4>

//       </Modal.Title>
//       <Modal.Body closeButton>
//         <br></br>
//         Do you want to sign-up as a Business seeking to raise funds?
//         <br></br>
//         <br></br>
//         <Button className='upload-button' onClick={props.onHide}>Close</Button>
//         <Button className='upload-button' onClick={props.onHide}>Close</Button>
//       </Modal.Body>
//     </Modal.Header>
//     <Modal.Body closeButton>
//       <br></br>

//       {/* <h4 className='center-title'>Select Partner Type...</h4> */}
//       {/* <br></br> */}
//       {/* <br></br> */}

//       {/* <Button className='upload-button' onClick={props.onHide}>Close</Button> */}

//     </Modal.Body>
//     {/* <Modal.Footer>
//     </Modal.Footer> */}
//   </Modal>
//   )
// }

const Profile = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessRoles = useSelector(state => state.app.businessRoles);
  const externalId = useSelector(state => state.app.externalId);
  const uid = useSelector(state => state.app.uid);
  const profile = useSelector((state) => state.app.profileRole);
  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [confirmationModalShow, setConfirmationModalShow] = React.useState(false);
  const [newRole, setNewRole] = useState('');
  const [showSuccessFullPopup, setShowSuccessFullPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const associations = JSON.parse(localStorage.getItem('associations'));
  const existingBusinessRoles = [];
  
  // if(response.data && response.data[0].Associations.length === 0 && response.data && response.data[0].UserName === null){
  //   navigate("/profile");
  // }
  // else{
  //   navigate("/selectAssociations");
  // }
  useEffect(() =>{
    // if(associations){
    //   associations.length > 0 && associations.map(association => {
    //     existingBusinessRoles.push(association.BusinessRoles);
    //   })
    // }
  },[])
  
  const selectedRole = (role) =>{
    dispatch(setProfileRole(role));
    setShowPartnerModal(false);
    setNewRole(role);
    if(businessRoles.length === 0 || !businessRoles.includes(role)){
      // dispatch(setProfileCompleted(false));
      setConfirmationModalShow(true);
    }
    else{
      setSuccessMessage('Role changed successfully');
      // dispatch(setProfileCompleted(true));
      setShowSuccessFullPopup(true);
      setTimeout(() =>{
        setShowSuccessFullPopup(false);
        navigate(PRIVATE_ROUTE[BUSINESSROLE_BASED_URL[role]]);
      }, 2000);
    }
  }
  const goto = () =>{
    setConfirmationModalShow(false);
    if(externalId !== ''){
    const data = {
        CompanyInfo: {
          ExternalId: externalId
        },
        BusinessRoles: [newRole]
    }
    apiManager.updateProfile(data, uid)
    .then((response) => {
      dispatch(setBusinessRoles([...businessRoles, newRole]));
      setSuccessMessage('Role added successfully');
      setShowSuccessFullPopup(true);
      setTimeout(() =>{
        setShowSuccessFullPopup(false);
        navigate(PRIVATE_ROUTE[BUSINESSROLE_BASED_URL[newRole]]);
        // navigate('common/profileForm');
      }, 2000);
    })
    .catch((error) => {
      alert('error')
    })
  }
  else{
    dispatch(setBusinessRole(newRole));
    dispatch(setBusinessRoles([...businessRoles, newRole]));
    navigate('/common/profileForm');
  }
  }
  return (
    <Container style={{ padding: '40px' }}>
      <div className="Text-center select-role" style={{  maxWidth: '1000px',margin: '0 auto'}}>
      <Link to="/business" className='center-title'>
          <img
            src={qiamLogoWhite}
            alt="Logo"
            style={{ width: '50px', height: '50px' }}
            className=""
          />
        </Link>
        <div className="watermark right"></div>
        <br />

        <br />

        <h1>
          Welcome
        </h1>
        <br />
        <br />

        <h2 style={{fontWeight: 400}}>Select Your Role...</h2>

        <br />
        <br />
        <br />

        <div className="profile-role">
          <div className="layout layout-vertical mb-4" onClick={() => selectedRole(BUSINESS_ROLES['Customer'])}>
            {/* <Link to="/dashboard">
              <img src={Business} className="profile-icon" />
            </Link> */}
            {/* <Link to="/dashboard"> */}
            <div>
              <img
                src={Business}
                className="profile-icon"
              />
            </div>
            {/* </Link> */}
            <label className="role-lable">Business</label>
          </div>
          <div className="layout layout-vertical mb-4" onClick={() => setShowPartnerModal(true)}>
            <div>
              <img src={Partner} className="profile-icon" />
            </div>
            <label className="role-lable"> Partner</label>
          </div>
          <div className="layout layout-vertical layout-item-center mb-4">
              <img src={Investor} className="profile-icon" />
            <label className="role-lable"> Investor</label>
            <span className="comingSoonBox">Coming soon</span>
          </div>
          {/* <div className="layout layout-vertical mb-4" onClick={() => selectedRole(BUSINESS_ROLES['SPV'])}>
              <img src={SPV} className="profile-icon" />
            <label className="role-lable"> SPV</label>
          </div> */}
        </div>

        <br />
        <br />
        <br />
        <br />

        {/* <div className="profile-role">
          
        <div className="layout layout-vertical mb-4" onClick={() => setShowPartnerModal(true)}>
            <div>
              <img src={Partner} className="profile-icon" />
            </div>
            <label className="role-lable"> Partner</label>
          </div> */}
       
          {/* <div className="layout layout-vertical mb-4"  onClick={() => selectedRole(BUSINESS_ROLES['QAdmin'])}>
              <img src={QAdmin} className="profile-icon" />
            <label className="role-lable"> QAdmin</label>
          </div> */}
        {/* </div> */}
      </div>
      <PartnerModal show={showPartnerModal} onHide={() => setShowPartnerModal(false)} setShowPartnerModal={setShowPartnerModal} setConfirmationModalShow={setConfirmationModalShow} confirmationModalShow={confirmationModalShow} selectedRole ={selectedRole} profile />
      {/* <BusinessModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="business-modal"
        show={confirmationModalShow}
        onHide={() => setConfirmationModalShow(false)}
      >
        {/* Business Modal  */}

        <Modal.Body>
          <div className="">
            <br></br>
            <h3>{roleConfirmationText[profile]}</h3>
            <br></br>
            <div className="d-flex justify-content-center" >
            <Button
              className="custom-button"
              onClick={() => setConfirmationModalShow(false)}
            >
              No
            </Button>
              <Button onClick={() => goto()} className="custom-button">Yes</Button>
              </div>
          </div>
        </Modal.Body>
        <Modal.Body>
          <br></br>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        centered
        className="error-handling-modal"
        show={showSuccessFullPopup}
        onHide={() => setShowSuccessFullPopup(false)}
      >

        <Modal.Body>
          <div className="center-title">
            <br></br>
            <p style={{textAlign: 'center'}}>{successMessage}</p>
            <br></br>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default Profile
