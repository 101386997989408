import { SIGN_IN, SIGN_OUT } from "../actions/types";


const INTIAL_STATE ={
    idToken: null
}

export default (state = INTIAL_STATE, action) => {
    switch(action.type){
        case SIGN_IN:
            return { ...state, idToken : action.token};
            case SIGN_OUT:
                return INTIAL_STATE;
                default:
                     return state
    }
};


