import React, { useState, useEffect} from 'react'
// import './requests.css'
import SukukCard from '../../components/SukukCard/sukukCard';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import apiManager from '../../helpers/apiManager';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setApplicationId, setSelectedSukukInfo } from '../../actions';
import Loading from '../../helpers/Loader/loader';

const sortOptions=[{label: 'Date Received'},
{label: 'Location'},
{label: 'Sukuk Size'},
{label: 'Tenure'}];
const StructuringRequest = () => {
  const externalId = useSelector(state => state.app.externalId);
  const uid = useSelector(state => state.app.uid);
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const [loader, setLoader] = useState(true);
 const [serverError, setServerError] = useState(false);
 const [structuringPartnerApplicationList, setStructuringPartnerApplicationList] = useState([]);


  useEffect(() =>{
    const data = {"StructuringExternalId" : externalId};
    // const data = {"DuediligenceExternalId" : 'd8c73082-6416-4c2f-839f-1867579ed01f'};
    apiManager
            .searchStructuringRequests(data, uid)
            .then((response) => {
              const structirungRequestApplications = response.data.length > 0 ? response.data.filter(application => application.ApplicationStatus === 21) : [];
              setStructuringPartnerApplicationList(structirungRequestApplications);
              setLoader(false);
              // localStorage.setItem('categories', JSON.stringify(response.data.data));
            })
            .catch((error) => {
              setServerError(true);
              setLoader(false);
            });
  },[]);
  const gotoInfo = (id, app, Country) =>{
    navigate("info", {state:{ applicationId: id }});
    dispatch(setApplicationId(id));
    dispatch(setSelectedSukukInfo({...app, Country : Country}));
  }
const details ={
  SubmittedOn: '05/Mar/2022',
  ApplicationName: 'Ennovate LLC',
  Account: 'Pied Piper Private Limited',
  size: '$1,000,000,000',
  place: 'Dubai, UAE',
  CompanyInfo : {Country: 'Dubai, UAE'},
  tenure: '40 months'
}
return(

<>
    {loader && <Loading />}
    <div className="container_">
      <div className="header_ text-start">
        <h4>New Structuring Requests</h4>
      </div>
      <Row className={"justify-content-end"} style={{width: '100%'}}>
        <Col sm={5} style={{paddingRight: 0}}>
        <Form>
            <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="3" className='text-end'>Sort by:</Form.Label>
          <Col sm={9} style={{paddingRight: 0}}><Form.Select>
              {sortOptions.length > 0  && sortOptions.map((sort, idx) =>
              <option key={idx} value={sort.label}>{sort.label}</option>
              )}
          </Form.Select>
          </Col>
          </Form.Group>
          </Form>
          </Col>
          </Row>
          {structuringPartnerApplicationList.length > 0 && structuringPartnerApplicationList.map(app =>{
      const info = {
        SubmittedOn: app.AssignedDate, // since submittedOn is not present in the response
        ApplicationName: app.ApplicationName, 
        Account: app.Account, 
        CompanyInfo: {Country : ''}, // since Company is not present in the response
        IssuanceSize: app.ApplicationInfo.SukukIssuanceSize, 
        TenureInMonths: app.ApplicationInfo.SukukTenureInMonths,
        Currency: app.ApplicationInfo.SukukIssuanceCurrency ? app.ApplicationInfo.SukukIssuanceCurrency : '',
      }
        return(
          <div onClick={() => gotoInfo(app.ApplicationId, app, '')} >
            <SukukCard info= {info}/>
          </div>
        )
    })}
    {structuringPartnerApplicationList.length === 0 && <div className="noDataText">There are no Requests</div>}
    </div>
    </>
      )}

      export default StructuringRequest;