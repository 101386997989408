import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';

import { PUBLIC_ROUTE, PRIVATE_ROUTE } from './helpers/routerConstants';
import SignIn from './publicPages/signIn';
import SignUp from './publicPages/signUp';
import Profile from './publicPages/profile';
import Blank from './publicPages/blankPage';
import Container from './container';
import Associations from './components/associations';
import { Navigate } from "react-router-dom";




// function PrivateRoute({ children, ...rest }) {
//     alert('asf');
//   const isLoggedIn = useSelector(state => state.Auth.idToken);
//   return (
//     <Route
//       path="/dashboard"
//       element={<Dashboard/>}
//     />
//   );
// }
export default function AppRoutes() {
    const isLoggedIn = useSelector(state => state.app.loggedIn);
    const publicRoutes = [
      {
        path: PUBLIC_ROUTE.LANDING,
        exact: "true",
        component: <SignIn/>,
      },
      {
        path: PUBLIC_ROUTE.SIGN_IN,
        component: <SignIn/>,
      },
      {
        path: PUBLIC_ROUTE.SIGN_UP,
        component: <SignUp/>,
      },
      {
        path: PUBLIC_ROUTE.PROFILE,
        component: <Profile/>,
      },
      {
        path: '/blank',
        component: <Blank/>,
      },
      // {
      //   path: PUBLIC_ROUTE.AUTH0_CALLBACK,
      //   component: lazy(() =>
      //     import('@iso/containers/Authentication/Auth0/Auth0Callback')
      //   ),
      // },
    ];
    const privateRoutes = [
      {
        path: PRIVATE_ROUTE.BUSINESS,
        exact: "true",
      },
      {
        path: PRIVATE_ROUTE.DueDiligencePartner,
      },
      {
        path: PRIVATE_ROUTE.COMMON,
      },
      {
        path: PRIVATE_ROUTE.STRUCTURING,
      },
      {
        path: PRIVATE_ROUTE.SPV
      },
      {
        path: PRIVATE_ROUTE.QADMIN,
      },
      {
        path: PRIVATE_ROUTE.ASSOCIATION
      }
      // {
      //   path: PUBLIC_ROUTE.AUTH0_CALLBACK,
      //   component: lazy(() =>
      //     import('@iso/containers/Authentication/Auth0/Auth0Callback')
      //   ),
      // },
    ];
  return (
    <ErrorBoundary>
        <Router>
          <Routes>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact} element={route.component}/>
            ))}
            {/* <Route path = '/dashboard' element = {!isLoggedIn ? <Dashboard/> : <Navigate to="/signin" />} /> */}
            {/* <Route  path = '/business/*' element = {<Container path=/>}  />
            <Route  path = '/dd/*' element = {<Container/>}  /> */}
              <Route path={PRIVATE_ROUTE.ASSOCIATION} exact={true} element={<Associations/>}/>
            {privateRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact} element={isLoggedIn ? <Container customPath={route.path}/> : <Navigate to="/signin" />}/>
            ))}
          </Routes>
        </Router>
        </ErrorBoundary>
  );
}
