import styled from 'styled-components';
import { APPCOLORS } from './helpers/constants';
import WithDirection from './helpers/rtl';

const ContainerStyleWrapper = styled.div`
.container_{
    /* height: 100vh; */
    .right_content{
        width: calc(100% - 320px);
        height: 100vh;
    }
    a{
        text-decoration: none !important;
    }
    .body{
    padding: 30px 30px 30px 30px;
    background-color: ${APPCOLORS.containerBg};
    color: #000000;
    min-height: calc(100vh - 70px);
    height: calc(100vh - 70px);
    overflow-y: auto;
    }
}
`;

export default WithDirection(ContainerStyleWrapper);