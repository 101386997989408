export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const PROFILE_COMPLETED = 'PROFILE_COMPLETED';
export const PROFILE_ROLE = 'PROFILE_ROLE';
export const PROFILE_ROLE_DESCRIPTION = 'PROFILE_ROLE_DESCRIPTION';
export const EXTERNAL_ID = 'EXTERNAL_ID';
export const BUSINESS_ROLE = 'BUSINESS_ROLE';
export const BUSINESS_ROLES = 'BUSINESS_ROLES';
export const APPLICATION_ID = 'APPLICATION_ID';
export const APPLICATION_NAME = 'APPLICATION_NAME';
export const UID = 'UID';
export const SELECTED_SUKUK_INFO = {};
export const EMAIL_ID = 'EMAIL_ID';
export const LOGGED_IN = 'LOGGED_IN';
export const SPV_NAME = 'SPV_NAME';
export const RESET = 'RESET';
export const DOCUMENT = 'DOCUMENT';
export const DOCUMENTNAME = 'DOCUMENTNAME';
export const DOCUMENTTYPE = 'DOCUMENTTYPE';
export const AIRTABLEURL = 'AIRTABLEURL';

