import React, { useEffect, useState, memo } from 'react'
import {Card} from 'react-bootstrap'
import { useSelector } from 'react-redux';
import apiManager from '../../helpers/apiManager';
import SukukCardStyleWrapper from './sukukCard.style';
import menuOption from '../../helpers/images/assets/VerticalDotsMenu.svg';
import {Dropdown} from 'react-bootstrap';
import utility from './../../helpers/utility';
const SPVSubscriptionCard = (props) => {
  const uid = useSelector(state => state.app.uid);
  // const [companyInfo, setCompanyInfo] = useState({
  //   sukukSubmittedDate : '',
  //   ApplicationName: '',
  //   Account: '',
  //   Country: '',
  //   IssuanceSize: '',
  //   TenureInMonths: ''
  // })
  useEffect(() =>{
    if(props){
      // const {id, name, size, tenure, Account, SubmittedOn, } = props;
      // setCompanyInfo({...companyInfo,
      //   ApplicationName: name,
      //   IssuanceSize: size,
      //   TenureInMonths: tenure
      // })
    // const data = {
    //   "CompanyInfo": {
    //     "ExternalId":id 
    //   }
    // }
    // apiManager.getProfileData(data,uid)
    // .then((response) =>{
    //   const profileData = response.data[0].CompanyInfo; 
    //   let tempSukukSubmittedDate = new Date(profileData.ServiceEnrollmentDate);
    //   tempSukukSubmittedDate = tempSukukSubmittedDate.getDate() + '/'+ months[tempSukukSubmittedDate.getMonth()] + '/'+ tempSukukSubmittedDate.getFullYear();
    //   setCompanyInfo({...companyInfo,
    //     sukukSubmittedDate : tempSukukSubmittedDate,
    //     Account: profileData.Name,
    //     Country: profileData.Address.Country,
    //   })
     
    // })
    // .catch((error) => {

    // })
  }
  },[props])
  const {StatusDate, SubscriptionAmount, Status, Currency, Investor} = props.prospectus;
  const {statusColors, currency, statuses} = utility;
  let color = statusColors[Status];
  const subscriptionStatus = statuses[Status];
  const subsciptionStartDate = utility.getFormattedDate(StatusDate) + ' '+ utility.getFormattedTime(StatusDate);
  const nf = new Intl.NumberFormat("en-"+currency[Currency] ? currency[Currency] : 'US', {
    // style: "currency", 
    currency: Currency,
  });
return(
  <SukukCardStyleWrapper>
      <Card className="request-card spvCard m-0">
        <Card.Body>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div className="layout layout-vertical layout-item-start-align mb=2 px-2" style={{width: 'calc(100% - 70px)'}}>
          <div className="layout layout-horizontal layout-justify-space-between style-card w-100 mb-2" style={{alignItems: 'baseline'}}>
            <div className='sukuk-name mb-3'>{Investor}</div>
            </div>
            <div className="d-flex mb-3" style={{alignItems: 'baseline'}}>
              <label className="cardLabel">Subscription Date</label>
              <div style={{marginLeft: 20}}>{subsciptionStartDate ? subsciptionStartDate  : ''}</div>
            </div>
            <div className="d-flex" style={{alignItems: 'baseline'}}>
              <label className="cardLabel">Subscription Amount</label>
              <div style={{marginLeft: 20}}>{Currency + ' ' + nf.format(SubscriptionAmount)}</div>
            </div>
          </div>
            <div className='statusBlock'>
              <div className=''>
                  <label>Status</label>   
                  <div className={`statusCircle`} style={{backgroundColor : color}}></div>
              </div>
              <div className="mt-2">{subscriptionStatus}</div>
            </div>
        </div>
        </Card.Body>
      </Card>
      </SukukCardStyleWrapper>
      )}

      export default memo(SPVSubscriptionCard);