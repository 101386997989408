import React, { useState, useEffect } from 'react'
// import './requests.css'
import SukukCard from '../../components/SukukCard/sukukCard';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setApplicationId, setApplicationName } from '../../actions';
import apiManager from '../../helpers/apiManager';
import Loading from '../../helpers/Loader/loader';

const CreateSPV = () => {
  const externalId = useSelector(state => state.app.externalId);
  const uid = useSelector(state => state.app.uid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [ApplicationListForSPV, setApplicationListForSPV] = useState([]);

  useEffect(() => {
    const data = { "ExternalId": externalId, "InstrumentId": 101 };
    apiManager
      .initiateSpvList(data, uid)
      .then((response) => {
        const applicationListForSPV = response.data.length > 0 ? response.data : [];
        setApplicationListForSPV(applicationListForSPV);
        setLoading(false);
        // localStorage.setItem('categories', JSON.stringify(response.data.data));
      })
      .catch((error) => {
        setServerError(true);
        setLoading(false);
      });
  }, []);
  const details = {
    SubmittedOn: '05/Mar/2022',
    ApplicationName: 'Ennovate LLC',
    Account: 'Pied Piper Private Limited',
    size: '$1,000,000,000',
    CompanyInfo: { Country: 'Dubai, UAE' },
    tenure: '40 months'
  }


  const gotoInfo = (id, appName) => {
    navigate("info");
    dispatch(setApplicationId(id));
    dispatch(setApplicationName(appName));
  }
  return (

    <>
      {loading && <Loading />}
      <div className="container_">
        <div className="header_ text-start">
          <h4>Initiate SPV</h4>
        </div>
        {ApplicationListForSPV.length > 0 && ApplicationListForSPV.map(app => {
          const {Application} = app;
          const info = {
            SubmittedOn: Application.SubmissionInfo ? Application.SubmissionInfo.SubmittedDate : Application.ApplicationDate,
            ApplicationName: Application.ApplicationName,
            Account: Application.Account ? Application.Account : '',
            CompanyInfo: Application.CompanyInfo ? Application.CompanyInfo : {},
            IssuanceSize: Application.ApplicationInfo.SukukIssuanceSize ? Application.ApplicationInfo.SukukIssuanceSize : '',
            TenureInMonths: Application.ApplicationInfo.SukukTenureInMonths ? Application.ApplicationInfo.SukukTenureInMonths : '',
            Currency: Application.ApplicationInfo.SukukIssuanceCurrency ? Application.ApplicationInfo.SukukIssuanceCurrency : '',
          }
          return (
            <div onClick={() => gotoInfo(Application.ApplicationId, Application.ApplicationName)}>
              <SukukCard info={info} />
            </div>
          )
        })}
        {ApplicationListForSPV.length === 0 && <div className="noDataText">There is no sukuk for SPV initiation.</div>}
      </div>
    </>
  )
}

export default CreateSPV;