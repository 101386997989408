import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const FormStyleWrapper = styled.div`
.nav-tabs .nav-link{
    color:#000000;
    border: none !important;
    padding:5px 50px;
    margin: 0 20px;
}
.nav-tabs .nav-link.active{
    border: none !important;
    border-bottom: 4px solid ${APPCOLORS.bgColor} !important;
    color: ${APPCOLORS.bgColor} !important;
    font-weight: 600;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    border: none;
    border-bottom:1px solid #dee2e6;
}
.info-icon{
    color: red;
    font-size: 50px;
}
h3{
    text-align: left;
}
.info-card{
    width: 70%;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    .card{
        min-height: 650px;
    }
}
.sukuk-info-card .info-lable{
    white-space: nowrap;
    overflow: hidden;
    text-align: end;
    display: block;
    width: 150px;
}
.info-lable{
    /* white-space: nowrap; */
    overflow: hidden;
    text-align: end;
    display: block;
    width: 200px;
    margin-top: 10px;

}
/* label{
    font-size: 16px;
} */
.info-icon-ai{
    font-size: 30px;
    margin-left: 10px;
    opacity: 0.6;
}
p span{
    color: red;
}
.sukuk-info-input{
    height: 40px;
    opacity: 1;
}
.sukuk-info-select{
    height: 40px !important;
    opacity: 0.5;

}
#sukuk-info-issuanse-select{
    height: 40px !important;
    opacity: 1;
    width: 109px;
    background-position-x: 80px;
    border : 1px solid #ced4da


}

input.form-control{
    height: 45px;
}
.sukuk-info-issuanse-input{
    width: 290px;
    margin-left: 10px;
    text-align: right;

}
@media (max-width: 1200px) {
    .sukuk-info-input{
        width: 150px;

    }
    .sukuk-info-select{
        width: 150px;
    }
}



/* document */

.sukuk-info-table{
    tr{
        display: inline-flex;
        margin: -10px;
    }
}

.sukuk-document-input{
    height: 40px;
    width: 110px;
    margin-left: -5px;
    background-color: #e9ecef;

}
.sukuk-document-input-text{
    height: 40px;
    width: calc(100% - 77px);
    margin-left: 0px;
    margin-right: 5px;
    padding-right: 28px;
}

.sukuk-document-title{
    background-color: black;
    color: white;
    font-size: 20px;

}
td{
    margin: 10px;
}

.sukuk-info-table-th{
    margin: 100px;
}

.sukuk-init-nav{
  border-radius: 10px 10px 0px 0;
  /* background: #949391; fallback for old browsers */
  /* border: 1px solid #949391; */
  /* color: white; */
  padding: 5px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  position: relative;
  margin: 0px 5px 0px 0px;
  font-weight: 600;
  &.active{
    border-bottom: 3px solid;
    border-color:  ${APPCOLORS.bgColor}; /*fallback for old browsers*/
    border-color: ${APPCOLORS.sidebarBg};
    color: ${APPCOLORS.bgColor};

  }
}
.document .info-icon-ai{
    font-size: 30px;
    margin-left: 2px;
    opacity: 0.6;
    /* margin-bottom: 40px; */
    /* position: relative; */
    /* top: 4px; */
}
#esg{
    margin-top: 4px;
}

  .upload-button{
    border: 2px solid #ffffff;
    border-radius: 5px;
    background: ${APPCOLORS.bgColor}; /* fallback for old browsers */
    background: -webkit-${APPCOLORS.sidebarBg}; /* Chrome 10-25, Safari 5.1-6 */
    background: ${APPCOLORS.sidebarBg};
    border: none;
    color: white;
    padding: 0px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    position: relative;
    /* margin-left:  -20px;
    margin-right: -15px; */
    height: 40px;
    /* margin-bottom: 36px; */

    /* left: 20px; */
  }
.new-sukuk-init select {
    padding: 5px;
    font-size: 16px;
    border: 1px solid #949391;
    border-radius: 5px;
    height: 34px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
    background-position-x: calc(100% - 10px);
    background-color: white;
    -webkit-appearance: none;
}
.info-card .card-body{
    padding :1rem 0rem;
}

  /* remark */

.remark-sukuk{
    margin-top: 20px;
    width: 90%;
    margin-left: 5%;
    .remark-label{
        font-size: 15px;
        text-align: start;
    }
    .remark-textarea{
        font-size: 15px;
        min-height: 100px;
        opacity: 1;
        border: 1px solid #ced4da;
    }
}


  /* side card */







/* loading componenet   */

.progress{
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: -5px;
    /* margin-left: 105px; */

}
.progress-bar{
    background-color: ${APPCOLORS.bgColor};

}
.progress-custom {
    background-color: purple;
    color: white;
  }

.virus-check-icon{
    color: green;
}
.virus-error-icon{
    color: red;
}
.uploadingLoader, .virus-icon{
    position: absolute;
    right: 12px;
    top: 14px;
}
.virus-icon{
    font-size: 20px;
    right: 10px;
    top: 13px;
}
.inputWrapper{
    width:calc(100% - 150px);
}
button:disabled{
    cursor: not-allowed;
}
`;

export default WithDirection(FormStyleWrapper);