import React, { useEffect, useState } from 'react'
import { Button, Card, Container, OverlayTrigger, Form, Popover,Modal } from 'react-bootstrap'
import backgroundImage from '../helpers/images/assets/qiam-logo-without-name.png'
import qiamLogoWhite from '../helpers/images/assets/qiam-white-logo.png'
import help from '../helpers/images/header-logos/Help_Icon.svg';
import { customData } from '../helpers/constants';
import apiManager from '../helpers/apiManager';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import FormStyleWrapper from './form.style';

const emailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const passwordRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])[A-Za-z\d@#$%^&+=]{8,30}$/);
const formValid = formErrors => {
  let valid = true;
  Object.values(formErrors).forEach(val => {
      if (val.length === 0 || val !== 'blank') {
          valid = false;
      }
  });
  return valid;
}
const SignUp = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: '',
    password: '',  
    confirmPassword: '',
    mobileCountryCode: '1',
    mobile:'',
    error: '',
    success: false,
  })
  const [formErrors, setFormErrors] = useState({
    email_: '',
    mobile_: '',
    mobileCountryCode_: 'blank',
    password_: '',
    confirmPassword_: ''
  })
  const [passwordValidationPopup, setPasswordValidationPopup] = useState(false);
  const [showSuccessFullPopup, setShowSuccessFullPopup] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [agreed, setAgreed] = useState(false);
  const handleChange = (event) => {
    const { name, value} = event.target;
    const tempFormErrors = {...formErrors};
    switch(name){
    case 'email':
      if (value.length === 0) {
          tempFormErrors.email_ = '';
      }
      else if (!emailRegex.test(value) && value.length > 0) {
          tempFormErrors.email_ = 'false';
      }
      else {
          tempFormErrors.email_ = 'blank';
      }
      break;
          case 'password':
            if (value.length === 0) {
              tempFormErrors.password_ = '';
          }
            
      else if (!passwordRegex.test(value) && value.length > 0) {
        tempFormErrors.password_ = 'false';
    }else {
              tempFormErrors.password_ = 'blank';
          }
          break;
          case 'mobile':
          if (value === ''){
              tempFormErrors.mobile_ = "";
          }
          else{
            tempFormErrors.mobile_ = "blank";
          }
          break;
          case 'confirmPassword':
            if (value.length === 0) {
              tempFormErrors.confirmPassword_ = '';
          }
            else if(values.password !== value){
              tempFormErrors.confirmPassword_ = 'false';
            }else {
              tempFormErrors.confirmPassword_ = 'blank';
          }
          break;
  }
    setValues({ ...values, [name]: value })
    setFormErrors( {...tempFormErrors})
  }
	
  useEffect(() =>{
    const data = {
      "PhoneCountryCode": [] ,
      "ISOCountryCode": customData.ISO_COUNTRY_CODES ,
      "Country": [] ,
      "ShortISOCountryCode": [],
      "FetchStrategy" : {}
  }
  const storedCountryCodes = JSON.parse(localStorage.getItem('isoCountryCodes'));
  if(storedCountryCodes){
    setCountryCodes(storedCountryCodes);
  }
  else{
  apiManager.getISOCountryCodes(data)
  .then((response) => {
      setCountryCodes(response.data);
      localStorage.setItem('isoCountryCodes', JSON.stringify(response.data));
      // setLoading(false);
      // localStorage.setItem('categories', JSON.stringify(response.data.data));
    })
    .catch((error) => {});
  }
  },[])
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body style={{width: 350}}>
      <div  style={{width: '100%'}}>
      <h4>Password validation</h4>
      <p className="mb-1">Must contain at least 1 number</p>
      <p className="mb-1">Must contain at least 1 uppercase letter</p>
      <p className="mb-1">Must contain at least 1 special character(@#$%^&+=)</p>
      <p className="mb-1">Length must be at least 8 characters</p>
        </div>
      </Popover.Body>
    </Popover>
  );
//   const onSubmit = (event) => {
//     event.preventDefault()
//     setValues({ ...values, error: false })
//     signup(values)
//       .then((data) => {
//         if (data.error) {
//           setValues({ ...values, error: data.error, success: false })
//         } else {
//           setValues({
//             ...values,
//             email: '',
//             PWD: '',
//             mobile:'',
//             error: '',
//             success: true,
//           })
//         }
//       })
//       .catch(console.log('Error in signup'))
//   }
    const signUp = (e) =>{
      e.preventDefault();
      console.log(values);
      if (formValid(formErrors)) {
        let errorTxt = "something went wrong, please try again after sometime.";
        const data = {
          // "FirstName": "Sanjay",
          // "LastName": "N",
          // "DOB": "1994-01-01",
          // "Gender": "Male",
          "PWD": password,
          "Status": "Active",
          // "UId": "",
          "Personas": [
              { "Type": "Email",
                "ID": email
              },
              { "Type": "Mobile",
                "ID": mobileCountryCode+mobile
              } ]
            }
            apiManager.signUp(data)
            .then((response) => {
              setShowSuccessFullPopup(true);
                setTimeout(() =>{
                  setShowSuccessFullPopup(false);
                  navigate('/signin');
                }, 2000)
              })
              .catch((error) => {
                errorTxt = error.res?.data?.Error?.errorMessage;
                alert(errorTxt);
              });
      }
      else{
        setValues({...values, error : true});
      }
    }
    const setCheckbox =(e) => {
      // e.preventDefault();
      // console.log('check', e.target.value);
      setAgreed(!agreed);
    }
    const { email_, password_, confirmPassword_, mobile_, mobileCountryCode_ } = formErrors;
    const { email, password, confirmPassword, mobile, mobileCountryCode, error, success } = values;
  return (
    <FormStyleWrapper>
    <Container style={{ padding: '40px' }}>
      <div className="watermark"></div>
      <div>
        <div className="center-title">
          <img
            src={qiamLogoWhite}
            alt="Logo"
            style={{ width: '50px', height: '50px' }}
            className="center-title"
          />
          <h2 style={{fontWeight: 400}}>Sign Up</h2>
        </div>
        <Card className="signup-card">
          <Card.Body className="signup-card-body">
            <Card.Text>
              <form>
                <div className="form-group mb-4">
                  <label className="form-label mb-0">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Your email"
                    value={email}
                    name="email"
                    onChange={handleChange}
                  />
                  {error && email_ === 'false' && <span className='errorMsg'>Please enter a valid email</span>}
                  {error && email_ === '' && <span className='errorMsg'>Please enter an email</span>}
                </div>
                {/* <div className="form-group">
                  <label className="form-label"> Country code</label>
                  <Dropdown className='country-dropdown'>
                    <Dropdown.Toggle id="dropdown-basic" className='country-dropdown-main'>
                      Dropdown Button
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
                <div className="form-group mb-4">
                  <label className="form-label mb-0">Mobile Number</label>
                  <div className='d-flex position-relative'>
                  <span className='position-absolute' style={{left: '8px', top: '9px', fontSize: 18}}>+</span>
                  <Form.Select
                  value={mobileCountryCode}
                  name="mobileCountryCode"
                  onChange={handleChange}
                  className={mobileCountryCode ? 'highlight phoneCountryCode' : 'phoneCountryCode'}
                  style={error && mobileCountryCode_ === '' ? {borderColor : 'red'} : {}}
                >
                  {countryCodes.length > 0 && countryCodes.map((country) => (
                    <option key={country.PhoneCountryCode} value={country.PhoneCountryCode}>{country.PhoneCountryCode}</option>
                  ))}
                </Form.Select>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputMobileNumber"
                    placeholder="Your Mobile Number"
                    value={mobile}
                    name="mobile"
                    onChange={handleChange}
                    // onInput = {handleChange}
                  />
                  </div>
                  {error && mobileCountryCode_ === '' && <span className='errorMsg'>Please select the country currency.</span>}
                  {error && mobile_ === '' && <span className='errorMsg'>Please enter the mobile number.</span>}
                  {error && mobile_ === '' && mobileCountryCode_ === '' && <span className='errorMsg'>Please fill the mobile number.</span>}
                </div>
                <div className="form-group position-relative  mb-4">
                  <label className="form-label mb-0">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Your Password"
                    value={password}
                    name="password"
                    onChange={handleChange}
                  />
                  
                  <OverlayTrigger trigger="click" placement="top" overlay={popover}>
    <img className="position-absolute h-auto" style={{right: '12px', top: '43px',width: 20, cursor: 'pointer' }} src={help} />
  </OverlayTrigger>
                  {error && password_ === 'false' && <span className='errorMsg'>Please enter a valid password</span>}
                  {error && password_ === '' && <span className='errorMsg'>Please enter password</span>}
                </div>
                <div className="form-group  mb-4">
                  <label className="form-label mb-0">Confirm Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword2"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    name="confirmPassword"
                    onChange={handleChange}
                  />
                  {error && confirmPassword_ === 'false' && <span className='errorMsg'>Confirm password is not matching with the password</span>}
                  {error && confirmPassword_ === '' && <span className='errorMsg'>Please enter password again.</span>}
                </div>
                <input type="checkbox" checked={agreed} onChange={setCheckbox} style={{width:"15px", marginTop:"-30px", position:"relative", top:"17px", marginRight:"10px"}}/>
                <label style={{ fontWeight: 100, display:"inline-block", fontSize:"15px" }}>
                  By signing up, I agree to Qiam’s &nbsp;
                  <span className="tnc">Terms of Service</span> and&nbsp;
                  <span className="tnc">Privacy Policy</span>
                </label>
                <br></br>
                <Button className="custom-button my-4" disabled={!agreed} onClick={signUp}>
                  Sign Up
                </Button>
              <p>Already Signed-up? Sign-in <Link to="/signin">here</Link></p>
                  
              </form>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <Modal
        size="lg"
        centered
        className="error-handling-modal"
        show={showSuccessFullPopup}
        onHide={() => setShowSuccessFullPopup(false)}
      >

        <Modal.Body>
          <div className="center-title">
            <br></br>
            <h3 style={{textAlign: 'center'}}> Sign Up is successful.Please Sign In</h3>
            <br></br>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Button className="custom-button" onClick={testFun}>Test</Button> */}
    </Container>
    </FormStyleWrapper>
  )
}

export default SignUp
