import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const DashboardStyleWrapper = styled.div`
        .accordion-header{
            .accordion-button{
                color: #000000;
                font-size: 18px;
                font-weight: 600;
                padding: 1.5rem 1.25rem;
            }
        }
        .accordion-body{
            padding: 1rem 0.5rem;
        }
        .accordion-item, .accordion-button{
            background-color: #e7f1ff !important;
            box-shadow: none !important;
        }
        h6{
            font-size: 14px;
        }
        .subscription{
            .box_{
                background-color: #ffffff;
                color: black;
                > div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    .currency{
                        position: absolute;
                        left: 0px;
                        font-weight: 600;
                    }
                    .valueText{
                        font-size: 38px;
                        font-weight: 600;
                        position: relative;
                        display: flex;
                        align-items: end;
                        .unit_{
                            position: absolute;
                            right: -30px;
                            width: 35px;
                            text-align: left;
                            padding-bottom: 5px;
                            font-size: 21px;
                            bottom: 3px;
                        }
                    }
                    .lbl{
                        font-size: 38px;
                        font-weight: 600;
                    }
                }
            }
        }
        .tenure{
            .box_{
                background-color: #ffffff;
                color: black;
                > div{
                    display: flex;
                    flex-direction: column;
                    .valueText{
                        font-size: 38px;
                        font-weight: 600;
                    }
                }
            }
        }
`;

export default WithDirection(DashboardStyleWrapper);
