import React, { useState, useEffect } from 'react'
import { config, customData } from './../../helpers/constants';
import DashboardStyleWrapper from './dashboard.style';
import { Accordion, Container, Row, Col } from 'react-bootstrap';
import Loading from '../../helpers/Loader/loader';
import { useSelector } from 'react-redux';
import apiManager from '../../helpers/apiManager';
import utility from '../../helpers/utility';


const url = config.url.API_URL;
const Dashboard = () => {
  const externalId = useSelector(state => state.app.externalId);
  const uid = useSelector(state => state.app.uid);
  const [loading, setLoading] = useState(false);
  const [spvSubscription, setSPVSubscription] = useState('');
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    totalAmount: '',
    verifiedAmount: '',
    pendingAmount: '',
    rejectedAmount: '',
    totalAmountUnit: '',
    verifiedAmountUnit: '',
    pendingAmountUnit: '',
    rejectedAmountUnit: '',
    totalNo: '',
    verifiedNo: '',
    pendingNo: '',
    rejectedNo: '',
    currency: customData.DEFAULT_CURRENCY
  })
  const [tenureInMonths, setTenureInMonths] = useState('');
  useEffect(() => {
    getSPVSubscription();
  }, [])

  const getSPVSubscription = () => {
    const data = { "SpvId": externalId };
    apiManager
      .getSPVSubscriptionList(data, uid)
      .then((response) => {
        const tempList = response.data.length > 0 ? response.data[0] : [];
        let totalSubNo = 0;
        let totalSubAmount = 0;
        tempList.SubscriptionInStages && tempList.SubscriptionInStages.map((sub) => {
          totalSubNo = totalSubNo + sub.NoOfSubscriptions;
          totalSubAmount = totalSubAmount + sub.SubscriptionAmount;
        })
        const receivedSub = tempList.SubscriptionInStages.find(sub => sub.Status === 4);
        const pendingSub = tempList.SubscriptionInStages.find(sub => sub.Status === 3);
        const approvedSub = tempList.SubscriptionInStages.find(sub => sub.Status === 5);
        const rejectedSub = tempList.SubscriptionInStages.find(sub => sub.Status === 2);
        setSubscriptionDetails({
          totalAmount: utility.getNumberUnit(totalSubAmount)?.number,
          pendingAmount: utility.getNumberUnit((receivedSub ? receivedSub.SubscriptionAmount : 0) + (pendingSub ? pendingSub.SubscriptionAmount : 0))?.number,
          verifiedAmount: utility.getNumberUnit(approvedSub ? approvedSub.SubscriptionAmount : 0)?.number,
          rejectedAmount: utility.getNumberUnit(rejectedSub ? rejectedSub.SubscriptionAmount : 0)?.number,
          totalAmountUnit: utility.getNumberUnit(totalSubAmount)?.unit,
          pendingAmountUnit: utility.getNumberUnit((receivedSub ? receivedSub.SubscriptionAmount : 0) + (pendingSub ? pendingSub.SubscriptionAmount : 0))?.unit,
          verifiedAmountUnit: utility.getNumberUnit(approvedSub ? approvedSub.SubscriptionAmount : 0)?.unit,
          rejectedAmountUnit: utility.getNumberUnit(rejectedSub ? rejectedSub.SubscriptionAmount : 0)?.unit, 
          totalNo: totalSubNo,
          pendingNo: (receivedSub ? receivedSub.NoOfSubscriptions : 0) + (pendingSub ? pendingSub.NoOfSubscriptions : 0),
          verifiedNo: approvedSub ? approvedSub.NoOfSubscriptions : 0,
          rejectedNo: rejectedSub ? rejectedSub.NoOfSubscriptions : 0,
          currency: tempList.Prospectus.IssuanceCurrency
        });
        setTenureInMonths(tempList.Prospectus.TenureInMonths);
        setSPVSubscription(tempList);
        setLoading(false);
        // localStorage.setItem('categories', JSON.stringify(response.data.data));
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  const { totalAmount, rejectedAmount, verifiedAmount, pendingAmount, totalAmountUnit, rejectedAmountUnit, verifiedAmountUnit, pendingAmountUnit, totalNo, rejectedNo, verifiedNo, pendingNo, currency } = subscriptionDetails;
   return (
    <DashboardStyleWrapper>
      {loading && <Loading />}
      <div className="container_">
        <div className="header_ text-start">
          <h4>Dashboard</h4>
        </div>
        <Accordion alwaysOpen>
          <Accordion.Item eventKey="0" className="mb-5 tenure">
            <Accordion.Header>Tenure</Accordion.Header>
            <Accordion.Body>
              <Container>
                <Row>
                  <Col xs={3} md={3}>
                    <div className="box_ p-2">
                      <h6>Total Tenure</h6>
                      <div className="pt-2">
                        <span className='valueText'>{tenureInMonths}</span>
                        <span className="lbl mb-2">Months</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={3} md={3}>
                    <div className="box_ p-2">
                      <h6>Tenure Completed</h6>
                      <div className="pt-2">
                        <span className='valueText'>0</span>
                        <span className="lbl mb-2">Months</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={3} md={3}>
                    <div className="box_ p-2">
                      <h6>Tenure To Go</h6>
                      <div className="pt-2">
                        <span className='valueText'>{tenureInMonths}</span>
                        <span className="lbl mb-2">Months</span>
                      </div>
                    </div>
                  </Col>
                  {/* <Col xs={3} md={3}>
                <div className="box_ p-2">
                  <h6>Total Tenure</h6>
                  <div className="pt-2">
                    <span className='valueText'>{tenureInMonths}</span>
                    <span className="lbl mb-2">Months</span>
                  </div>
                </div>
              </Col> */}
                </Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="subscription mb-5">
            <Accordion.Header>Subscription</Accordion.Header>
            <Accordion.Body>
              <Container>
                <Row>
                  <Col xs={3} md={3} className='px-2'>
                    <div className="box_ p-2 py-3 mb-3">
                      <h6>Received Subscriptions</h6>
                      <div className="py-2">
                        <span className='currency'>{currency}</span>
                        <span className='valueText px-2'>{totalAmount}<span className='unit_'>{totalAmountUnit}</span></span>
                        {/* <span className="lbl">M</span> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={3} md={3} className='px-2'>
                    <div className="box_ p-2 py-3 mb-3">
                      <h6>Subscriptions Approved</h6>
                      <div className="py-2">
                        <span className='currency'>{currency}</span>
                        <span className='valueText px-2'>{verifiedAmount}<span className='unit_'>{verifiedAmountUnit}</span></span>
                        {/* <span className="lbl">M</span> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={3} md={3} className='px-2'>
                    <div className="box_ p-2 py-3 mb-3">
                      <h6>Pending Verification</h6>
                      <div className="py-2">
                        <span className='currency'>{currency}</span>
                        <span className='valueText px-2'>{pendingAmount}<span className='unit_'>{pendingAmountUnit}</span></span>
                        {/* <span className="lbl">M</span> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={3} md={3} className='px-2'>
                    <div className="box_ p-2 py-3 mb-3">
                      <h6>Subscriptions Rejected</h6>
                      <div className="py-2">
                        <span className='currency'>{currency}</span>
                        <span className='valueText px-2'>{rejectedAmount}<span className='unit_'>{rejectedAmountUnit}</span></span>
                        {/* <span className="lbl">M</span> */}
                      </div>
                    </div>
                  </Col>

                  <Col xs={3} md={3} className='px-2'>
                    <div className="box_ p-2 py-3 mb-3">
                      <h6>Received Subscriptions</h6>
                      <div className="py-2">
                        <span className='valueText px-2'>{totalNo}</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={3} md={3} className='px-2'>
                    <div className="box_ p-2 py-3 mb-3">
                      <h6>Subscriptions Approved</h6>
                      <div className="py-2">
                        <span className='valueText px-2'>{verifiedNo}</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={3} md={3} className='px-2'>
                    <div className="box_ p-2 py-3 mb-3">
                      <h6>Pending Verification</h6>
                      <div className="py-2">
                        <span className='valueText px-2'>{pendingNo}</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={3} md={3} className='px-2'>
                    <div className="box_ p-2 py-3 mb-3">
                      <h6>Subscriptions Rejected</h6>
                      <div className="py-2">
                        <span className='valueText px-2'>{rejectedNo}</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </DashboardStyleWrapper>
  )

}
export default Dashboard;