import React, { lazy, Suspense } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import { PRIVATE_ROUTE } from '../helpers/routerConstants';
import ProfileForm from '../components/ProfileForm/profileForm';
import BlankPage from '../publicPages/blankPage';
import ErrorBoundary from '../ErrorBoundary';
import FilePreview from '../components/FilePreview/filePreview';

const routes = [
  {
    path: PRIVATE_ROUTE.BUSINESSPROFILE,
    exact: 'true',
    component: <ProfileForm/>,
  },
  {
    path: PRIVATE_ROUTE.COMMONDOCPREVIEW,
    exact: 'true',
    component: <FilePreview/>,
  },
];
export default function CommonRoutes() {
  return (
    <Suspense fallback={<ErrorBoundary />}>
    <Routes>
       {routes.map((route, idx) => (
          <Route key={idx} path={route.path} exact={route.exact} element={route.component} />
       )
       )}
    </Routes>
    </Suspense>
  );
}
