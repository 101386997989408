import React, { useState, useEffect } from 'react'
// import './requests.css'
import SukukCard from '../../components/SukukCard/spvSukukCard';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setApplicationId } from '../../actions';
import apiManager from '../../helpers/apiManager';
import Loading from '../../helpers/Loader/loader';
import FundsHistoryTable from '../../components/fundsHistory/fundsHistoryTable';
// import FundDisbursementStyleWrapper from './fundDisbursement.style';
import { Row, Col, Form, Button } from 'react-bootstrap';


const sponsorList = ['Ennovate'];
const sukukList = ['Dubai Capital Sukuk-1'];
const customerList = ['Dubai Capital FZCO'];
const scoreList = ['', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
const tableRecord = [{
  Criteria: 'Jurisdiction',
  score: '',
  ScoringRationale: 'High for jurisdiction where we currently operate / are comfortable with'
},
{
  Criteria: 'Legal Entity Type / Structure',
  score: '',
  ScoringRationale: 'Higher for corporate, lower for partnership, least for individual'
},
{
  Criteria: 'Vintage',
  score: '',
  ScoringRationale: 'Higher for more than 5 years in operation and 0 for new setup'
},
{
  Criteria: 'Product Revenue and Cost Isolation',
  score: '',
  ScoringRationale: 'High for higher ease of isolation of revenue / business line'
},
{
  Criteria: 'Nature of Capitalization to date',
  score: '',
  ScoringRationale: 'High for organized / institutional risk capital (VC), lower for self funded and unorganized funding sources.'
},
{
  Criteria: 'Digital Traceability',
  score: '',
  ScoringRationale: 'High for higher digital traceability of economic activity and vice versa'
},
{
  Criteria: 'Digital Asset Control and Influence',
  score: '',
  ScoringRationale: 'High for digital control, like crypto assets, low for SPV based control'
},
{
  Criteria: 'Clarity of Use of Funds',
  score: '',
  ScoringRationale: 'Rating of granular business plan for use of funds'
},
{
  Criteria: 'Profitability for the product / division seeking Sukuk',
  score: '',
  ScoringRationale: 'Higher for higher margin and low for lower margin'
},
{
  Criteria: 'Ongoing concerns with positive cash flow',
  score: '',
  ScoringRationale: 'Higher for healthy financials and lower otherwise'
},
{
  Criteria: 'Audited financial statements',
  score: '',
  ScoringRationale: 'Higher for availability of audited financials and 5 and below for poor data or quality'
},
{
  Criteria: 'Preference for Islamic Financing',
  score: '',
  ScoringRationale: '10 for Strong preference and 1 for neutral'
},
{
  Criteria: 'Basic understanding of blockchain',
  score: '',
  ScoringRationale: '10 for proficiency with good tech team'
},
{
  Criteria: 'Clear and realistic repayment plan',
  score: '',
  ScoringRationale: 'Rating based on quality / confidence of repayment plan'
},
{
  Criteria: 'Probability of repeat transactions',
  score: '',
  ScoringRationale: '10 for high chance of repeat Sukuk orders'
},
{
  Criteria: 'Realistic expectations of when funds are needed',
  score: '',
  ScoringRationale: '10 for within next 3 months'
},
{
  Criteria: 'Ability to pay fees',
  score: '',
  ScoringRationale: '10 for room for fees + readiness to pay'
}
]
const UserCaseToBeDone = () => {
  const externalId = useSelector(state => state.app.externalId);
  const uid = useSelector(state => state.app.uid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [scoreResult, setScoreResult] = useState(0);
  const [values, setValues] = useState({
    Sponsor: 'Ennovate',
    customer: 'Dubai Capital FZCO',
    sukuk: 'Dubai Capital Sukuk-1'
  });
  const [record, setRecord] = useState(tableRecord);
  const [buttonDisable, setButtonDisableStatus] = useState(false);
  useEffect(() => {
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }
  const handleScoreChange = (e, idx) => {
    const tempRecord = [...record];
    tempRecord[idx].score = e.target.value;
    setRecord(tempRecord);
    let scoreAvg = getAvgOfScores(tempRecord);
    // tempRecord.reduce((n, {score}) =>{
    //   console.log('n ==>', n);
    //   console.log('score ==>', Number(score));
    //   return(
    //    n + Number(score), 0
    // ) 
    //   });

    // scoreSum = (scoreSum/17).toFixed(1);
    setScoreResult(scoreAvg);
    const isFilled = Object.values(tempRecord).every(
      value => value
    );
    setButtonDisableStatus(isFilled);
  }
  const getAvgOfScores =(tempRecord) =>{
    let sumOfScores = 0;
    tempRecord.map(rec => {
      const tempScore = rec.score !== '' ? Number(rec.score) : 0;
      sumOfScores = sumOfScores + tempScore;
    })
    return (sumOfScores/tempRecord.length).toFixed(1);
  }
  const { customer, sukuk, Sponsor } = values;
  return (

    // <FundDisbursementStyleWrapper>
    <>
      {loading && <Loading />}
      <div className="container_">
        <div className="header_ text-start">
          <h4>User Case Consideration</h4>
        </div>
        <div className="summaryRow mb-3">
          <Form>
            <Row className="mb-4 align-items-center">
              <Col>
                <Row className="align-items-center">
                  <Col className='text-end' md={3}>
                    <label style={{fontSize: 20}}>
                      Date:
                    </label>
                  </Col>
                  <Col md={9}>
                    <p className='text-start mb-0'>20-Apr-2022</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Group as={Row} className="mb-4">
                  <Form.Label className='text-end' as={Col} md={3} >Sponsor:</Form.Label>
                  <Col
                    md={9}>
                      <Form.Select
                      name="Sponsor"
                      onChange={handleChange}
                      value={Sponsor}>
                      {sponsorList.length > 0 && sponsorList.map((sponsor, id) => (
                        <option key={id} value={sponsor}>{sponsor}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Group as={Row} className="mb-4">
                  <Form.Label as={Col} md={3} className='text-end' >Customer:</Form.Label>
                  <Col
                    md={9}><Form.Select
                      name="customer"
                      onChange={handleChange}
                      value={customer}>
                      {customerList.length > 0 && customerList.map((cust, index) => (
                        <option key={index} value={cust}>{cust}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Row} className="mb-4">
                  <Form.Label as={Col} md={3} className='text-end'>Sukuk:</Form.Label> <Col
                    md={9}>
                    <Form.Select
                      name="sukuk"
                      onChange={handleChange}
                      value={sukuk}>
                      {sukukList.length > 0 && sukukList.map((suk, idx) => (
                        <option key={idx} value={suk}>{suk}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
        <div className='table_'>
        <table className="w-100">
        <tr style={{borderBottom: '1px solid #949391'}}>
            <th className='text-end px-3 py-3' style={{width: '38%'}}>Criteria</th>
            <th className='text-center px-3 py-3' style={{width: 120}}>Score</th>
            <th className='text-start px-3 py-3' style={{width: '52%'}}>Scoring Rationale</th>
            </tr>
            {record.length > 0 && record.map((record_,index) =>{
            return(
              <tr>
              <td className='px-3 py-3 text-end'>{record_.Criteria}</td>
              <td className='px-3 py-3'>
                <Form.Select
                      name="score"
                      required
                      onChange={(e) => handleScoreChange(e, index)}
                      value={record_.score}>
                      {scoreList.length > 0 && scoreList.map((score, id) => (
                        <option key={score} value={score}>{score}</option>
                      ))}
                </Form.Select></td>
              <td className='px-3 py-3 text-start'>{record_.ScoringRationale}</td>
            </tr>
            )
            })}
            </table>
            <div className='resultWrapper'>
              <div className='d-flex align-items-center mb-3'><label className="text-end" style={{width: ' 37%'}}><b>Overall Score</b></label>
              <p className="result mx-5 mb-0"><b>{scoreResult}</b></p>
              </div>
              <div className='d-flex align-items-center mb-3'>
              <label className="text-end" style={{width: ' 37%'}}><b>Status</b></label> 
              <p className="result mx-5 mb-0"><b>In Progress</b></p>
              </div>
              <Row>
                <Col className="text-end"><Button className="custom-button">Save</Button></Col>
                <Col className="text-start"><Button className="custom-button" disabled={!buttonDisable}>Submit</Button></Col>
              </Row>
            </div>
          {/* <FundsHistoryTable parent="SPV"/> */}
        </div>
      </div>
      {/* // </FundDisbursementStyleWrapper> */}
    </>
  )
}

export default UserCaseToBeDone;