import React, { lazy, Suspense } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import { PRIVATE_ROUTE } from '../../helpers/routerConstants';
import NewSukuk from './newSukukRequest';
import ProfileForm from '../../components/ProfileForm/profileForm';
import BlankPage from '../../publicPages/blankPage';
import ErrorBoundary from '../../ErrorBoundary';
import PendingSukukUpdates from './pendingSukukUpdates';
import SukukInfo from './../../components/NewSukuk/sukukForm';
import PendingSukukApprovals from './pendingSukukApprovals';
import PendingApprovalSukukInfo from './sukukPendingApprovalInfo';
import CreateSPV from './createSPV';
import SPVInfo from './spvInfo';
import SavedSukukRequests from './savedSukukRequests';
import FundsRequests from './fundRequests';
import FundRequestInfo from  './fundRequestForm';
import Dashboard from './Dashboard';
import CreateProspectus from './Prospectus/createProspectus';
import FinanceData from './financeData';
import ProcessData from './processData';


const routes = [
  {
    path: PRIVATE_ROUTE.DASHBOARD,
    exact: 'true',
    component: <Dashboard/>,
  },
  {
    path: PRIVATE_ROUTE.NEWSUKUKREQUEST,
    exact: 'true',
    component: <NewSukuk/>,
  },
  {
    path: PRIVATE_ROUTE.SAVEDSUKUKREQUESTS,
    exact: 'true',
    component: <SavedSukukRequests/>,
  },
  {
    path: PRIVATE_ROUTE.BUSINESSPROFILE,
    exact: 'true',
    component: <ProfileForm/>,
  },
  {
    path: PRIVATE_ROUTE.SUKUKPENDINGAPPROVALS,
    exact: 'true',
    component: <PendingSukukApprovals/>,
  },
  {
    path: PRIVATE_ROUTE.SUKUKPENDINGUPDATES,
    exact: 'true',
    component: <PendingSukukUpdates/>,
  },
  
  {
    path: PRIVATE_ROUTE.STRUCTURINGAPPROVALINFO,
    exact: 'true',
    component: <SukukInfo/>,
  },
  {
    path: PRIVATE_ROUTE.SAVEDSUKUKREQUESTINFO,
    exact: 'true',
    component: <NewSukuk/>,
  },
  {
    path: PRIVATE_ROUTE.SUKUKPENDINGAPPROVALINFO,
    exact: 'true',
    component: <PendingApprovalSukukInfo/>,
  },{
    path: PRIVATE_ROUTE.SPVINITIATION,
    exact: 'true',
    component: <CreateSPV/>,
  },{
    path: PRIVATE_ROUTE.FUNDSREQUESTS,
    exact: 'true',
    component: <FundsRequests/>,
  },{
    path: PRIVATE_ROUTE.FUNDSREQUESTSINFO,
    exact: 'true',
    component: <FundRequestInfo/>,
  },
  {
    path: PRIVATE_ROUTE.SPVINITIATIONINFO,
    exact: 'true',
    component: <SPVInfo/>,
  },{
    path: PRIVATE_ROUTE.CREATEPROSPECTUS,
    exact: 'true',
    component: <CreateProspectus/>,
  },{
    path: PRIVATE_ROUTE.FINANCEDATA,
    exact: 'true',
    component: <FinanceData/>,
  },{
    path: PRIVATE_ROUTE.PROCESSDATA,
    exact: 'true',
    component: <ProcessData/>,
  },
];
export default function BusinessRoutes() {
  return (
    <Suspense fallback={<ErrorBoundary />}>
    <Routes>
       {routes.map((route, idx) => (
          <Route key={idx} path={route.path} exact={route.exact} element={route.component} />
       )
       )}
    </Routes>
    </Suspense>
  );
}
