import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Tabs, Tab, Modal, OverlayTrigger, Tooltip, Dropdown, Button  } from 'react-bootstrap';
import Documents from '../../components/sukukTabComponents/documents';
import Info from '../../components/sukukTabComponents/Info';
import apiManager from '../../helpers/apiManager';
import Loading from '../../helpers/Loader/loader';
import { useSelector } from 'react-redux';
import OtherInfo from './otherInfo';
import FormStyleWrapper from './../../components/NewSukuk/form.style';
import {customData} from './../../helpers/constants';
// import FundRequestFormStyleWrapper from './fundRequestForm.style';
import FundsHistoryTableStyleWrapper from './../../components/fundsHistory/fundsHistoryTable.style';
import { FcCheckmark } from 'react-icons/fc';
import {PRIVATE_ROUTE} from '../../helpers/routerConstants'
import menuOption from '../../helpers/images/assets/VerticalDotsMenu-Grey.svg';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

import axios from 'axios';
import { config } from './../../helpers/constants';

import { AiOutlineInfoCircle } from 'react-icons/ai'
import FundsHistoryTable from '../../components/fundsHistory/fundsHistoryTable';
import { useNavigate } from 'react-router';
import utility from '../../helpers/utility';


const url = config.url.API_URL;

const companies = ['Equity Address', 'XYZ Corporation', 'Dubi Capital Privarte LImited'];

let intervalToken;
const ProcessData = () => {
  const externalId = useSelector(state => state.app.externalId);
  const applicationName= useSelector(state => state.app.applicationName);
  const uid = useSelector(state => state.app.uid);
  const [records, setRecords] = useState([]);
  const [completedServiceFee, setCompletedServiceFee] = useState(0);
  const [pendingPaymentServiceFee, setPendingPaymentServiceFee] = useState();
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [successPopup, setSuccessPopup] =useState(false);
  const [airTableUrl, setAirTableUrl] = useState('');
  const [completedRecords, setCompletedRecords] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');

  useEffect(() =>{
    return () => clearInterval(intervalToken);
  },[])
  
  const handleChange = (e) =>{
    const {name, value} = e.target;
    setSelectedCompany(value);
    setLoader(true);
    fetchRecords();
     intervalToken = setInterval(() => fetchRecords(), 3000);
  }
  useEffect(() =>{
    apiManager.getBlockChainUrl()
    .then((response) => {
      let blockChainUrl = '';
      if(response.fields.useAirtable === 'yes'){
        blockChainUrl = response.fields.airtableUrl;
    }
    else{
        blockChainUrl = response.fields.firebaseURL;
    }
    setAirTableUrl(blockChainUrl);
  });
  },[])
  const fetchRecords = () =>{
    apiManager.getCompanyRecords()
    .then((res) => {
      setLoader(false);
      let sortedRecord = res.records.sort((prev, next) => (new Date(next.fields.StartDate)).getTime() - (new Date(prev.fields.StartDate)).getTime() );
      sortedRecord = sortedRecord.filter(record => record.fields.Status !== 'Completed');
      setRecords(sortedRecord);
      // const tempCompletedRecords = res.records.filter((record) => { if(record.fields.Status === 'Completed' && checkIfTodaysDate(record.fields.StartDate)) return {id: record.id}});
      // console.log('comprecords: ', tempCompletedRecords);
      const comp = res.records.reduce((total, record) => (record.fields.Status === 'Completed') ? record.fields.ServiceFee + total : total, 0);
      setCompletedServiceFee(comp);
      // const pendingPayments = res.records.reduce((total, record) => record.fields.Status !== 'Completed' ? record.fields.ServiceFee + total : total, 0);
      // setPendingPaymentServiceFee(pendingPayments);
    })
    .catch((err) =>{
      setLoader(false);
    })
  }
  const checkIfTodaysDate=(date)=>{
    let date1 = new Date(date);
    date1.setHours(0, 0, 0, 0);
    let date2 = new Date();
    date2.setHours(0, 0, 0, 0);
    if(date1 > date2 || date1 < date2){
      return false;
    }
    else{
      return true;
    }
  }
  const callNodeToAddBusinessData = () =>{
    setLoadingPopup(true);
    const today = utility.getFormattedDate(new Date());
    const data ={
      externalId: externalId, 
      date: today,
      salesAmount: completedServiceFee
    }
    apiManager.addBusinessData(data, airTableUrl)
    .then((response) =>{
      setLoadingPopup(false);
      setSuccessPopup(true);
      console.log('data hash: ', response);
    })
    .catch((error) =>{
      setLoadingPopup(false);
      console.log(error);
    })
  }
  return (
    <FundsHistoryTableStyleWrapper>
    <FormStyleWrapper>
    {loader && <Loading/> }
      <div className="fundWrapper">
        <div className="header_ text-start" style={{color : 'black'}}>
          <div className='d-flex flex-column justify-content-between align-items-center'>
          <h4 className="w-100 text-start">Process Data</h4>
          <Form.Group className="mb-4 position-relative d-flex align-items-center">
            <Form.Label className="mx-3 my-0">Select Company</Form.Label>
            <Form.Select style={{margin : '0 auto', width: '400px'}} name="companies" placeholder="Your Email Id" value={selectedCompany} onChange={handleChange} >
                      <option value={''} disabled></option>
                {companies.map(company => {
                    return(
                      <option value={company}>{company}</option>
                    )
                })}
            </Form.Select>
          </Form.Group>
          <div>
            {/* <label className='mx-3 my-2' style={{fontSize: 18}}>Monthly Sales: <b>{completedServiceFee ? records[0]?.fields?.Currency : '' +' '+utility.getNumberUnit(completedServiceFee).number + utility.getNumberUnit(completedServiceFee).unit}</b></label> */}
            {/* <label className='mx-3' style={{fontSize: 18}}>Pending Payment: <b>{records[0]?.fields?.Currency +' '+utility.getNumberUnit(pendingPaymentServiceFee).number + utility.getNumberUnit(pendingPaymentServiceFee).unit}</b></label> */}
          </div>
          <div style={{width: 200}}></div>
          {/* <button className="custom-button my-3" onClick={callNodeToAddBusinessData} >Provenance</button> */}
          </div>
          <div style={{overflow: 'auto'}}>
          <table style={{width: 'max-content', minWidth: '100%'}}>
        <tr>
            <th>DP1</th>
            <th>DP2</th>
            <th>DP3</th> 
            <th>DP4</th>
            <th>DP5</th>
            {/* <th style={{textAlign: 'right'}}>Currency</th>
            <th>Service Fee</th> */}
            <th>DP6</th>
        </tr>
        {records && records.length > 0 && records.map((record) =>{
          return(
        <tr id={record.id}>
            <td>{record.fields.CustomerName}</td>
            <td>{record.fields.StartDate}</td>
            <td>{record.fields.Stage}</td>
            <td><span className={`${record.fields.Status === 'Completed' ? 'highlightedBg px-2 py-1 rounded' : 'py-1' }`}>{record.fields.Status}</span></td>
            <td>{record.fields.PaymentDate}</td>
            {/* <td style={{textAlign: 'right'}}>{record.fields.Currency}</td>
            <td>{record.fields.ServiceFee}</td> */}
            <td>{record.fields.TxnHash}</td>
        </tr>
          )}
        )}
          </table>
          </div>
        </div>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={loadingPopup}
          onHide={() => setLoadingPopup(false)}
        >
          {/* Error Modal  */}
  
          <Modal.Body>
            <div className="text-center">
              <br></br>
              <Loading className="light" top={20}/>
              <br></br>
              <br></br>
              <br></br>
              <h3>Sales Data Provenance in Progress</h3>
              <br></br>
              <span>This may take a while. Please wait...</span>
              <br></br>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="error-handling-modal"
          show={successPopup}
          onHide={() => setSuccessPopup(false)}
        >
          {/* Error Modal  */}
  
          <Modal.Body>
            <div className="text-center">
              <br></br>
              {/* <Loading className="light" top={20}/> */}
              <span className='tick check_'><FcCheckmark /></span>
              <br></br>
              <br></br>
              <br></br>
              <h3>Data Provenance Complete!</h3>
              <br></br>
              <span></span>
              <br></br>
              <br></br>
              {/* <Button className="custom-button" onClick={() => setModalInfoShow(false)}>
                No
              </Button>
              <Link to="/partner/ddp/dashboard">
                <Button className="custom-button">Yes</Button>
              </Link> */}
            </div>
          </Modal.Body>
        </Modal>
        </div>
        </FormStyleWrapper>
        </FundsHistoryTableStyleWrapper>
  )}
  export default ProcessData;