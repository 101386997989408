import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const FundDisbursementStyleWrapper = styled.div`
    .summaryRow{
        .box_{
            border-color: #949391 !important;
        }
        label{
            font-size: 14px;
        }
        p{
            font-weight: 600;
            span{
                color: grey;
            }
            &.red{
                color: red;
            }
            &.green{
                color: green;
            }
        }
    }
    .form_{
        max-width: 1024px;
        p{
            font-weight: 600;
        }
        .icon_ img{
            width:30px;
        }
    }
`;

export default WithDirection(FundDisbursementStyleWrapper);
