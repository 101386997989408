import {BrowserRouter,Routes, Route} from 'react-router-dom';
import AppRoutes from './router';
// import './../public/avenue.ttf';
import { APPCOLORS } from './helpers/constants';
import { ThemeProvider } from 'styled-components';

const App = () => {
  return (
    <ThemeProvider theme={{ colors: APPCOLORS }}>
    <div className="App">
        <AppRoutes/>
    </div>
    </ThemeProvider>
  );
}

export default App;
