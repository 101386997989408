
import styled from 'styled-components';
import { APPCOLORS } from '../../helpers/constants';
import WithDirection from '../../helpers/rtl';

const FundRequestFormStyleWrapper = styled.div`
    .fundWrapper{
        .inputWrapper{
          width: calc(100% - 95px);
        }
        .sukuk-document-input{
          min-width: 110px;
        }
        // .was-validated :invalid~div .invalid-feedback{
        //   display: block;
        // }
    }
      `;

export default WithDirection(FundRequestFormStyleWrapper);