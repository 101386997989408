import React, { useState, useEffect } from 'react'
// import './requests.css'
import SukukCard from '../../components/SukukCard/spvSukukCard';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setApplicationId } from '../../actions';
import apiManager from '../../helpers/apiManager';
import Loading from '../../helpers/Loader/loader';

const SukukList = () => {
  const externalId = useSelector(state => state.app.externalId);
  const uid = useSelector(state => state.app.uid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [sukukList, setSukukList] = useState([]);


  useEffect(() => {
    getSukukList();
  }, []);
  const getSukukList = () => {
    const data = { "SpvId": externalId };
    // if(sukukStatus === 'listed'){
    apiManager
      .getSukukListed(data, uid)
      .then((response) => {
        const tempSukukList = response.data.length > 0 ? response.data.filter(application => application.CurrentStatus !== 1) : [];
        setSukukList(tempSukukList);
        setLoading(false);
        // localStorage.setItem('categories', JSON.stringify(response.data.data));
      })
      .catch((error) => {
        setServerError(true);
        setLoading(false);
      });
    // else{
    // apiManager
    //         .getSukukListforSPVs(data, uid)
    //         .then((response) => {
    //           const tempSukukList = response.data.length > 0 ? response.data.filter(application => application.CurrentStatus !== 1) : [];
    //           setSukukList(tempSukukList);
    //           setLoading(false);
    //           // localStorage.setItem('categories', JSON.stringify(response.data.data));
    //         })
    //         .catch((error) => {
    //           setServerError(true);
    //           setLoading(false);
    //         });
    // }

  }
  const details = {
    SubmittedOn: '05/Mar/2022',
    ApplicationName: 'Ennovate LLC',
    Account: 'Pied Piper Private Limited',
    size: '$1,000,000,000',
    CompanyInfo: { Country: 'Dubai, UAE' },
    tenure: '40 months'
  }


  const gotoInfo = (id, app) => {
    navigate("info", { state: { app: app } });
    dispatch(setApplicationId(id));
  }
  const updateFunction = (id, status) => {
    if (status === 'Active') {
      const data = { "ApplicationId": id };
      setLoading(true);
      apiManager
        .setSukukToListed(data, uid)
        .then((response) => {
          //   const tempSukukList = response.data.length > 0 && response.data.filter(application => application.CurrentStatus !== 1);
          //   setSukukList(tempSukukList);
          setLoading(false);
          getSukukList();
          //  setModalShow(true);
          setTimeout(() => {
            // setModalShow(false);
          })
          // localStorage.setItem('categories', JSON.stringify(response.data.data));
        })
        .catch((error) => {
          //   setServerError(true);
          setLoading(false);
        });
    }
  }
  return (

    <>
      {loading && <Loading />}
      <div className="container_">
        <div className="header_ text-start">
          <h4>Sukuk Details</h4>
        </div>
        {sukukList.length > 0 && sukukList.map(app => {
          const { Application, Customer } = app;
          const info = {
            SubmittedOn: Application.SubmissionInfo.SubmittedDate ? Application.SubmissionInfo.SubmittedDate : Application.ApplicationDate,
            ApplicationName: Application.ApplicationName,
            Account: Customer.Name ? Customer.Name : '',
            CompanyInfo: Customer ? Customer.Address : {},
            IssuanceSize: Application.ApplicationInfo.SukukIssuanceSize ? Application.ApplicationInfo.SukukIssuanceSize : '',
            TenureInMonths: Application.ApplicationInfo.SukukTenureInMonths ? Application.ApplicationInfo.SukukTenureInMonths : '',
            status: app.Status,
            Currency: Application.ApplicationInfo.SukukIssuanceCurrency ? Application.ApplicationInfo.SukukIssuanceCurrency : '',
          }
          return (
            <div style={{ marginTop: 50 }} >
              <SukukCard info={info} updateFunction={() => updateFunction(Application.ApplicationId, app.Status)} />
            </div>
          )
        })}
        {sukukList.length === 0 && <div className="noDataText">There are no Requests</div>}
      </div>
    </>
  )
}

export default SukukList;