import {Card} from 'react-bootstrap';
// import './simpleCard.css';
import SimpleCardStyleWrapper from './simpleCard.style';

const CustomCard = (data) => {
    return(
      <SimpleCardStyleWrapper>
        <Card className="side-card mt-2">
        <Card.Body>
          <Card.Title>{data.title}</Card.Title>
          <Card.Text>{data.info}</Card.Text>
        </Card.Body>
      </Card>
      </SimpleCardStyleWrapper>
    )
}
export default CustomCard;